import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { Constant } from '../../constant';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    
    constructor(private authService: AuthService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        let token = this.authService.getToken();

        if (token) {
            if (request.url.indexOf('expert-24') < 1) {

                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        SourceApp: Constant.AIProwSource
                    },
                });
            }
        }

        return next.handle(request);
    }
}
