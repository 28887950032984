<div>
   <div>
       <span>
               By giving us a little more information,
               you can find which benefits are available with your Medicare Advantage plan.
               We can also help you look for local community resources.
       </span>
   </div>

   <div>
       <div>
           <div style="padding-top: .8em;" class="section-heading">
            <strong>Medicare Advantage Benefits Look up</strong>
           </div>
               <div style="padding-top: .8em;">
                     <div>
                        <span class="step-color">Step 1:</span>  Enter your zip code &nbsp; <input type="text" title="search" pInputText [(ngModel)]="planZipCode" placeholder="Enter a zip code"  maxlength="5" onlynumber>&nbsp;<button pButton  type="button" label="Search For Plans" (click)="searchForPlans()"></button>
                            <div class="warning" style="padding-top: .8em;" *ngIf="!isPlanZipCodeValid()">
                                Please enter a valid zip code
                            </div>
                    </div>
               </div>
               <div class="p-grid" style="padding-top: .8em;">
                    <div class="p-col-6" >
                        <span class="step-color">Step 2 (optional):</span> To see all benefits available for the zip code you entered, pick the Medicare Advantage Plan from the list using the arrow below. Then, click the Search button.
                    </div>
                    <div class="p-col-6">
                        <label>Plan(s):</label>&nbsp;&nbsp;&nbsp;<p-multiSelect [options]="plansList" [(ngModel)]="selectedPlans" [showToggleAll]="false"
                        defaultLabel="Select one or multiple and then select search" optionLabel="name" optionValue="id"
                        styleClass="p-plans-multi TextFont"
                        emptyMessage="No plans found for this zip code. Please enter a new zip code." [style]="{'width':'400px'}" [panelStyle]="{'width':'400px'}" display="chip">
                        </p-multiSelect>
                    </div>
                </div>
                <div class="p-grid" style="padding-top: .8em;">					   
                    <div class="p-col-6">   
                        <span class="step-color" >Step 3 (optional):</span> Would you like to check for a specific benefit or item? Please enter a keyword (like vision, hearing, home health, etc.) and then click the Search button to see if there are any results.
                    </div>
                    <div class="p-col-6">
                        <label>Keyword(s):</label>&nbsp;<span><input type="text" [(ngModel)]="keywords" size="40" pInputText placeholder="Keyword Search" ></span>
                    </div>
                </div>  

                   <div>
                       <div class="d-grid gap-2 d-md-flex justify-content-md-center mt-4">
                            <button pButton  type="button" label="Clear" (click)="resetPlanForm()"></button>
                            <button pButton  type="button" label="Search" [disabled]="!isPlanZipCodeValid()" (click)="submitPlanForm()"></button>
                       </div>
                   </div>
                
                <div style="padding-top: .8em;">	
                    <hr style="height:2px;border-width:0;margin: 0px;padding: 0px;color:gray;background-color:gray">
                </div>       

                <div *ngIf="showPlanTab" style="padding-top: .8em;" id="containerdiv">
                    <div id="benefits-nav" [ngClass]="isTableFixed ? 'table-header' : 'table-headerfixed'">
                        <div id="infinite-table" class="table-container" (scroll)="loadBenefits($event)"
                             [ngClass]="[isTableFixed ? 'custom-table-size' : '', isTableVarie ? 'default-table-size' : '', isTableEmpty ? 'b_table_empty' : '']">
                             <div id = "header-container">
                            <table id="infinite-table-header" class="table-bordered tbl-fix-header" style="border-top: 0 solid black;">
                                <thead style="background-color: rgb(220, 220, 220);  border-top: 0 solid black;">
                                    <tr style="border-top: 0 solid black;">
                                        <th *ngFor="let col of planBenefitColumns" style="border-top: 0 solid black;">{{col.header}}</th>
                                    </tr>
                                </thead>
                                </table>
                            </div>
                                <table id = "infinite-table-body">
                                <tbody>
                                    <tr *ngFor="let planBenefit of planBenefitsList; let i = index" [ngClass]="{'white-row': i % 2 === 0, 'gray-row': i % 2 !== 0}">
                                        <td>{{planBenefit.planName}}</td>
                                        <td>{{planBenefit.benefitName}}</td>
                                        <td *ngIf="!planBenefit.ishyperlink">{{planBenefit.coverage}}</td>
                                        <td *ngIf="planBenefit.ishyperlink">
                                            <a href={{planBenefit.coverage}} target="_blank" rel="noopener noreferrer">
                                                {{cleanUrl(planBenefit.coverage)}}
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                
                    <div *ngIf="isTableEmpty">
                        <p style="color:red; text-align: center;">No Records Found</p>
                    </div>
                </div>
                
               <div style="padding-top: 1.2em;">
                    Your Medicare Advantage Plan Benefits<br>
                    <span><strong>IMPORTANT NOTE:</strong></span>&nbsp;<span >
                           Benefits may change over time and this information may no longer be current. Some
                           benefits may require qualification (proof of need), deductible, co-pay, authorization, referral
                           co-insurance, or have a limit on the item or service. Please confirm your benefits with your Medicare
                           Advantage plan.
                       </span>
               </div>
       </div>
       <div style="padding-top: .8em;">	
             <hr style="height:2px;border-width:0;margin: 0px;padding: 0px;color:gray;background-color:gray">
       </div>     
       <div>
           <div style="padding-top: .8em;" class="section-heading">
            <strong>Local Resources</strong>
           </div>
           <div style="padding-top: .8em;" >
                   <div>
                       <div>
                           <div>
                                   You may also find local resources that provide items or services at lower cost
                                   or no cost. Some items and services may require qualification (proof of need). Contact one or more of
                                   these services to learn more.
                           </div>
                           <div style="padding-top: .8em;">
                              <span class="step-color" >Step 4:</span> Find local resources by zip code (if needed):
                               <input type="text" pInputText [(ngModel)]="localResourcesZipCode" placeholder="Enter Zip Code" onlynumber>&nbsp;
                               <button pButton  type="button" label="Clear" (click)="resetResourcesForm()"></button>&nbsp;
                               <button pButton  type="button" label="Search"  [disabled]="!isLocalResourcesZipCodeValid()" (click)="submitResourcesForm()"></button>   
                               <img class="b_copyright_logo" src="./../../../assets/images/Copyrightsymbol.png">
                               <img class="b_elder_logo" src="./../../../assets/images/Eldercare-logo.png">
                                                       
                           </div>
                           <div class="warning" style="padding-top: .8em;" *ngIf="!isLocalResourcesZipCodeValid()">
                            Please enter a valid zip code
                        </div>
                       </div>
                   </div>
           </div>

           <div style="padding-top: .8em;">
                 <p-table id="localResourceTable" #localResourceTable [value]="localResourceList" 
                 [responsive]="true"responsiveLayout="stack" 
                   [scrollable]="true" [resizableColumns]="true" [scrollHeight]="getTableHeight()" 
                   styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm resourceTable" >
                       <ng-template pTemplate="header">
                           <tr >
                               <th  style="text-align: center !important;font-size: 18px !important;">Service</th>
                               <th  style="text-align: center !important;font-size: 18px !important">Contact Details</th>

                           </tr>
                       </ng-template>
                       <ng-template pTemplate="body" let-resource>
                           <tr>
                               <td>{{resource.serviceName}}</td>

                               <td>
                                   <span><span style="font-weight:bold">Address:&nbsp;</span>{{resource.address}}</span><br>
                                   <span><span style="font-weight:bold">Agency:&nbsp;</span>{{resource.agencyName}}</span><br>
                                   <span>
                                       <span style="font-weight:bold">Website:&nbsp;</span><a href="{{resource.website}}"
                                                                                              target="_blank" rel="noopener noreferrer">{{cleanUrl(resource.website)}}</a>
                                   </span><br>
                                   <span><span style="font-weight:bold">Phone Number:&nbsp;</span>{{resource.phoneNumber}}</span>
                               </td>
                           </tr>
                       </ng-template>
                   </p-table>
           </div>
           <div>
               <span><strong>Disclaimer:</strong> You are about to select an external link. The Aging in Place program is not responsible for the accuracy, legality, or availability of content on external links or for that of subsequent links.</span>
           </div>

       </div>
   </div>
</div>
