import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InputTextModule }    from 'primeng/inputtext';
import { ButtonModule }       from 'primeng/button';
import { DropdownModule }     from 'primeng/dropdown';
import { TableModule }        from 'primeng/table';
import { CheckboxModule }     from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule }     from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AssessmentSectionComponent } from './assessment-section.component';
import { AssessmentWrapperModule } from './assessment-wrapper/assessment-wrapper.module';
import { AssessmentPrintModule } from './assessment-print/assessment-print.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    
    AssessmentWrapperModule, AssessmentPrintModule,

    TableModule, InputTextModule, ButtonModule, DropdownModule, OverlayPanelModule, CalendarModule, CheckboxModule, ConfirmDialogModule
  ],
  declarations: [AssessmentSectionComponent],
  providers: [AssessmentSectionComponent],
  exports: [AssessmentSectionComponent]  
})
export class AssessmentSectionModule {}
