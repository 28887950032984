<div>
    <div class="row">
        body row
    </div>
    <div class="row">
        body row
    </div>
    <div class="hr-divider"></div>

    <div class="row">
        <div [formGroup]="sectionForm" *ngIf="sectionForm !== null && sectionForm !== undefined">
            <div class="p-col-12">
                <div for="field1" class="field-is-required">
                    Field 1
                </div>
                <input pInputText id="field1" type="text" size="40" maxlength="40" formControlName="field1"/>
                <div *ngIf="(!saveClicked && field1Control.touched && !field1Control.valid) || (saveClicked && !field1Control.valid)">
                    <div *ngIf="field1Control && field1Control.errors && field1Control.errors.required" class="field-is-required">
                        Field 1 is required.
                    </div>
                </div>
            </div>

            <div class="p-col-12">
                <div for="field2" class="field-is-required">
                    Field 2
                </div>
                <input pInputText id="field2" type="text" size="40" maxlength="40" formControlName="field2"/>
                <div *ngIf="(!saveClicked && field2Control.touched && !field2Control.valid) || (saveClicked && !field2Control.valid)">
                    <div *ngIf="field2Control && field2Control.errors && field2Control.errors.required" class="field-is-required">
                        Field 2 is required.
                    </div>
                </div>
            </div>

            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                <button class="btn button-1" type="button" (click)="addNew()">Add New</button>
                <button class="btn button-2" type="button" (click)="looksGood()">Looks Good</button>
            </div>
        </div>
    </div>
</div>