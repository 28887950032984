
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { CarePlanPersistInstructionActivity, CarePlanProfileDto, SaveCarePlanDto, PersistCarePlanGoalResponseDto  } from './goal-detail.dtos';
import { CarePlanGoalDetailsDto } from '../careplan-section.dtos';

@Injectable()
export class GoalDetailService {

    private getCarePlanGoalDetailsUrl: string = '/MemberCarePlan/GetMemberCarePlanGoalDetails?memberCarePlanGoalId={memberGoalId}';
    private getCarePlanProfileUrl: string = '/MemberCarePlan/GetCarePlanProfile';
    private persistCarePlanGoalUrl: string = '/MemberCarePlan/PersistMemberCarePlanGoal';
    private downloadEducationDocumentUrl: string = '/MemberCarePlan/DownloadEducationDocument/{memberCarePlanGoalBarrierInterventionId}/{documentId}';
    private persistInstructionActivityUrl: string = '/MemberCarePlan/PersistInstructionActivity';
    
    private headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {

        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');

        environment.contentServiceApiBaseUrl = _appConfig.contentServiceApiBaseUrl;
    }

    public getGoalDetailData(memberCarePlanGoalId: string): Observable<CarePlanGoalDetailsDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getCarePlanGoalDetailsUrl).replace('{memberGoalId}', memberCarePlanGoalId);

        return this._http.get<CarePlanGoalDetailsDto[]>(url, {headers: this.headers});
    }

    public getCarePlanProfile(): Observable<CarePlanProfileDto> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getCarePlanProfileUrl);

        return this._http.get<CarePlanProfileDto>(url, {headers: this.headers});
    }

    public persistCarePlanGoal(saveDto: SaveCarePlanDto): Observable<PersistCarePlanGoalResponseDto> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + this.persistCarePlanGoalUrl);

        return this._http.post<PersistCarePlanGoalResponseDto>(url, saveDto, {headers: this.headers});
    }

    public downloadEducationDocument(memberCarePlanGoalBarrierInterventionId: string, documentId: string) : Observable<any> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.downloadEducationDocumentUrl)
            .replace('{memberCarePlanGoalBarrierInterventionId}', memberCarePlanGoalBarrierInterventionId)
            .replace('{documentId}', documentId);

        return this._http.get(url, {responseType: 'blob'});
    }

    public persistInstructionActivity(memberCarePlanGoalBarrierInterventionId: string, instructionId: string) : Observable<any> {

        let activityDto: CarePlanPersistInstructionActivity = new CarePlanPersistInstructionActivity();
        activityDto.memberCarePlanGoalBarrierInterventionId = memberCarePlanGoalBarrierInterventionId;
        activityDto.instructionId = instructionId;

        let url = (this._appConfig.memberManagerApiBaseUrl + this.persistInstructionActivityUrl);

        return this._http.post<any>(url, activityDto, {headers: this.headers});
    }
}