export class PortalUser {
    id?: string = "";
    emailAddress: string = "";
    firstName: string = "";
    lastName: string = "";
    passwordHash: string = "";
    passwordSalt: string = "";
    isActive: boolean = false;
    isPasswordReset: boolean = false;
    isLocked: boolean = false;
    code:string="";
    password: string = "";
    confirmPassword: string = "";
    acceptTerms:boolean=false;
    phoneNumber:string;
    userMfas:UserMfas;
    token:string;
    constructor() { 
        this.userMfas = new UserMfas(); 
     }
    edit:boolean=false;
}
export class UserMfas {
    id?: string = "";
    type: string = "1";
    value: string = "";
    isActive: boolean = false;
    userId:string;
    user: string;
}