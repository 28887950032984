import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TableModule }   from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { BenefitsSectionComponent } from './benefits-section.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TableModule, 
    InputTextModule, 
    ButtonModule,
    MultiSelectModule 
  ],
  declarations: [BenefitsSectionComponent],
  providers: [BenefitsSectionComponent],
  exports: [BenefitsSectionComponent]  
})
export class BenefitsSectionModule {}
