import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { BannerDto, MemberDetails } from './header-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { HeaderSectionService } from './header-section.service';

@Component({
    selector: 'header-section',
    templateUrl: 'header-section.component.html',
    styleUrls: ['header-section.component.scss'],
    providers: [HeaderSectionService]
})
export class HeaderSectionComponent extends BaseComponent implements OnInit, OnDestroy
{
    @Input() memberId: string = null;
    @Output() sectionLoadedEvent = new EventEmitter<string>();
    @Output() memberIsDeceasedEvent = new EventEmitter<boolean>();
    @Output() headerIsLoadedEvent = new EventEmitter<string>();
    @Output() headerIsBenefitsSectionAvailableEvent = new EventEmitter<boolean>();
    @Output() setClientIdEvent = new EventEmitter<string>();
 
    public memberdetails: MemberDetails[] = [];
    private tabTitleService: Title;
    public memberBannerDto: BannerDto = new BannerDto();

    private getMemberBannerDetailsSubscription: Subscription = null;
    private getMemberDetailReportSubscription: Subscription = null;
    
    constructor(private _sectionService: HeaderSectionService, private _tabTitleService: Title) {
        
        super();
        
        this.tabTitleService = _tabTitleService;
    }

    ngOnInit(): void {
    }

    public initialize() {
    }

    public isDirty(): boolean {

        return false;
    }

    public isLoaded(): boolean {
     
        return this.isNotEmpty(this.memberBannerDto);
    }

   
    public downLoadMemberDetailReport(){
        if (this.isNotEmpty(this.getMemberDetailReportSubscription)) {
            this.getMemberDetailReportSubscription.unsubscribe();
        }

       this.getMemberDetailReportSubscription = this._sectionService.downLoadMemberDetailReport(this.memberBannerDto.memberCode).subscribe({
            next : (res) => {
                let downloadElement = document.createElement("a");
                downloadElement.href = URL.createObjectURL(res);
                downloadElement.download = this.memberBannerDto.memberCode + ".pdf";
                downloadElement.click();
            },
            error: (err: any) => { 
                console.error(err);
            }
       });
    }

    public save() {
    }

    private setBrowserPageTitle(memberName: string) {

        this.tabTitleService.setTitle(memberName);
    }

    public getSectionData() {

        if (this.isNotEmpty(this.getMemberBannerDetailsSubscription)) {
             this.getMemberBannerDetailsSubscription.unsubscribe();
        }
 
        this.getMemberBannerDetailsSubscription = this._sectionService.getMemberBannerData(this.memberId).subscribe({
            next: (resultData) => {

                if (this.isNotEmpty(resultData)) {

                    this.memberBannerDto = resultData;
                    this.setBrowserPageTitle(this.memberBannerDto.firstName + ' ' + this.memberBannerDto.lastName);

                    if (this.isNotEmpty(resultData.deceasedDate)) {
                        this.memberIsDeceasedEvent.emit(true);
                    }

                    this.headerIsLoadedEvent.emit(this.memberBannerDto.memberCode);
                    this.headerIsBenefitsSectionAvailableEvent.emit(this.memberBannerDto.benefitsSectionEnabled);
                    this.setClientIdEvent.emit(this.memberBannerDto.clientId);
                }
            },
            error: (err: any) => { 
                console.error(err);
            }
        });
     }

     public getGender(memberBannerDto: BannerDto): string {

        if (memberBannerDto !== null && memberBannerDto.gender !== undefined) {
            return memberBannerDto.gender === 'M' ? 'Male' : 'Female';
        }

        return "";
     }

     public getMaskPhoneNumber(rawNum: string) {

        const countryCodeStr = rawNum.slice(0,3);
        const areaCodeStr = rawNum.slice(3,6);
        const lastSectionStr = rawNum.slice(6,10);   

        return `(${countryCodeStr}) ${areaCodeStr}-${lastSectionStr}`;
    }

    ngOnDestroy(): void {

        if (this.getMemberBannerDetailsSubscription !== null) {
            this.getMemberBannerDetailsSubscription.unsubscribe();
        }
    }
}
