import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PortalUser } from 'src/app/auth/models/portaluser';
import { PortalUserService } from 'src/app/auth/services/portaluser.service';

@Component({
  selector: 'app-edit-portaluser',
  templateUrl: './edit-portaluser.component.html',
  styleUrls: ['./edit-portaluser.component.scss'],
})
export class EditPortalUserComponent implements OnInit {
  @Input() portalUser?: PortalUser;
  @Output() portalUsersUpdated = new EventEmitter<PortalUser[]>();

  constructor(private PortalUserService: PortalUserService) {}

  ngOnInit(): void {}

  updatePortalUser(portalUser: PortalUser) {
    this.PortalUserService
      .updatePortalUser(portalUser)
      .subscribe((portalUsers: PortalUser[]) => this.portalUsersUpdated.emit(portalUsers));
  }

  deletePortalUser(portalUser: PortalUser) {
    this.PortalUserService
      .deletePortalUser(portalUser)
      .subscribe((portalUsers: PortalUser[]) => this.portalUsersUpdated.emit(portalUsers));
  }

  createPortalUser(portalUser: PortalUser) {
    this.PortalUserService
      .createPortalUser(portalUser)
      .subscribe((portalUsers: PortalUser[]) => this.portalUsersUpdated.emit(portalUsers));
  }
}