
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { AddressDto, EmailDto, GenericMasterDataType, LanguageDto, PhoneDto, StateDto, TimeZoneDto, UserNameDto, saveContactInfoData } from './contact-section.dtos';

@Injectable()
export class ContactSectionService {

    private getGenericMasterUrl: string = '/GenericMasterData/GenericMasterData?genericMasterData'; 
    private getStatesUrl: string = '/MemberRegistration/GetStates';
    private getMemberAddressUrl: string = '/MemberAddress/MemberAddressDetails?MemberId=##memberId##';
    private getMemberPhoneUrl: string ='/MemberPhoneNumber/GetMemberPhoneDetails?MemberId=##memberId##';
    private getMemberBasicUrl: string = '/Member/MemberBasicDetails?MemberId=##memberId##';
    private deletePhoneUrl: string = '/Member/##memberId##/phone/##phoneId##?MemberSource=##memberSource##&phoneSource=##phoneSource##';
    private deleteAddressUrl: string = '/Member/##memberId##/address/##addressId##?MemberSource=##memberSource##&AddressSource=##phoneSource##';
    private postContactInfoUrl: string = '/Member/Update/##memberId##';

    private getMemberEmailsUrl: string ='/MemberEmailAddress/MemberEmailAddress?MemberId=##memberId##&sourceType=##sourceType##';
    private deleteEmailUrl: string = '/member/##memberId##/email/##emailId##?MemberSource=##memberSource##&emailSource=##emailSource##';
    private getLanguagesUrl: string = '/Member/GetLanguages';
    private getTimeZonesUrl: string = '/Member/GetTimeZones';


    private headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {

        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');

        environment.contentServiceApiBaseUrl = _appConfig.contentServiceApiBaseUrl;
    }

    public getGenericMasterData(type: string): Observable<GenericMasterDataType[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getGenericMasterUrl+"="+type);
        return this._http.get<GenericMasterDataType[]>(url, {headers: this.headers});
    }

    public getLanguageMasterData(): Observable<LanguageDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getLanguagesUrl);
        return this._http.get<LanguageDto[]>(url, {headers: this.headers});
    }

    public getTimeZoneMasterData(): Observable<TimeZoneDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getTimeZonesUrl);
        return this._http.get<TimeZoneDto[]>(url, {headers: this.headers});
    }

    public getStatesMasterData(): Observable<StateDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getStatesUrl);
        return this._http.get<StateDto[]>(url, {headers: this.headers});
    }

    public getMemberBasicData(memberId: string): Observable<UserNameDto> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getMemberBasicUrl)
        .replace("##memberId##", memberId);

        return this._http.get<UserNameDto>(url, {headers: this.headers});
    }

    public getAddressData(memberId: string): Observable<AddressDto[]> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + this.getMemberAddressUrl)
        .replace("##memberId##", memberId)

        return this._http.get<AddressDto[]>(url, {headers: this.headers});
    }

    public getPhoneData(memberId: string): Observable<PhoneDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getMemberPhoneUrl)
        .replace("##memberId##", memberId)

        return this._http.get<PhoneDto[]>(url, {headers: this.headers});
    }
    
    public deletePhone(memberId: string, phoneId: string) {
        const url = (this._appConfig.memberManagerApiBaseUrl + this.deletePhoneUrl)
        .replace("##memberId##", memberId).replace(/##phoneId##/g, phoneId)
        .replace(/##memberSource##/g, Constant.memberSource).replace(/##phoneSource##/g, Constant.memberSource)
        return this._http.delete(url, { headers: this.headers, responseType: 'text' })
    }

    public deleteAddress(memberId: string, addressId: string) {
        const url = (this._appConfig.memberManagerApiBaseUrl + this.deleteAddressUrl)
        .replace("##memberId##", memberId).replace(/##addressId##/g, addressId)
        .replace(/##memberSource##/g, Constant.memberSource).replace(/##phoneSource##/g, Constant.memberSource)
        return this._http.delete(url, { headers: this.headers,responseType: 'text' })
    }

    public postContactInfoData(memberId: string, formToSave: saveContactInfoData) {
        let url = (this._appConfig.memberManagerApiBaseUrl + this.postContactInfoUrl)
        .replace("##memberId##", memberId);

        return this._http.post<saveContactInfoData>(url, formToSave, {headers: this.headers});
    }

    public getEmailsData(memberId: string): Observable<EmailDto[]> {
        let url = (this._appConfig.memberManagerApiBaseUrl + this.getMemberEmailsUrl)
        .replace("##memberId##", memberId).replace(/##sourceType##/g, Constant.memberSource)

        return this._http.get<EmailDto[]>(url, {headers: this.headers});
    }
    
    public deleteEmail(memberId: string, emailId: string) {
        const url = (this._appConfig.memberManagerApiBaseUrl + this.deleteEmailUrl)
        .replace("##memberId##", memberId).replace("##emailId##", emailId)
        .replace(/##memberSource##/g, Constant.memberSource).replace(/##emailSource##/g, Constant.memberSource)
        return this._http.delete(url, { headers: this.headers, responseType: 'text' })
    }

}