<div class="p-grid" id="secureMessageSection">
    <div class="p-col-6" style="padding: 0">
        <div style="margin-top: 1.9em;">
            There are <span style="color: #ff4d4d; font-size: 19px;"> {{unreadMessageCount}} </span> unread messages.
        </div>
    </div>
    <div class="p-col-6" style="display: flex; justify-content: right">
        <button pButton type="button" (click)="addMessage()" id="addMessage" style="float: right" label="{{createSecureMessageLabel}}"></button>&nbsp;&nbsp;
        <button pButton type="button" (click)="getSectionData()" id="refresh" style="float: right" label="{{refreshLabel}}"></button>
    </div>

    <div class="p-col-12" style="border: 1px solid #d0d0c8; padding: 0;">
        <p-table id="memberSecureMessageTable" #memberSecureMessageTable [columns]="memberSecureMessageTableColumns"
            [value]="secureMessageListings" [scrollable]="true" [resizableColumns]="true"
            [scrollHeight]="getTableHeight()" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field" [style.width]="col.width" style="text-align: center !important">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns" style="flex: inherit" [style.width]="col.width">
                        <input pInputText type="text" style="width:100%" (input)="filterTable($event, memberSecureMessageTable, col.field)">
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-columns="columns" let-rowData>
                <tr [pSelectableRow]="rowData" (click)="handleRowSelect(rowData)" [ngClass]="{'bold-row': isAgentUnRead(rowData)}">
                    <td *ngFor="let col of columns" style="flex: inherit; cursor: pointer;" [style.width]="col.width"
                        [ngClass]="{'table-cell-align-center':(col.align==='center'), 'table-cell-align-left':(col.align!=='center')}">

                        <div *ngIf="col.field !== 'text' " class="secure-word-break">{{rowData[col.field]}}</div>
                        <div *ngIf="col.field === 'text'" class="message-word-break" [innerHTML]="truncateValue(rowData[col.field])"></div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td id="noMatchingMembersFound" [attr.colspan]="getNumColumns()" style="text-align: center !important">{{noRecordsMessage}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog #createSecureMessage [header]="createSecureMessageLabel" [(visible)]="displayMessageWindow" (onHide)="close()"
    [closable]="true" [closeOnEscape]="false" [modal]="true" [style]="{'width': '1200px', 'min-height': '450px'}">

    <div [formGroup]="sectionForm" *ngIf="sectionForm !== null && sectionForm !== undefined">
        <div class="p-grid">
            <div class="p-col-12" style="margin-top:1em">
                <div *ngIf="primaryEmailAddress !== undefined" style="float: left"><span style="font-weight: bold; padding-right: .5em;">Notification
                        To:</span>{{primaryEmailAddress.emailAddress}}

                    <label for='selectedTemplateName' style="font-weight: bold; padding-right: .5em;padding-left: 3.5em;">Template:</label>
                    <p-dropdown [options]="templateDefinitionList" optionLabel="templateName" optionValue="id"
                        placeholder="Select a Template" formControlName="selectedTemplateName" [showClear]="true" (onChange)="changeTemplate($event)">
                        <ng-template let-item pTemplate="selectedItem">
                            {{item.templateName}}
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            {{item.templateName}}
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <div class="p-col-12">
                <label for='MessageSubject' style="display: block;">Subject:<span class="field-is-required"></span></label>
                <input pInputText id="messageSubject" type="text" formControlName="messageSubject" style="width:100%" maxlength="200" />
                <div *ngIf="(saveClicked && messageSubjectControl.touched && !messageSubjectControl.valid) || (saveClicked && !messageSubjectControl.valid)">
                    <div class="missing-value" *ngIf="messageSubjectControl && messageSubjectControl.errors && messageSubjectControl.errors.required">
                        Subject is required.
                    </div>
                    <div class="missing-value" *ngIf="messageSubjectControl.hasError('whitespaceOrEmpty') && messageSubjectControl.touched && !messageSubjectControl.errors.required">
                        This field cannot be empty or just whitespace.
                    </div>
                </div>
            </div>

            <div class="p-col-12" *ngIf="selectedTemplateControl.value">
                <label for="MessageText" style="display: block;">Message:<span class="field-is-required"></span></label>
                <div class="p-col-12" #editorContainer formControlName="quillEditorText" style="max-height:160px;overflow-y:auto"></div>
                <div *ngIf="(saveClicked && quillEditorTextControl.touched && !quillEditorTextControl.valid) || (saveClicked && !quillEditorTextControl.valid)">
                    <div class="missing-value" *ngIf="quillEditorTextControl && quillEditorTextControl.errors && quillEditorTextControl.errors.required">
                        Message is required.
                    </div>
                </div>
            </div>

            <div class="p-col-12" *ngIf="!selectedTemplateControl.value">
                <label for="MessageText" style="display: block;">Message:<span class="field-is-required"></span></label>
                <textarea pInputTextarea rows="3" maxlength="4000" class="W100" formControlName="messageText"></textarea>
                <div *ngIf="(saveClicked && messageTextControl.touched && !messageTextControl.valid) || (saveClicked && !messageTextControl.valid)">
                    <div class="missing-value" *ngIf="messageTextControl && messageTextControl.errors && messageTextControl.errors.required">
                        Message is required.
                    </div>
                    <div class="missing-value" *ngIf="messageTextControl.hasError('whitespaceOrEmpty') && messageTextControl.touched && !messageTextControl.errors.required">
                        This field cannot be empty or just whitespace.
                    </div>
                </div>
            </div>

            <div class="p-col-12" style="padding-top: 0; padding-bottom: 1em; display: flex; gap: 1em;">
                <button pButton type="button" id="addFullfillmentBtn" (click)="showFulfillmentAddDialog()" label="Add Fulfillment"></button>
                <button pButton type="button" id="addDocumentBtn" (click)="showDocumentAddDialog()" label="Add Document"></button>


                <!-- <p-fileUpload #attachmentUploader chooseLabel="Upload Attachment" [customUpload]="true"  style="margin-left: 1em"
                (onSelect)="validateAttachment($event, attachmentUploader)" (uploadHandler)="uploadAttachmentFiles($event, attachmentUploader)" 
                    [multiple]="true"  [auto]="true">

                    <ng-template pTemplate="content" let-files>
                        
                        <div *ngIf="getUploadErrorKeys().length > 0" style="display: flow-root; padding-bottom: .5em">
                            <hr style="height:2px;border-width:0;margin: 0px;padding: 0px;color:gray;background-color:gray">
                        </div>

                        <div style="display: flow-root; padding-bottom: .5em" *ngFor="let uploadErrorKey of getUploadErrorKeys()">
                            <span class="file-is-too-large-message">{{ getUploadError(uploadErrorKey) }}</span>
                            <span  style="float: right">
                                <button pButton type="button" icon="pi pi-times" class="p-button-icon-only" style="padding: 0.12em; width: 1.5em; background-color:red;" (click)="removeErrorMessage(uploadErrorKey)"></button> 
                            </span>
                        </div>
                    </ng-template>

                    <ng-template let-file pTemplate="file">
                        <div *ngIf="file.size < 5242880" style="display: flow-root; padding-bottom: .5em">
                            <span style="font-weight: normal;">{{file.name}}</span>
                            <span style="float: right">
                                <button pButton type="button" icon="pi pi-times" class="p-button-icon-only" style="padding: 0.12em; width: 1.5em;" (click)="removeAttachmentFile($event, file)"></button>
                            </span>
                        </div>
                    </ng-template>
                </p-fileUpload> -->
            </div>

            <div class="p-col-12" *ngIf="attachedFullfillmentList.length > 0" style="font-size: larger;">
                <p-table #dtSelectedFullfillments [value]="attachedFullfillmentList" dataKey="id" editMode="row"
                    [scrollable]="true" [resizableColumns]="true" styleClass="p-datatable-gridlines" [scrollHeight]="getTableHeight()">

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align: center;">Attached fulfillment items</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
                        <tr>
                            <td>
                                {{item.documentDescription}}
                            </td>
                            <td style="width: 11.4em;">
                                <button pButton type="button" style="float: right; margin-left: 17px;"
                                    id="viewBtnForRemoveSelctdFullfillment" (click)="downloadFullfillment(item)" label="View">
                                </button>
                                <button pButton type="button" style="float: right; margin-right: -6px;"
                                    id="removeBtnForSelectedFullfillment" (click)="deleteSelectedFullfillment(item)" label="Remove">
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="p-col-12" *ngIf="attachedDocumentList.length > 0" style="font-size: larger;">
                <p-table [value]="attachedDocumentList" dataKey="id" editMode="row" [scrollable]="true"
                    [resizableColumns]="true" styleClass="p-datatable-gridlines" [scrollHeight]="getTableHeight()">

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align: center;">Attached document items</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
                        <tr>
                            <td>
                                <p pTooltip="{{item.documentName}}" tooltipPosition="top" tooltipStyleClass="custom-tooltip" class="textWrap3"> {{item.documentName}} </p>
                            </td>
                            <td style="width: 11.4em;">
                                <button pButton type="button" style="float: right; margin-left: 17px;"
                                    id="viewBtnForRemoveSelctdDocument" (click)="downloadDocument(item)" label="View">
                                </button>
                                <button pButton type="button" style="float: right; margin-right: -6px;"
                                    id="removeBtnForSelectedDocument" (click)="deleteSelectedDocument(item)" label="Remove">
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="p-col-12" style="display: flex; justify-content: right; padding-top: 0; padding-bottom:0">
                <button pButton type="button" id="closeEmailButton" (click)="close()" label="Close"></button>
                <button pButton type="button" id="sendEmailButton" (click)="sendEmail()" label="Send" style="margin-left: 1em; margin-right: 7px;"></button>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="displayAlertWindow" header="{{cancelMessageLabel}}" [modal]="true" [closable]="false" [style]="{'width': '400px'}">
    <div class="p-col-12">
        <label for="alertWindowMessage" style="display: block;">Do you want to cancel?</label>
    </div>

    <div class="p-col-12" style="display: flex; justify-content: right; padding-top: 0; padding-bottom:0">
        <button pButton type="button" id="closeAlertWindow" (click)="closeAlertWindow()" label="No"></button>
        <button pButton type="button" id="okAlertWindow" (click)="okAlertWindow()" label="Yes" style="margin-left: 1em"></button>
    </div>
</p-dialog>

<div *ngIf="selectedMessageListing !== null && selectedMessageListing !== undefined">
    <p-dialog [(visible)]="displayDetailWindow" header="{{messageDetailsLabel}}" id="readMessageDialog"
        [closable]="false" [closeOnEscape]="false" [modal]="true" class="custom-dilogheight" [style]="{'width': '1200px'}">

        <ng-template pTemplate="header">
            <span style="width:100%">
                <p-header>
                    <div style='display: flex; justify-content: space-between;'>
                        <div style='justify-content: flex-start;font-size: 1.3em;'>
                            {{messageDetailsLabel}}
                        </div>
                        <div style='justify-content: flex-end; cursor: pointer;'>
                            <i class="fa fa-times fa-2x" (click)="close()"></i>
                        </div>
                    </div>
                </p-header>
            </span>
        </ng-template>

        <div>
            <label for="messageSubject" style="display: block;"><b>Subject</b>: {{selectedMessageListing.subject}}</label>
        </div>
        <div style="max-height:300px;overflow-y:auto">
            <ng-container *ngFor="let item  of messageConverstions">
                <div class="wd-100 clr-both">
                    <div [ngClass]="item.from == memberstr ? 'chat-container' : 'chat-container darker '">
                        <span *ngIf="item.from != memberstr" class="time-right">
                            {{item.createdDate}}
                        </span>
                        <span class="time-left">
                            {{item.from}}
                        </span>
                        <span *ngIf="item.from == memberstr" class="time-right">
                            {{item.createdDate}}
                        </span>

                        <div class="wd-100 clr-both">
                            <ng-container *ngIf="item.text">
                                <div [innerHTML]="sanitizeHtml(item.text)"></div>
                            </ng-container>
                        </div>

                        <ng-container *ngIf="item.attachments.length > 0">
                            <ng-container *ngFor="let attachment  of item.attachments">
                                <div class="{{getFileExtension(attachment.fileName)}}" style="cursor:pointer; color:#5cb85c" (click)="downloadAttachment(attachment)">
                                    {{attachment.fileName}}</div>
                                <div><i class="pi pi-download" (click)="downloadAttachment(attachment)"></i></div>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="item.fullfillments.length > 0">
                            <ng-container *ngFor="let fullfillment  of item.fullfillments">
                                <div style="cursor:pointer; color:#034b45" (click)="downloadFullfillment(fullfillment)">
                                    {{fullfillment.documentDescription}}
                                    <i class="pi pi-eye" (click)="downloadFullfillment(fullfillment)" style="padding-left: 1em;"></i>
                                </div>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="item.documents.length >0">
                            <ng-container *ngFor="let document  of item.documents">
                                <div style="cursor:pointer; color:#034b45" (click)="downloadDocument(document)">
                                    {{document.documentName}}
                                    <i class="pi pi-eye" (click)="downloadDocument(document)" style="padding-left: 1em;"></i>
                                </div>
                            </ng-container>
                        </ng-container>

                        <span *ngIf="item.from != memberstr" title="Resend" (click)="resendMessage(item)" style="cursor: pointer" class="time-right">
                            <i class="fa fa-solid fa-reply fa-2x action-btn"></i>
                        </span>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="p-col-12" *ngIf="!selectedTemplateEditTime">
            <ng-container>
                <textarea pInputTextarea id="messageText" #messageText rows="2" class="wd-100" cols="85"
                    maxlength="4000" placeholder="Type a message" [(ngModel)]="replyMsgText">
                </textarea>
            </ng-container>
        </div>

        <div class="p-col-12" *ngIf="selectedTemplateEditTime" style="overflow: hidden">
            <label for="MessageText" style="display: block;">Message:<span class="field-is-required"></span></label>
            <div class="p-col-12" #editTimeEditorContainer style="height:150px;overflow-y:auto"></div>
        </div>

        <div class="p-col-12" style="padding-top: 0; padding-bottom: 1em; display: flex; gap: 1em;">
            <button pButton type="button" id="addFullfillmentBtn" (click)="showFulfillmentAddDialog()" label="Add Fulfillment"></button>
            <button pButton type="button" id="addDocumentBtn" (click)="showDocumentAddDialog()" label="Add Document"></button>
            <p-dropdown [options]="templateDefinitionList" optionLabel="templateName" optionValue="id"
                [style]="{'width': '330px', 'margin-left': '15px'}" placeholder="Select a Template"
                [(ngModel)]="selectedTemplateEditTime" [showClear]="true" (onChange)="changeTemplateOnEditTime($event)">
                <ng-template let-item pTemplate="selectedItem">
                    {{item.templateName}}
                </ng-template>
                <ng-template let-item pTemplate="item">
                    {{item.templateName}}
                </ng-template>
            </p-dropdown>
        </div>

        <!-- <div class="p-col-10" *ngIf="resendAttachments.length > 0">
                <ng-container *ngFor="let attachment  of resendAttachments">
                    <div class="{{getFileExtension(attachment.fileName)}}" style="cursor:pointer; color:#5cb85c" 
                    (click)="downloadAttachment(attachment)">{{attachment.fileName}}</div>
                </ng-container>
             </div> -->

        <!-- <div class="p-col-12" style="padding-top: 0; padding-bottom: 1em">
                <p-fileUpload #replyAttachmentUploader chooseLabel="Upload Attachment" [customUpload]="true"
                 (onSelect)="validateAttachment($event, replyAttachmentUploader)"  (onBeforeUpload)="onBeforeUploadAttachment($event, replyAttachmentUploader)"
                 (uploadHandler)="uploadAttachmentFiles($event, replyAttachmentUploader)" 
                    [multiple]="true"  [auto]="true">

                    <ng-template pTemplate="content" let-files>
                        
                        <div *ngIf="getUploadErrorKeys().length > 0" style="display: flow-root; padding-bottom: .5em">
                            <hr style="height:2px;border-width:0;margin: 0px;padding: 0px;color:gray;background-color:gray">
                        </div>

                        <div style="display: flow-root; padding-bottom: .5em" *ngFor="let uploadErrorKey of getUploadErrorKeys()">
                            <span class="file-is-too-large-message">{{ getUploadError(uploadErrorKey) }}</span>
                            <span  style="float: right">
                                <button pButton type="button" icon="pi pi-times" class="p-button-icon-only" style="padding: 0.12em; width: 1.5em; background-color:red;" (click)="removeErrorMessage(uploadErrorKey)"></button> 
                            </span>
                        </div>
                    </ng-template>

                    <ng-template let-file pTemplate="file">
                        <div *ngIf="file.size < 5242880" style="display: flow-root; padding-bottom: .5em">
                            <span style="font-weight: normal;">{{file.name}}</span>
                            <span style="float: right">
                                <button pButton type="button" icon="pi pi-times" class="p-button-icon-only" style="padding: 0.12em; width: 1.5em;" (click)="removeAttachmentFile($event, file)"></button>
                            </span>
                        </div>
                    </ng-template>
                </p-fileUpload>
            </div> -->

        <div class="p-col-12" *ngIf="attachedFullfillmentList.length > 0" style="font-size: larger;">
            <p-table #dtSelectedFullfillments [value]="attachedFullfillmentList" dataKey="id" editMode="row"
                [scrollable]="true" [resizableColumns]="true" styleClass="p-datatable-gridlines" [scrollHeight]="getTableHeight()">

                <ng-template pTemplate="header">
                    <tr>
                        <th style="text-align: center;">Attached fulfillment items</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
                    <tr>
                        <td>
                            {{item.documentDescription}}
                        </td>
                        <td style="width: 11.5em;">
                            <button pButton type="button" style="float: right; margin-left: 17px;"
                                id="viewBtnForRemoveSelectedFullfillment" (click)="downloadFullfillment(item)" label="View">
                            </button>
                            <button pButton type="button" style="float: right; margin-right: -6px;"
                                id="removeBtnForSelectedFullfillment" (click)="deleteSelectedFullfillment(item)" label="Remove">
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="p-col-12" *ngIf="attachedDocumentList.length > 0" style="font-size: larger;">
            <p-table [value]="attachedDocumentList" dataKey="id" editMode="row" [scrollable]="true"
                [resizableColumns]="true" styleClass="p-datatable-gridlines" [scrollHeight]="getTableHeight()">

                <ng-template pTemplate="header">
                    <tr>
                        <th style="text-align: center;">Attached document items</th>
                        <th></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
                    <tr>
                        <td>
                            {{item.documentName}}
                        </td>
                        <td style="width: 11.5em;">
                            <button pButton type="button" style="float: right; margin-left: 17px;"
                                id="viewBtnForRemoveSelctdDocument" (click)="downloadDocument(item)" label="View">
                            </button>
                            <button pButton type="button" style="float: right; margin-right: -6px;"
                                id="removeBtnForSelectedDocument" (click)="deleteSelectedDocument(item)" label="Remove">
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="p-col-12" style="display: flex; justify-content: right; padding-top: 0; padding-bottom:0">
            <button pButton type="button" id="closeEmailButton" (click)="close()" label="Close"></button>
            <button pButton type="button" id="sendEmailButton" (click)="sendReply()" label="Send" style="margin-left: 1em"></button>
        </div>
    </p-dialog>
</div>

<p-dialog [(visible)]="displayAttachmentAlertWindow" header="Alert!" [modal]="true" [closable]="false" [style]="{'width': '400px'}">
    <div class="p-col-12">
        <label for="alertMessageForWithoutAttachment" style="display: block;">
            <ng-container *ngIf="validFilesForUploadCount == 0">
                Do you want to send the message without the attachment?
            </ng-container>
            <ng-container *ngIf="validFilesForUploadCount > 0">
                Do you want to send the message with the attachment that contains less than 5MB?
            </ng-container>
        </label>
    </div>
    <div class="p-col-12" style="display: flex; justify-content: right; padding-top: 0; padding-bottom:0">
        <button pButton type="button" id="closeBtnForWithoutAttachment" (click)="closeBtnForWithoutAttachment()" label="No"></button>
        <button pButton type="button" id="okBtnForWithoutAttachment" (click)="okBtnForWithoutAttachment()" label="Yes" style="margin-left: 1em"></button>
    </div>
</p-dialog>

<p-dialog [(visible)]="displayMandatoryAlertWindow" header="Alert!" [modal]="true" [closable]="false" [style]="{'width': '400px'}">
    <div class="p-col-12">
        <label for="alertMessageForMandatory" style="display: block;">
            Please enter message to send reply
        </label>
    </div>
    <div class="p-col-12" style="display: flex; justify-content: right; padding-top: 0; padding-bottom:0">
        <button pButton type="button" id="closeBtnForMandatory" (click)="closeBtnForMandatory()" label="Ok"></button>
    </div>
</p-dialog>


<!-- Fulfillment Search Dialog -->
<p-dialog [(visible)]="displayAddFullfillmentWindow" header="Fulfillment Search" [modal]="true" [resizable]=false [style]="{'width': '920px'}">

    <div class="p-col-12" style="margin-top: 1em">
        <div>
            <p-dropdown [options]="documentCategoryList" optionLabel="name" optionValue="id" [style]="{'width': '330px'}" [(ngModel)]="selectedCategory" 
                placeholder="Select a Category" [showClear]="true" (onChange)="changeCategory($event)">
                <ng-template let-item pTemplate="selectedItem">
                    {{item.name}}
                </ng-template>
                <ng-template let-item pTemplate="item">
                    {{item.name}}
                </ng-template>
            </p-dropdown>

            <span class="p-input-icon-right">
                <input type="text" pInputText placeholder="Search Term" style="width:325px;margin-left:4%" [(ngModel)]="fullfilmentSearchText" />
                <i class="pi pi-search"></i>
            </span>

            <button pButton type="button" id="seacrhBtnForFullfillment" class="fullfillment-search-btn" (click)="searchFullfillment()" label="Search"></button>
        </div>
    </div>

    <div *ngIf="showAdvanceSearch" class="col-p-12" class="advance-search-div">
        <button pButton type="button" id="seacrhBtnForFullfillment" class="advance-search-btn" (click)="advanceSearchFullfillment()" label="Advanced Search"></button>
        <p class="advance-search-tooltip">
            <i class="fa fa-info-circle" icon="pi pi-check" tooltipPosition="{{tooltipPosition}}"
                pTooltip="The advanced search is a feature that allows you to narrow your search results by using keywords or phrases to focus in on the information you want."
                tabindex="0" tooltipEvent="{{toolTipEventType}}" style="outline: 0;"></i>
        </p>
    </div>

    <div class="p-col-12" style="margin-top: 2em">
        <span>Select a fulfillment item to link it to the message</span>

        <p-table id="tableFullfillmentId" #dtFullfillments [value]="fullfillmentList" styleClass="p-datatable-gridlines"
            dataKey="id" [scrollable]="true" [resizableColumns]="true" [scrollHeight]="getTableHeight()">

            <ng-template pTemplate="header">
                <tr>
                    <th class="add-fulfillment-list-header" style="text-align: center;">Matching fulfillment items</th>
                    <th class="add-fulfillment-list-header"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-i="rowIndex">
                <tr id="{{item.id}}">
                    <td>
                        {{item.documentDescription}}
                    </td>
                    <td style="width: 10.2em;">
                        <button pButton type="button" style="float: right; margin-left: 17px;" [disabled]="item.isSelected" id="viewBtnForSelectFullfillment"
                            (click)="downloadFullfillment(item)" label="View">
                        </button>

                        <button pButton type="button" style="float: right; margin-right: -7px;" [disabled]="item.isSelected" id="addBtnForSelectFullfillment"
                            (click)="onRowSelectFullfillment(item)" label="Add">
                        </button>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td id="noEmailRecordFound" [attr.colspan]="2" style="text-align: center !important">{{noRecordsMessage}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="p-col-12 field-is-required-message" style="white-space:pre-wrap;" *ngIf="genAiFileErrorMessage !== null && genAiFileErrorMessage !== undefined">
        {{genAiFileErrorMessage}}
    </div>

    <div class="p-col-12" *ngIf="selectedFullfillmentList !== null && selectedFullfillmentList !== undefined && selectedFullfillmentList.length > 0" style="padding-top: 1em;">
        <p-table #dtSelectedFullfillments [value]="selectedFullfillmentList" dataKey="id" [scrollable]="true"
            [resizableColumns]="true" styleClass="p-datatable-gridlines" [scrollHeight]="getTableHeight()">
            <ng-template pTemplate="header">
                <tr>
                    <th class="add-fulfillment-list-header" style="text-align: center;">Selected fulfillment items</th>
                    <th class="add-fulfillment-list-header"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
                <tr>
                    <td>
                        {{item.documentDescription}}
                    </td>
                    <td style="width: 11.4em;">
                        <button pButton type="button" style="float: right; margin-left: 17px;"
                            id="viewBtnForRemoveFullfillment" (click)="downloadFullfillment(item)" label="View">
                        </button>
                        <button pButton type="button" style="float: right; margin-right: -7px;" id="removeBtnForSelectedFullfillment" 
                            (click)="removeSelectedFullfillment(i, item)" label="Remove">
                        </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td id="noEmailRecordFound" [attr.colspan]="2" style="text-align: center !important">{{noRecordsMessage}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="p-col-12" style="display: flex; justify-content: right; padding-top: .5em; padding-bottom:0">
        <button pButton type="button" id="closeBtnForUploadFullfillment" (click)="closeBtnForFullfillment()" style="margin-right: 12px;" label="Close"></button>
    </div>
</p-dialog>

<p-dialog [(visible)]="displayAddDocumentWindow" header="Document Search" [modal]="true" [style]="{'width': '910px'}">
    <div class="p-col-12" style="margin-top: 1em">
        <div>
            <span>Select a Document item to link it to the message</span>

            <p-table id="documentsTable" #documentsTable [columns]="tableColumns" [value]="documentsList"
                (sortFunction)="customSort($event)" [customSort]="true" sortMode="single" sortField="createdDate"
                [sortOrder]="tableSort" [scrollable]="true" [resizableColumns]="true" [scrollHeight]="getTableHeight()"
                styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field" [style.width]="col.width" style="text-align: center !important">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.field !== 'action'" [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>

                    <tr>
                        <th *ngFor="let col of columns" style="flex: inherit" [style.width]="col.width"
                            [ngClass]="{'axis-align-center':(col.align==='center'), 'axis-align-left':(col.align!=='center')}">

                            <input pInputText type="text" style="width:100%" *ngIf="col.field !== 'action'" (input)="filterTable($event, documentsTable, col.field)">
                            <span *ngIf="col.field === 'action'">&nbsp;</span>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-columns="columns" let-rowData>
                    <tr>
                        <td *ngFor="let col of columns" style="flex: inherit; cursor: pointer;" [style.width]="col.width"
                            [ngClass]="{'table-cell-align-center':(col.align==='center'), 'table-cell-align-left':(col.align!=='center')}">

                            <div *ngIf="col.field !== 'text'">
                                <p pTooltip="{{rowData[col.field]}}" tooltipPosition="top" tooltipStyleClass="custom-tooltip" class="textWrap1"> {{rowData[col.field]}} </p>
                            </div>

                            <div *ngIf="col.field === 'action'" style="display: flex; justify-content: flex-end;">
                                <button pButton type="button" style="margin-left: 10px;" [disabled]="rowData.isSelected"
                                    (click)="onRowSelectDocument(rowData)" label="Add">
                                </button>
                                <button pButton type="button" style="margin-left: 17px;" [disabled]="rowData.isSelected"
                                    (click)="downloadDocument(rowData)" label="View">
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td id="noMatchingMembersFound" [attr.colspan]="getNumColumns()" style="text-align: center !important">{{noRecordsMessage}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <div class="p-col-12" *ngIf="selectedDocumentList !== null && selectedDocumentList !== undefined && selectedDocumentList.length > 0">
        <p-table [value]="selectedDocumentList" dataKey="id" [scrollable]="true" [resizableColumns]="true"
            styleClass="p-datatable-gridlines" [scrollHeight]="getTableHeight()">
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center;">Selected document items</th>
                    <th></th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
                <tr>
                    <td>
                        <p pTooltip="{{item.documentName}}" tooltipPosition="top" tooltipStyleClass="custom-tooltip"
                            class="textWrap2"> {{item.documentName}} </p>

                    </td>
                    <td style="width: 11.4em;">
                        <button pButton type="button" style="float: right; margin-left: 17px;"
                            id="viewBtnForRemoveDocument" (click)="downloadDocument(item)" label="View">
                        </button>
                        <button pButton type="button" style="float: right; margin-right: -7px;"
                            id="removeBtnForSelectedDocument" (click)="removeSelectedDocument(i, item)"
                            label="Remove"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td id="noEmailRecordFound" [attr.colspan]="2" style="text-align: center !important">
                        {{noRecordsMessage}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="p-col-12" style="display: flex; justify-content: right; padding-top: .5em; padding-bottom:0">
        <button pButton type="button" id="closeBtnForUploadDocument" (click)="closeBtnForDocuement()"
            style="margin-right: 7px;" label="Close"></button>
    </div>
</p-dialog>

<!-- advanced search (GenAI) dialog -->
<p-dialog id="advancedSearchGenAiDialog" header="Advanced Fulfillment Search" [(visible)]="displayAddVanceFullfillmentWindow"
    (onHide)="closeBtnForAdvanceSearch()" [modal]="true" [style]="{'width': '1200px', 'height':'766px'}">

    <div class="p-grid">
        <div class="p-col-12 genai-load-css">
            <div id="genaiIframeParent" class="genai-iframe-parent">
                <p-scrollPanel [style]="{width: '100%', height: '76vh'}"  styleClass="custombar1">
                    <iframe *ngIf="sanitizedGenAIUrl !== null && sanitizedGenAIUrl !== undefined" referrerpolicy="unsafe-url"
                        style="position:absolute;top:0px;width:100%;height:76vh;" id="genai_iframe" [src]="sanitizedGenAIUrl" frameborder="0">

                        <!-- Fallback content for browsers that do not support iframes -->
                        <p>Your browser does not support iframes.</p>
                    </iframe>
                </p-scrollPanel>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer" style="padding: .25rem">
        <div class="p-col-12">
            <button pButton type="button" id="closeBtnForAdvanceSearch" (click)="closeBtnForAdvanceSearch()" style="margin-right: 26px;" label="Close"></button>
        </div>
    </ng-template>
</p-dialog>