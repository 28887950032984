<div id="member-assessment-print">

    <div *ngIf="loading" style="margin-top:50px; margin-left:45%; font-weight:bold;">
        <img src="assets/images/loading_small.gif"> Loading Assessment ...
    </div>

    <div *ngIf="showNoResponsesMessage">
        {{noResponsesMessage}}
    </div>

    <div *ngIf="!loading && !showNoResponsesMessage" style="border: 1px solid lightgray; border-radius: 5px;">

        <div id="assessmentPrintQuestions" style="width: 100%;">

            <p-scrollPanel #assessmentPrintScrollPanel id="assessmentPrintScrollPanel" styleClass="scroll-panel-custom-bar" 
                [style]="{width:'100%', height:getScrollPanelHeight(assessmentPrintScrollPanel)}"> 

                <div id="assessmentPrintBody_{{memberHealthAssessmentId}}" style="padding-top: 1.5em; background-color: white;">
                    <div style="height:100%">
                        <div class="panel panel-default page-content">                    
                            <div class="ui-g-12 question-answer-container" *ngIf="assessmentResponses !== null && assessmentResponses !== undefined">

                                <div style="font-size: 1.2em; font-weight: bold;">{{assessmentName}} - Read Only</div>

                                <div class="ui-g-12 ui-md-12 ui-lg-12" style="margin-bottom: 1em;" 
                                    *ngFor="let question of assessmentResponses; let i = index">
                                    
                                    <div class="mt-10">
                                        <span> {{i+1}}. </span>
                                        <span [innerHTML]="question.questionText"></span>
                                    </div>
            
                                    <div class="ui-g">
                                        <div class="ui-g-12 answer-text" *ngIf="question.answerText">
                                            <span style="margin-top: -.1em; color: var(--text-color); padding-left: .4em">Answer: </span><span style="margin-left: .4em;" [innerHTML]="question.answerText"></span> 
                                        </div>                                    
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin: .5em">&nbsp;</div>
            </p-scrollPanel>
        </div>
    </div>    
</div>