import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MemberTaskDetailsDto } from './task-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { TaskSectionService } from './task-section.service';
import { TableColumnDto } from '../common/common-dtos';
import { Table } from 'primeng/table';

@Component({
    selector: 'task-section',
    templateUrl: 'task-section.component.html',
    styleUrls: ['task-section.component.scss'],
    providers: [TaskSectionService]
})
export class TaskSectionComponent extends BaseComponent implements OnInit, OnDestroy
{
    @Input() memberId: string = null;
    @Output() sectionLoadedEvent = new EventEmitter<string>();
    
    public saveClicked: boolean = false;
    public sectionIsLoaded: boolean = false;

    public sectionForm: FormGroup;

    private saveSubscription: Subscription = null;
    private getSubscription: Subscription = null;

    private getMeberTaskSubscription:Subscription=null;
    public memberTaskDetails:MemberTaskDetailsDto[]=[];
    public memberTaskDetailsDto:MemberTaskDetailsDto;

    public tableColumns: TableColumnDto[] = [];
    public tableSort: number = -1;
    public noRecordsMessage = "No member tasks found";
    
    constructor(private _sectionService: TaskSectionService) {
        super();
    }

    ngOnInit(): void {

        this.initializeColumns();
    }

    
    private initializeColumns() {

        this.tableColumns = [];
 
        const tableColumnName: TableColumnDto = new TableColumnDto();
        tableColumnName.field = 'name';
        tableColumnName.header = 'Name';
        tableColumnName.width = '20%';
        tableColumnName.align = 'left';
        
        const tableColumnDescription: TableColumnDto = new TableColumnDto();
        tableColumnDescription.field = 'description';
        tableColumnDescription.header = 'Description';
        tableColumnDescription.width = '30%';
        tableColumnDescription.align = 'left';

        const tableColumnTasktype: TableColumnDto = new TableColumnDto();
        tableColumnTasktype.field = 'taskType';
        tableColumnTasktype.header = 'Task Type';
        tableColumnTasktype.width = '8%';
        tableColumnTasktype.align = 'left';

        const tableColumnPriority: TableColumnDto = new TableColumnDto();
        tableColumnPriority.field = 'priority';
        tableColumnPriority.header = 'Priority';
        tableColumnPriority.width = '7%';
        tableColumnPriority.align = 'left';


        const tableColumnStatus: TableColumnDto = new TableColumnDto();
        tableColumnStatus.field = 'status';
        tableColumnStatus.header = 'Status';
        tableColumnStatus.width = '7%';
        tableColumnStatus.align = 'left';

        const tableColumnIsAlerted: TableColumnDto = new TableColumnDto();
        tableColumnIsAlerted.field = 'isAlerted';
        tableColumnIsAlerted.header = 'IsAlerted';
        tableColumnIsAlerted.width = '7%';
        tableColumnIsAlerted.align = 'left';
        
        const tableColumnAssignedDate: TableColumnDto = new TableColumnDto();
        tableColumnAssignedDate.field = 'assignedDate';
        tableColumnAssignedDate.header = 'Assigned Date';
        tableColumnAssignedDate.width = '7%';
        tableColumnAssignedDate.align = 'left';

        const tableColumnDueDate: TableColumnDto = new TableColumnDto();
        tableColumnDueDate.field = 'dueDate';
        tableColumnDueDate.header = 'Due Date';
        tableColumnDueDate.width = '7%';
        tableColumnDueDate.align = 'left';

       
        const tableColumnCompletedDate: TableColumnDto = new TableColumnDto();
        tableColumnCompletedDate.field = 'completedDate';
        tableColumnCompletedDate.header = 'Completed Date';
        tableColumnCompletedDate.width = '7%';
        tableColumnCompletedDate.align = 'left';
        
        this.tableColumns.push(tableColumnName);
        this.tableColumns.push(tableColumnDescription);
        this.tableColumns.push(tableColumnTasktype);
        this.tableColumns.push(tableColumnPriority);
        this.tableColumns.push(tableColumnStatus);
        this.tableColumns.push(tableColumnIsAlerted);
        this.tableColumns.push(tableColumnAssignedDate);
        this.tableColumns.push(tableColumnDueDate);
        this.tableColumns.push(tableColumnCompletedDate);
    }

    public getNumColumns(): number {
        return this.isEmpty(this.tableColumns) ? 0 : this.tableColumns.length;
    }

    public filterTable($event: any, memberTasksTable: Table, columnField: any) {
        memberTasksTable.filter($event.target.value, columnField, 'contains');
    }

    public getTableHeight(): string {
        return '650px';
    }

    public customSort($event: { field: string; data: any[]; order: number; }) {

        let isDate: boolean = false;

        if (this.isNotEmpty($event) && this.isNotEmpty($event.field) && $event.field.toLowerCase().indexOf('date') > -1) {
            
            isDate = true;
        }

        $event.data.sort((data1, data2) => {
    
            let value1 = null;
            let value2 = null;
    
            if ( isDate) {
    
                let val1 = data1[$event.field];
                let val2 = data2[$event.field];
                value1 = new Date(val1);
                value2 = new Date(val2);
    
            } else {
    
                value1 = data1[$event.field];
                value2 = data2[$event.field];
            }
    
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ($event.order * result);
        });
    }
    
    public isDirty(): boolean {

        return this.sectionForm.dirty;
    }
    public isLoaded(): boolean {
    
        return this.sectionIsLoaded;
    }

    public save() {

        this.saveClicked = true;

        if (this.sectionForm.valid) {            
            if (this.saveSubscription != null) {
                this.saveSubscription.unsubscribe();
            }
        }
    }

    public getSectionData() {

        this.sectionIsLoaded = true;

        if (this.isNotEmpty(this.getSubscription)) {
            this.getSubscription.unsubscribe();
        }
        
        this.getMemberTaskDetails();

    }

   
    public getMemberTaskDetails()
    {
        if(this.isNotEmpty(this.getMeberTaskSubscription)){
            this.getMeberTaskSubscription.unsubscribe();
        }

        this.getMeberTaskSubscription = this._sectionService.getMemberTaskDetails(this.memberId).subscribe({
            next: (result)=>{
                this.memberTaskDetails=result;
            },
            error: (err: any) => { 
                // do we need to persist error information to the server
                console.error(err);
            }
        });
    }

    ngOnDestroy(): void {

        if (this.saveSubscription !== null) {
            this.saveSubscription.unsubscribe();
        }

        if (this.getSubscription !== null) {
            this.getSubscription.unsubscribe();
        }

        if(this.getMeberTaskSubscription !=null){
            this.getMeberTaskSubscription.unsubscribe();
        }
    }
}
