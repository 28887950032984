
export class MemberDetails{
    firstName: string;
    lastName: string;
    memberId: string;
    gender: string;
    dateOfBirth: string;
    status: string;
    activePopulation:string;
}

export class BannerDto {
    id: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    dateOfBirth: Date;
    deceasedDate: Date;
    gender: string;
    populationName: string;
    programName: string;
    memberCode: string;
    client: string;
    clientId: string;
    primaryPhone: string;
    age: string;
    language: string; 
    downloadMemberDetailReport: boolean;
    benefitsSectionEnabled: boolean;
    registrationDate: Date;
}