import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';

import { BaseComponent } from 'src/app/member/common/base.component';
import { CareplanSectionService } from './careplan-section.service';
import { GoalDetailService } from './goal-detail/goal-detail.service';
import { CareGoalTextDto, CarePlanGoalDto, SaveCarePlanDto, SaveCarePlanGoalsDto, CarePlanPersistGoalDto } from './careplan-section.dtos';
import { CarePlanProfileItemDto  } from './goal-detail/goal-detail.dtos';


import { TableColumnDto } from '../common/common-dtos';
import { GoalDetailComponent } from 'src/app/member/careplan-section/goal-detail/goal-detail.component';
import { DateTime, Interval } from 'luxon';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'careplan-section',
    templateUrl: 'careplan-section.component.html',
    styleUrls: ['careplan-section.component.scss'],
    providers: [CareplanSectionService,GoalDetailService]
})
export class CareplanSectionComponent extends BaseComponent implements OnInit, OnDestroy
{
    @Input() memberId: string = null;
    @Output() sectionLoadedEvent = new EventEmitter<string>();
    @Output() goalSavedEvent = new EventEmitter<string>();

    @ViewChild(GoalDetailComponent, { static: false }) goalDetailComponent: GoalDetailComponent;
     
    public sectionIsLoaded: boolean = false;
    public goalsList: CarePlanGoalDto[] = [];
    public openGoalsList: CarePlanGoalDto[] = [];
    public goalTableColumns: TableColumnDto[] = [];
    public goalBatchTableColumns: TableColumnDto[] = [];
    public goalPrioritySort: number = 1;
    public noRecordsMessage = "No records found";
    public careGoalText: string = null;
    public careGoalTextIsReadOnly: boolean = true;
    public showGoalDetailPanel: boolean = false;
    public messageCloseGoals = "Apply Status To Selected Goals";
    public displayCloseGoalBatch:boolean = false;

    private getGoalInterventionStatusProfileSubscription: Subscription = null;
    private getCarePlanGoalsSubscription: Subscription = null;
    private getCareGoalTextSubscription: Subscription = null;
    private persistCareGoalTextSubscription: Subscription = null;
    private persistCareGoalsSubscription: Subscription = null;

    public closedGoalDropdownStatus: string = null;
    public closedGoalStatuses: SelectItem[] = [];
    public fullListClosedGoalStatuses: string[] = [];
    public closedInterventionStatuses: SelectItem[] = [];
    public clearText:string=null;
    public saveClicked: boolean = false; 

    public selectAllGoals: boolean = false;

    constructor(private _goalDetailService: GoalDetailService, private _sectionService: CareplanSectionService,
        private _changeDetectorRef: ChangeDetectorRef) {

        super();
    }

    ngOnInit(): void {

        this.getClosedGoalInterventions();        
    }

    private getClosedGoalInterventions() {

        if (this.isNotEmpty(this.getGoalInterventionStatusProfileSubscription)) {
            this.getGoalInterventionStatusProfileSubscription.unsubscribe();
        }

        this.getGoalInterventionStatusProfileSubscription = this._goalDetailService.getCarePlanProfile().subscribe({
            next: (goalDetails) => {

                if (this.isNotEmpty(goalDetails)) {

                    goalDetails.goalStatuses = this.sortList(goalDetails.goalStatuses);
                    goalDetails.interventionStatuses = this.sortList(goalDetails.interventionStatuses);

                    for (let item of goalDetails.goalStatuses ) {
                        if(item.itemType === "Closed" && item.itemId !== 'met'){
                            this.closedGoalStatuses.push({ label: item.itemDesc, value: item.itemId, disabled: !item.isActive });
                        }

                        if (item.itemType === "Closed"){
                            this.fullListClosedGoalStatuses.push(item.itemId);
                        }
                    }

                    for (let item of goalDetails.interventionStatuses ) {
                        if(item.itemType === "Closed"){
                            this.closedInterventionStatuses.push({ label: item.itemDesc, value: item.itemId, disabled: !item.isActive });
                        }
                    }

                    this.closedGoalStatuses.unshift({label: 'Select...', value: null, disabled: false});
                    this.closedInterventionStatuses.unshift({label: 'Select...', value: null, disabled: false});

                    this.closedGoalDropdownStatus = null;
     
                }

                this.initializeColumns();

            },
            error: (err: any) => { 
                console.error(err);
            }
        });
    }

    private createOpenGoalsList(){

        let openList : CarePlanGoalDto[] = [];
       
        for(let goal of this.goalsList){
            if(!this.checkIfGoalIsClosed(goal.goalStatusId)){
                openList.push(goal);
            }
        }

        let sortedOpenGoals: CarePlanGoalDto[] = openList.sort((a: CarePlanGoalDto, b: CarePlanGoalDto) => {

            if (a.goalOrder < b.goalOrder) {
                return -1;
            } else if (a.goalOrder > b.goalOrder) {
                return 1;
            }

            return 0;
        });

        this.openGoalsList = sortedOpenGoals;

    }

    public closeGoalBatch(){
    
        let closedGoals : SaveCarePlanGoalsDto  = new SaveCarePlanGoalsDto();

        for(let goal of this.openGoalsList){
            if(goal.update){
                closedGoals.goals.push(this.createSaveCarePlanGoal(goal, this.closedGoalDropdownStatus));
            }
        }

        if(closedGoals.goals.length > 0){
            this.saveClosedGoals(closedGoals);
        }
    }

    private createSaveCarePlanGoal(closedCarePlanGoal: CarePlanGoalDto, closedGoalStatus: string) : SaveCarePlanDto {

        let closedSaveCarePlanDto: SaveCarePlanDto = new SaveCarePlanDto();
        closedSaveCarePlanDto.memberId = this.memberId;
        closedSaveCarePlanDto.interventions = [];

        let closedCarePlanPersistGoalDto: CarePlanPersistGoalDto = new CarePlanPersistGoalDto();
        closedCarePlanPersistGoalDto.careGiverPriorityId = closedCarePlanGoal.goalCareGiverPriorityId;
        closedCarePlanPersistGoalDto.careManagerPriorityId = closedCarePlanGoal.goalCareManagerPriorityId;
        closedCarePlanPersistGoalDto.memberCarePlanGoalId = closedCarePlanGoal.memberCarePlanGoalId
        closedCarePlanPersistGoalDto.rowSource = "Agent Portal";
        closedCarePlanPersistGoalDto.statusId = closedGoalStatus;
        closedCarePlanPersistGoalDto.targetDate = closedCarePlanGoal.targetDate;

        closedSaveCarePlanDto.goal = closedCarePlanPersistGoalDto;

        return closedSaveCarePlanDto;

    }

    public saveClosedGoals(goals:SaveCarePlanGoalsDto) {
   
        this.saveClicked = false

            if (this.persistCareGoalsSubscription != null) {
                this.persistCareGoalsSubscription.unsubscribe();
            }

            this.persistCareGoalsSubscription = this._sectionService.persistCarePlanGoals(goals).subscribe({
                next: (saveResult) => {

                    this.saveClicked = false;
                    this.displayCloseGoalBatch = false;
                    this.clearUpdateFlag();
                    this.refreshCarePlan();
                },
                error: (err: any) => { 
                    console.error(err);
                }
            });
        
    }

    public checkIfGoalIsClosed(goalStatusId: String) : boolean{

        let goalAlreadyClosed: boolean = false;
        
        for(let closedGoal of this.fullListClosedGoalStatuses){

            if(goalStatusId === closedGoal){
                goalAlreadyClosed = true;
                break;
            } 

        }

        return goalAlreadyClosed;

    }

    

    public clearUpdateFlag() {

        for(let goal of this.openGoalsList){

            goal.update = false;
        }

    }

    public disableUpdateGoalsButton():boolean {
        let disableButton: boolean = true;

        if(this.isUpdateSelected() && this.isNotEmpty(this.closedGoalDropdownStatus) ){
            disableButton = false;
        }

        return  disableButton;

    }

    private isUpdateSelected():boolean  {

        let updateSelected: boolean = false;

        for(let goal of this.openGoalsList){
            if(goal.update){
                updateSelected = true;
                break;
            }
        }

        return updateSelected;

    }

    public allGoalsAreClosed():boolean {

        let allGoalsAreClosed: boolean = true;
        
        for(let goal of this.goalsList){

            if(!this.checkIfGoalIsClosed(goal.goalStatusId)){
                allGoalsAreClosed = false;
                break;
            } 

        }

        return allGoalsAreClosed;

    }

    public selectAllOpenGoals($event: any) {

        this.selectAllGoals = $event.checked;

        for (let Goals of this.openGoalsList) {
            Goals.update = this.selectAllGoals;
        }

    }

    public childUpdateGoalCheckbox(){       
          this.selectAllGoals =  this.allAreTrue(this.openGoalsList.map(x=>x.update))
    }  
    

     public openCloseGoalBatchDialog() {
        this.closedGoalDropdownStatus = null;
        this.displayCloseGoalBatch = true;
    }

    public closeGoalBatchDialog(goalsBatchTable:Table){
        this.displayCloseGoalBatch = false;
        this.selectAllGoals=false;
        if(this.clearText)
        {
            this.clearText='';
        }
        goalsBatchTable.clear();
        this.clearUpdateFlag();
    }

    public isDirty(): boolean {

        return this.isNotEmpty(this.goalDetailComponent) && this.goalDetailComponent.isDirty();
    }

    public isLoaded(): boolean {
     
        return this.sectionIsLoaded;
    }

    public save() {

        if (this.isNotEmpty(this.goalDetailComponent)) {

            this.goalDetailComponent.saveGoalEdit(false, false);
        }
    }

    public refreshCarePlan() {

        this.getCarePlan();
    }

    public getSectionData() {
    
        this.sectionIsLoaded = true;

        this.getCarePlan();
        this.getCareGoalText();
    }

    private getCarePlan() {        
        
        if (this.isNotEmpty(this.getCarePlanGoalsSubscription)) {
            this.getCarePlanGoalsSubscription.unsubscribe();
        }

        this.getCarePlanGoalsSubscription = this._sectionService.getCarePlanData(this.memberId).subscribe({
            next: (resultData: CarePlanGoalDto[]) => {

                if (this.isNotEmpty(resultData)) {
             
                    this.goalsList = [];
                    
                    resultData.forEach(result => {
                        this.goalsList.push(result);
 
                    });

                    this.removeMarkUp();
                    this.sortGoals();
                    this.formatTargetDates();

                    this.sectionLoadedEvent.emit('CareplanSection');
                    
                    this.createOpenGoalsList();
                }
            },
            error: (err: any) => { 
                console.error(err);
                this.sectionLoadedEvent.emit('CareplanSection');
            }
        });
    }

    public goalLoadedEvent($event: any) {

        this.sectionLoadedEvent.emit('CareplanSection');
    }

    private getCareGoalText() {
        
        if (this.isNotEmpty(this.getCareGoalTextSubscription)) {
            this.getCareGoalTextSubscription.unsubscribe();
        }

        this.getCareGoalTextSubscription = this._sectionService.getCareGoalText(this.memberId).subscribe({
            next: (resultData) => {

                if (this.isNotEmpty(resultData)) {
                    
                    this.careGoalText = resultData.careGoalText;
                }
            },
            error: (err: any) => { 
                console.error(err);
            }
        });
    }

    public saveCareGoalText() {
        
        if (this.isNotEmpty(this.persistCareGoalTextSubscription)) {
            this.persistCareGoalTextSubscription.unsubscribe();
        }

        let careGoalTextDto: CareGoalTextDto = new CareGoalTextDto();
        careGoalTextDto.memberId = this.memberId;
        careGoalTextDto.careGoalText = this.careGoalText;

        this.persistCareGoalTextSubscription = this._sectionService.persistCareGoalText(careGoalTextDto).subscribe({
            next: (resultData) => {

                if (this.isNotEmpty(resultData)) {
                    
                    this.careGoalText = resultData.careGoalText;
                }

                this.careGoalTextIsReadOnly = true;
            },
            error: (err: any) => { 
                console.error(err);
            }
        });
    }

    public persistGoals(){


    }

    public editGoal(rowData: CarePlanGoalDto) {

        this.showGoalDetailPanel = true;
    
        this._changeDetectorRef.detectChanges();

        let editable: boolean = (rowData.goalStatusId === 'available' || rowData.goalStatusId === 'inprogress');

        this.goalDetailComponent.loadMemberGoal(rowData, this.memberId, editable);
    }

    public cancelGoalEdit($event: any) {
        this.showGoalDetailPanel = false;
    }

    public saveGoalEdit($event: any) {

        this.showGoalDetailPanel = false;

        this.refreshCarePlan();
    }

    public editCareGoalText() {

        this.careGoalTextIsReadOnly = false;
    }

    public cancelEditCareGoalText() {

        this.careGoalTextIsReadOnly = true;
    }

    private sortGoals() {

        let activeGoals: CarePlanGoalDto[] = this.goalsList.filter(x => (x.goalStatusId === 'available' || x.goalStatusId === 'inprogress'));

        let inactiveGoals: CarePlanGoalDto[] = this.goalsList.filter(x => (x.goalStatusId !== 'available' && x.goalStatusId !== 'inprogress'));

        let sortedActiveGoals: CarePlanGoalDto[] = activeGoals.sort((a: CarePlanGoalDto, b: CarePlanGoalDto) => {

            if (a.goalOrder < b.goalOrder) {
                return -1;
            } else if (a.goalOrder > b.goalOrder) {
                return 1;
            }

            return 0;
        });

        let sortedInactiveGoals: CarePlanGoalDto[] = inactiveGoals.sort((a: CarePlanGoalDto, b: CarePlanGoalDto) => {

            if (a.goalOrder < b.goalOrder) {
                return -1;
            } else if (a.goalOrder > b.goalOrder) {
                return 1;
            }

            return 0;
        });

        this.goalsList = sortedActiveGoals.concat(sortedInactiveGoals);
    }

    private removeMarkUp() {

        if (this.isNotEmpty(this.goalsList)) {

            this.goalsList.forEach(goal => {
                
                goal.goalDisplayText = goal.goalDisplayText.replace('<b>', '');
                goal.goalDisplayText = goal.goalDisplayText.replace('</b>', '');
            });
        }
    }

    private formatTargetDates() {
     
        if (this.isNotEmpty(this.goalsList)) {

            this.goalsList.forEach(goal => {
         
                if (this.isNotEmpty(goal.targetDate)) {
             
                    const targetDate = DateTime.fromISO(goal.targetDate).startOf('day');
                    const nowDate = DateTime.now().startOf('day');
                    const diff = Interval.fromDateTimes(nowDate, targetDate);
                    const diffDays = diff.length('days');

                    if (diffDays >= 0 && diffDays < 1) {
                        
                        goal.targetDateDueDays = 'Due today'
                        goal.targetDateClass = 'goal-target-red';

                    } else if (diffDays > 0 && diffDays < 8) {
                        
                        goal.targetDateDueDays = Math.ceil(diffDays) + ' days to complete';
                        goal.targetDateClass = 'goal-target-yellow';

                    } else if (diffDays > 0) {
                        
                        // do nothing

                    } else {

                        goal.targetDateDueDays = 'Past due'
                        goal.targetDateClass = 'goal-target-red';
                    }

                    goal.targetDate = (DateTime.fromISO(goal.targetDate).toFormat('MM/dd/yyyy'));
                }

                if (this.isNotEmpty(goal.goalActivityDate)) {
                    
                    goal.goalActivityDate = (DateTime.fromISO(goal.goalActivityDate).toFormat('MM/dd/yyyy HH:mm'));
                }
                if (this.isNotEmpty(goal.updatedDate)) {
                    
                    goal.updatedDate = (DateTime.fromISO(goal.updatedDate).toFormat('MM/dd/yyyy HH:mm'));
                }
                else{
                    goal.updatedDate  = (DateTime.fromISO(goal.createdDate).toFormat('MM/dd/yyyy HH:mm'));
                }

                if (this.isNotEmpty(goal.updatedBy)) {
                    
                    goal.createdBy = goal.updatedBy;
                }
            });
        }
    }

    private initializeColumns() {

        this.goalTableColumns = [];
        
        let tableColumnColor: TableColumnDto = new TableColumnDto();
        tableColumnColor.field = 'color';
        tableColumnColor.header = '';
        tableColumnColor.width = '2%';
        tableColumnColor.align = 'center';

        let tableColumnGoalName: TableColumnDto = new TableColumnDto();
        tableColumnGoalName.field = 'goalDisplayText';
        tableColumnGoalName.header = 'Goal';
        tableColumnGoalName.width = '26%';
        tableColumnGoalName.align = 'left';
        
        let tableColumnDueDate: TableColumnDto = new TableColumnDto();
        tableColumnDueDate.field = 'targetDate';
        tableColumnDueDate.header = 'Due Date';
        tableColumnDueDate.width = '10%';
        tableColumnDueDate.align = 'center';

        let tableColumnStatus: TableColumnDto = new TableColumnDto();
        tableColumnStatus.field = 'goalStatusDescription';
        tableColumnStatus.header = 'Status';
        tableColumnStatus.width = '8%';
        tableColumnStatus.align = 'center';

        let tableColumnMemberPriority: TableColumnDto = new TableColumnDto();
        tableColumnMemberPriority.field = 'goalMemberPriorityDesc';
        tableColumnMemberPriority.header = 'Member Priority';
        tableColumnMemberPriority.width = '10%';
        tableColumnMemberPriority.align = 'center';

        let tableColumnCareGiverPriority: TableColumnDto = new TableColumnDto();
        tableColumnCareGiverPriority.field = 'goalCareGiverPriorityDesc';
        tableColumnCareGiverPriority.header = 'Care Giver Priority';
        tableColumnCareGiverPriority.width = '11%';
        tableColumnCareGiverPriority.align = 'center';

        let tableColumnCareManagerPriority: TableColumnDto = new TableColumnDto();
        tableColumnCareManagerPriority.field = 'goalCareManagerPriorityDesc';
        tableColumnCareManagerPriority.header = 'Care Manager Priority';
        tableColumnCareManagerPriority.width = '13%';
        tableColumnCareManagerPriority.align = 'center';

        let tableColumnGroup: TableColumnDto = new TableColumnDto();
        tableColumnGroup.field = 'goalGroupName';
        tableColumnGroup.header = 'Group';
        tableColumnGroup.width = '15%';
        tableColumnGroup.align = 'center';

        let tableColumnActions: TableColumnDto = new TableColumnDto();
        tableColumnActions.field = 'action';
        tableColumnActions.header = '';
        tableColumnActions.width = '5%';
        tableColumnActions.align = 'center';
        
        this.goalTableColumns.push(tableColumnColor);
        this.goalTableColumns.push(tableColumnGoalName);
        this.goalTableColumns.push(tableColumnDueDate);
        this.goalTableColumns.push(tableColumnStatus);
        this.goalTableColumns.push(tableColumnMemberPriority);
        this.goalTableColumns.push(tableColumnCareGiverPriority);
        this.goalTableColumns.push(tableColumnCareManagerPriority);
        this.goalTableColumns.push(tableColumnGroup);
        this.goalTableColumns.push(tableColumnActions);


        this.goalBatchTableColumns = [];
        
        let tableBatchColumnColor: TableColumnDto = new TableColumnDto();
        tableBatchColumnColor.field = 'color';
        tableBatchColumnColor.header = '';
        tableBatchColumnColor.width = '2%';
        tableBatchColumnColor.align = 'center';

        let tableBatchColumnGoalName: TableColumnDto = new TableColumnDto();
        tableBatchColumnGoalName.field = 'goalDisplayText';
        tableBatchColumnGoalName.header = 'Goal';
        tableBatchColumnGoalName.width = '75%';
        tableBatchColumnGoalName.align = 'left';
        
        let tableBatchColumnStatus: TableColumnDto = new TableColumnDto();
        tableBatchColumnStatus.field = 'goalStatusDescription';
        tableBatchColumnStatus.header = 'Status';
        tableBatchColumnStatus.width = '11.5%';
        tableBatchColumnStatus.align = 'center';
                
        let tableBatchColumnActions: TableColumnDto = new TableColumnDto();
        tableBatchColumnActions.field = 'update';
        tableBatchColumnActions.header = 'Update';
        tableBatchColumnActions.width = '11.5%';
        tableBatchColumnActions.align = 'center';
       
        this.goalBatchTableColumns.push(tableBatchColumnColor);
        this.goalBatchTableColumns.push(tableBatchColumnGoalName);
        this.goalBatchTableColumns.push(tableBatchColumnStatus);
        this.goalBatchTableColumns.push(tableBatchColumnActions);

  
    }

    public getNumColumns(): number {
        return this.isEmpty(this.goalTableColumns) ? 0 : this.goalTableColumns.length;
    }

    public getNumBatchColumns(): number {
        return this.isEmpty(this.goalBatchTableColumns) ? 0 : this.goalBatchTableColumns.length;
    }

    public filterTable($event: any, goalsTable: Table, columnField: any) {      
        goalsTable.filter($event.target.value, columnField, 'contains');
    }

    public getTableHeight(): string {
        return '600px';
    }

    public customSort($event: { field: string; data: any[]; order: number; }) {

        let isDate: boolean = false;

        if (this.isNotEmpty($event) && this.isNotEmpty($event.field) && $event.field.toLowerCase().indexOf('date') > -1) {
            
            isDate = true;
        }

        $event.data.sort((data1, data2) => {
    
            let value1 = null;
            let value2 = null;
    
            if ( isDate) {
    
                let val1 = data1[$event.field];
                let val2 = data2[$event.field];
                value1 = new Date(val1);
                value2 = new Date(val2);
    
            } else {
    
                value1 = data1[$event.field];
                value2 = data2[$event.field];
            }
    
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ($event.order * result);
        });
    }

    private sortList(list: CarePlanProfileItemDto[]): CarePlanProfileItemDto[] {

        return list.sort((a: CarePlanProfileItemDto, b: CarePlanProfileItemDto) => {

            if (a.itemOrder < b.itemOrder) {
                return -1;
            } else if (a.itemOrder > b.itemOrder) {
                return 1;
            }

            return 0;
        });
    }

    private sortDropdownList(list: SelectItem[]): SelectItem[] {

        return list.sort((a:SelectItem, b: SelectItem) => {

            if (a.label < b.label ) {
                return -1;
            } else if (a.label  > b.label ) {
                return 1;
            }

            return 0;
        });
    }

    
    ngOnDestroy(): void {

        if(this.getGoalInterventionStatusProfileSubscription !== null){
            this.getGoalInterventionStatusProfileSubscription.unsubscribe();
        }

        if (this.getCarePlanGoalsSubscription !== null) {
            this.getCarePlanGoalsSubscription.unsubscribe();
        }

        if (this.getCareGoalTextSubscription !== null) {
            this.getCareGoalTextSubscription.unsubscribe();
        }

        if (this.persistCareGoalTextSubscription !== null) {
            this.persistCareGoalTextSubscription.unsubscribe();
        }

        if (this.persistCareGoalsSubscription !== null) {
            this.persistCareGoalsSubscription.unsubscribe();
        }
        
    }
}
