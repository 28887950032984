import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';

import { DocumentsSectionComponent } from './documents-section.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    
    TableModule, InputTextModule, ButtonModule
  ],
  declarations: [DocumentsSectionComponent],
  providers: [DocumentsSectionComponent],
  exports: [DocumentsSectionComponent]  
})
export class DocumentsSectionModule {}
