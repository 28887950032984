import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { MagiclinkComponent } from './auth/components/magiclink/magiclink.component';
import { ResetComponent } from 'src/app/auth/components/reset/reset.component';
import { SignUpComponent } from 'src/app/auth/components/sign-up/sign-up.component';
import { SignUpForgotPasswordComponent } from 'src/app/auth/components/sign-up-forgot-password/sign-up-forgot-password.component';
import { SignUpUserdetailsComponent } from './auth/components/sign-up-userdetails/sign-up-userdetails.component';
import { SignUpDomainSpecificComponent } from 'src/app/auth/components/sign-up-domain-specific/sign-up-domain-specific.component';
import { SignUpAcknowledgementComponent } from 'src/app/auth/components/sign-up-acknowledgement/sign-up-acknowledgement.component';
import { AuthGuard } from './auth/guards/auth.guard';

import { EditPortalUserComponent } from 'src/app/auth/components/edit-portaluser/edit-portaluser.component';
import { HomeComponent } from 'src/app/auth/components/home/home.component';
import { MemberPageComponent } from 'src/app/member/member-page/member-page.component';

const routes: Routes = [
  { path:"", redirectTo:"login", pathMatch:"full" },
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'sign-up-forgot-password', component: SignUpForgotPasswordComponent },
  { path: 'sign-up-userdetails', component: SignUpUserdetailsComponent },
  { path: 'sign-up-domain-specific', component: SignUpDomainSpecificComponent },
  { path: 'sign-up-acknowledgement', component: SignUpAcknowledgementComponent },
  { path: 'reset/:id', component: ResetComponent },
  { path: 'ml', component: MagiclinkComponent },
  { path: 'users', component: EditPortalUserComponent },
  
  { path: 'home', component: MemberPageComponent },  // this is temporary. member page will be direct linked on the route below

  { path: 'member/:memberid', component: MemberPageComponent, canActivate: [AuthGuard]},
  { path: 'member/:memberid/:memberassessmentid', component: MemberPageComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
