
export class AssessmentDefinitionDto {
    public id?: string;
    public assessmentName: string;
    public assessmentDescription?: string;
    public assessmentVersion: number;
    public e24Id: number;
    public e24MemberId: number;
    public isDeleted: boolean = false;
};


export class E24MemberIdTraversalIdDto {
    public E24MemberId: string;
    public E24TraversalId: string;    
};

export class AssessmentFactResolvedListDto {
    public resolveList: AssessmentFactResolvedDto[];
}

export class AssessmentFactResolvedDto {    
    public paramName: string;
    public resolveResult: string;
};

export class ContentEnvConfigsDto {
    expert24Url: string;
}

export class AssessmentDto {
    id: string;
    memberId: string;
    assessmentDefinitionId: string;
    status: string;
    assignmentDate: string;
    e24MemberId: string;
    e24TraversalId: string;
}

export class AssessmentInitDto {
    public Id: string;
    public E24TraversalId: string;
    public E24MemberId: string;
    public Status: string;
    public RowSource: string;
    public PrepopDataList: AssessmentDataDto[] = [];
}

export class AssessmentCompleteDto {
    public Id: string;
    public Status: string;
    public RowSource: string;
    public ResponseList: AssessmentResponseDto[] = [];
    public OutcomeList: AssessmentOutcomeDto[] = [];
    public CarePlanGoalList: CarePlanGoalDto[] = [];
}

export class AssessmentDataDto {
    public PrepopName: string;
    public PrepopValue: string;
}

export class AssessmentResponseDto {
    public MemberHealthAssessmentId: string;
    public NodeId: string;
    public QuestionId: string;
    public QuestionText: string;
    public AnswerId: string;
    public AnswerText: string;
    public RowSource: string;
}

export class AssessmentOutcomeDto {
    public MemberHealthAssessmentId: string;
    public Category: string;
    public SubCategory1: string;
    public SubCategory2: string;
    public DisplayText: string;
    public ExpertText: string;
    public Title: string;
    public Explanation: string;
    public Truncated: string;
    public RowSource: string;
    public PropertyList: AssessmentOutcomePropertyDto[] = [];
}

export class AssessmentOutcomePropertyDto {
    public PropertyName: string;
    public PropertyValue: string;
}

export class CreateMemberHealthAssessmentDto {
    public memberId: string;
    public assessmentDefinitionId: string;
    public e24TraversalId: string;
    public e24MemberId: string;
    public status: string;
    public assignmentDate: string;
    public startDate?: string;
    public completionDate?: string;
    public rowSource: string;
}

export class CreateMemberHealthAssessmentResponse {
    public memberHealthAssessmentId: string;
    public isNotFound: boolean;
    public errors: string;
}

export class CarePlanGoalDto {
	public Id: string;
	public GoalExpert24Id: number;
    public GoalContentCompareId: string;
    public GoalDisplayText: string;
	public GoalVersion: string;
	public GoalOrder: number;
    public RowSource: string;
    public BarriersList: CarePlanBarrierDto[] = [];
}

export class CarePlanGoalGroupDto {
	public GoalGroupId: string;
	public GoalGroupName: string;
    public RowSource: string;
}

export class CarePlanBarrierDto {	
    public Id: string;
	public BarrierExpert24Id: number;
	public BarrierDescription: string;
    public BarrierVersion: string;
	public BarrierOrder: number;
    public RowSource: string;
    public InterventionsList: CarePlanInterventionDto[] = [];
}
	 
export class CarePlanInterventionDto {
	public Id: string;
	public InterventionExpert24Id: number;
	public InterventionDescription: string;
	public InterventionVersion: string;
	public InterventionOrder: number;
    public RowSource: string;
    public EducationDocument: CarePlanInterventionDocumentDto;
    public Instruction: CarePlanInterventionInstructionDto;
}
	 
export class CarePlanGoalBarrierDto {
	public Id: string;
	public GoalId: string;
	public BarrierId: string;
    public RowSource: string;
}	 
	 
export class CarePlanBarrierInterventionDto {
	public Id: string;
	public BarrierId: string;
	public InterventionId: string;
    public RowSource: string;
}	

export class CarePlanInterventionDocumentDto {
	public Id: string;
	public DocumentId: string;
    public DocumentVersion: string;
    public RowSource: string;
}

export class CarePlanInterventionInstructionDto {
	public Id: string;
	public InstructionTitle: string; 
	public InstructionText: string;
    public RowSource: string;
}
