import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../auth/models/app-config";
import { Constant } from "../../auth/utils/constant";
import { environment } from "src/environments/environment";



@Injectable({
    providedIn: 'root'
 })

export class ClientConfigService {     
    private configUrl: string = '/MemberSiteVisit/GetClientConfigById/##key##/##clientId##';

    headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        @Inject(Constant.memberManagerApiBaseUrl) private appConfig: AppConfig) {

        this.headers = new HttpHeaders()
        .set('UserName', 'Test')
        .set('RoleId', '1');

        environment.apiUrl = appConfig.memberManagerApiBaseUrl; 
    }

    public getConfig(key:string, clientId:string):any{

        let url = this.appConfig.memberManagerApiBaseUrl + this.configUrl;
      
          if(key && clientId){
              url = url.replace("##key##",key);   
              url = url.replace("##clientId##",clientId);   
              return this.http.get(url, { responseType: 'text' });
          }
      }

}
