<section class="vh-100">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9 col-lg-6 px-0 col-xl-7">
          <div class="image">
            <img src="../assets/images/background_Image_5.png" alt="Login image" class="w-100 vh-100"
              style="object-fit: cover; object-position: left;">
            <div class="hero-section">
              <p class="welcome text-center">Welcome<p>
            </div>
            <div class="hero-info">
                <p> to the Agent Portal </p>
            </div>
          </div>
        </div>
        <div class="login-details col-md-8 col-lg-6 col-xl-5 mt-4">
        
          <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm"
            [appMatchPassword]="['password', 'confirmPassword']" novalidate>
            <div class="lead d-flex flex-row align-items-center justify-content-center text-center ">
              <p class="mb-2 me-3">Sign Up</p>
            </div>
            <span>
              <h5 class="fw-bold">Domain Specific</h5>
            </span>
            <span class="small-text">This is an area for domain specific text or additional instructions.</span>
              <div class="text-center" *ngIf="isError">
                <h6 style="color: red;" class="error">
                  {{error}}
                </h6> 
              </div>
            <!-- User Details -->
            <div class="form-outline text-center">
              <!-- <input type="registrationCode" id="" class="form-control form-control-lg" placeholder="Registration Code" /> -->
              <img src="../../assets/images/email.png" class="icons" alt="">
              <input type="email" name="emailAddress" class="form-control form-control-lg" placeholder="Email Address"
                [(ngModel)]="domainUser.emailAddress" #email="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email>
              <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                <div *ngIf="email.errors?.['required']">Email is required</div>
                <div *ngIf="email.errors?.['email']">Email must be a valid email address</div>
              </div>
            </div>
  
            <div class="form-outline text-center">
              <img src="../../assets/images/shield.png" class="icons" alt=""> 
              <span (click)="hideShowPass()" class="eyeicons fa {{pasEyeIcon}} fa-lg"></span>
              <input type="{{passType}}" name="password" class="form-control form-control-lg" placeholder="Enter Password"
                [(ngModel)]="domainUser.password" #password="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required minlength="6">
                <app-password-strength [passwordToCheck]="f.value.password"></app-password-strength>
              <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                <div *ngIf="password.errors?.['required']">Password is required</div>
                <div *ngIf="password.errors?.['']">Password must be at least 6 characters</div>
              </div>
            </div>
            <div class="form-outline text-center mb-4">
              <img src="../../assets/images/shield.png" class="icons" alt="">
               <span (click)="hideShowConfPass()" class="eyeicons fa {{confPassEyeIcon}} fa-lg"></span>
              <input type="{{confPassType}}" name="confirmPassword" class="form-control form-control-lg" placeholder="Confirm Password"
                [(ngModel)]="domainUser.confirmPassword" #confirmPassword="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && confirmPassword.invalid }" required minlength="1">
              <div *ngIf="f.submitted && confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="confirmPassword.errors?.['required']">
                  Confirm Password is required
                </div>
                <div *ngIf="confirmPassword.errors?.['matching']">
                  Confirm Password does not match
                </div>
              </div>
            </div>
            <div class="form-outline text-center mb-4">
              <!-- <input type="tel" id="" class="form-control form-control-md" placeholder="Phone number" required /> -->
              <input type="text" name="phonrNumber" class="form-control form-control-lg"
              [(ngModel)]="domainUser.userMfas.value" #phonrNumber="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && phonrNumber.invalid }" required maxlength="10" minlength="10" />
              <div *ngIf="f.submitted && phonrNumber.errors" class="invalid-feedback">
                <div *ngIf="phonrNumber.errors?.['required']"> Phone number is required</div>
                <div *ngIf="phonrNumber.errors?.['minlength']"> Enter 10 digit number </div>
              </div>
            </div>
            <div class="form-outline text-center mb-4">
              <select class="form-select form-control-lg mb-1" name="phoneType"  style="height:3.6em;"
                  #phoneType="ngModel" [(ngModel)]="domainUser.userMfas.type"
              [ngClass]="{ 'is-invalid': f.submitted && phoneType.invalid }" required>
                <option value="0">Select Phone Type</option>
                <option value="1">CDH</option>
                <option value="2">Option two</option>
              </select>
              <div *ngIf="f.submitted && phoneType.errors" class="invalid-feedback">
                <div *ngIf="phoneType.errors?.['required']">Phone Type is required</div>
              </div>
            </div>
  
            <div class="text-center pt-2 mt-4 mb-1 pb-4">
              <button type="button" style="width: 48% ;margin-right: 4%; float: left;" class="btn btn-md btn-block log-in" routerLink="/sign-up-userdetails">Back</button>
              <button type="submit" style="width: 48%;" class="btn btn-md btn-block log-in" >Next Step</button>
            </div>
  
          </form>
        </div>
      </div>
    </div>
  </section>

