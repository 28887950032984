<div id="goalDetailDiv">
    <ng-container *ngIf="dataLoaded">
        <div class="p-grid" style="padding: 0">
            <div class="p-col-12 goal-detail-panel-top goal-row-padding">

                <div class="p-grid">
                    <div class="p-col-8">
                        <div class="goal-detail-name">Goal: {{goal.goalDisplayText}}</div>
                        <div *ngFor="let barrier of barriers" style="font-weight: 500;">
                            Barrier: {{barrier}}
                        </div>
                    </div>
                    <div class="p-col-2">
                        <button [disabled]="disableCloseIntervetionsButton()" (click)="openCloseInterventionBatchDialog()"  class="btn button-1" type="button" style="margin-top: 2em;">Close Interventions</button>
                     </div>
                    <div class="p-col-2">
                        <label style="font-weight: 500; margin-top: 0.5em;">Due Date:</label>
                        <p-calendar dateFormat="mm/dd/yy" inputId="targetDate" [(ngModel)]="goal.targetDate" [showWeek]="true" 
                            [minDate]="minAssignDate" [style]="{'width':'100%'}" [disabled]="!goalIsEditable">
                        </p-calendar>
                        <span *ngIf="goal.targetDateDueDays !== null && goalIsEditable" [ngClass]="{'goal-target-warning-yellow':(goal.targetDateClass === 'goal-target-yellow'), 
                                                                                  'goal-target-warning-red':(goal.targetDateClass === 'goal-target-red')}">
                            {{goal.targetDateDueDays}}
                        </span>
                    </div>
                    <div class="p-col-2">
                        <label for='goalStatusDropdown' class="goal-dd-label">Status</label>
                        <p-dropdown id="goalStatusDropdown" [options]="goalStatuses" [(ngModel)]="goal.goalStatusId" [style]="{'width':'100%'}" scrollHeight="600px" [readonly]="!goalIsEditable"></p-dropdown>
                    </div>
                    <div class="p-col-4">
                        <label class="cp-activity-label" style="margin-top: 1.25em">Last updated {{goal.goalActivityDate}}</label>
                        <label class="cp-activity-label">by {{goal.createdBy}}</label>
                    </div>
                    <div class="p-col-2">
                        <label for='memberPrioritiesDropdown' class="goal-dd-label">Member Priority</label>
                        <p-dropdown id="memberPrioritiesDropdown" [options]="memberPriorities" [(ngModel)]="goal.goalMemberPriorityId" [style]="{'width':'100%'}" scrollHeight="400px" [readonly]="!goalIsEditable"></p-dropdown>
                    </div>
                    <div class="p-col-2">
                        <label for='careGiverPrioritiesDropdown' class="goal-dd-label">Care Giver Priority</label>
                        <p-dropdown id="careGiverPrioritiesDropdown" [options]="careGiverPriorities" [(ngModel)]="goal.goalCareGiverPriorityId" [style]="{'width':'100%'}" scrollHeight="400px" [readonly]="!goalIsEditable"></p-dropdown>
                    </div>
                    <div class="p-col-2">
                        <label for='careManagerPrioritiesDropdown' class="goal-dd-label">Care Manager Priority</label>
                        <p-dropdown id="careManagerPrioritiesDropdown" [options]="careManagerPriorities" [(ngModel)]="goal.goalCareManagerPriorityId" [style]="{'width':'100%'}" scrollHeight="400px" [readonly]="!goalIsEditable"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="p-col-6 intervention-header" style="padding-right: 0; padding-left: 0.9em; border-top: 1px solid lightgray;">
                Interventions (* = mandatory to set goal to met)
            </div>
            <div class="p-col-6 intervention-header" style="padding-right: 0; padding-left: 0.9em; border-right: 1px solid lightgray; border-top: 1px solid lightgray;">
                Details
            </div>
            <div class="p-col-6 intervention-list" style="padding: 0">
                <p-table id="interventionsTable" #interventionsTable [columns]="interventionColumns" [value]="interventions" 
                    selectionMode="single" (onRowSelect)="interventionRowSelected($event)" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">

                    <ng-template pTemplate="body" let-columns="columns" let-rowData>
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" style="white-space: break-spaces; padding-left: 0.7em;" [style.width]="col.width" 
                                [ngClass]="{'table-cell-align-center':(col.align==='center'), 'table-cell-align-left':(col.align!=='center')}">

                                <div *ngIf="col.field === 'color'" [ngClass]="{'intervention-status-default':(rowData['interventionStatusId'] === 'available'), 
                                                                            'intervention-status-green':(rowData['interventionStatusId'] === 'complete'),
                                                                            'intervention-status-yellow':(rowData['interventionStatusId'] === 'inprogress'),
                                                                            'intervention-status-grey':(rowData['interventionStatusId'] !== 'inprogress') 
                                                                                && rowData['interventionStatusId'] !== 'available' 
                                                                                && rowData['interventionStatusId'] !== 'complete'}">
                                    &nbsp;
                                </div>
                                <div *ngIf="col.field !== 'color'" >{{rowData[col.field]}}</div>
                            </td>
                        </tr>
                    </ng-template>                
                </p-table>
            </div>
            <div class="p-col-6 intervention-detail-panel">
                <div *ngIf="currentIntervention !== null && currentIntervention !== undefined" class="p-grid">

                    <div class="p-col-12 intervention-detail-name">
                        <div style="padding: 0 0.3em">
                            {{currentIntervention.interventionDescription}}
                        </div>
                    </div>

                    <div class="p-col-12" style="padding: 0.5em 0.8em">
                        <label for='interventionStatusDropdown' class="goal-dd-label">Status</label>
                        <p-dropdown id="interventionStatusDropdown" [options]="interventionStatuses" [(ngModel)]="currentIntervention.interventionStatusId" [style]="{'width':'300px'}" 
                            scrollHeight="400px" [readonly]="!goalIsEditable" (click)="checkGoalStatus()"></p-dropdown>
                    </div>

                    <div class="p-col-12" style="padding: 0.5em 0.8em 0 0.8em" *ngIf="currentIntervention !== null && currentIntervention.instructions !== null && currentIntervention.instructions !== undefined && currentIntervention.instructions.length > 0">
                        <div *ngFor="let instruction of currentIntervention.instructions">
                            <label class="goal-dd-label">{{instruction.instructionTitle}}</label>
                            <!-- <textarea class="fullWidth" rows="6" pInputTextarea [(ngModel)]="instruction.instructionText"></textarea> -->
                            <div style="width: 100%; display: block;">
                                <pre [innerHTML]="instruction.instructionText" class="instruction-text">

                                </pre>
                            </div>                           
                        </div>
                    </div>

                    <div class="p-col-12" style="padding: 0.5em 0.8em" *ngIf="currentIntervention !== null && currentIntervention.documents !== null && currentIntervention.documents !== undefined && currentIntervention.documents.length > 0">
                        <label class="goal-dd-label">Education</label>
                        <div *ngFor="let education of currentIntervention.documents">
                            <div class="intervention-link" (click)="loadEducation(education)">{{education.documentName}}</div>
                        </div>
                    </div>

                    <div class="p-col-12" style="padding: 0.5em 0.8em">
                        <label class="whole-person-care-goal-label">Note</label>
                        <p-editor #editor (onInit)=onInit() 
                        [(ngModel)]="currentIntervention.interventionNote" [style]="{'height':'10em'}" [readonly]="!goalIsEditable">
                            <p-header>                        
                                <span class="ql-formats">
                                    <button class="ql-bold" aria-label="Bold"></button>
                                    <button class="ql-italic" aria-label="Italic"></button>
                                    <button class="ql-underline" aria-label="Underline"></button>
                                </span>
                                
                                <span class="ql-formats">
                                    <select class="ql-color"></select>
                                    <select class="ql-background"></select>
                                </span>
                            
                                <span class="ql-formats">
                                    <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
                                    <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
                                </span>
                            </p-header>
                        </p-editor>
                    </div>

                    <div class="p-col-12" style="padding: 0.5em 0.8em">
                        <label class="cp-activity-label">Last updated {{currentIntervention.interventionActivityDate}} by {{currentIntervention.interventionCreatedBy}}</label>
                    </div>
                </div>
            </div>

            <div class="p-col-12" style="margin-top: .8em; padding: 0">
                <div style="display: inline-flex; float: right">
                    <button class="btn button-1" type="button" (click)="cancelGoalEdit()">Cancel</button>
                    <button class="btn button-1" type="button" *ngIf="goalIsEditable" (click)="saveGoalEdit(false, false)" style="margin-left: 1em;">Save</button>
                    <button class="btn button-1" type="button" *ngIf="goalIsEditable" (click)="saveGoalEdit(false, true)" style="margin-left: 1em;">Save And Close</button>
                </div>
            </div>
        </div>
        <p-dialog [(visible)]="displayCloseInterventionBatch" header="{{messageCloseInterventions}}" id="closeInterventionDialog" [modal]="true" [style]="{'width': '900px'}" (onHide)="closeInterventionBatchDialog(interventionsBatchTable)" >
            
            <div class="p-col-6" style="padding-top: 5; padding-bottom:5">
                <label for='closedInterventionStatusDropdown' class="intervention-dd-label">Closed Intervention Status</label>
                <p-dropdown id="closedInterventionStatusDropdown" appendTo="body" [options]="closedInterventionStatuses" [(ngModel)]="closedInterventionStatus" [style]="{'width':'100%'}" scrollHeight="800px" ></p-dropdown>
            </div>

            <div class="p-col-12">
                <p-table id="interventionsBatchTable" #interventionsBatchTable [columns]="interventionBatchColumns" [value]="this.openInterventions" (sortFunction)="customSort($event)" 
                    [customSort]="true" sortMode="single" [scrollable]="true" [resizableColumns]="true" [scrollHeight]="getTableHeight()" 
                    styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">

                     <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field"  [style.width]="col.width" 
                                style="text-align: center !important; white-space: break-spaces;">
                                
                                {{col.header}}
                                <p-sortIcon  *ngIf="col.field !== 'update' && col.field !== 'action' && col.field !== 'color'" [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>

                        <tr>
                            <th *ngFor="let col of columns" style="flex: inherit" [style.width]="col.width"
                                [ngClass]="{'axis-align-center':(col.align==='center'), 'axis-align-left':(col.align!=='center')}">

                                <input  pInputText type="text"  [(ngModel)]='clearText' style="width:100%" *ngIf="col.field !== 'interventionStatusDescription' && col.field !== 'update' && col.field !== 'color'" 
                                    (input)="filterTable($event, interventionsBatchTable, col.field)">
                            
                                <span *ngIf="col.field === 'action'">&nbsp;</span>
                                <p-checkbox  [binary]="true" id ="selectAllInterventions"  style="padding-left: 2em"                             
                                    *ngIf="col.field === 'update' && col.field!=='color' && col.field !== 'interventionName' && col.field!=='interventionStatusDescription'" (onChange)="selectAllOpenInterventions($event)" [(ngModel)]="selectAllInterventions" ></p-checkbox>
                            </th>
                        </tr>  
                    </ng-template>

                    <ng-template pTemplate="body" let-columns="columns" let-rowData>
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" style="flex: inherit; white-space: break-spaces;" [style.width]="col.width" 
                                [ngClass]="{'table-cell-align-center':(col.align==='center'), 'table-cell-align-left':(col.align!=='center')}">

                                <div *ngIf="col.field === 'color'" 
                                    [ngClass]="{'intervention-status-default':(rowData['interventionStatusId']==='available'), 
                                                'intervention-status-green':(rowData['interventionStatusId']==='met'),
                                                'intervention-status-yellow':(rowData['interventionStatusId']==='inprogress'),
                                                'intervention-status-grey':(rowData['interventionStatusId'] !== 'inprogress') && rowData['interventionStatusId'] !== 'available' && rowData['interventionStatusId'] !== 'met'}">
                                    &nbsp;
                                </div>
                                <div *ngIf="col.field === 'interventionName'" >{{rowData[col.field]}}</div>
                                <div *ngIf="col.field !== 'update' && col.field !== 'color' && col.field !== 'interventionName'"  style="text-align: center">{{rowData[col.field]}}</div>
                                                             <div *ngIf="col.field === 'update'" style="padding-left: 2em;">
                                    <p-checkbox  [(ngModel)]="rowData.update"  [binary]="true"  name="updateInterventionCheckbox_{{rowData.interventionId}}" (onChange)="childUpdateInterventionsCheckbox()" >
						            </p-checkbox>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td id="noInterventionsFound" [attr.colspan]="getNumBatchColumns()" style="text-align: center !important">
                                {{noRecordsMessage}}
                            </td>
                        </tr>
                    </ng-template>
                    
                </p-table>
            </div>

            <div class="p-col-12" style="display: flex; justify-content: center; padding-top: 1.25em">
                <button pButton type="button" id="closeInterventionBatchButton" (click)="closeInterventionBatchDialog(interventionsBatchTable)" label="Close"></button>
                <button pButton type="button" id="updateInterventionBatchButton" [disabled]="disableUpdateInterventionsButton()" (click)="updateInterventionBatch()" label="Update Interventions" style="margin-left: 1em"></button>  
            </div>
        </p-dialog>
    </ng-container>
</div>
