import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { SecureMessageAttachmentDto, SecureMessageListingDto, AttachmentDto, EmailDto, 
    MessageConversionListingDto, UpdateAgentReadDateDto, FullfillmentDto, DocumentCategoryDto, CreateOrUpdateSecureMessageDto,
    SaveFullfillmentRequestDto, TemplateDefinitionDto, saveMemberDocumentRequestDto , TemplateResolutionRequestDto, GenAiEventDto} from './secure-message-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { TableColumnDto } from 'src/app/member/common/common-dtos';
import { SecureMessageSectionService } from './secure-message-section.service';
import { Table } from 'primeng/table';
import { FileUpload } from 'primeng/fileupload';
import { Constant } from 'src/app/auth/utils/constant';
import * as moment from 'moment';

import Quill from 'quill';
import { DocumentDto } from '../documents-section/documents-section.dtos';
import { DocumentsSectionService } from '../documents-section/documents-section.service';
import { TemplateResolutionResponseDto } from './secure-message-section.dtos';
import { ConfigurationService } from 'src/app/auth/services/Configuration/configuration.service';
import { nonEmptyWhitespaceValidator } from 'src/app/auth/utils/validation';
const FontAttributor: any = Quill.import('attributors/class/font');
const FontSize: any = Quill.import('attributors/style/size');

@Component({
    selector: 'secure-message-section',
    templateUrl: 'secure-message-section.component.html',
    styleUrls: ['secure-message-section.component.scss'],
    providers: [SecureMessageSectionService, DocumentsSectionService], 
    encapsulation: ViewEncapsulation.None
})

export class SecureMessageSectionComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input() memberId: string = null;
    @Input() clientId: string = null;
    @Input() userId: string = null;
    
    @Output() sectionLoadedEvent = new EventEmitter<string>();

    @ViewChild('messageText') messageTextElement: ElementRef;
    @ViewChild('dtFullfillments') dtFullfillments: Table;
    @ViewChild("editorContainer", { static: false }) editorContainer: ElementRef | null = null;
    @ViewChild("editTimeEditorContainer", { static: false }) editTimeEditorContainer: ElementRef | null = null;
    

    public saveClicked: boolean = false;
    public sectionIsLoaded: boolean = false;
    public displayMessageWindow: boolean = false;
    public displayDetailWindow: boolean = false;
    public displayAlertWindow: boolean = false;
    public displayAttachmentAlertWindow: boolean = false;
    public displayMandatoryAlertWindow: boolean = false;
    public displayAddFullfillmentWindow: boolean = false;
    public displayAddDocumentWindow: boolean = false;
    public tableColumns: TableColumnDto[] = [];
    public displayAddVanceFullfillmentWindow: boolean = false;

    public sectionForm: FormGroup;
    public messageSubjectControl: any;
    public messageTextControl: any;
    public selectedTemplateControl: any;
    public quillEditorTextControl: any;
   
    private saveSubscription: Subscription = null;
    private getSubscription: Subscription = null;
    private updateSubscription: Subscription = null;
    private uploadSubscription: Subscription = null;
    private emailSubscription: Subscription = null;
    private downloadSubscription: Subscription = null;
    private viewSubscription: Subscription = null;
    private resendSubscription: Subscription =  null;
    private getDocCategorySubscription: Subscription = null;
    private getMemberDocumentSubscription : Subscription = null;
    private getFullfillmentSubscription: Subscription = null;
    private saveFullfillmentSubscription: Subscription = null;
    private saveDocumentSuubscription : Subscription=null;
    private downloadFullfillmentSubscription: Subscription = null;
    private downloadDocumentSubscription : Subscription = null;
    private getTemplateDefinitionSubscription: Subscription = null;
    private resolveTemplateSubscription: Subscription = null;
    
    public emailAddressList: EmailDto[] = [];
    public primaryEmailAddress:EmailDto;
    public messageConverstions: MessageConversionListingDto[] = [];
    public secureMessageListings: SecureMessageListingDto[] = [];
    public selectedMessageListing: SecureMessageListingDto;
    public selectedAttachmentForDownload: AttachmentDto;
    public resendAttachments: AttachmentDto[] = [];
    public documentCategoryList: DocumentCategoryDto[] = [];
    public templateDefinitionList: TemplateDefinitionDto[] = [];
    public templateDefinition: TemplateDefinitionDto;

    public memberSecureMessageTableColumns: TableColumnDto[] = [];
    public memberSelectedMessageListingTableColumns: TableColumnDto[] = [];

    public memberSecureMessageSort: number = 1;
    public memberMessageAttachmentsSort: number = 1;
    public noRecordsMessage: string = "No records found";
    public createSecureMessageLabel: string = "Create Message";
    public refreshLabel: string = "Refresh";
    public cancelMessageLabel: string = "Cancel Message";
    public secureMessageLabel: string = "Secure Messages";
    public messageDetailsLabel: string = "Message Details";
    public unreadMessageCount: number = 0;
    public fileUploader: FileUpload;
    public secureMessageAttachmentDto: SecureMessageAttachmentDto;
    public agentPortalSenderType = "Agent";
    public memberstr: string = 'Policy Holder';
    public uploadFileErrors: Map<string, string> = null;
    public genAiFileErrorMessage: string = null;

    public todaysDate: string = '';

    public secureMessageUploadFileFormData: FormData = null;

    public validFilesForUploadCount: number = 0;

    private tabTitleService: Title;

    public iconList: Array<{ type: string, icon: string }> = [ // array of icon class list based on type
        { type: "xlsx", icon: "fa fa-file-excel-o" },
        { type: "pdf", icon: "fa fa-file-pdf-o" },
        { type: "jpg", icon: "fa fa-file-image-o" },
        { type: "txt", icon: "fa fa-file-text-o" }
    ];

    public replyMsgText: string;
    public currentMemberName: string;
    public agentFullName :string;
    public fullfillmentList: FullfillmentDto[] = [];
    public genAiFullfillmentList: FullfillmentDto[] = [];
    public selectedFullfillmentList: FullfillmentDto[] = [];
    public attachedFullfillmentList: FullfillmentDto[] = [];
    public fullfilmentSearchText: string;
    public selectedFullfillments: any;
    public selectedCategory: string;
    public selectedTemplateEditTime: string;
    private templateRequest: TemplateResolutionRequestDto = new TemplateResolutionRequestDto();
    public inputParams: string[];
    public resolveResult: string = '';

    public tableSort: number = -1;
    public documentsList: DocumentDto[] = [];
    public selectedDocumentList : DocumentDto[]=[];
    public attachedDocumentList : DocumentDto[]=[];

    public quillObj: Quill;
    private editor: Quill | undefined;
    private editTimeEditor: Quill | undefined;
    private table: any;
    private editTimetable: any;
    toolTipEventType: string = 'hover';
    tooltipPosition: string = 'top';
    showAdvanceSearch: boolean = false;
    sanitizedGenAIUrl: SafeResourceUrl;
  

    constructor(private _sectionService: SecureMessageSectionService, 
        private _tabTitleService: Title, 
        private sanitizer: DomSanitizer,
        private _documentservice : DocumentsSectionService, 
        private configService: ConfigurationService) {
        
        super();

        this.tabTitleService = _tabTitleService;
    }

    ngOnInit(): void {

        const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedIn'));
        this.agentFullName = `${loggedInUserDetails.firstName}`;
        this.initializeColumns();
        this.initializeForm();
        this.todaysDate = this.getTodaysDate();

        window.addEventListener("message", this.handleGenAiEvent.bind(this), false);
    }

    private async handleGenAiEvent(event: any) {
              
        if (event.data.type !== "document-viewed-event") {

            console.log('received unsupported genAI iframe message event type: ', event.data.type);
        
            return;

        } else {

            console.log('received genAI iframe message: ', event.data);

            let eventGenAiDocument = event.data as GenAiEventDto;

            if (eventGenAiDocument !== undefined && eventGenAiDocument !== null) {

                let genAiDocument: FullfillmentDto = await this.getDocumentDefinition(eventGenAiDocument.data.documentId);

                if (this.isNotEmpty(genAiDocument)) {

                    this.genAiFullfillmentList.push(genAiDocument);
                } else {

                    let message: string = 'Received GenAI document id ' + eventGenAiDocument.data.documentId + ' and found it doesn\'t exist in the database.';
                    console.error(message);

                    if (this.isEmpty(this.genAiFileErrorMessage)) {

                        this.genAiFileErrorMessage = message;

                    } else {

                        if (this.genAiFileErrorMessage.indexOf(message) === -1) {
                            this.genAiFileErrorMessage = this.genAiFileErrorMessage.trim() + '\n ' + message;
                        }
                    }
                }
            }
        }
    }

    public isDirty(): boolean {

        return this.sectionForm.dirty;
    }

    public isFormEmpty() {

        let formIsEmpty: boolean = true;

        if (this.isNotEmpty(this.sectionForm.get('messageSubject').value)) {
            formIsEmpty = false;
        }

        if(!this.selectedTemplateControl.value) {

            if (this.isNotEmpty(this.sectionForm.get('messageText').value)) {
                formIsEmpty = false;
            }
        } else {
            if (this.isNotEmpty(this.sectionForm.get('quillEditorText').value)) {
                formIsEmpty = false;
            } 
        }       

        if (this.secureMessageUploadFileFormData !== null) {
            formIsEmpty = false;
        }

        return formIsEmpty;
    }

    public close(): void {

        if (!this.isFormEmpty() && this.displayMessageWindow) {

            this.openAlertWindow();

        } else {

            this.displayMessageWindow = false;
            this.displayDetailWindow = false;
            this.selectedTemplateEditTime = "";
            this.editTimeEditor = undefined;
            this.editor = undefined;
            this.selectedTemplateControl.setValue("");
        }
    }

    public openAlertWindow() {
        this.displayAlertWindow = true;
    }

    public closeAlertWindow() {
        this.displayAlertWindow = false;
    }

    public okAlertWindow() {
        this.displayAlertWindow = false;
        this.displayMessageWindow = false;
        this.displayDetailWindow = false;
        this.editTimeEditor = undefined;
        this.editor = undefined;
    }

    public isLoaded(): boolean {
        return this.sectionIsLoaded;
    }

    public sendEmail(): void {

        this.save();
    }

    public triggerEmail(emailId:string,isFromReply?: boolean) {

        if (this.emailSubscription != null) {
            this.emailSubscription.unsubscribe();
        }

        this.emailSubscription = this._sectionService.sendEmail(this.memberId,emailId).subscribe({
            next: (saveResult) => {            
                if (isFromReply) {
                    this.viewConversionMessagesByMessageId(this.selectedMessageListing.conversationId);
                }
                this.displayMessageWindow = false;
                this.saveClicked = false;
                this.getSectionData();
            },
            error: (err: any) => {
                console.error(err);
                this.saveClicked = false;
            }
        });
    }

    public addMessage(): void {
        
        this.getTemplateDefinitionList();
        this.getEmailAddressDetails();
        this.displayMessageWindow = true;
        this.secureMessageUploadFileFormData = null;

        if (this.isNotEmpty(this.fileUploader)) {
            this.fileUploader.clear();
        }

        if (this.isNotEmpty(this.uploadFileErrors)) {
            this.uploadFileErrors = new Map<string, string>();
        }

        this.saveClicked = false;
        this.sectionForm.clearValidators();
        this.sectionForm.reset();
        this.attachedFullfillmentList = [];
        this.attachedDocumentList=[];
    }

    public handleRowSelect($event: SecureMessageListingDto): void {

        this.getTemplateDefinitionList();
        this.selectedMessageListing = $event;
        this.viewConversionMessagesByMessageId(this.selectedMessageListing.conversationId);
        let now = new Date();
        this.getEmailAddressDetails();
        this.resendAttachments = [];
        this.attachedFullfillmentList = [];
        this.attachedDocumentList=[];

        const updateAgentReadDateDto : UpdateAgentReadDateDto = {
                conversationId: this.selectedMessageListing.conversationId,
                agentReadDate: now.toISOString(),
                memberReadDate: this.selectedMessageListing.memberReadDate
        }

        this.updateSubscription = this._sectionService.updateAgentReadDate(updateAgentReadDateDto).subscribe({
            next: (saveResult) => {
                this.getSectionData();
                this.displayDetailWindow = true;
                this.secureMessageUploadFileFormData = null;
                this.replyMsgText = "";
                if (this.isNotEmpty(this.fileUploader)) {
                    this.fileUploader.clear();
                }
                if (this.isNotEmpty(this.uploadFileErrors)) {
                    this.uploadFileErrors = new Map<string, string>();
                }

            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    public updateMessage() {
        const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedIn'));
        let now = new Date();

        const updateSecureMessageDto : CreateOrUpdateSecureMessageDto = {
            "conversationId": this.selectedMessageListing.conversationId,
            "subject": this.selectedMessageListing.subject,
            "memberId": this.selectedMessageListing.memberId,
            "rowSource": this.selectedMessageListing.rowSource,           
            "text": this.selectedTemplateEditTime ? this.getEditorContent(this.editTimeEditor) : this.replyMsgText,
            "from":this.agentFullName,
            "to": this.memberstr,          
            "memberReadDate":null,
            "agentReadDate": now.toISOString(),
            "templateId": this.selectedTemplateEditTime ? this.selectedTemplateEditTime : null
        }

    this.updateSubscription = this._sectionService.sendReply(updateSecureMessageDto).subscribe({
        next: (saveResult: any) => {
            if (this.attachedFullfillmentList.length > 0 ) {
                let documentDefinitionIds = this.attachedFullfillmentList.map((el: FullfillmentDto) => el.id);
                this.saveFullfillment(saveResult.memberMessageId, documentDefinitionIds, true);
            }
            if(this.attachedDocumentList.length >0){
             
                let memberDocumentIds= this.attachedDocumentList.map((ez: DocumentDto)=> ez.id);
                this.saveMemberDocument(saveResult.memberMessageId, memberDocumentIds, true);
            }

            if(this.attachedFullfillmentList.length > 0 || this.attachedFullfillmentList.length > 0)
            {
                if (this.isNotEmpty(this.primaryEmailAddress) && this.isNotEmpty(this.primaryEmailAddress.emailId)) {
                    this.triggerEmail(this.primaryEmailAddress.emailId, true);
                }
            }
            else {
                this.triggerEmail(this.primaryEmailAddress.emailId,true);
            } 

            this.replyMsgText = "";
                      
            // if (this.isNotEmpty(this.secureMessageUploadFileFormData)) {    
            //     if(this.validFilesForUploadCount > 0) {
            //         this.saveAttachments(messageId, true);
            //     }
            // } else if (this.resendAttachments.length > 0) {
            //     this.resendAttachment(this.resendAttachments[0].messageId, messageId);
            // } else {
            //  this.triggerEmail(this.primaryEmailAddress.emailId,true);
            // }
            // this.secureMessageUploadFileFormData = null;
            // this.replyMsgText = "";
            // if (this.isNotEmpty(this.fileUploader)) {
            //     this.fileUploader.clear();
            // }
            // if(this.isNotEmpty(this.uploadFileErrors)){
            //     this.uploadFileErrors = new Map<string,string>();
            // }
            this.selectedTemplateEditTime = "";
            this.editTimeEditor = undefined;
           
        },
        error: (err: any) => { 
            console.error(err);
        }
    });

    }

    public isAgentUnRead($event: SecureMessageListingDto): boolean {

        let currentRow: SecureMessageListingDto = $event;
        let messageNotRead: boolean = true;

        messageNotRead = currentRow.isAgentUnRead;
        return messageNotRead;

    }

    public closeListing() {
        this.displayDetailWindow = false;
    }

    public truncateValue($event: any): SafeHtml {

        const strHtml = this.removeImgTags($event);
        
        if (this.isNotEmpty(strHtml) && strHtml.length > 93) {
            const truncatedHTML = this.getSubstringWithTags(strHtml, 93) + '...';
            return this.sanitizeHtml(truncatedHTML);
        } else {
            return this.sanitizeHtml(strHtml);
        }
    }

    public getUploadErrorKeys(): string[] {

        let keys: string[] = [];

        if (this.isNotEmpty(this.uploadFileErrors)) {
            for (let error of this.uploadFileErrors.keys()) {
                keys.push(error);
            }
        }

        return keys;

    }

    public getUploadError(key: string): string {

        return this.uploadFileErrors.get(key);

    }

    public removeErrorMessage(key: string) {

        this.uploadFileErrors.delete(key);
    }

    public uploadAttachmentFiles($event:any, fileUploader:FileUpload) {

        this.fileUploader = fileUploader;

        if (this.isEmpty(this.secureMessageUploadFileFormData)) {
            this.secureMessageUploadFileFormData = new FormData();
        }

        for (let file of $event.files) {

            if (file.size < 5242880) {
                if (this.secureMessageUploadFileFormData.has(file.name)) {
                    this.secureMessageUploadFileFormData.delete(file.name);
                }
                this.secureMessageUploadFileFormData.append(file.name, file);
            }

        }

    }

    getEmailAddressDetails() {

        this._sectionService.getDropdownValues(this.memberId).subscribe({
            next: (resultData) => {
                this.emailAddressList = resultData;
                this.primaryEmailAddress = this.emailAddressList.find(x=>x.isPrimary);
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    public validateAttachment($event:any, fileUploader:FileUpload) {

        let errorMessage: string = '';
        this.fileUploader = fileUploader;

        if (this.isEmpty(this.uploadFileErrors)) {
            this.uploadFileErrors = new Map<string, string>();
        }

        this.validFilesForUploadCount = 0;

        for (let file of $event.files) {

            if (file.size > 5242880) {

                errorMessage = '';
                errorMessage = file.name + ' exceeds the 5MB upload limit, total bytes: ' + file.size + ' ';
                this.uploadFileErrors.set(file.name, errorMessage);

            }
        }

        for (let file of $event.currentFiles) {

            if (file.size <= 5242880) {
                this.validFilesForUploadCount += 1;
            }
        }

    }

    public removeAttachmentFile($event: any, file: any) {
        if (this.isNotEmpty(this.secureMessageUploadFileFormData)) {

            if (file.size <= 5242880 && this.validFilesForUploadCount > 0) {
                this.validFilesForUploadCount = this.validFilesForUploadCount - 1;
            }

            this.secureMessageUploadFileFormData.delete(file.name);
            if (this.validFilesForUploadCount === 0) {
                this.secureMessageUploadFileFormData = null;
            }

            const index = this.fileUploader.files.indexOf(file);
            this.fileUploader.remove($event, index);
        }
    }

    public getDate() {
        let _now = new Date();
        return {
            month: _now.getMonth() + 1,
            day: _now.getDate(),
            year: _now.getFullYear(),
            hour: _now.getHours(),
            minute: _now.getMinutes(),
            seconds: _now.getSeconds()
        }
    }

    private getTodaysDate() {

        let now = this.getDate();

        let date: number = now.day;
        let month: number = now.month;
        let fullYear: number = now.year;
        let hours: number = now.hour;
        let minutes: number | string = now.minute;
        let seconds: number = now.seconds;

        let ampm: string = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let stringDate: string = month.toString() + "/" + date.toString() + "/" + fullYear.toString() + " " + hours.toString() + ":" + minutes.toString() + ":" + seconds.toString() + " " + ampm;
        return stringDate;
    }

    private initializeColumns() {

        this.memberSecureMessageTableColumns = [];

        let tableColumnCreatedDate: TableColumnDto = new TableColumnDto();
        tableColumnCreatedDate.field = 'createdDate';
        tableColumnCreatedDate.header = 'Created Date';
        tableColumnCreatedDate.width = '10%';
        tableColumnCreatedDate.align = 'center';

        let tableColumnFrom: TableColumnDto = new TableColumnDto();
        tableColumnFrom.field = 'from';
        tableColumnFrom.header = 'From';
        tableColumnFrom.width = '10%';
        tableColumnFrom.align = 'left';

        let tableColumnTo: TableColumnDto = new TableColumnDto();
        tableColumnTo.field = 'to';
        tableColumnTo.header = 'To';
        tableColumnTo.width = '10%';
        tableColumnTo.align = 'left';

        let tableColumnSubject: TableColumnDto = new TableColumnDto();
        tableColumnSubject.field = 'subject';
        tableColumnSubject.header = 'Subject';
        tableColumnSubject.width = '20%';
        tableColumnSubject.align = 'left';

        let tableColumnText: TableColumnDto = new TableColumnDto();
        tableColumnText.field = 'text';
        tableColumnText.header = 'Message';
        tableColumnText.width = '40%';
        tableColumnText.align = 'left';

        let tableColumnSecureMessageAction: TableColumnDto = new TableColumnDto();
        tableColumnSecureMessageAction.field = 'attachmentFulfillmentDocumentCount';
        tableColumnSecureMessageAction.header = 'Attachments';
        tableColumnSecureMessageAction.width = '10%';
        tableColumnSecureMessageAction.align = 'center';

        this.memberSecureMessageTableColumns.push(tableColumnCreatedDate);
        this.memberSecureMessageTableColumns.push(tableColumnFrom);
        this.memberSecureMessageTableColumns.push(tableColumnTo);
        this.memberSecureMessageTableColumns.push(tableColumnSubject);
        this.memberSecureMessageTableColumns.push(tableColumnText);
        this.memberSecureMessageTableColumns.push(tableColumnSecureMessageAction);


        this.memberSelectedMessageListingTableColumns = [];

        let tableColumnFileName: TableColumnDto = new TableColumnDto();
        tableColumnFileName.field = 'fileName';
        tableColumnFileName.header = 'Attachment File';
        tableColumnFileName.width = '95%';
        tableColumnFileName.align = 'left';

        let tableColumnDownloadAction: TableColumnDto = new TableColumnDto();
        tableColumnDownloadAction.field = 'action';
        tableColumnDownloadAction.header = '';
        tableColumnDownloadAction.width = '5%';
        tableColumnDownloadAction.align = 'center';

        this.memberSelectedMessageListingTableColumns.push(tableColumnFileName);
        this.memberSelectedMessageListingTableColumns.push(tableColumnDownloadAction);

        this.tableColumns = [];
 
        let columnName: TableColumnDto = new TableColumnDto();
        columnName.field = 'documentName';
        columnName.header = 'Document Name';
        columnName.width = '50%';
        columnName.align = 'left';
        
        let columnCreatedOn: TableColumnDto = new TableColumnDto();
        columnCreatedOn.field = 'createdDate';
        columnCreatedOn.header = 'Created On';
        columnCreatedOn.width = '30%';
        columnCreatedOn.align = 'center';

        let columnAction: TableColumnDto = new TableColumnDto();
        columnAction.field = 'action';
        columnAction.header = '';
        columnAction.width = '20%';
        columnAction.align = 'center';
        
        this.tableColumns.push(columnName);
        this.tableColumns.push(columnCreatedOn);
        this.tableColumns.push(columnAction);
    }

    public getTableHeight(): string {
        return '600px';
    }

    public getTableMessageAttachmentHeight(): string {
        return '600px';
    }

    public getNumColumns(): number {
        return this.isEmpty(this.memberSecureMessageTableColumns) ? 0 : this.memberSecureMessageTableColumns.length;
    }

    public getNumMessageAttachmentColumns(): number {
        return this.isEmpty(this.memberSecureMessageTableColumns) ? 0 : this.memberSecureMessageTableColumns.length;
    }

    public filterTable($event: any, filterTable: Table, columnField: any) {
        filterTable.filter($event.target.value, columnField, 'contains');
    }

    public customSort($event: { field: string; data: any[]; order: number; }) {

        let isDate: boolean = false;

        if (this.isNotEmpty($event) && this.isNotEmpty($event.field) && $event.field.toLowerCase().indexOf('date') > -1) {

            isDate = true;
        }

        let dataToSort: any[] = $event.data;

        if (this.isNotEmpty($event.data)) {

            dataToSort.sort((data1, data2) => {

                let value1 = null;
                let value2 = null;

                if (isDate) {

                    let val1 = data1[$event.field];
                    let val2 = data2[$event.field];
                    value1 = new Date(val1);
                    value2 = new Date(val2);

                } else {

                    value1 = data1[$event.field];
                    value2 = data2[$event.field];
                }

                let result = null;

                if (value1 == null && value2 != null) {
                    result = -1;
                } else if (value1 != null && value2 == null) {
                    result = 1;
                } else if (value1 == null && value2 == null) {
                    result = 0;
                } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                    result = value1.localeCompare(value2);
                } else {
                    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
                }

                return ($event.order * result);
            });
        }
    }

    public save() {

        this.saveClicked = true;
        if (this.sectionForm.valid) {
            if (this.isNotEmpty(this.secureMessageUploadFileFormData) && this.getUploadErrorKeys().length > 0) {
                this.displayAttachmentAlertWindow = true;
            } else {
                this.saveMessage();
            }
        }
    }

    public closeBtnForWithoutAttachment() {
        this.displayAttachmentAlertWindow = false;
    }

    public okBtnForWithoutAttachment() {
        this.displayAttachmentAlertWindow = false;
        this.saveMessage();
    }

    saveMessage() {        
        let formToSave: CreateOrUpdateSecureMessageDto = this.getFormValues();
        if (this.saveSubscription != null) {
            this.saveSubscription.unsubscribe();
        }

        this.saveSubscription = this._sectionService.saveSectionData(formToSave).subscribe({
            next: (saveResult: any) => {
                                  
                if (this.attachedFullfillmentList.length > 0 ) {
                    let documentDefinitionIds = this.attachedFullfillmentList.map((el: FullfillmentDto) => el.id);
                    this.saveFullfillment(saveResult.memberMessageId, documentDefinitionIds, false);
                   
                } 
                if(this.attachedDocumentList.length >0){
                
                    let memberDocumentIds= this.attachedDocumentList.map((ez: DocumentDto)=> ez.id);
                    this.saveMemberDocument(saveResult.memberMessageId, memberDocumentIds, false);
                }
                if(this.attachedFullfillmentList.length > 0 || this.attachedDocumentList.length >0)
                {
                    if (this.isNotEmpty(this.primaryEmailAddress) && this.isNotEmpty(this.primaryEmailAddress.emailId)) {
                        this.triggerEmail(this.primaryEmailAddress.emailId, false);
                    }
                }
                else {
                 this.triggerEmail(this.primaryEmailAddress.emailId,false);
                }

                this.editor = undefined;
            },
            error: (err: any) => {
                console.error(err);
                this.saveClicked = false;
            }
        });
    }

    public saveFullfillment(memberMessageId: string, documentDefinitionIds: Array<string>, isfromReply?: boolean ) {
        const saveFullfillmentReq: SaveFullfillmentRequestDto = {
            memberMessageId: memberMessageId,
            documentDefinitionIds: documentDefinitionIds,
            rowSource: Constant.AIProwSource
        }
        this.saveFullfillmentSubscription = this._sectionService.saveFullfillment(saveFullfillmentReq).subscribe({
            next: (saveResult) => {              
                this.displayMessageWindow = false;
                this.saveClicked = false;
                this.attachedFullfillmentList = [];
            },
            error: (err: any) => {
                console.error(err);
                this.saveClicked = false;
            }
        });
    }

    public saveMemberDocument(memberMessageId: string, memberDocumentIds: Array<string>, isfromReply?: boolean) {

        const saveDocumentReq: saveMemberDocumentRequestDto = {
            memberMessageId: memberMessageId,
            memberDocumentIds: memberDocumentIds,
            rowSource: Constant.AIProwSource
            
        }
        this.saveDocumentSuubscription = this._sectionService.saveMemberDocument(saveDocumentReq).subscribe({
            next: (saveResult) => {
                this.displayMessageWindow = false;
                this.saveClicked = false;
                this.attachedDocumentList = [];
            },
            error: (err: any) => {
                console.error(err);
                this.saveClicked = false;
            }
        });
    }

    public saveAttachments(messageId: string, isFromReply?: boolean) {

        if (this.uploadSubscription != null) {
            this.uploadSubscription.unsubscribe();
        }

        this.uploadSubscription = this._sectionService.uploadAttachmentFiles(this.secureMessageUploadFileFormData, messageId, this.memberId).subscribe({
            next: (saveResult) => {
                if (isFromReply) {
                    this.triggerEmail(this.primaryEmailAddress.emailId,isFromReply);
                }
                this.displayMessageWindow = false;
                this.saveClicked = false;
                this.secureMessageUploadFileFormData = null;
                if (this.isNotEmpty(this.fileUploader)) {
                    this.fileUploader.clear();
                }

                if (this.isNotEmpty(this.uploadFileErrors)) {
                    this.uploadFileErrors = new Map<string, string>();
                }
                
                this.getSectionData();
            },
            error: (err: any) => {
                console.error(err);
                this.saveClicked = false;
            }
        });
    }

    public downloadAttachment($event:AttachmentDto) {

        this.selectedAttachmentForDownload = $event;

        this.downloadSubscription = this._sectionService.downLoadAttachmentFile(this.selectedAttachmentForDownload.messageId, this.memberId, this.selectedAttachmentForDownload.fileName).subscribe({
            next: (res) => {
                let downloadElement = document.createElement("a");
                downloadElement.href = URL.createObjectURL(res);
                downloadElement.download = this.selectedAttachmentForDownload.fileName;
                downloadElement.click();
            },
            error: (err: any) => {
                console.error(err);
                this.saveClicked = false;
            }
        });
    }

    public getSectionData() {

        this.sectionIsLoaded = true;
        this.currentMemberName = this.tabTitleService.getTitle();

        if (this.isNotEmpty(this.getSubscription)) {
            this.getSubscription.unsubscribe();
        }
       
      
        this.getSubscription = this._sectionService.getSecureMessages(this.memberId).subscribe({
            next: (resultData) => {

                this.secureMessageListings = [];
                this.unreadMessageCount = 0;
                if (this.isNotEmpty(resultData)) {

                    let notReadArray: SecureMessageListingDto[] = resultData.filter(a => this.isEmpty(a.agentReadDate));
                    let readArray: SecureMessageListingDto[] = resultData.filter(a => this.isNotEmpty(a.agentReadDate));

                    let sortedNotReadArray: SecureMessageListingDto[] = notReadArray.sort((a: SecureMessageListingDto, b: SecureMessageListingDto) => {

                        let aAgentReadDate: number = Date.parse(a.agentReadDate);
                        let bAgentReadDate: number = Date.parse(b.agentReadDate);

                        if (this.isEmpty(a.agentReadDate)) {
                            return -1;
                        } else {
                            return (bAgentReadDate - aAgentReadDate);
                        }
                    });

                    let sortedReadArray: SecureMessageListingDto[] = readArray.sort((a: SecureMessageListingDto, b: SecureMessageListingDto) => {

                        let aCreatedDate: number = Date.parse(a.createdDate);
                        let bCreatedDate: number = Date.parse(b.createdDate);

                        if (this.isEmpty(a.createdDate)) {
                            return -1;
                        } else {
                            return (bCreatedDate - aCreatedDate);
                        }
                    });

                    let rawSecureMessageList: SecureMessageListingDto[] = sortedNotReadArray.concat(sortedReadArray);

                    let secureMessageListingDto: SecureMessageListingDto = null;

                    for (let rawSecureMessage of rawSecureMessageList) {

                        secureMessageListingDto = this.getSecureMessageListingMatch(rawSecureMessage.messageId)

                        if (this.isNotEmpty(secureMessageListingDto)) {

                            secureMessageListingDto.attachmentCount = rawSecureMessage.attachmentCount;
                            //secureMessageListingDto.attachments = rawSecureMessage.attachments;

                        } else {

                            if(rawSecureMessage.isAgentUnRead) {
                                this.unreadMessageCount++;
                            }

                            secureMessageListingDto = new SecureMessageListingDto();
                            secureMessageListingDto.createdDate = this.formatDates(rawSecureMessage.createdDate);
                            secureMessageListingDto.messageId = rawSecureMessage.messageId;
                            secureMessageListingDto.memberId = rawSecureMessage.memberId;
                            secureMessageListingDto.conversationId = rawSecureMessage.conversationId;
                            secureMessageListingDto.to = rawSecureMessage.to;
                            secureMessageListingDto.from = rawSecureMessage.from;
                            secureMessageListingDto.subject = rawSecureMessage.subject;
                            secureMessageListingDto.text = this.abbreviateMessageText(rawSecureMessage.text);
                            secureMessageListingDto.agentReadDate = rawSecureMessage.agentReadDate;
                            secureMessageListingDto.memberReadDate = rawSecureMessage.memberReadDate;
                            secureMessageListingDto.rowSource = rawSecureMessage.rowSource;
                            secureMessageListingDto.attachmentCount = rawSecureMessage.attachmentCount;
                            secureMessageListingDto.fulfillmentCount = rawSecureMessage.fulfillmentCount;
                            secureMessageListingDto.documentCount=rawSecureMessage.documentCount;
                            secureMessageListingDto.isAgentUnRead = rawSecureMessage.isAgentUnRead;
                            //secureMessageListingDto.attachments = rawSecureMessage.attachments;
                            secureMessageListingDto.attachmentFulfillmentDocumentCount = secureMessageListingDto.attachmentCount + secureMessageListingDto.fulfillmentCount+secureMessageListingDto.documentCount;
                            this.secureMessageListings.push(secureMessageListingDto);
                        }

                    }

                }

                this.sectionLoadedEvent.emit('SecureMessagesSection');
            },
            error: (err: any) => {
                console.error(err);
                this.sectionLoadedEvent.emit('SecureMessagesSection');
            }
        });
    }

    private abbreviateMessageText(message: string): string {

        let temp: string = message.replace(new RegExp('<p>', 'g'), '').replace(new RegExp('</p>', 'g'), '').replace(/<[^>]*>/g, '');

        return temp;
    }

    private getSecureMessageListingMatch(id: string): SecureMessageListingDto {
        return this.secureMessageListings.find(x => x.messageId === id);
    }

    private formatDates(dateToParse: string, isFromConversation?: boolean): string {
     
        if (this.isNotEmpty(dateToParse)) {

            let startDateArray: string[] = dateToParse.split('T');
            let dateArray: string[] = startDateArray[0].split('-');
            let timeArray: string[] = startDateArray[1].split(':')
            let reconstitutedDate = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0] + ' ' + timeArray[0] + ':' + timeArray[1] + ':' + timeArray[2];
            let currentDateTime: Date = new Date(reconstitutedDate + ' UTC');
            var date= (isFromConversation) ? moment(currentDateTime).format('MM/DD/YYYY') : moment(currentDateTime).format('MM/DD/YYYY HH:mm');

            return date;
        }

        return null;
    }

    public addNew() {

        // build up a new dto and add its data to the sectionForm set of FormControls
    }

    public looksGood() {

        this.save();
    }

    private initializeForm() {

        this.sectionForm = null;
        let formGroup: any = {};

        
        formGroup['selectedTemplateName'] = new FormControl('');
        formGroup['messageSubject'] = new FormControl('', [Validators.required, nonEmptyWhitespaceValidator()]);
        formGroup['messageText'] = new FormControl('',[Validators.required, nonEmptyWhitespaceValidator()]);
        formGroup['quillEditorText'] = new FormControl('')
        this.sectionForm = new FormGroup(formGroup);

        this.messageSubjectControl = this.sectionForm.get('messageSubject');
        this.selectedTemplateControl = this.sectionForm.get('selectedTemplateName');
        this.messageTextControl = this.sectionForm.get('messageText');
        this.quillEditorTextControl = this.sectionForm.get('quillEditorText');

        this.setValidators();

    }

    private getFormValues(): CreateOrUpdateSecureMessageDto {
        
        let secureMessageDto = new CreateOrUpdateSecureMessageDto();
        let now = new Date();
        secureMessageDto.memberId = this.memberId;
        secureMessageDto.rowSource = Constant.AIProwSource;
        secureMessageDto.subject = this.sectionForm.get('messageSubject').value;
        secureMessageDto.from = this.agentFullName;
        secureMessageDto.to = this.memberstr;
        secureMessageDto.text = this.selectedTemplateControl.value ? this.getEditorContent(this.editor) : this.sectionForm.get('messageText').value;
        secureMessageDto.agentReadDate = now.toISOString();
        secureMessageDto.templateId = this.selectedTemplateControl.value ? this.selectedTemplateControl.value : null;
        return secureMessageDto;
    }

    resendMessage(item: MessageConversionListingDto) {
        this.resendAttachments = item.attachments;
        this.attachedFullfillmentList = item.fullfillments;
        this.attachedDocumentList=item.documents;
        if(item.templateId) {
          this.selectedTemplateEditTime  = item.templateId;
          setTimeout(() => {
            if(!this.editTimeEditor) {
             this.quillEditorViewInitOnEditTime();
            }
            const delta = this.editTimeEditor.clipboard.convert({html: item.text});
            this.editTimeEditor.setContents(delta, 'silent');
            this.templateDefinition = this.templateDefinitionList.find((item: TemplateDefinitionDto) => item.id === this.selectedTemplateEditTime);
            if(this.templateDefinition) {
             this.editTimeEditor.enable(this.templateDefinition.allowUserEntry); 
            }
          }, 0);
        } else {
          this.selectedTemplateEditTime = "";
          this.replyMsgText = item.text;
          this.editTimeEditor = undefined;
          setTimeout(() => {
          this.messageTextElement.nativeElement.focus();
          },0);
        }
       
    }

    public resendAttachment(oldMessageId: string, messageId: string) {


        if (this.resendSubscription != null) {
            this.resendSubscription.unsubscribe();
        }

        this.resendSubscription = this._sectionService.resendAttachmentFiles(oldMessageId, messageId, this.memberId).subscribe({
            next: (saveResult) => {
                this.resendAttachments = [];
                this.triggerEmail(this.primaryEmailAddress.emailId,true);
                this.displayMessageWindow = false;
                this.saveClicked = false;
                
                this.getSectionData();
            },
            error: (err: any) => { 
                console.error(err);
                this.saveClicked = false;
            }
        });
    }

    private setValidators() {

        (<FormControl>this.sectionForm.controls['messageSubject']).setValidators([Validators.required, nonEmptyWhitespaceValidator()]);
        (<FormControl>this.sectionForm.controls['messageSubject']).updateValueAndValidity();

        if(!this.selectedTemplateControl.value) {

            (<FormControl>this.sectionForm.controls['messageText']).setValidators([Validators.required, nonEmptyWhitespaceValidator()]);
            (<FormControl>this.sectionForm.controls['messageText']).updateValueAndValidity();

        } else {
            (<FormControl>this.sectionForm.controls['quillEditorText']).setValidators(Validators.required);
            (<FormControl>this.sectionForm.controls['quillEditorText']).updateValueAndValidity();
        }
    }

    sendReply() {
        if (!this.selectedTemplateEditTime) {
            if ((!this.replyMsgText) || (!this.replyMsgText.trim())) {
                this.displayMandatoryAlertWindow = true;
            } else {
                this.updateMessage();
            }
        } else {

            if(!this.isQuillEditorvalidate(this.editTimeEditor)) {
                this.displayMandatoryAlertWindow = true;
                return;
            } else {
                this.updateMessage();
            }

        }
        
    }

    getFileExtension(filename: string) { // this will give you icon class name
        let ext = filename.split(".").pop();
        let obj = this.iconList.filter((row: any) => row.type == ext);
        if (obj.length > 0) {
            let icon = obj[0].icon;
            return icon;
        } else {
            return "";
        }
    }

    public closeBtnForMandatory() {
        this.displayMandatoryAlertWindow = false;
    }

    public viewConversionMessagesByMessageId(messageId: string) {

        if (this.isNotEmpty(this.viewSubscription)) {
            this.viewSubscription.unsubscribe();
        }
        this.messageConverstions = [];
        this.viewSubscription = this._sectionService.getViewConversationMessages(messageId).subscribe({
            next: (resultData) => {
              if (resultData.length > 0 ) {
                 resultData.map((el: MessageConversionListingDto) =>{ 
                    el.createdDate = this.formatDates(el.createdDate, true );
                    this.messageConverstions.push(el);
                });

                setTimeout(()=>{ 
                    this.messageTextElement.nativeElement.focus();
                  },0); 
              } 
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getDocumentCategoryList() {
        if (this.isNotEmpty(this.getDocCategorySubscription)) {
            this.getDocCategorySubscription.unsubscribe();
        }

        this.getDocCategorySubscription = this._sectionService.getDocumentCategories().subscribe({
            next: (resultData) => {
                this.documentCategoryList = resultData;
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getMemberDocuments()  {
        if (this.isNotEmpty(this.getMemberDocumentSubscription)) {
            this.getMemberDocumentSubscription.unsubscribe();
        }

        this.getMemberDocumentSubscription = this._documentservice.getDocumentList(this.memberId).subscribe({
            next: (resultData) => {
                this.documentsList = resultData;
                this.documentsList.map((el: DocumentDto) => {
                    el.createdDate = this.formatDates(el.createdDate,true);
                 });
                 
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    public showFulfillmentAddDialog() {
               
        this.getDocumentCategoryList();
        this.displayAddFullfillmentWindow = true;
        this.fullfillmentList = [];
        this.genAiFileErrorMessage = null;
        this.fullfilmentSearchText = '';
        this.selectedFullfillmentList = [];
        this.selectedCategory = '';
        this.checkAdvanceSearchShow();
    }

    public showDocumentAddDialog() {
     
        this.getMemberDocuments();
        this.displayAddDocumentWindow = true;
        this.selectedDocumentList=[];       
    }

    closeBtnForFullfillment() {
        this.displayAddFullfillmentWindow = false;
        if (this.attachedFullfillmentList.length > 0) {
            var ids = new Set(this.attachedFullfillmentList.map(d => d.id));
            this.attachedFullfillmentList = [...this.attachedFullfillmentList, ...this.selectedFullfillmentList.filter(d => !ids.has(d.id))];
        } else {
            this.attachedFullfillmentList = this.selectedFullfillmentList.slice();
        }
    }
    closeBtnForDocuement() {
        this.displayAddDocumentWindow = false;
        if (this.attachedDocumentList.length > 0) {
            var ids = new Set(this.attachedDocumentList.map(d => d.id));
            this.attachedDocumentList = [...this.attachedDocumentList, ...this.selectedDocumentList.filter(d => !ids.has(d.id))];
        } else {
            this.attachedDocumentList = this.selectedDocumentList.slice();
        }
    }

    searchFullfillment() {
        this.fullfillmentList = [];
        this.getFullfillmentsByCategory();
    }

    advanceSearchFullfillment() {
       this.displayAddVanceFullfillmentWindow = true;
    }

    closeBtnForAdvanceSearch() {

        this.genAiFullfillmentList.forEach(genAiDoc => {
        
            let existingItem = this.fullfillmentList.find((item: FullfillmentDto) => item.id === genAiDoc.id);

            if (this.isEmpty(existingItem)) {

                this.fullfillmentList.push(genAiDoc);
            }
        });

        this.genAiFullfillmentList = [];

        this.fullfillmentList = this.fullfillmentList.sort((a: FullfillmentDto, b: FullfillmentDto) => {

            if (a.documentName.toLowerCase() < b.documentName.toLowerCase()) {
                return -1;
            } else if (a.documentName.toLowerCase() > b.documentName.toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });

        console.log('fulfillment list', this.fullfillmentList);

        this.displayAddVanceFullfillmentWindow = false;  
    }

    removeSelectedFullfillment( index: number, item: FullfillmentDto) {
        this.selectedFullfillmentList.splice(index,1); 
        item.isSelected = false;
    }

    removeSelectedDocument( index: number, item: DocumentDto) {
        this.selectedDocumentList.splice(index,1); 
        item.isSelected = false;
    }

    onRowSelectFullfillment(item: FullfillmentDto) {     
      const isExists = this.selectedFullfillmentList.some((el: FullfillmentDto) => el.id === item.id);
      if(!isExists) {
        this.fullfillmentList.forEach((el: FullfillmentDto) => {
            if(el.id === item.id) {
                el.isSelected = true;
                this.selectedFullfillmentList.push(el);
            }
        });
      }
    }

    onRowSelectDocument(item: DocumentDto) {
        
      const isExists = this.selectedDocumentList.some((el:DocumentDto ) => el.id === item.id);
      if(!isExists) {
        this.documentsList.forEach((el: DocumentDto) => {
            if(el.id === item.id) {
                el.isSelected = true;
                this.selectedDocumentList.push(el);
            }
        });
      }
     
    }

    public downloadFullfillment(item: FullfillmentDto)
    {        
        if (this.isNotEmpty(this.downloadFullfillmentSubscription)) {
            this.downloadFullfillmentSubscription.unsubscribe();
        }

        this.downloadFullfillmentSubscription = this._sectionService.downloadFulfillmentDocument(this.memberId, item.id).subscribe({
            next: (blob: Blob): void => {

                const file = new Blob([blob], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                var newWindow = window.open(fileURL, '_blank');
                URL.revokeObjectURL(fileURL);

                setTimeout(function () {
                    newWindow.document.title = item.documentDescription;                       
                }, 150);
            },
            error: (err: any) => {
                console.error(err);
                this.saveClicked = false;
            }
        });
    }

    public downloadDocument(item: DocumentDto)
    {        
        if (this.isNotEmpty(this.downloadDocumentSubscription)) {
            this.downloadDocumentSubscription.unsubscribe();
        }

        this.downloadDocumentSubscription = this._documentservice.getDocument(item.documentName, item.id).subscribe({
            next: (blob: Blob): void => {

                const file = new Blob([blob], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                var newWindow = window.open(fileURL, '_blank');
                URL.revokeObjectURL(fileURL);

                setTimeout(function () {
                    newWindow.document.title = item.documentName;                       
                }, 150);
            },
            error: (err: any) => {
                console.error(err);
                this.saveClicked = false;
            }
        });
    }

    deleteSelectedFullfillment(item: FullfillmentDto) {
        this.attachedFullfillmentList = this.attachedFullfillmentList.filter((e) => e .id !== item.id );
    }
    deleteSelectedDocument(item: DocumentDto)
    {
        this.attachedDocumentList= this.attachedDocumentList.filter((e)=> e.id !==item.id);
    }

    changeCategory(event: any) {
        this.fullfillmentList = [];
        this.getFullfillmentsByCategory();
    } 

    getFullfillmentsByCategory() {
        if (this.isNotEmpty(this.getFullfillmentSubscription)) {
            this.getFullfillmentSubscription.unsubscribe();
        }
       if(this.selectedCategory || this.fullfilmentSearchText.trim()) {
            this.getFullfillmentSubscription = this._sectionService.getFullfillmentByCategory(this.selectedCategory, this.fullfilmentSearchText).subscribe({
                next: (resultData) => {
                    this.fullfillmentList = resultData;
                },
                error: (err: any) => {
                    console.error(err);
                }
            });
        }
    }

    changeTemplate(event: any) {
        this.selectedTemplateControl = this.sectionForm.get('selectedTemplateName');
        this.templateDefinition = this.templateDefinitionList.find((item: TemplateDefinitionDto) => item.id === event.value);
        if(this.templateDefinition && this.selectedTemplateControl.value) {
            
            if (this.isNotEmpty(this.resolveTemplateSubscription)) {
                this.resolveTemplateSubscription.unsubscribe();
            }

            this.templateRequest = this.getResolveTemplateRequestValues(this.templateDefinition.id); 
            this.resolveTemplateDefinition(this.templateRequest);
          
        } else {
            (<FormControl>this.sectionForm.controls['messageText']).setValidators([Validators.required, nonEmptyWhitespaceValidator()]);
            (<FormControl>this.sectionForm.controls['messageText']).updateValueAndValidity();

            (<FormControl>this.sectionForm.controls['quillEditorText']).clearValidators();
            (<FormControl>this.sectionForm.controls['quillEditorText']).updateValueAndValidity();
            this.editor =  undefined;
            this.messageTextControl.setValue('');
        }
    } 

    getResolveTemplateRequestValues(templateDefinitionId: string) : TemplateResolutionRequestDto {
        this.templateRequest.templateDefinitionId = templateDefinitionId;
        this.templateRequest.asFullHtmlDocument = false;

        this.templateRequest.inputParams = [{
            queryParamName:"memberId",
            queryParamValue:this.memberId
        }];

        return this.templateRequest;
    }

    resolveTemplateDefinition(templateRequest: TemplateResolutionRequestDto) {
        this.resolveTemplateSubscription = this._sectionService.resolveTemplateDefinition(templateRequest).subscribe(
            {
                next: (result: TemplateResolutionResponseDto) => {
                
                    this.resolveResult = result.resolvedTemplate;
                    this.templateDefinition.templateBody = this.resolveResult;
                    if(this.displayDetailWindow && this.selectedMessageListing) {
                        this.setQuillEditorContentonEditTime();
                    } else {
                        this.setQuillEditorContent();
                    }
                },
                error: (err: any) => { 
                    this.resolveResult = "";
                }
            }
        );
    }

    private async getDocumentDefinition(documentId: string): Promise<FullfillmentDto> {

        try {
            return await this._sectionService.getAsyncDocumentDefinitionById(documentId);

        } catch (error) {
            console.error('An error occurred retrieving the document definition ' + documentId, 'Get Document Definition');
            return null;
        }
    }

    setQuillEditorContent() {
        (<FormControl>this.sectionForm.controls['messageText']).clearValidators();
        (<FormControl>this.sectionForm.controls['messageText']).updateValueAndValidity();

       
        (<FormControl>this.sectionForm.controls['quillEditorText']).setValidators([Validators.required]);
        (<FormControl>this.sectionForm.controls['quillEditorText']).updateValueAndValidity();

        setTimeout(() => {
            if(!this.editor) {
             this.quillEditorViewInit(); 
            }
            const delta = this.editor.clipboard.convert({html: this.templateDefinition.templateBody});
            this.editor.setContents(delta, 'silent');
            this.editor.enable(this.templateDefinition.allowUserEntry);
            this.sectionForm.patchValue({
                quillEditorText: this.templateDefinition.templateBody
            });
        }, 0);
    }

    changeTemplateOnEditTime(event: any) {
        this.templateDefinition = this.templateDefinitionList.find((item: TemplateDefinitionDto) => item.id === event.value);
        this.selectedTemplateEditTime = event.value;
        if(this.selectedTemplateEditTime && this.templateDefinition) {
            this.templateRequest = this.getResolveTemplateRequestValues(this.templateDefinition.id); 
            this.resolveTemplateDefinition(this.templateRequest);         
        } else {
           this.editTimeEditor =  undefined;
           this.replyMsgText = "";
        }
    }

    setQuillEditorContentonEditTime() {
        setTimeout(() => {
            if(!this.editTimeEditor) {
             this.quillEditorViewInitOnEditTime();
            }
            if(this.templateDefinition) {
                const delta = this.editTimeEditor.clipboard.convert({html: this.templateDefinition.templateBody});
                this.editTimeEditor.setContents(delta, 'silent');
                this.editTimeEditor.enable(this.templateDefinition.allowUserEntry);
            }
        }, 0); 
    }

    private getEditorContent(editor: any) {

        if (editor) {
            return editor.root.innerHTML;
        } 
        return "";
    }

    getTemplateDefinitionList() {
        if (this.isNotEmpty(this.getTemplateDefinitionSubscription)) {
            this.getTemplateDefinitionSubscription.unsubscribe();
        }

        this.templateDefinitionList = [];

        this.getTemplateDefinitionSubscription = this._sectionService.getTemplateDefinitions(this.clientId).subscribe({
            next: (resultData: any) => {
                this.templateDefinitionList = resultData.templateDefinitionList;                ;
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    public getEditorHeight(): string {
        return (window.innerHeight - 570) + 'px';
    }

    isQuillEditorvalidate(editor: any): boolean {
        const content = editor.root.innerHTML.trim();
        return content === '<p><br></p>' ? false : true;
    }

    quillEditorViewInit() {

        if (this.editorContainer) {
            
            try {
                const toolbarOptions = this.setAndGetQuillFont();

                this.editor = new Quill(this.editorContainer.nativeElement, {
                    modules: {
                        table: true,
                        toolbar: toolbarOptions
                    },
                    theme: "snow",
                });

                this.table = this.editor.getModule('table');

                // Listen to changes in editor content and update form control
                this.editor.on('text-change', () => {
                    this.sectionForm.patchValue({
                     quillEditorText: this.editor.root.innerHTML.trim()
                    });

                    if(!this.isQuillEditorvalidate(this.editor)) {
                        this.quillEditorTextControl.setValue('');

                        // Set the control to invalid and set an error
                        this.quillEditorTextControl.setErrors({ required: true });
                    }
                });

            } catch (error) {
                console.error("Error creating Quill editor:", error);
            }
        } else {
           console.error("Element with #editorContainer not found!");
        }
    }

    quillEditorViewInitOnEditTime() {

        if (this.editTimeEditorContainer) {
            
            try {

                const toolbarOptions = this.setAndGetQuillFont();
                
                this.editTimeEditor = new Quill(this.editTimeEditorContainer.nativeElement, {
                    modules: {
                        table: true,
                        toolbar: toolbarOptions
                    },
                    theme: "snow"
                });

                this.editTimetable = this.editTimeEditor.getModule('table');

            } catch (error) {
                console.error("Error creating Quill editor:", error);
            }
        } else {
           console.error("Element with #editTimeEditorContainer not found!");
        }
    }

    setAndGetQuillFont():Array<any>  {
        FontAttributor.whitelist = [
            'arial',
            'courier',
            'tahoma',
            'times',
            'verdana'                    
            ];
        Quill.register(FontAttributor, true);

        FontSize.whitelist = ['8px', '10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px'];
        Quill.register(FontSize, true);

        const toolbarOptions = [

            [{ font: FontAttributor.whitelist }],                            
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ size: [false, '8px', '10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px'] }],
            ["bold", "italic", "underline", "strike", "clean"],
            [{ script: 'sub'}, { script: 'super' }],
            [{ color: [] as string[] }, { background: [] as string[]}],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] as string[]}],                            
            ["link"] 
        ];

        return toolbarOptions;
    }

    sanitizeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    private getGenAiUrl() {      

        this.getConfigValue(Constant.AdvancedSearchGenAISiteUrl).then((result: any) => {
            
            if (this.isNotEmpty(result)) {
              
                // temp url for the mock GenAI app
                // result = 'http://localhost:4300?user_id=#userId#&tenant_id=#clientId#';

                result = result.replace('#clientId#', this.clientId);
                result = result.replace('#userId#', this.userId);

                this.sanitizedGenAIUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result);
                
            } else {

              console.error('unable to retrieve the GenAI url configuration value.')
            }
        });
    }

    private checkAdvanceSearchShow() {

        this.getConfigValue(Constant.GenAIAdvanceSearchKey).then((result: any) => {
            
            if (this.isNotEmpty(result) && result && result == 'true') {
              
              this.showAdvanceSearch = true;

              this.getGenAiUrl();

            } else {
              this.showAdvanceSearch = false;
            }
        });
    }

    private getConfigValue(soughtKey: string) {
    
        return this._sectionService.getConfig(soughtKey, this.clientId)
            .then((response: any) => {                        
                return Promise.resolve(response);
            })
            .catch((error) => {                
                return Promise.reject();               
            });
    }

    private getSubstringWithTags(htmlString: string, maxLength: number): string {
        // Create a DOMParser to parse the HTML string
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
    
        let result = '';
        let currentLength = 0;
    
         // Recursive function to traverse nodes
        const traverseNode = (node: Node): void => {
            if (currentLength >= maxLength) return;
    
            if (node.nodeType === Node.TEXT_NODE) {
            // Handle text nodes
            const text = (node as Text).textContent || '';
            if (currentLength + text.length > maxLength) {
                result += text.slice(0, maxLength - currentLength);
                currentLength = maxLength;
            } else {
                result += text;
                currentLength += text.length;
            }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
            // Handle element nodes
            const element = node as HTMLElement;
            const tagName = element.tagName.toLowerCase();
            result += `<${tagName}`;
    
            // Add attributes
            Array.from(element.attributes).forEach(attr => {
                result += ` ${attr.name}="${attr.value}"`;
            });
    
            result += '>';
    
            // Traverse child nodes
            Array.from(element.childNodes).forEach(child => {
                traverseNode(child);
                if (currentLength >= maxLength) return;
            });
    
            if (currentLength < maxLength) {
                result += `</${tagName}>`;
            }
            }
        };
  
        traverseNode(doc.body);

        return result;
    }  
    
    private removeImgTags(htmlString: string): string {
        // Regular expression to match <img> tags
        return htmlString.replace(/<img[^>]*>/g, '');
    }

    ngOnDestroy(): void {

        if (this.saveSubscription !== null) {
            this.saveSubscription.unsubscribe();
        }

        if (this.getSubscription !== null) {
            this.getSubscription.unsubscribe();
        }

        if (this.uploadSubscription !== null) {
            this.uploadSubscription.unsubscribe();
        }

        if (this.downloadSubscription !== null) {
            this.downloadSubscription.unsubscribe();
        }

        if (this.updateSubscription !== null) {
            this.updateSubscription.unsubscribe();
        }

        if (this.viewSubscription !== null) {
            this.viewSubscription.unsubscribe();
        }

        if (this.resendSubscription !== null) {
            this.resendSubscription.unsubscribe();
        }

        if (this.getDocCategorySubscription !== null) {
            this.getDocCategorySubscription.unsubscribe();
        }

        if (this.getMemberDocumentSubscription !== null) {
            this.getMemberDocumentSubscription.unsubscribe();
        }


        if (this.getFullfillmentSubscription !== null) {
            this.getFullfillmentSubscription.unsubscribe();
        }

        if (this.saveFullfillmentSubscription !== null) {
            this.saveFullfillmentSubscription.unsubscribe();
        }

        if(this.saveDocumentSuubscription !== null ){
            this.saveDocumentSuubscription.unsubscribe();
        }
     
        if (this.downloadFullfillmentSubscription !== null) {
            this.downloadFullfillmentSubscription.unsubscribe();
        }

        if(this.getTemplateDefinitionSubscription !== null) {
            this.getTemplateDefinitionSubscription.unsubscribe();
        }

        if(this.resolveTemplateSubscription !== null) {
            this.resolveTemplateSubscription.unsubscribe();
        }
    }
}
