import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    
    constructor(private router: Router, private authService: AuthService) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        let url: string = state.url;

        const currentUser = this.authService.getLoggedUser();

        console.log('currentUser', currentUser);

        let authTokenDetail = localStorage.getItem('authTokenDetail');

        let notExpired: boolean = false;

        if (authTokenDetail !== null && authTokenDetail !== undefined) {
     
            let authData = authTokenDetail.split(',');

            const expires = new Date(Number(authData[2]) * 1000);

            let expa = expires.getTime() - Date.now();
            
            notExpired = (expa > 0);
        }

        if (currentUser !== null &&
            currentUser !== undefined &&
            currentUser.id !== null &&
            currentUser.id !== undefined &&
            currentUser.id.trim() !== '' &&
            notExpired) {
            
            return true;
        }

        // Store the attempted URL for redirecting
        sessionStorage.setItem('redirectUrl', url.substring(1));
        
        this.router.navigate(['/login']);

        return false;
    }
}
