<div >
    <div>
     <div class="row">
          <div  *ngIf="memberBannerDto.downloadMemberDetailReport === true" class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <div *ngIf="memberBannerDto.preferredName">
              <label class="b_drp_lable namewrap h_label"><span class="header-label">Name:</span><span (click)="downLoadMemberDetailReport()" class="link">{{memberBannerDto?.firstName}}&nbsp;{{memberBannerDto?.lastName}}&nbsp;({{memberBannerDto?.preferredName}})</span></label>
            </div>
            <div *ngIf="!memberBannerDto.preferredName">
            <label class="b_drp_lable namewrap h_label"><span class="header-label">Name:</span><span (click)="downLoadMemberDetailReport()" class="link">{{memberBannerDto?.firstName}}&nbsp;{{memberBannerDto?.lastName}}</span></label>
            </div>
          </div>
          <div *ngIf="memberBannerDto.downloadMemberDetailReport === false" class="col-lg-4 col-md-4 col-sm-4 col-xs-4" >
            <div *ngIf="memberBannerDto.preferredName">
              <label class="b_drp_lable namewrap h_label"><span class="header-label">Name:</span>{{memberBannerDto?.firstName}}&nbsp;{{memberBannerDto?.lastName}}&nbsp;({{memberBannerDto?.preferredName}})</label>
                </div>
                <div *ngIf="!memberBannerDto.preferredName">
            <label class="b_drp_lable namewrap h_label"><span class="header-label">Name:</span>{{memberBannerDto?.firstName}}&nbsp;{{memberBannerDto?.lastName}}</label>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <label class="b_drp_lable h_label"><span class="header-label">Member ID:</span>{{memberBannerDto?.memberCode}}</label>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <label class="b_drp_lable h_label"><span class="header-label">Client:</span>{{memberBannerDto?.client}}</label>
          </div>
     </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label class="b_drp_lable h_label"><span class="header-label">Age:</span>{{memberBannerDto?.age}}</label>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label class="b_drp_lable h_label"><span class="header-label">Preferred Language:</span>{{memberBannerDto?.language}}</label>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label class="b_drp_lable h_label"><span class="header-label">Risk Population:</span>{{memberBannerDto?.populationName}}</label>
              </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <label class="b_drp_lable h_label"><span class="header-label">Gender:</span>{{getGender(memberBannerDto)}}</label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label class="b_drp_lable h_label"><span class="header-label">Primary Phone:</span>{{memberBannerDto?.primaryPhone ? getMaskPhoneNumber(memberBannerDto.primaryPhone) : memberBannerDto?.primaryPhone }}</label>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label class="b_drp_lable h_label"><span class="header-label">Program:</span>{{memberBannerDto?.programName}}</label>
              </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <label class="b_drp_lable h_label"><span class="header-label">Registration Date:</span>{{memberBannerDto?.registrationDate ? (memberBannerDto?.registrationDate | date:'MM/dd/yyyy') : '-'}}</label>
            </div>
          </div>
          
  </div>
  <hr>
  <div>
    <p class="h_paragraph">
      Below, you will find the information we have on file for the Member. You may add, remove, or update existing information. Once you have completed updates for each section, click on "Save", and click "Submit All" at the end of the page for submitting any unsaved changes.
    </p>
  </div>
</div>