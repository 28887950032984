import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import {AuthUser} from 'src/app/auth/models/authuser';
import { Router } from '@angular/router';
import { PortalUserService } from 'src/app/auth/services/portaluser.service';
import {PortalUser} from 'src/app/auth/models/portaluser';
import { ToastrService } from 'ngx-toastr';
import { SignupService } from 'src/app/auth/services/signup/signup.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  passType: string = "password";
  confPassType: string = "password";
  isTextPass: boolean = false;
  isTextConfPass: boolean = false;
  pasEyeIcon: string = "fa-eye-slash";
  confPassEyeIcon: string = "fa-eye-slash";
  isError:boolean=false;
  error:any;
  user:AuthUser;
  portalUser:PortalUser;
  acceptTerms:false;
  acceptTermsPopUp:boolean=false;
  constructor(private authService:AuthService,private router: Router,
    private portalUserService:PortalUserService,private toastr: ToastrService,private signupService:SignupService) {
    this.user=new AuthUser();
    this.portalUser=new PortalUser();
   }

  ngOnInit(): void {
    debugger;
    this.isError=false;
    localStorage.clear();
  }
  TCPopUp(){
   this.acceptTermsPopUp=!this.acceptTermsPopUp;
  
  }
  
  onSubmit() {

      this.signupService.setPortalUser(this.portalUser);
      this.router.navigate(['sign-up-userdetails']);
  // this.authService.Authenticate(this.portalUser.code).subscribe({
  //       next: (v) => {
  //         debugger;
  //         if (v=="true") {
  //           this.signupService.setPortalUser(this.portalUser);
  //           this.router.navigate(['sign-up-userdetails']);
  //         }
  //         else {
  //           this.isError=true;
  //           this.error="Not a valid code";
  //         }
  //       },
  //       error: (e) => {
  //         this.isError=true;
  //         this.error="Not a valid code";
  //       }
  //     })

       // this.toastr.clear();
      //   this.authService.UserRegistration(this.user).subscribe({
      //     next: (data: PortalUser) => {
      //       if(data){ 
      //         //#region Login Aut User
      //         this.authService.ValidateUser(this.user).subscribe({
      //           next: (v) => {
      //             if (v != null) {
      //               //this.toastr.success("Registered successfully,Welcome :) ")
      //               localStorage.setItem('authToken', v)
      //               var  token = JSON.parse(atob(v.split('.')[1]))
      //               var result = Object.keys(token).map((key) => [token[key]]);
      //               localStorage.setItem('authTokenDetail', result.toString());
      //               localStorage.setItem('portalUser',JSON.stringify(data));
      //               this.router.navigate(['home']);
      //             }
      //             else { this.isError=true;} //this.toastr.error(v) }
      //           },
      //           error: (e) => this.isError=true
      //         })
      //         //#endregion
      //       } 
      //       else{this.isError=true }
      //     },
      //     error: (e) => this.isError=true
      // })
  }

  hideShowPass(): void {
    this.isTextPass = !this.isTextPass;
    this.pasEyeIcon = this.isTextPass ? "fa-eye" : "fa-eye-slash";
    this.passType = this.isTextPass ? "text" : "password";
  }

  hideShowConfPass(): void {
    this.isTextConfPass = !this.isTextConfPass;
    this.confPassEyeIcon = this.isTextConfPass ? "fa-eye" : "fa-eye-slash";
    this.confPassType = this.isTextConfPass ? "text" : "password";
  }
  
  displayStyle = "none";
  
  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }
}
