<section class="vh-100">
    <div class="container-fluid">
      <div *ngIf="!isDisplay" class="row">
        <div class="col-md-9 col-lg-6 px-0 col-xl-7">
          <div class="image">
            <img src="../assets/images/background_Image_5.png" alt="Login image" class="w-100 vh-100"
              style="object-fit: cover; object-position: left;">
            <div class="hero-section">
              <p class="welcome text-center">Welcome<p>
            </div>
            <div class="hero-info">
                <p> to the Agent Portal </p>
            </div>
          </div>
        </div>
        <div class="login-details col-md-8 col-lg-6 col-xl-5 mt-4">
          <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
            <div class="lead d-flex flex-row align-items-center justify-content-center text-center ">
              <p class="mb-2 me-3">Forgot Password</p>
            </div>

            <span class="small-text">Enter your email address below and click Submit. Password reset instructions will be sent to the email address on file.</span> 
            <div *ngIf="isError">
              <h6 style="color: red;margin-left: 30%;" class="error">
                Not a valid user
              </h6> 
            </div>
            <!-- User Details -->
            <!-- <div class="form-outline text-center mt-4 mb-4">            
              <input type="text" id="" class="form-control form-control-lg" placeholder="User Name" />

              <div class="divider d-flex align-items-center my-4">
                <p class="text-center fw-bold mx-3 mb-0"> <span> Or</span></p>
              </div>
              
            </div> -->
            <div class="form-outline text-center mt-4 mb-5">             
              <!-- <input type="email" id="" class="form-control form-control-lg" placeholder="Email address" /> -->
              <input type="email" name="emailAddress" class="form-control form-control-lg" placeholder="Enter Email Address"
              [(ngModel)]="user.emailAddress" #email="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email>
            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
              <div *ngIf="email.errors?.['required']">Email is required</div>
              <div *ngIf="email.errors?.['email']">Email must be a valid email address</div>
            </div>
            </div>

            <div class="form-outline text-center mb-4">             
              <button type="submit" class="sign-up btn btn-lg btn-block mt-2" >Reset</button>
            </div>
            
            <p class="mt-2 pt-1"><span>First time user?</span>
              <button type="button" class="sign-up btn btn-lg btn-block mt-2" routerLink="/sign-up">Sign up</button>
  
          </form>
        </div>
      </div>
      <div *ngIf="isDisplay" class="row text-center">
           <p class="mt-4">Password reset instructions has sent to the email address </p>
          <p>for Login? <a routerLink="/login"> click here</a></p> 
      </div>
    </div>
  </section>


