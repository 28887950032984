
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { CareGiverDetailsDto, PostCareGiverDto, RelationshipDto } from './caregiver-section.dtos';

@Injectable()
export class CaregiverSectionService {

  
    private getCaregiverDetailsUrl: string = '/member/{memberId}/Contacts?MemberSource={memberSource}';
    //private postCaregiverDetailsUrl: string = '/member/{memberId}/Contact?MemberSource={memberSource}&ContactSource={contactSource}';
    private postCaregiverDetailsUrl: string = '/member/{memberId}/AgentContact?MemberSource={memberSource}&ContactSource={contactSource}';
    private deleteCaregiverDetailsUrl: string = '/member/{memberId}/Contact/{ContactId}?MemberSource={memberSource}&ContactSource={contactSource}';
    private getRelationshipTypesUrl: string = '/MemberContact/GetRelationship';

    private headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {

        let userName: string = 'NotProvided';

        if (this._authService !== null && this._authService !== undefined
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');

        environment.contentServiceApiBaseUrl = _appConfig.contentServiceApiBaseUrl;
    }


    public getCaregiverDetails(memberId: string): Observable<CareGiverDetailsDto[]> {
       
        let url = (this._appConfig.memberManagerApiBaseUrl + this.getCaregiverDetailsUrl)
                    .replace('{memberId}', memberId).replace('{memberSource}',Constant.memberSource);

        return this._http.get<CareGiverDetailsDto[]>(url, { headers: this.headers });

    }
    public postCaregiverDetails(saveData: PostCareGiverDto, memberId: string) {
       
        let url = (this._appConfig.memberManagerApiBaseUrl + this.postCaregiverDetailsUrl)
                    .replace('{memberId}', memberId).replace('{memberSource}',Constant.memberSource).
                    replace('{contactSource}',Constant.memberSource);

        return this._http.post(url, saveData, { headers: this.headers });

    }

    public deleteCaregiverDetails(memberId: string, ContactId: string) {
       
        let url = (this._appConfig.memberManagerApiBaseUrl + this.deleteCaregiverDetailsUrl)
                    .replace('{memberId}', memberId).replace('{ContactId}', ContactId).
                    replace('{memberSource}',Constant.memberSource).replace('{contactSource}',Constant.memberSource);

        return this._http.delete(url, { headers: this.headers, responseType: 'text' });
    }

    public getRelationshipTypes(): Observable<RelationshipDto[]> {
       
        let url = this._appConfig.memberManagerApiBaseUrl + this.getRelationshipTypesUrl;

        return this._http.get<RelationshipDto[]>(url, { headers: this.headers });

    }
}