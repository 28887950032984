import { Component } from '@angular/core';
import { PortalUser } from './auth/models/portaluser';
import { PortalUserService } from './auth/services/portaluser.service';
import Quill from 'quill';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'PortalUI';
  portalUsers: PortalUser[] = [];
  portalUserToEdit?: PortalUser;

  constructor(private portaluserService: PortalUserService) {
    
  }

  ngOnInit(): void {

  }

  updatePortalUserList(portalUsers: PortalUser[]) {
    this.portalUsers = portalUsers;
  }

  initNewPortalUser() {
    this.portalUserToEdit = new PortalUser();
  }

  editPortalUser(portalUser: PortalUser) {
    this.portalUserToEdit = portalUser;
  }
}