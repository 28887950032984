export class AuthUser {
    userid?:string="";
    firstName: string = "";
    lastName: string = "";
    emailAddress: string = "";
    password: string = "";
    confirmPassword: string = "";
    acceptTerms:boolean=false;
    code:string;
    token:string;
  }