import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';

import { SiteVisitDto } from './website-visit-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { TableColumnDto } from 'src/app/member/common/common-dtos';
import { WebsiteVisitSectionService } from './website-visit-section.service';
import * as moment from 'moment';

@Component({
    selector: 'website-visit-section',
    templateUrl: 'website-visit-section.component.html',
    styleUrls: ['website-visit-section.component.scss'],
    providers: [WebsiteVisitSectionService]
})
export class WebsiteVisitSectionComponent extends BaseComponent implements OnInit, OnDestroy
{
    @Input() memberId: string = null;
    @Output() sectionLoadedEvent = new EventEmitter<string>();

    public siteVisitHistoryList: SiteVisitDto[] = [];
    private sectionIsLoaded: boolean = false;

    public tableColumns: TableColumnDto[] = [];
    public tableSort: number = -1;
    public noRecordsMessage = "No website visits found";

    private getSubscription: Subscription = null;
    
    constructor(private _sectionService: WebsiteVisitSectionService) {
        super();
    }

    ngOnInit(): void {

        this.initializeColumns();
    }

    public getSectionData() {

        this.sectionIsLoaded = true;
        
        if (this.isNotEmpty(this.getSubscription)) {
            this.getSubscription.unsubscribe();
        }
        
        this.getSubscription = this._sectionService.getSiteVisitHistoryData(this.memberId).subscribe({
            next: (resultData: Array<SiteVisitDto>) => {
                this.siteVisitHistoryList = resultData;
                this.siteVisitHistoryList.map((el: SiteVisitDto) => {
                   el.timeStamp = this.formatDates(el.timeStamp);
                });
                this.sectionLoadedEvent.emit('WebsitesSection');
            },
            error: (err: any) => { 
                console.error(err);
                this.sectionLoadedEvent.emit('WebsitesSection');
            }
        });
    }

    public isDirty(): boolean {
        return false;
    }

    public isLoaded(): boolean {
        return this.sectionIsLoaded;
    }

    public save() {
    }

    private initializeColumns() {

        this.tableColumns = [];
 
        let tableColumnSession: TableColumnDto = new TableColumnDto();
        tableColumnSession.field = 'sessionID';
        tableColumnSession.header = 'Session Id';
        tableColumnSession.width = '22%';
        tableColumnSession.align = 'left';
        
        let tableColumnTimeStamp: TableColumnDto = new TableColumnDto();
        tableColumnTimeStamp.field = 'timeStamp';
        tableColumnTimeStamp.header = 'Time Stamp';
        tableColumnTimeStamp.width = '13%';
        tableColumnTimeStamp.align = 'center';

        let tableColumnUrl: TableColumnDto = new TableColumnDto();
        tableColumnUrl.field = 'visitedURL';
        tableColumnUrl.header = 'Visited URL';
        tableColumnUrl.width = '25%';
        tableColumnUrl.align = 'left';

        // let tableColumnData: TableColumnDto = new TableColumnDto();
        // tableColumnData.field = 'data';
        // tableColumnData.header = 'Data';
        // tableColumnData.width = '20%';
        // tableColumnData.align = 'left';

        let tableColumnIpAddress: TableColumnDto = new TableColumnDto();
        tableColumnIpAddress.field = 'clientIPAddress';
        tableColumnIpAddress.header = 'Client IP Address';
        tableColumnIpAddress.width = '20%';
        tableColumnIpAddress.align = 'center';
        
        this.tableColumns.push(tableColumnSession);
        this.tableColumns.push(tableColumnTimeStamp);
        this.tableColumns.push(tableColumnUrl);
        // this.tableColumns.push(tableColumnData);
        this.tableColumns.push(tableColumnIpAddress);
    }

    public getNumColumns(): number {
        return this.isEmpty(this.tableColumns) ? 0 : this.tableColumns.length;
    }

    public filterTable($event: any, memberAssessmentHistoryTable: Table, columnField: any) {
        memberAssessmentHistoryTable.filter($event.target.value, columnField, 'contains');
    }

    public getTableHeight(): string {
        return '650px';
    }

    public customSort($event: { field: string; data: any[]; order: number; }) {

        let isDate: boolean = false;

        if (this.isNotEmpty($event) && this.isNotEmpty($event.field) && $event.field.toLowerCase().indexOf('date') > -1) {
            
            isDate = true;
        }

        $event.data.sort((data1, data2) => {
    
            let value1 = null;
            let value2 = null;
    
            if ( isDate) {
    
                let val1 = data1[$event.field];
                let val2 = data2[$event.field];
                value1 = new Date(val1);
                value2 = new Date(val2);
    
            } else {
    
                value1 = data1[$event.field];
                value2 = data2[$event.field];
            }
    
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ($event.order * result);
        });
    }

    private formatDates(dateToParse: string): string {
     
        if (this.isNotEmpty(dateToParse)) {
    
            let startDateArray:string[] = dateToParse.split('T');
            let dateArray:string[] = startDateArray[0].split('-');
            let timeArray: string[]=  startDateArray[1].split(':')
            let reconstitutedDate = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0] + ' ' + timeArray[0] + ':' + timeArray[1] + ':' + timeArray[2];
            let currentDateTime: Date = new Date(reconstitutedDate + ' UTC');
            var date= moment(currentDateTime).format('MM/DD/YYYY HH:mm:ss');


            return date;
        }
        
        return null;
    }

    ngOnDestroy(): void {

        if (this.getSubscription !== null) {
            this.getSubscription.unsubscribe();
        }
    }
}