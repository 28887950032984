
<div>
  <span class="margin-div">Username cannot be updated</span>
</div>
<div>
  
  <form #contactInfoForm="ngForm"   novalidate>
    <div class="row">
     

          <table class="table">
            <thead>
              <tr>
                <th class="wd-20">First Name <span class="text-danger">*</span></th>
                <th class="wd-20">Last Name <span class="text-danger">*</span></th>
                <th class="wd-20">Date Of Birth <span class="text-danger">*</span></th>
                <th class="wd-20">Deceased Date</th>
                <th class="wd-20"></th>
              </tr>            </thead>
            <tbody>
              <tr *ngIf="contactInfoModel?.nameSection">
                <td class="wd-20">
                  <ng-container *ngIf="contactInfoModel.nameSection.edit">
                    <input pInputText class="p-inputtext wd-200" name="firstname" pattern="[a-zA-Z'_\-][a-zA-Z'_\- ]+" maxlength="100" [(ngModel)]="contactInfoModel.nameSection.firstName" #firstname="ngModel" required>
                     <div *ngIf="saveClicked && firstname.invalid " class="text-danger">
                      <div *ngIf="firstname.errors['required']">Please enter First Name</div>
                      <div *ngIf="firstname.errors['pattern']">
                        Invalid First Name
                      </div>
                      <div *ngIf="firstname.errors['maxlength']">
                        Allowed Max Length 100
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!contactInfoModel.nameSection.edit">
                    <p pTooltip="{{contactInfoModel.nameSection.firstName}}" tooltipPosition="bottom"  class="word-break-name">  {{contactInfoModel.nameSection.firstName}} </p>                  
                  </ng-container>
                </td>

                <td class="wd-20">
                  <ng-container *ngIf="contactInfoModel.nameSection.edit">
                    <input pInputText class="p-inputtext wd-200" name="lastname" pattern="[a-zA-Z'_\-][a-zA-Z'_\- ]+" maxlength="100" [(ngModel)]="contactInfoModel.nameSection.lastName" #lastname="ngModel" required>
                    <div *ngIf="saveClicked && lastname.invalid" class="text-danger">
                      <div *ngIf="lastname.errors['required']">Please enter Last Name</div>
                      <div *ngIf="lastname.errors['pattern']">
                        Invalid Last Name
                      </div>
                      <div *ngIf="lastname.errors['maxlength']">
                        Allowed Max Length 100
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!contactInfoModel.nameSection.edit">
                    <p pTooltip="{{contactInfoModel.nameSection.lastName}}" tooltipPosition="bottom"  class="word-break-name">  {{contactInfoModel.nameSection.lastName}} </p>           
                  </ng-container>
                </td>

                <td class="wd-20">
                  <ng-container *ngIf="contactInfoModel.nameSection.edit">
                    <input type="date" name="dateofbirth"  [ngModel]="contactInfoModel.nameSection.dateOfBirth | date:'yyyy-MM-dd'" 
                    (ngModelChange)="contactInfoModel.nameSection.dateOfBirth = $event" #dateofbirth="ngModel" 
                    [max]="getCurrentDate()" required >
                    <div *ngIf="saveClicked && dateofbirth.invalid" class="text-danger">
                      <div *ngIf="dateofbirth.errors['required']">Please enter Date of Birth</div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!contactInfoModel.nameSection.edit">
                    <p>{{contactInfoModel.nameSection.dateOfBirth | date:'MM/dd/yyyy'}}</p>
                  </ng-container>
                </td>

                
                <td class="wd-20">
                  <ng-container *ngIf="contactInfoModel.nameSection.edit">
                    <input type="date" name="deceasedDate"  [ngModel]="contactInfoModel.nameSection.deceasedDate | date:'yyyy-MM-dd'" 
                    (ngModelChange)="contactInfoModel.nameSection.deceasedDate = $event" #deceasedDate="ngModel" 
                    [max]="getCurrentDate()" >
                  </ng-container>
                  <ng-container *ngIf="!contactInfoModel.nameSection.edit">
                    <p>{{contactInfoModel.nameSection.deceasedDate | date:'MM/dd/yyyy'}}</p>
                  </ng-container>
                </td>

               
                 <td class="wd-20"></td>
              </tr>
            </tbody>
          </table>


          <table class="table">
            <thead>
              <tr>
                <th class="wd-20">Pref. Name</th>
                <th class="wd-20">Pref. Language</th>
                <th class="wd-20" >PH Time Zone <span class="text-danger">*</span></th>
                <th class="wd-30">User Name</th>
                <th class="wd-10"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="contactInfoModel?.nameSection">
                <td class="wd-20">
                  <ng-container *ngIf="contactInfoModel.nameSection.edit">
                    <input pInputText class="p-inputtext wd-200" name="preferedname" pattern="[a-zA-Z][a-zA-Z ]+"  maxlength="100"  [(ngModel)]="contactInfoModel.nameSection.preferredName" #preferedname="ngModel">
                    <div *ngIf="saveClicked && preferedname.invalid" class="text-danger">                                      
                      <div *ngIf="preferedname.errors['maxlength']">
                        Allowed Max Length 100
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!contactInfoModel.nameSection.edit">
                    <p pTooltip="{{contactInfoModel.nameSection.preferredName}}" tooltipPosition="bottom"  class="word-break-name">  {{contactInfoModel.nameSection.preferredName}} </p>
                  </ng-container>
                </td>
                <td class="wd-20">
                  <ng-container *ngIf="contactInfoModel.nameSection.edit">
                    <p-dropdown name="PreferredLanguage" optionValue="value" optionLabel="description"  [options]="languages" appendTo="body" 
                    [(ngModel)]="contactInfoModel.nameSection.preferredLanguageValue" #PreferredLanguage="ngModel" placeholder="Select" [style]="{'minWidth':'77.3%'}" ></p-dropdown>
                  </ng-container>
                  <ng-container *ngIf="!contactInfoModel.nameSection.edit">
                    <p>{{contactInfoModel.nameSection.preferredLanguageText}} </p>
                  </ng-container>
                </td>
                <td class="wd-20" >
                  <ng-container *ngIf="contactInfoModel.nameSection.edit">
                    <p-dropdown name="PHTimeZone" optionValue="timezoneId" optionLabel="timezoneName"  [options]="timeZones" appendTo="body" 
                    [(ngModel)]="contactInfoModel.nameSection.policyHolderTimezoneId" #PHTimeZone="ngModel" placeholder="Select" [style]="{'minWidth':'80.2%'}" ></p-dropdown>
                  </ng-container>
                  <ng-container *ngIf="!contactInfoModel.nameSection.edit">
                    <p>{{contactInfoModel.nameSection.policyHolderTimezoneName}} </p>
                  </ng-container>
                </td>
                  <td class="wd-30">
                    <ng-container > 
                      <p pTooltip="{{contactInfoModel.nameSection.userName}}" tooltipPosition="bottom">{{contactInfoModel.nameSection.userName}} </p>
                    </ng-container>
                  </td>
  
                <td class="wd-10">
                  <button *ngIf="!contactInfoModel.nameSection.edit && !memberIsDeceased" pButton pRipple  type="button"  icon="pi pi-pencil" 
                  (click)="editUserName(contactInfoModel.nameSection)" class="p-button-rounded p-button-text">
              </button>
              <button *ngIf="contactInfoModel.nameSection.edit && !memberIsDeceased" pButton pRipple type="button"  icon="pi pi-times" 
                  (click)="cancelEditUserName(contactInfoModel.nameSection)" class="p-button-rounded p-button-text">
              </button>
                </td>
              </tr>
            </tbody>
          </table>

    </div>

      <div class="row">
        <h4>Email</h4>
    </div>

    <div class="row" >
        
        <p-table #dtEmail [value]="contactInfoModel.emailSection" dataKey="emailId" 
          editMode="row" [scrollable]="true" [resizableColumns]="true" [scrollHeight]="getTableHeight()">
            <ng-template pTemplate="header">
                <tr>
                  <th class="wd-50">Email Address<span class="text-danger">*</span></th>
                  <th class="wd-10">Primary</th>
                  <th class="wd-10">Source</th>
                  <th class="wd-30"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
                <tr  [pEditableRow]="item" class="vert-top">
                  
                  <td class="col-5" >
                    <p-cellEditor>
                      <ng-template  pTemplate="input">
                        <ng-container *ngIf="item.add" >
                            <input pInputText class="p-inputtext wd-500" name="emailaddress{{i}}"
                            [(ngModel)]="item.emailAddress" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-za-z]{2,4}$"
                            #emailaddress="ngModel" required>
                            <div *ngIf="saveClicked && emailaddress.invalid" class="text-danger">
                              <div *ngIf="emailaddress.errors['required']">Please enter email address</div>
                                <div *ngIf="emailaddress.errors['pattern']">Please enter valid email</div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!item.add">
                          <p  pTooltip="{{item.EmailAddress }}" tooltipPosition="bottom"   *ngIf="isEmailFieldDisable">{{item.emailAddress}}</p>

                          <ng-container *ngIf="!isEmailFieldDisable">
                          <input  pInputText class="p-inputtext wd-500" [disabled]="i==0" name="emailaddress{{i}}"
                          [(ngModel)]="item.emailAddress" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-za-z]{2,4}$"
                          #emailaddress="ngModel" (input)="onEmailChanged(item)" >

                          <div *ngIf="saveClicked && emailaddress.invalid " class="text-danger">
                              <div *ngIf="emailaddress.errors['pattern']">Please enter valid email</div>
                          </div>
                        </ng-container>

                        </ng-container>
                    </ng-template>
                    <ng-template  pTemplate="output">
                      <div *ngIf="item.isPrimary">
                        <p  class="bgPrimary word-break"  pTooltip="{{item.emailAddress }}" tooltipPosition="bottom"  >{{item.emailAddress }}</p>
                      </div>
                      <div *ngIf="!item.isPrimary">
                        <p  class="word-break" pTooltip="{{item.emailAddress }}" tooltipPosition="bottom"  >{{item.emailAddress }}</p>
                        </div>
                    </ng-template>
                    </p-cellEditor>
                  </td>

                    <td class="col-1">
                      <p-cellEditor>
                       
                        <ng-template  pTemplate="input">
                          <p>
                            <p-radioButton [inputId]="item"  (click)="checkConfirmPrimaryEmail(item)" [value]="item" id="PrimaryEmail{{i}}"
                            [(ngModel)]="selectedPrimaryEmail"  name="isPrimaryEmail">
                            </p-radioButton>
                        </p>
                        </ng-template>
                        <ng-template  pTemplate="output">
                           <p>
                                  <p-radioButton [inputId]="item"  [value]="item" 
                                  [(ngModel)]="selectedPrimaryEmail"  [disabled]="true" name="isViewPrimaryEmail"> 
                                  </p-radioButton>
                                  </p>
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td class="col-2">
                      <p-cellEditor>
                        <ng-template  pTemplate="input">
                          <p>{{item.rowSource}}</p>
                      </ng-template>
                      <ng-template  pTemplate="output">                       
                        <p>{{item.rowSource}}</p>               
                      </ng-template>
                      </p-cellEditor>
                    </td>

                    <td >
                        <div class="flex align-items-center justify-content-center gap-2" >
                            <button *ngIf="!item.add && !item.edit && !memberIsDeceased"  pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="editEmail(item)" class="p-button-rounded"></button>
                            <button *ngIf="item.edit && !item.add && !memberIsDeceased"  pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="cancelEditEmail(i, item)" class="p-button-rounded "></button>
                            <button *ngIf="!item.add && !memberIsDeceased" [hidden]="i==0" pButton pRipple type="button"  icon="pi pi-trash" (click)="deleteConfirmEmail(item)" class="p-button-rounded  mr-5"></button>
                            <button *ngIf="item.add && !memberIsDeceased"  pButton pRipple type="button"  icon="pi pi-times" (click)="deleteEmailRow(i)" class="p-button-rounded "></button>
                        </div>
                    </td>


                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td id="noEmailRecordFound" [attr.colspan]="4" style="text-align: center !important">
                  {{noRecordsMessage}}
                  </td>
              </tr>
            </ng-template>
        </p-table>
        <div class="item">
        <div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-center mt-6">
            <button *ngIf="!memberIsDeceased" class="btn button-1" type="button" (click)="addEmailRow()" >Add Email</button>
          </div>
        </div>
      </div>

    </div>

      <div class="row">
          <h4>Phone</h4>
      </div>

      <div class="row" >
          
          <p-table #dtPhone [value]="contactInfoModel.phoneSection" dataKey="phoneId" 
            editMode="row" [scrollable]="true" [resizableColumns]="true" [scrollHeight]="getTableHeight()">
              <ng-template pTemplate="header">
                  <tr>
                    <th class="wd-20">Phone Type <span class="text-danger">*</span></th>
                    <th class="wd-20">Phone Number <span class="text-danger">*</span></th>
                    <th class="wd-10">Extension</th>
                    <th class="wd-10">Primary <span class="text-danger">*</span></th>
                    <th class="wd-10">Source</th>
                    <th class="wd-10">Notes</th>
                    <th class="wd-30"></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
                  <tr  [pEditableRow]="item" class="vert-top">
                      <td class="col-2">
                          <p-cellEditor>
                              <ng-template  pTemplate="input">
                                <p-dropdown name="PhoneType-{{i}}" optionValue="value" optionLabel="value"  [options]="phoneTypes" appendTo="body" 
                                [(ngModel)]="item.phoneType" #PhoneType="ngModel" placeholder="Select" [style]="{'width':'70%'}"  [required]="true"></p-dropdown>
                                <div *ngIf="saveClicked && PhoneType.invalid" class="text-danger">
                                  <div *ngIf="PhoneType.errors?.['required']">Phone No. Type is mandatory</div>
                                </div>
                              </ng-template>
                              <ng-template  pTemplate="output">
                                <p>  {{item.phoneType}} </p>
                              </ng-template>
                          </p-cellEditor>
                      </td>

                      <td class="col-2">
                          <p-cellEditor>
                              <ng-template  pTemplate="input">
                                <p-inputMask  mask="(999) 999-9999" placeholder="1234567890"
                                name="Phone-{{i}}" 
                                #Phone="ngModel" [(ngModel)]="item.number" 
                                [required]="true"></p-inputMask>
                                  <div *ngIf="saveClicked && Phone.invalid" class="text-danger">
                                    <div *ngIf="Phone.errors?.['required']"> Number is mandatory</div>
                                    <div *ngIf="Phone.errors?.['pattern']"> Enter valid Number ex: 1234567890</div>
                                    <div *ngIf="Phone.errors?.['minlength']"> Phone should be 10 digits</div>
                                    <div *ngIf="Phone.errors?.['maxlength']"> Phone should be 10 digits</div>
                                  </div>
                              </ng-template>
                              <ng-template  pTemplate="output">
                                <div *ngIf="item.isPrimary">
                                  <p class="bgPrimary">{{item.number ? getMaskPhoneNumber(item.number) : item.number}}</p>
                                </div>
                                <div *ngIf="!item.isPrimary">
                                <p>{{item.number ? getMaskPhoneNumber(item.number) : item.number}}</p>
                                </div>
                              </ng-template>
                          </p-cellEditor>
                      </td>

                      <td class="col-1">
                        <p-cellEditor>
                          <ng-template  pTemplate="input">
                            <p-inputNumber [useGrouping]="false" name="Extension-{{i}}" 
                                #Extension="ngModel" [(ngModel)]="item.extension" (onInput)="inputchange($event.value)"
                                 [maxlength]="6"></p-inputNumber>                            
                            <div *ngIf="saveClicked && Extension.invalid" class="text-danger">
                              <div *ngIf="Extension.errors?.['maxlength']"> Maximum length is 6</div>
                            </div>
                          </ng-template>
                          <ng-template  pTemplate="output">
                            <p> {{item.extension}} </p>
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      
                      <td class="col-1">
                        <p-cellEditor>
                          <ng-template  pTemplate="input">
                            <p-radioButton [inputId]="item" id="PrimaryPhone{{i}}"
                            (click)="checkConfirmPrimaryPhone(item)"
                              name="isPrimaryPhone" [value]="item" 
                            [(ngModel)]="selectedPhonePrimary"></p-radioButton>
                          </ng-template>
                          <ng-template  pTemplate="output">
                            <p>
                                <p-radioButton [inputId]="item"  [value]="item" 
                                [(ngModel)]="selectedPhonePrimary"  [disabled]="true" name="isViewPrimaryPhone">
                                </p-radioButton>
                            </p>
                          </ng-template>
                        </p-cellEditor>
                      </td>

                      <td class="col-2">
                        <p-cellEditor>
                          <ng-template  pTemplate="input">
                            <p>{{item.rowSource}}</p>
                        </ng-template>
                        <ng-template  pTemplate="output">
                          <p>{{item.rowSource}}</p>
                        </ng-template>
                        </p-cellEditor>
                      </td>

                      <td class="col-2">
                        <p-cellEditor>
                            <ng-template  pTemplate="input">
                              <textarea pInputTextarea  class="p-inputtext"  name="notes{{i}}" 
                              [(ngModel)]="item.notes" #notes="ngModel" maxlength="255"></textarea>
                              <div *ngIf="saveClicked && notes.invalid " class="text-danger">
                                 
                                  <div *ngIf="notes.errors['maxlength']">you can enter only 255 characters</div>
                                  
                              </div>
                              </ng-template>
                            <ng-template  pTemplate="output">
                            <p pTooltip="{{item.notes}}" tooltipPosition="bottom"  class="word-break-phone">  {{item.notes}} </p>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                      <td >
                          <div class="flex align-items-center justify-content-center gap-2">
                              <button *ngIf="!item.add && !item.edit && !memberIsDeceased" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="editPhone(item)" class="p-button-rounded p-button-text"></button>
                              <button *ngIf="item.edit && !item.add && !memberIsDeceased" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="cancelEditPhone(i, item)" class="p-button-rounded p-button-text"></button>
                              <button *ngIf="!item.add && !memberIsDeceased" pButton pRipple type="button"  icon="pi pi-trash" (click)="deleteConfirmPhone(item)" class="p-button-rounded p-button-text mr-5"></button>
                              <button *ngIf="item.add && !memberIsDeceased" pButton pRipple type="button"  icon="pi pi-times" (click)="deletePhoneRow(i)" class="p-button-rounded p-button-text"></button>
                          </div>
                      </td>
                  </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td id="noPhoneRecordFound" [attr.colspan]="9" style="text-align: center !important">
                    {{noRecordsMessage}}
                    </td>
                </tr>
              </ng-template>
          </p-table>
          <div class="item">
          
            <div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-center mt-6">
                <p-checkbox id="textConsent" [(ngModel)]="contactInfoModel.nameSection.textConsent" label="Policyholder agrees to receive texts from Sagility at all phone numbers with Phone Type=Text" 
                    [binary]="true" [disabled]="enableIfTextPhoneExists()" autofocus>
                </p-checkbox><br>
              </div>
            </div>   

          <div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center mt-6">
              <button *ngIf="!memberIsDeceased" class="btn button-1" type="button" (click)="addPhoneRow()" >Add Phone</button>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
          <h4>Address</h4>
      </div>

      <div class="row">
        
        <p-table #dtAddress [value]="contactInfoModel.addressSection" dataKey="addressId" [responsive]="true"
        editMode="row" [tableStyle]="{'min-width': '50rem'}" [scrollable]="true" [resizableColumns]="true"   [scrollHeight]="getTableHeight()" >
          <ng-template pTemplate="header">
              <tr>
                  <th class="wd-15">Address Type <span class="text-danger">*</span></th>
                  <th class="wd-10">Street 1 <span class="text-danger">*</span></th>
                  <th class="wd-10">Street 2</th>
                  <th class="wd-10">City <span class="text-danger">*</span></th>
                  <th class="wd-15">State/Province <span class="text-danger">*</span></th>
                  <th class="wd-5">Zipcode <span class="text-danger">*</span></th>
                  <th class="wd-5">County</th>
                  <th class="wd-5">Primary <span class="text-danger">*</span></th>
                  <th class="wd-5">Source</th>
                  <th class="wd-25"></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
              <tr  [pEditableRow]="item" class="vert-top">
                  <td class="line-break">
                      <p-cellEditor>
                          <ng-template  pTemplate="input">
                          
                            <p-dropdown name="AddressType-{{i}}" optionValue="value" optionLabel="value"  [options]="addressTypes" appendTo="body" 
                            [(ngModel)]="item.addressType" #AddressType="ngModel" placeholder="Select" [style]="{'width':'70%'}" [required]="true"></p-dropdown>
                            <div *ngIf="saveClicked && AddressType.invalid" class="text-danger line-break">
                              <div *ngIf="AddressType.errors?.['required']">Address Type is mandatory</div>
                            </div>
                          </ng-template>
                          <ng-template  pTemplate="output">           
                            <p> {{item.addressType}} </p>
                          </ng-template>
                      </p-cellEditor>
                  </td>

                  <td class="line-break">
                      <p-cellEditor>
                          <ng-template  pTemplate="input">
                            <textarea rows="1" cols="30" pInputTextarea  name="Street1-{{i}}" [(ngModel)]="item.street1" maxlength="300" #Street1="ngModel" required></textarea>
                            <div *ngIf="saveClicked && Street1.invalid" class="text-danger textarea-error-margin line-break">
                              <div *ngIf="Street1.errors?.['required']">Street 1 is mandatory</div>
                            </div>
                          </ng-template>
                          <ng-template  pTemplate="output">
                            <p pTooltip="{{item.street1}}" tooltipPosition="bottom"  class="word-break-name"> {{item.street1}}</p>
                          </ng-template>
                      </p-cellEditor>
                  </td>

                  <td class="line-break" >
                    <p-cellEditor>
                        <ng-template  pTemplate="input">
                          <textarea rows="1" cols="30" pInputTextarea name="Street2-{{i}}" maxlength="300" [(ngModel)]="item.street2" #Street2="ngModel"></textarea>                          
                        </ng-template>
                        <ng-template  pTemplate="output">
                          <p pTooltip="{{item.street2}}" tooltipPosition="bottom" class="word-break-name">  {{item.street2}}</p>
                        </ng-template>
                    </p-cellEditor>
                  </td>

                  <td class="line-break" >
                    <p-cellEditor>
                        <ng-template  pTemplate="input">
                        
                        <input pInputText  class="p-inputtext" maxlength="300" name="City-{{i}}" [(ngModel)]="item.city" #City="ngModel" required>
                        <div *ngIf="saveClicked && City.invalid" class="text-danger line-break">
                          <div *ngIf="City.errors?.['required']">City is mandatory</div>
                        </div>
                        </ng-template>
                        <ng-template  pTemplate="output">
                          <div *ngIf="item.isPrimary">
                            <p  pTooltip="{{item.city}}" tooltipPosition="bottom"  class="word-break-name bgPrimary">    {{item.city}} </p>
                          </div>
                        <div *ngIf="!item.isPrimary">
                          <p pTooltip="{{item.city}}" tooltipPosition="bottom"  class="word-break-name">    {{item.city}} </p>
                          </div>
                        </ng-template>
                    </p-cellEditor>
                  </td>

                  <td class="line-break" >
                    <p-cellEditor>
                        <ng-template  pTemplate="input">
                        
                          <p-dropdown name="State-{{i}}" optionValue="value" optionLabel="text"  [options]="states" appendTo="body" 
                          [(ngModel)]="item.state" #State="ngModel" placeholder="Select" [style]="{'width':'70%'}" [required]="true"></p-dropdown>
                          <div *ngIf="saveClicked && State.invalid" class="text-danger line-break">
                            <div *ngIf="State.errors?.['required']">State is mandatory</div>
                          </div>
                        </ng-template>
                        <ng-template  pTemplate="output">
                        <p> {{item.state}} </p>
                        </ng-template>
                    </p-cellEditor>
                  </td> 
                  <td class="line-break" >
                    <p-cellEditor>
                      <ng-template  pTemplate="input">
                        <span class="p-inputnumber p-component">
                        <input type="text" id="zipcode" name="Zipcode-{{i}}" 
                        #Zipcode="ngModel" [(ngModel)]="item.zip"
                        (blur)="inputchange($event)" (keypress)="keyPress($event)"
                        class="p-inputtext p-component p-element p-inputnumber-input"
                         minlength="5"  maxlength="9" [required]="true"  pattern="^([0-9]{5}([0-9]{4})?)">
                        </span>
                        <div *ngIf="saveClicked && Zipcode.invalid" class="text-danger line-break">
                          <div *ngIf="Zipcode.errors?.['required']">Zip is mandatory</div>
                          <div *ngIf="Zipcode.errors?.['pattern']"> Enter valid Zip ex: 98302 or 980321234</div>
                          <div *ngIf="Zipcode.errors?.['minlength']"> Minimum length is 5</div>
                          <div *ngIf="Zipcode.errors?.['maxlength']"> Maximum length is 9</div>
                        </div>
                    </ng-template>
                    <ng-template  pTemplate="output">
                      <p> {{item.zip}} </p>
                    </ng-template>
                    </p-cellEditor>
                  </td> 

                  <td class="line-break" >
                    <p-cellEditor>
                        <ng-template  pTemplate="input">
                          <input pInputText  class="p-inputtext" maxlength="50"  name="County-{{i}}" [(ngModel)]="item.county" #County="ngModel">
                        </ng-template>
                        <ng-template  pTemplate="output">
                          <div>
                            <p  pTooltip="{{item.county}}" tooltipPosition="bottom"  class="word-break-name">{{item.county}}</p>
                          </div>
                        </ng-template>
                    </p-cellEditor>
                  </td>

                  <td class="line-break">
                      <p-cellEditor>
                        <ng-template  pTemplate="input">
                          <p-radioButton [inputId]="item" id="PrimaryAddress{{i}}"
                          (click)="checkConfirmPrimaryAddress(item)"
                          name="isPrimaryAddress" [value]="item" 
                          [(ngModel)]="selectedPrimaryAddress"
                          ></p-radioButton>
                      </ng-template>
                      <ng-template  pTemplate="output">                        
                        <p-radioButton [inputId]="item"  [value]="item" 
                          [(ngModel)]="selectedPrimaryAddress" name="isViewPrimaryAddress"
                          [disabled]="true"
                          ></p-radioButton>
                    </ng-template>
                      </p-cellEditor>
                  </td>

                  <td class="line-break">
                    <p-cellEditor>
                      <ng-template  pTemplate="input">
                        <p> {{item.rowSource}}</p>
                    </ng-template>
                    <ng-template  pTemplate="output">
                      <p> {{item.rowSource}}</p>
                    </ng-template>
                    </p-cellEditor>
                  </td>

                  <td>
                      <div class="flex align-items-center justify-content-center gap-2">
                          <button *ngIf="!item.add && !item.edit && !memberIsDeceased" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="editAddress(item)" class="p-button-rounded p-button-text"></button>
                          <button *ngIf="item.edit && !item.add && !memberIsDeceased" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="cancelEditAddress(i, item)" class="p-button-rounded p-button-text"></button>
                          <button *ngIf="!item.add && !memberIsDeceased" pButton pRipple type="button"  icon="pi pi-trash" (click)="deleteConfirmAddress(item)" class="p-button-rounded p-button-text mr-5"></button>
                          <button *ngIf="item.add && !memberIsDeceased" pButton pRipple type="button"  icon="pi pi-times" (click)="deleteAddressRow(i)" class="p-button-rounded p-button-text"></button>
                        </div>
                  </td>
              </tr>

            
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td id="noAddressRecordFound" [attr.colspan]="12" style="text-align: center !important">
                {{noRecordsMessage}}
                </td>
            </tr>
          </ng-template>
      </p-table> 
              
              <div class="row" class="mt-6">
              <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                  <button *ngIf="!memberIsDeceased" class="btn button-1 me-md-2"  type="button" (click)="addAddressRow()" >Add Address</button>
              </div>
              </div>
      </div>

      <div class="row" class="mt-3" *ngIf="isShowSaveButton">
          <div class="d-grid gap-2 d-md-flex justify-content-md-center">
              <button *ngIf="!memberIsDeceased" class="btn button-2  me-md-2"  type="submit" (click)="saveContactInfo(contactInfoForm)" >Save</button>        
          </div>
      </div> 
  </form>


<p-confirmDialog [style]="{width: '50vw'}"  [baseZIndex]="10000">
  
</p-confirmDialog>

<p-dialog header="Alert" [(visible)]="display" [style]="{width: '50vw'}" [modal]="true">
  <p>{{alertMsg}}</p>
  
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="display=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="showNoTextPhoneWarningDialog" [style]="{width: '30vw'}" >
  <p>{{alertMsgTextConsent}}</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="showNoTextPhoneWarningDialog=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
</div>
