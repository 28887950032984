export class ContactInfoDto{
  nameSection: UserNameDto;
  emailSection: Array<EmailDto> = [];
  phoneSection: Array<PhoneDto> = [];
  addressSection: Array<AddressDto> =[];  
}

export class UserNameDto {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  deceasedDate: Date;
  preferredName: string;
  userName: string;
  source: string;
  edit: boolean = false;
  textConsent: boolean;
  preferredLanguageValue: number;
  preferredLanguageText: string;
  policyHolderTimezoneId: string;
  policyHolderTimezoneName: string;
}

export class EmailDto {
  emailAddress: string;
  emailId?: string;
  isPrimary?: boolean;
  isActive?: boolean = true;
  edit?: boolean = false;
  add?: boolean = false;
  rowSource: string;
  isEmailChanged?:boolean;
}

export class PhoneDto {
  phoneType: string;
  number: string;
  extension: string;
  phoneId?: string;
  phoneExternalId?: string;
  country?: string;
  isPrimary?: boolean;
  isActive?: boolean;
  startDate?: string
  endDate?: string;
  edit?: boolean = false;
  add?: boolean = false;
  rowSource: string;
  notes?:string;
}

export class AddressDto {
  addressType: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  stateName: string;
  zip: string;
  county: string;
  addressId?: string;
  addressExternalId?: string;
  latitude?: string;
  longitude?: string;
  isPrimary?: boolean;
  isActive?: boolean = true;
  startDate?: string
  endDate?: string;
  edit?: boolean = false;
  add?: boolean = false;
  rowSource: string;
}

export class GenericMasterDataType {
  category: string;
  value: string;
  text: string;
  id: string;
  deletedDate: Date;
  isDeleted: boolean;
  reatedBy: string;
  createdDate: Date; 
  updatedBy: string;
  updatedDate: Date;
}

export class StateDto{
  createdBy: string;
  createdDate: string;
  deletedDate: string;
  id: number;
  isActive: boolean;
  stateCode: string
  stateName: string
  updatedBy: string
  updatedDate: string
  value: string
}

export class saveContactInfoData
{
  memberSource: string
  memberExternalId: string
  firstName: string
  lastName: string
  dateOfBirth: string
  deceasedDate: string
  preferredName: string
  memberEmailAddress: string
  textConsent:boolean
  memberAgentPhoneNumbers: PhoneDto[]
  memberAgentAddresses: AddressDto[]
  MemberAgentEmailAddresses: EmailDto[]
  source: string
  PreferredLanguage: number
  PolicyHolderTimezone: string
}

export class BaseDto {
  createdBy: string;
  createdDate: string;
  deletedDate: string;
  id: number;
  updatedBy: string;
  updatedDate: string;
}

export class LanguageDto extends BaseDto{
  value: number;
  description: string;
}

export class TimeZoneDto {
  timezoneId: string;
  timezoneName: string;
}


