
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { MemberPopulationDetails, SampleDto, DeleteDto,PopulationDetails, SavePopulationDetails } from './population-section.dtos';

@Injectable()
export class PopulationSectionService {

    private saveUrl: string = '/member/{memberId}/Population';    
    private getMemberPopulationDetailsUrl: string = '/member/{memberId}/population';
    private updateMemberPopulationStatus:string = '/member/{memberId}/Population/{populationID}'
    private getPopulationDetailsUrl: string = '/member/population';
    private headers: HttpHeaders;   

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {

        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');

        environment.contentServiceApiBaseUrl = _appConfig.contentServiceApiBaseUrl;
    }

    // public saveSectionData(formToSave: SavePopulationDetails,memberId:string): Observable<SavePopulationDetails[]> {
        
    //     let url = (this._appConfig.memberManagerApiBaseUrl + this.saveUrl).replace('{memberId}',memberId);

    //     return this._http.post<SavePopulationDetails[]>(url, formToSave, {headers: this.headers});
    // }

    public getSectionData(memberId: string): Observable<MemberPopulationDetails[]> {  
        //console.log("memberId " + memberId);
        //memberId = "5940108E-8B64-4B3F-40BE-08D9DA9D5000";
        let url = (this._appConfig.memberManagerApiBaseUrl + this.getMemberPopulationDetailsUrl).replace('{memberId}', memberId);
       
        return this._http.get<MemberPopulationDetails[]>(url, {headers: this.headers});
    }
     
    // public setMemberPopulationStatus(memberId:string, populationId:string) : Observable<DeleteDto> {  
    //    // memberId = "5940108E-8B64-4B3F-40BE-08D9DA9D5000";
    //     let url = (this._appConfig.memberManagerApiBaseUrl + this.updateMemberPopulationStatus).replace('{memberId}', memberId).replace('{populationID}',populationId);
    //     return this._http.delete<DeleteDto>(url, {headers: this.headers});
    // }

    public getPopulationData(): Observable<PopulationDetails[]> {  
        let url = (this._appConfig.memberManagerApiBaseUrl + this.getPopulationDetailsUrl);
       
        return this._http.get<PopulationDetails[]>(url, {headers: this.headers});
    }
}
