import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { Constant } from 'src/app/auth/utils/constant';
import { BaseComponent } from 'src/app/member/common/base.component';
import { AssessmentWrapperService } from 'src/app/member/assessment-section/assessment-wrapper/assessment-wrapper.service';
import { AssessmentFactResolvedDto, AssessmentOutcomeDto, AssessmentResponseDto, AssessmentCompleteDto, AssessmentDataDto, AssessmentInitDto, AssessmentOutcomePropertyDto, CreateMemberHealthAssessmentDto, CarePlanGoalDto, CarePlanBarrierDto, CarePlanInterventionDto, CarePlanInterventionInstructionDto, CarePlanInterventionDocumentDto, E24MemberIdTraversalIdDto} from './assessment-wrapper.dtos';
import { AnsweredQuestionDto, Bullet, CategoryProperty, Conclusion, Properties } from 'assessment-ctrl/lib/e24.dtos';
import { HealthAssessmentStatusType } from '../assessment-section.dtos';

@Component({
    selector: 'assessment-ctrl',
    templateUrl: 'assessment-wrapper.component.html',
    styleUrls: ['assessment-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [AssessmentWrapperService]
})
export class AssessmentWrapperComponent extends BaseComponent implements OnInit, OnDestroy {

    @Output() assessmentCompletedEvent = new EventEmitter<string>();
    @Output() redirectToPrintViewEvent = new EventEmitter<string>();
    
    public assessmentStartData: string = undefined;
    public showAssessment: boolean = false;
    public initializingAssessment: boolean = false;
    public memberAtomId: string;
    
    private memberHealthAssessmentId: string = null;
    public memberId: string = null;
    private assessmentDefinitionId: string;
    public assessmentEnvironmentUrl: string;
    public assessmentAlgorithmId: string;
	public language: string = 'eng';
    public assessmentName: string;	
	public assessmentCompletedLabel: string;
    private prepopList: AssessmentFactResolvedDto[];
    public assessmentError: string = null;
    
    private getContentEnvConfigsSubscription: Subscription = null;    
    private getAssessmentDefinitionSubscription: Subscription = null;
    private getAssessmentDataSubscription: Subscription = null;
    private resolveAssessmentFactsSubscription: Subscription = null;
    private createNewMemberHealthAssessmentSubscription: Subscription = null;
    private saveAssessmentInitSubscription: Subscription = null;
    private saveAssessmentCompleteSubscription: Subscription = null;

    public e24TraversalId: string = null;
    public e24MemberId: string = null;


    constructor(private _assessmentService: AssessmentWrapperService, private _changeDetectorRef: ChangeDetectorRef) {
            
        super();
    }

    public ngOnInit() {

        this.initialize();
    }

    private initialize() {

        if (this.isNotEmpty(this.getContentEnvConfigsSubscription)) {
            this.getContentEnvConfigsSubscription.unsubscribe();
        }

        this.getContentEnvConfigsSubscription = this._assessmentService.getContentEnvConfigs().subscribe({
            next: (resultConfigs) => {

                this.assessmentEnvironmentUrl = resultConfigs.expert24Url;
            },
            error: (err: any) => { 
                console.error(err);
            }                
        });
    }

    public loadManualAssessment(memberId: string, assessmentDefinitionId: string, memberAtomId: string) {

        if (this.isNotEmpty(assessmentDefinitionId)) {

            this.memberId = memberId;
            this.memberAtomId = memberAtomId;
            this.assessmentDefinitionId = assessmentDefinitionId;
            this.getAssessmentDefinition(assessmentDefinitionId);

        } else {

            let errorMsg: string = 'Assessment could not be determined due to missing assessmentDefinitionId';
                                                
            this.showAssessment = false;
            this.initializingAssessment = false;
            this.assessmentError = errorMsg;
            this._changeDetectorRef.detectChanges();

            console.error(errorMsg);
        }
    }

    public loadMemberHealthAssessment(memberHealthAssessmentId: string, memberAtomId: string) {

        this.memberAtomId = memberAtomId;
        this.memberHealthAssessmentId = memberHealthAssessmentId;
        this.initializingAssessment = true;

        if (this.isNotEmpty(this.getAssessmentDataSubscription)) {
            this.getAssessmentDataSubscription.unsubscribe();
        }

        this.getAssessmentDataSubscription = this._assessmentService.getAssessmentInfo(memberHealthAssessmentId).subscribe({
            next: (resultData) => {

                if (this.isNotEmpty(resultData.assessmentDefinitionId)) {

                    if (resultData.status !== HealthAssessmentStatusType.COMPLETED) {
                    
                        this.memberId = resultData.memberId;
                        this.e24MemberId = resultData.e24MemberId;
                        this.e24TraversalId = resultData.e24TraversalId;
                        this.getAssessmentDefinition(resultData.assessmentDefinitionId);
                    
                    } else {

                        this.redirectToPrintViewEvent.emit(memberHealthAssessmentId);
                    }

                } else {

                    let errorMsg: string = 'Assessment definition id could not be determined for member health assessment record id: ' + memberHealthAssessmentId;
                                                
                    this.showAssessment = false;
                    this.initializingAssessment = false;
                    this.assessmentError = errorMsg;
                    this._changeDetectorRef.detectChanges();

                    console.error(errorMsg);
                }
            },
            error: (err: any) => { 
                console.error(err);
            }
        });
    }

    private getAssessmentDefinition(assessmentDefinitionId: string) {
        
        if (this.isNotEmpty(assessmentDefinitionId)) {

            if (this.isNotEmpty(this.getAssessmentDefinitionSubscription)) {
                this.getAssessmentDefinitionSubscription.unsubscribe();
            }

            this.getAssessmentDefinitionSubscription = this._assessmentService.getAssessmentDefinition(assessmentDefinitionId, true).subscribe({
                next: (resultAssessmentDefinition) => {

                    if (this.isNotEmpty(resultAssessmentDefinition) && this.isNotEmpty(resultAssessmentDefinition.id)) {
                 
                        this.assessmentAlgorithmId = String(resultAssessmentDefinition.e24Id);
                        this.assessmentName = resultAssessmentDefinition.assessmentName;
                        if(this.assessmentName.endsWith('Assessment')){
                            this.assessmentCompletedLabel = 'Thank you for completing the ' + resultAssessmentDefinition.assessmentName + '.';
                        }
                        else{
                        this.assessmentCompletedLabel = 'Thank you for completing the ' + resultAssessmentDefinition.assessmentName + ' assessment.';
                        }

                        if (this.isNotEmpty(resultAssessmentDefinition)) {

                            this.getAssessmentPrePopData(assessmentDefinitionId);
                        }
                    } else {

                        let errorMsg: string = 'Assessment definition with id ' + assessmentDefinitionId + ' not found in content service.';
                                                
                        this.showAssessment = false;
                        this.initializingAssessment = false;
                        this.assessmentError = errorMsg;
                        this._changeDetectorRef.detectChanges();
    
                        console.error(errorMsg);    
                    }
                },
                error: (err: any) => { 
                    console.error(err);
                }
            });
        }
    }

    private getAssessmentPrePopData(assessmentDefinitionId: string) {

        if (this.isNotEmpty(this.resolveAssessmentFactsSubscription)) {
            this.resolveAssessmentFactsSubscription.unsubscribe();
        }

        this.resolveAssessmentFactsSubscription = this._assessmentService.getAssessmentPrePopValues(assessmentDefinitionId, this.memberId).subscribe({
            next: (resolvedFacts) => {

                let prepop: string = '';

                this.prepopList = resolvedFacts.resolveList;

                resolvedFacts.resolveList.forEach(fact => {

                    prepop = prepop + '"' + fact.paramName + '": "' + fact.resolveResult + '",' 
                });

                if (this.isNotEmpty(prepop) && prepop.trim().length > 0) {
   
                    prepop = prepop.substring(0, prepop.length - 1);
                    prepop = '{' + prepop + '}';

                    this.assessmentStartData = prepop;
                    console.log('prepop data', this.assessmentStartData);

                } else {

                    let errorMsg: string = 'Prepop data was empty for assessment definition id: ' + assessmentDefinitionId;
                                                
                    this.showAssessment = false;
                    this.initializingAssessment = false;
                    this.assessmentError = errorMsg;
                    this._changeDetectorRef.detectChanges();
        
                    console.error(errorMsg);
                }

                this.initializingAssessment = false;
                this.showAssessment = true;
            },
            error: (err: any) => { 

                if (this.isNotEmpty(err) && this.isNotEmpty(err.error)) {
                    
                    this.showAssessment = false;
                    this.initializingAssessment = false;
                    this.assessmentError = err.error;
                    this._changeDetectorRef.detectChanges();

                    console.error(err.error);    

                } else {
                    console.error(err);
                }
            }                
        });
    }

    private persistAssessmentInit(e24Dto: E24MemberIdTraversalIdDto) {
        
        if (this.isNotEmpty(this.memberHealthAssessmentId)) {

            this.saveAssessmentInit(e24Dto, this.memberHealthAssessmentId);
        
        } else {

            // create a new member health assessment, and THEN persist the assessment init data
            const now = new Date();
            const isoString = now.toISOString();

            let createDto: CreateMemberHealthAssessmentDto = new CreateMemberHealthAssessmentDto();
            createDto.memberId = this.memberId;
            createDto.assessmentDefinitionId = this.assessmentDefinitionId;
            createDto.e24TraversalId = e24Dto.E24TraversalId;
            createDto.e24MemberId = e24Dto.E24MemberId;
            createDto.assignmentDate = isoString;
            createDto.status = HealthAssessmentStatusType.IN_PROCESS.replace(' ', '');
            createDto.rowSource = Constant.AIProwSource;

            if (this.isNotEmpty(this.createNewMemberHealthAssessmentSubscription)) {
                this.createNewMemberHealthAssessmentSubscription.unsubscribe();
            }

            this.createNewMemberHealthAssessmentSubscription = this._assessmentService.createNewMemberHealthAssessment(createDto).subscribe({
                next: (newRecordResult) => {

                    this.memberHealthAssessmentId = newRecordResult.memberHealthAssessmentId;
                    this.saveAssessmentInit(e24Dto, this.memberHealthAssessmentId);
                },
                error: (err: any) => { 
                    console.error(err);
                }
            });
        }
    }

    private saveAssessmentInit(e24Dto: E24MemberIdTraversalIdDto, memberHealthAssessmentId: string) {

        let initDto: AssessmentInitDto = new AssessmentInitDto();
        initDto.Id = memberHealthAssessmentId;
        initDto.E24TraversalId = e24Dto.E24TraversalId;
        initDto.E24MemberId = e24Dto.E24MemberId;
        initDto.Status = HealthAssessmentStatusType.IN_PROCESS.replace(' ', '');
        initDto.RowSource = Constant.AIProwSource;

        if (this.isNotEmpty(this.prepopList)) {

            this.prepopList.forEach(prepop => {

                let prepopDto: AssessmentDataDto = new AssessmentDataDto();
                prepopDto.PrepopName = prepop.paramName;
                prepopDto.PrepopValue = prepop.resolveResult;

                initDto.PrepopDataList.push(prepopDto);
            });
        }

        if (this.isNotEmpty(this.saveAssessmentInitSubscription)) {
            this.saveAssessmentInitSubscription.unsubscribe();
        }

        this.saveAssessmentInitSubscription = this._assessmentService.saveAssessmentInit(initDto).subscribe({
            next: (saveInitResult) => {

            },
            error: (err: any) => { 
                console.error(err);
            }
        });
    }

    public assessmentTraversalIdSet($event: E24MemberIdTraversalIdDto) {
        
        this.persistAssessmentInit($event);

        window.scroll({
            top: document.getElementById('AssessmentsSection').offsetTop,
            left: 0,    
            behavior: 'smooth'
        });
    }

    public assessmentErrorOccurred($event: { message: string; error: string; }) {

        if (this.isNotEmpty($event)) {

            let msg: string = $event.message;

            if (msg.toLowerCase().startsWith('could not begin continue assessment')) {

                msg = msg + '  Check the assessment definition, fact definition, and assessment fact definitions for accuracy of values.'
            }

            this.showAssessment = false;
            this.initializingAssessment = false;
            this.assessmentError = msg;
            this._changeDetectorRef.detectChanges();

            console.error($event.error + ' ' + msg);
        }

        console.log('assessmentErrorOccurred: ', $event);
	}

	public assessmentIsComplete($event: any) {

		console.log('assessmentIsComplete: ', $event);

        this.saveAssessmentComplete($event);
	}

    private saveAssessmentComplete(completionData: any) {
        
        if (this.isNotEmpty(this.memberHealthAssessmentId)) {

            let completionDto: AssessmentCompleteDto = new AssessmentCompleteDto();
            completionDto.Id = this.memberHealthAssessmentId;
            completionDto.Status = HealthAssessmentStatusType.COMPLETED;
            completionDto.RowSource = Constant.AIProwSource;

            completionDto.ResponseList = this.getAssessmentResponses(completionData);
            completionDto.OutcomeList = this.getOutcomeConclusions(completionData);
            completionDto.CarePlanGoalList = this.getCarePlanConclusions(completionData);

            console.log('assessment save dto', completionDto);
            
            if (this.isNotEmpty(this.saveAssessmentCompleteSubscription)) {
                this.saveAssessmentCompleteSubscription.unsubscribe();
            }

            this.saveAssessmentCompleteSubscription = this._assessmentService.saveAssessmentComplete(completionDto).subscribe({
                next: (saveCompleteResult) => {

                    this.assessmentCompletedEvent.emit('success');
                },
                error: (err: any) => { 
                    
                    if (this.isNotEmpty(err) && this.isNotEmpty(err.error)) {
                        
                        let theError: string = err.error;
                        theError = theError.substring(0, theError.indexOf(' at Colibrium'));
                        theError = theError.replace('System.InvalidOperationException: ', '');

                        this.assessmentError = theError;
                        console.error(theError);

                    } else {
                        console.error(err);
                    }
                }
            });
        }
    }

    private getCarePlanConclusions(completionData: any): CarePlanGoalDto[] {

        let resultCarePlanGoalList: CarePlanGoalDto[] = [];
        let currentGoalDto: CarePlanGoalDto = null;
        let currentBarrierDto: CarePlanBarrierDto = null;
        let contentVersion: string = completionData.ContentVersion;

        completionData.CarePlanConclusions.forEach((conclusion: Conclusion) => {
            
            if (this.isNotEmpty(conclusion)) {
         
                if (conclusion.Category === 'Goal') {

                    let goalDto: CarePlanGoalDto = new CarePlanGoalDto();
                    goalDto.GoalContentCompareId = conclusion.DisplayText;
                    goalDto.GoalDisplayText = conclusion.Explanation;
                    goalDto.GoalExpert24Id = conclusion.ConclusionID;
                    goalDto.GoalVersion = contentVersion;
                    goalDto.GoalOrder = this.getConclusionItemOrder(conclusion.Properties);
                    goalDto.RowSource = Constant.AIProwSource;

                    resultCarePlanGoalList.push(goalDto);

                    // capture the current goal as any following barriers before a new goal conclusion is encountered will belong to this goal
                    currentGoalDto = goalDto;
                    currentBarrierDto = null;

                    conclusion.Bullets.forEach((bullet: Bullet) => {
            
                        if (this.isNotEmpty(bullet)) {

                            if (bullet.Category === 'Barrier') {

                                let barrierDto: CarePlanBarrierDto = new CarePlanBarrierDto();
                                barrierDto.BarrierDescription = bullet.DisplayText;
                                barrierDto.BarrierExpert24Id = bullet.BulletID;
                                barrierDto.BarrierVersion = contentVersion;
                                barrierDto.BarrierOrder = this.getConclusionItemOrder(bullet.Properties);
                                barrierDto.RowSource = Constant.AIProwSource;

                                currentGoalDto.BarriersList.push(barrierDto);

                                // capture the current barrier as any following interventions before a new barrier conclusion is encountered will belong to this barrier
                                currentBarrierDto = barrierDto;
                            
                            } else if (bullet.Category === 'Intervention') {

                                let interventionDto: CarePlanInterventionDto = new CarePlanInterventionDto();
                                interventionDto.InterventionDescription = bullet.DisplayText;
                                interventionDto.InterventionExpert24Id = bullet.BulletID;
                                interventionDto.InterventionVersion = contentVersion;
                                interventionDto.InterventionOrder = this.getConclusionItemOrder(bullet.Properties);
                                interventionDto.RowSource = Constant.AIProwSource;

                                if (this.isNotEmpty(bullet.Properties) && this.isNotEmpty(bullet.Properties.instructionContent)) {
                                    
                                    interventionDto.Instruction = new CarePlanInterventionInstructionDto();
                                    interventionDto.Instruction.InstructionTitle = bullet.Properties.instructionTitle;
                                    interventionDto.Instruction.InstructionText = bullet.Properties.instructionContent;
                                    interventionDto.Instruction.RowSource = Constant.AIProwSource;
                                }

                                if (this.isNotEmpty(bullet.Properties) && this.isNotEmpty(bullet.Properties.educationTitle)) {
                                    
                                    interventionDto.EducationDocument = new CarePlanInterventionDocumentDto();
                                    interventionDto.EducationDocument.DocumentId = bullet.Properties.educationTitle;
                                    interventionDto.EducationDocument.DocumentVersion = contentVersion;
                                    interventionDto.EducationDocument.RowSource = Constant.AIProwSource;
                                }

                                currentBarrierDto.InterventionsList.push(interventionDto);
                            }
                        }
                    });
                }
            }
        });

        return resultCarePlanGoalList;
    }

    private getConclusionItemOrder(properties: Properties): number {
        
        let order: number = 0;

        if (this.isNotEmpty(properties) && this.isNotEmpty(properties.CategoryProperties)) {
     
            properties.CategoryProperties.forEach((categoryProperty: CategoryProperty) => {

                if (categoryProperty.Name === 'Workflow Sequence') {
                    
                    order = Number(categoryProperty.Categories[0].Name);
                }
            });
        }
        
        return order;
    }

    private getAssessmentResponses(completionData: any): AssessmentResponseDto[] {

        let resultResponseList: AssessmentResponseDto[] = [];

        completionData.Questions.forEach((question: AnsweredQuestionDto) => {
                
            let response: AssessmentResponseDto = new AssessmentResponseDto();
            response.NodeId = question.NodeID;
            response.QuestionId = question.QuestionID;
            response.QuestionText = question.Question;
            response.AnswerId = question.AnsID;
            response.AnswerText = question.Answer;
            response.RowSource = Constant.AIProwSource;

            resultResponseList.push(response);
        });

        return resultResponseList;
    }

    private getOutcomeConclusions(completionData: any): AssessmentOutcomeDto[] {

        let resultOutcomeList: AssessmentOutcomeDto[] = [];

        completionData.DefaultConclusions.forEach((conclusion: Conclusion) => {

            let outcome: AssessmentOutcomeDto = new AssessmentOutcomeDto();
            outcome.Category = conclusion.Category;
            outcome.SubCategory1 = conclusion.SubCat1;
            outcome.SubCategory2 = conclusion.SubCat2;
            outcome.DisplayText = conclusion.DisplayText;
            outcome.ExpertText = conclusion.ExpertText;
            outcome.Title = conclusion.Title;
            outcome.Explanation = conclusion.Explanation;
            outcome.Truncated = conclusion.Truncated;
            outcome.RowSource = Constant.AIProwSource;

            if (this.isNotEmpty(conclusion.Properties)) {

                // console.log('properties as string', JSON.stringify(conclusion.Properties));

                let obj: any = conclusion.Properties;

                if (obj.CategoryProperties === undefined) {
                    
                    let keys: string[] = Object.keys(conclusion.Properties);

                    keys.forEach(key => {
                    
                        let property: AssessmentOutcomePropertyDto = new AssessmentOutcomePropertyDto();
                        property.PropertyName = key;
                        property.PropertyValue = (conclusion.Properties as any)[key];
        
                        outcome.PropertyList.push(property);
                    });
                }
            }

            resultOutcomeList.push(outcome);
        });

        return resultOutcomeList;
    }
    
    public ngOnDestroy() {

        if (this.isNotEmpty(this.getAssessmentDefinitionSubscription)) {
            this.getAssessmentDefinitionSubscription.unsubscribe();
        }

        if (this.isNotEmpty(this.resolveAssessmentFactsSubscription)) {
            this.resolveAssessmentFactsSubscription.unsubscribe();
        }

        if (this.isNotEmpty(this.getContentEnvConfigsSubscription)) {
            this.getContentEnvConfigsSubscription.unsubscribe();
        }
        
        if (this.isNotEmpty(this.getAssessmentDataSubscription)) {
            this.getAssessmentDataSubscription.unsubscribe();
        }

        if (this.isNotEmpty(this.saveAssessmentInitSubscription)) {
            this.saveAssessmentInitSubscription.unsubscribe();
        }

        if (this.isNotEmpty(this.saveAssessmentCompleteSubscription)) {
            this.saveAssessmentCompleteSubscription.unsubscribe();
        }        

        if (this.isNotEmpty(this.createNewMemberHealthAssessmentSubscription)) {
            this.createNewMemberHealthAssessmentSubscription.unsubscribe();
        }
    }
}
