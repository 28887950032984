
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth/auth.service';

import { AssessmentDefinitionDto, AssessmentFactResolvedListDto, ContentEnvConfigsDto, AssessmentCompleteDto, 
    AssessmentDto, AssessmentInitDto, CreateMemberHealthAssessmentDto, CreateMemberHealthAssessmentResponse } from './assessment-wrapper.dtos';

@Injectable()
export class AssessmentWrapperService {

    private getAssessmentDefinitionUrl: string = '/AssessmentDefinition/{id}/{includeDeleted}';
    private getAssessmentPrePopValuesUrl: string = '/FactResolution/ResolveAssessmentFacts/{assessmentId}/{memberId}';
    private getContentEnvConfigsUrl: string = '/ContentConfig/ContentEnvConfigs';    

    private getMemberHealthAssessmentInfoUrl: string = '/MemberHealthAssessment/GetMemberHealthAssessmentById?id={id}';    
    private createMemberHealthAssessmentUrl: string = '/MemberHealthAssessment/CreateMemberHealthAssessment';
    private saveAssessmentInitUrl: string = '/MemberHealthAssessment/PersistAssessmentInit';    
    private saveAssessmentCompleteUrl: string = '/MemberHealthAssessment/PersistAssessmentComplete';    

    private headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {

        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');

        environment.contentServiceApiBaseUrl = _appConfig.contentServiceApiBaseUrl;
    }

    public getAssessmentPrePopValues(assessmentId: string, memberId: string): Observable<AssessmentFactResolvedListDto> {

        let url = (this._appConfig.contentServiceApiBaseUrl + this.getAssessmentPrePopValuesUrl)
            .replace('{assessmentId}', assessmentId)
            .replace('{memberId}', memberId);

        return this._http.get<AssessmentFactResolvedListDto>(url, {headers: this.headers});
    }

    public getAssessmentDefinition(assessmentId: string, includeDeleted: boolean): Observable<AssessmentDefinitionDto> {

        let url = (this._appConfig.contentServiceApiBaseUrl + this.getAssessmentDefinitionUrl)
            .replace('{id}', assessmentId)
            .replace('{includeDeleted}', String(includeDeleted));

        return this._http.get<AssessmentDefinitionDto>(url, {headers: this.headers});
    }

    public getContentEnvConfigs(): Observable<ContentEnvConfigsDto> {

        let url = (this._appConfig.contentServiceApiBaseUrl + this.getContentEnvConfigsUrl);

        return this._http.get<ContentEnvConfigsDto>(url, {headers: this.headers});
    }

    public getAssessmentInfo(memberHealthAssessmentId: string): Observable<AssessmentDto> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getMemberHealthAssessmentInfoUrl).replace('{id}', memberHealthAssessmentId);

        return this._http.get<AssessmentDto>(url, {headers: this.headers});
    }

    public createNewMemberHealthAssessment(createDto: CreateMemberHealthAssessmentDto): Observable<CreateMemberHealthAssessmentResponse> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + this.createMemberHealthAssessmentUrl);

        return this._http.post<CreateMemberHealthAssessmentResponse>(url, createDto, {headers: this.headers});
    }

    public saveAssessmentInit(initDto: AssessmentInitDto): Observable<AssessmentDto> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + this.saveAssessmentInitUrl);

        return this._http.post<AssessmentDto>(url, initDto, {headers: this.headers});
    }

    public saveAssessmentComplete(completionDto: AssessmentCompleteDto): Observable<AssessmentDto> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + this.saveAssessmentCompleteUrl);

        return this._http.post<AssessmentDto>(url, completionDto, {headers: this.headers});
    }
}