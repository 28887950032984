import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CareGiverDetailsDto, RelationshipDto,PostCareGiverDto } from './caregiver-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { CaregiverSectionService } from './caregiver-section.service';
import { ConfirmationService } from 'primeng/api';
import { Constant } from 'src/app/auth/utils/constant';
import { Table } from 'primeng/table';

@Component({
    selector: 'caregiver-section',
    templateUrl: 'caregiver-section.component.html',
    styleUrls: ['caregiver-section.component.scss'],
    providers: [CaregiverSectionService,ConfirmationService]
})
export class CaregiverSectionComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() memberId: string = null;
    @Output() sectionLoadedEvent = new EventEmitter<string>();
    @ViewChild(NgForm) careGiverForm: NgForm;
    @ViewChild('dtCaregiver', { static: false })caregiverTable: Table;

    public saveClicked: boolean = false;
    public isShowSaveButton: boolean = false;
    public sectionIsLoaded: boolean = false;
    public display: boolean = false;
    public alertMsg: string;
    private getCaregiverSubscription: Subscription = null;
    private saveCaregiverSubscription: Subscription = null;
    private deleteCaregiverSubscription: Subscription = null;
    private updateCaregiverSubscription: Subscription = null;
    private getRelationTypesSubscription: Subscription = null;
    public careGiverDetails: CareGiverDetailsDto[] = [];
    public careGiverData: CareGiverDetailsDto;
    public isButtonDisabled: boolean = false;
    public position:string='center';
    public relationShipList: Array<RelationshipDto> = [];
    public selectedCaregiverPrimary: any = null;
    public previousSelectedPrimaryCaregiver: string;
    public selectedPrimaryPhoneInitially: string;
    public clonedCaregiver: { [s: string]: CareGiverDetailsDto; } = {};
    public isEmailFieldDisable: boolean = false;
    public rowSource='Agent Portal';


    constructor(private _sectionService: CaregiverSectionService,
                private confirmationService: ConfirmationService) {
        super();
    }

    ngOnInit(): void {
        this.getRelationShipMasterData();
    }

    getRelationShipMasterData(){
        if (this.isNotEmpty(this.getRelationTypesSubscription)) {
            this.getRelationTypesSubscription.unsubscribe();
       }

       this.getRelationTypesSubscription = this._sectionService.getRelationshipTypes().subscribe({
           next: (resultData) => {
               this.relationShipList = resultData;
           },
           error: (err: any) => { 
               console.error(err);
           }
       });
    }

    public isDirty(): boolean {

        return this.careGiverForm.dirty;
    }

    public isLoaded(): boolean {
    
        return this.sectionIsLoaded;
    }

    public save() {
        

        this.saveCaregiverDetails(this.careGiverForm);
  
    }

    public getCareGiverDetails() {

        if (this.isNotEmpty(this.getCaregiverSubscription)) {
            this.getCaregiverSubscription.unsubscribe();
        }
        this.getCaregiverSubscription = this._sectionService.getCaregiverDetails(this.memberId).subscribe({
            next: (resultData) => {
                this.careGiverDetails = resultData;
                this.selectedCaregiverPrimary = this.careGiverDetails.find(e => e.isPrimary);
                if(this.selectedCaregiverPrimary) {
                this.previousSelectedPrimaryCaregiver = this.selectedCaregiverPrimary.contactId;
                this.selectedPrimaryPhoneInitially = this.selectedCaregiverPrimary.contactId;
                } 
            },
            error: (err: any) => {
                // do we need to persist error information to the server
                console.error(err);
            }
        });
    }

    addNewCareGiver() {
        if(this.careGiverDetails.length > 0) {
            const lastIndex = this.careGiverDetails.length-1;
            if(this.careGiverDetails[lastIndex].add === true) {
                if(!this.careGiverDetails[lastIndex].firstName.trim() ||
                !this.careGiverDetails[lastIndex].lastName.trim() ||
                !this.careGiverDetails[lastIndex].relationshipId) {
                    return;
                }
            }
        } 

        

        let careGiverData : CareGiverDetailsDto= {
            firstName: '',
            lastName: '',
            fullName: '',
            relationshipId: '',
            type: '',
            consentTypes: [Constant.memberPortalConsentType, Constant.talkToCaregiverConsentType],
            isActive: true,
            rowSource: this.rowSource,
            isPrimary: false,
            authorization: false,
            contactId:'',
            contactExternalId: '',
            emailAddresses: [{
                isPrimary: true,
                isActive: true,
                emailAddressType: '',
                emailAddress: '',
                source: ''
            }],
            phoneNumbers: [{
                isPrimary: true,
                isActive: true,
                phoneType: '',
                number: '',
                source: ''
            }],
            notes:'' 
        };

        this.careGiverDetails.push(careGiverData);
        careGiverData.add = true;
        this.caregiverTable.initRowEdit(careGiverData);
        this.saveClicked = false;
        if(this.careGiverDetails.length === 1) {
            this.careGiverDetails[this.careGiverDetails.length-1].isPrimary = true;
            this.selectedCaregiverPrimary = this.careGiverDetails[this.careGiverDetails.length-1];
            this.previousSelectedPrimaryCaregiver = this.selectedCaregiverPrimary.contactId;
            this.selectedPrimaryPhoneInitially = this.selectedCaregiverPrimary.contactId; 
        }        
    }

    public saveCaregiverDetails(form: NgForm) {
        if(this.validateSectionEditMode()) {
            this.saveClicked = true;
            if (this.isNotEmpty(this.saveCaregiverSubscription)) {
                this.saveCaregiverSubscription.unsubscribe();
            }
            if (form.valid) {
                const reqData = this.cgCreateData();
                if(reqData.length > 0) {
                    //if(this.validatePrimaryCaregiver()) {
                        const reqObj: PostCareGiverDto = {
                            agentContactList: reqData
                        }
                        this.saveCaregiverSubscription = this._sectionService.postCaregiverDetails(reqObj, this.memberId).subscribe({
                            next: (resultData) => {
                                if (resultData) {
                                    this.cancelEditRow();
                                    this.getSectionData();
                                    this.onResetForm();
                                    this.saveClicked = false;
                                }
                            },
                            error: (err: any) => { 
                                // do we need to persist error information to the server

                                if(err.error.length > 0) {
                                    this.confirmationService.confirm({
                                   message: err.error[0],
                                    header: Constant.addConfirmText,
                                    icon: 'pi pi-info-circle',
                                    acceptLabel: 'OK',
                                    rejectVisible: false, 
    
                                    key: 'positionDialog'
                                    
                                });
                                
    
                                }
                            }
                        });
                    //}
                }
            }
        }
    }

    private cgCreateData(): Array<CareGiverDetailsDto> {
        let records: Array<CareGiverDetailsDto> = [];
        this.careGiverDetails.forEach((el: CareGiverDetailsDto)=> {

          if(el.isEdit || el.add) {
            const obj = {
            firstName: el.firstName,
            lastName: el.lastName,
            fullName: el.firstName + ' ' + el.lastName,
            relationshipId: el.relationshipId,
            type: Constant.memberType,
            consentTypes: [Constant.memberPortalConsentType, Constant.talkToCaregiverConsentType],
            isActive: true,
            rowSource: el.add ? Constant.AIProwSource : el.rowSource,
            isPrimary: el.isPrimary ? true : false,
            authorization: el.authorization ? true : false,
            contactId: el.contactId,
            contactExternalId:el.contactExternalId,
            emailAddresses: [{
                isPrimary: true,
                isActive: true,
                emailAddressType: Constant.careGiverEmailAddressType,
                emailAddress: el.firstEmailAddress ? el.firstEmailAddress : null,
                source: Constant.memberSource
            }],
            phoneNumbers: [{
                isPrimary: true,
                isActive: true,
                phoneType: Constant.careGiverPhoneType,
                number: this.unmask(el.firstPhoneNumber),
                source: Constant.memberSource    
            }] ,
            notes:el.notes
            }
            records.push(obj);
          }
        });

        if(records.length > 0) {
            if (this.selectedPrimaryPhoneInitially !== this.previousSelectedPrimaryCaregiver) {
                const updatedRow = records.find(e => e.contactId === this.selectedPrimaryPhoneInitially);
                if(updatedRow == null) {
                 const initialRow =  this.careGiverDetails.find(e => e.contactId === this.selectedPrimaryPhoneInitially);
                 if(initialRow) {
                  records.push(initialRow);
                 }
                }
            }
        }
        return records; 
    }

    editRow(careGiverDetails: CareGiverDetailsDto) {
        careGiverDetails.isEdit = true;
        careGiverDetails.add=false;
        this.isButtonDisabled = true;
        this.isEmailFieldDisable = careGiverDetails.firstEmailAddress ? true : false;
        this.careGiverData = {...careGiverDetails}
        this.clonedCaregiver[careGiverDetails.contactId] = {...careGiverDetails};
    }

    cancelEdit(index:number, careGiverDetails: CareGiverDetailsDto) {
        this.careGiverDetails[index] = this.clonedCaregiver[careGiverDetails.contactId];
        this.careGiverDetails[index].isEdit=false;
        this.isButtonDisabled=false;
        this.isEmailFieldDisable = false;
        if(this.selectedPrimaryPhoneInitially !== this.previousSelectedPrimaryCaregiver) {
           this.setIsPrimaryCaregiver(); 
        } else {
            if(this.careGiverDetails[index].isPrimary) {
                this.selectedCaregiverPrimary = this.careGiverDetails[index];
                this.previousSelectedPrimaryCaregiver = this.careGiverDetails[index].contactId;
            }
        }

        delete this.clonedCaregiver[careGiverDetails.contactId];
    }

    private setIsPrimaryCaregiver() {
        this.careGiverDetails.forEach((el: CareGiverDetailsDto) => {
            if(el.contactId === this.selectedPrimaryPhoneInitially) {
              el.isPrimary = true;
              this.selectedCaregiverPrimary = el;
              this.previousSelectedPrimaryCaregiver = el.contactId;
            } else {
              el.isPrimary = false;
            }
        });
    }

    removeRow(index: number){
        this.careGiverDetails.splice(index,1);
        this.isButtonDisabled=false;
        this.setIsPrimaryCaregiver(); 
        if(this.careGiverDetails.length > 0) {
            if(this.validateSectionEditMode()) {
                this.isShowSaveButton = true;
            }
        }
    }

    public deleteConfirm(position:string,careGiver:CareGiverDetailsDto) {
        // if(careGiver.contactId === this.selectedPrimaryPhoneInitially) {
        //     this.display = true;
        //     this.alertMsg = Constant.primaryCaregiverRecordDeleteMsg;
        //     return;
        // }
        this.position = position;
        this.confirmationService.confirm({
            message: Constant.deleteConfirmMsg,
            header: Constant.deleteConfirmText,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.deleteCaregiverDetails(careGiver);
                if(!this.validateSectionEditMode()) {
                    this.isShowSaveButton = true;
                }
            },
            key: 'positionDialog'
        });
    }


    private deleteCaregiverDetails(careGiver:CareGiverDetailsDto) {
        if (this.isNotEmpty(this.deleteCaregiverSubscription)) {
            this.deleteCaregiverSubscription.unsubscribe();
        }
            this.deleteCaregiverSubscription = this._sectionService.deleteCaregiverDetails(this.memberId,careGiver.contactId).subscribe({
                next: (resultData) => {
                    if (resultData) {
                        this.getCareGiverDetails();
                        this.onResetForm();
                    }
                },

                error: (err: any) => {
                    // do we need to persist error information to the server
                    console.error(err);
                }
            });
    }

    validateSectionEditMode(): boolean {
        const isCaregiverEditMode: boolean = this.careGiverDetails.some(element => element.isEdit || element.add);
        return isCaregiverEditMode; 
    }

    public getSectionData() {

        this.sectionIsLoaded = true;
        this.careGiverForm.statusChanges.subscribe(() => {
            this.isShowSaveButton = this.careGiverForm.dirty;
          });
        this.getCareGiverDetails();
    }

    checkConfirmPrimaryPhone(caregiver: CareGiverDetailsDto, index: number) {
        if(!caregiver.isPrimary) {
            this.confirmationService.confirm({
                message: Constant.primaryCaregiverUpdateConfirmMsg,
                header: Constant.updateConfirmText,
                icon: 'pi pi-info-circle',
                accept: () => {
                   this.updatePrimaryPhone(caregiver, index); 
                },
                reject: () => {
                    this.undoPrimaryPhoneChanges();
                    return;
                },
                key: 'positionDialog'
            });
            
        }        
    }

    confirmAuthorization(click:boolean) {
   
            // if(click){
            //     this.confirmationService.confirm({
            //         message: Constant.caregiverAuthorizationUpdateConfirmMsg,
            //         header: Constant.updateConfirmText,
            //         icon: 'pi pi-info-circle',
            //         accept: () => {
                  
            //         },
            //         reject: () => {

            //             return;
            //         },
            //         key: 'positionDialog'
            //     });
            // }
               
    }

    undoPrimaryPhoneChanges() {
        this.careGiverDetails.forEach((el: CareGiverDetailsDto) => {
            if(el.contactId === this.previousSelectedPrimaryCaregiver && el.isPrimary) {
              el.isPrimary =  true;
              this.selectedCaregiverPrimary = el;
              this.previousSelectedPrimaryCaregiver = el.contactId;
            } else {
                el.isPrimary = false; 
            }
        });
    }

    updatePrimaryPhone(careGiver: CareGiverDetailsDto, index: number) {
        this.careGiverDetails.forEach((el: CareGiverDetailsDto, recordindex: number) => {
            if(el.contactId === careGiver.contactId && !el.isPrimary && index === recordindex) {
              el.isPrimary =  true;
              this.selectedCaregiverPrimary = el;
              this.previousSelectedPrimaryCaregiver = el.contactId;
            } else {
                el.isPrimary = false; 
            }
        });
    }

    public getTableHeight(): string {
        return '650px';
    }

    onResetForm() {
        this.careGiverForm.resetForm();
        this.isButtonDisabled = false;
    }

    private cancelEditRow() : void {
        if(this.careGiverDetails.length > 0) {
            this.careGiverDetails.forEach((element: CareGiverDetailsDto) => {
                if(element.isEdit) {
                    this.caregiverTable.editingRowKeys = {[element.contactId]:false}
                    delete this.clonedCaregiver[element.contactId];
                }
            });
        }
    }

    private validatePrimaryCaregiver(): boolean {
        let isValid = true;
        const isPrimaryCaregiverSelected: boolean = this.careGiverDetails.some(element => element.isPrimary);
        if(!isPrimaryCaregiverSelected) {
            this.display = true;
            this.alertMsg = Constant.validateMsgForPrimaryCaregiver;
            isValid = false;
        }

        return isValid;
    }

    unmask(val:string) {
        return val ? val.replace(/\D+/g, '') : val;
    }

    getMaskPhoneNumber(rawNum: string) {
        const countryCodeStr = rawNum.slice(0,3);
        const areaCodeStr = rawNum.slice(3,6);
        const lastSectionStr = rawNum.slice(6,10);   
        return `(${countryCodeStr}) ${areaCodeStr}-${lastSectionStr}`;
    }

    ngOnDestroy(): void {

        if (this.getCaregiverSubscription !== null) {
            this.getCaregiverSubscription.unsubscribe();
        }
        if (this.saveCaregiverSubscription !== null) {
            this.saveCaregiverSubscription.unsubscribe();
        }
        if (this.deleteCaregiverSubscription !== null) {
            this.deleteCaregiverSubscription.unsubscribe();
        }
        if (this.updateCaregiverSubscription !== null) {
            this.updateCaregiverSubscription.unsubscribe();
        }
        if(this.getRelationTypesSubscription !== null) {
            this.getRelationTypesSubscription.unsubscribe();     
        }
    }

}
