
export class CarePlanGoalDto {
    public memberCarePlanGoalId: string;
    public memberId: string;
    public goalId: string;
    public goalDisplayText: string;
    public goalOrder: number;
    public goalGroupId: string;
    public goalGroupName: string;
    public targetDate: string;
    public targetDateClass?: string = null;
    public targetDateDueDays? : string = null;
    public goalActivityDate: string;
    public goalStatusId: string;
    public goalStatusDescription: string;
    public goalMemberPriorityId: string;
    public goalMemberPriorityDesc: string;
    public goalCareGiverPriorityId: string;
    public goalCareGiverPriorityDesc: string;
    public goalCareManagerPriorityId: string;
    public goalCareManagerPriorityDesc: string;
    public createdBy: string;
    public updatedBy: string;
    public update: boolean = false;
    public createdDate: string;
    public updatedDate: string;
}

export class GoalDto {
    public id: string;
    public name: string;    
    public rowSource: string;
    public barriers: BarrierDto[] = [];
}

export class BarrierDto {
    public id: string;
    public name: string;
    public rowSource: string;    
    public interventions: InterventionDto[] = [];
}

export class InterventionDto {
    public id: string;
    public name: string;
    public rowSource: string;    
}

export class InstructionDto {
    public id: string;
    public rowSource: string;    
}

export class EducationDto {
    public id: string;
    public rowSource: string;    
}

export class CareGoalTextDto {
    public memberId: string;
    public careGoalText: string;
}

export class SaveCarePlanDto {
    public memberId: string;
    public goal: CarePlanPersistGoalDto;
    public interventions: CarePlanPersistInterventionDto[] = [];
}

export class SaveCarePlanGoalsDto {
    public goals: SaveCarePlanDto[] = [];
}

export class CarePlanPersistGoalDto {
    public memberCarePlanGoalId: string;
    public targetDate: string;
    public statusId: string;
    public memberPriorityId: string;
    public careGiverPriorityId: string;
    public careManagerPriorityId: string;
    public rowSource: string;
}

export class CarePlanPersistInterventionDto {
    public memberCarePlanGoalBarrierInterventionId: string;
    public statusId: string;
    public note: string;
    public rowSource: string;
}

export class CarePlanGoalDetailsDto {
    public memberCarePlanGoalId: string;
    public memberId: string;
    public memberCarePlanGoalBarrierId: string;
    public memberCarePlanGoalBarrierInterventionId: string;
    public barrierId: string;
    public barrierDescription: string;
    public interventionId: string;
    public interventionDescription: string;
    public interventionOrder: number;
    public interventionActivityDate: string;
    public interventionStatusId: string;
    public interventionNote: string;
    public interventionCreatedBy: string;
    public interventionUpdatedBy: string;
    public instructions?: InterventionInstructionDto[] = [];
    public documents?: InterventionEducationDto[] = []
}

export class InterventionInstructionDto {
    public instructionId: string;
    public instructionText: string;
    public instructionTitle: string;
    public isActive: boolean;
}

export class InterventionEducationDto {
    public documentId: string;
    public documentName: string;
    public fileType: string;
    public isActive: boolean;
}