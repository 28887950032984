export class Constant {
    public static readonly portalApiBaseUrl: string = 'portalApiBaseUrl';
    public static readonly contentServiceApiBaseUrl: string = 'contentServiceApiBaseUrl';
    public static readonly memberManagerApiBaseUrl: string = 'memberManagerApiBaseUrl'; 
    public static readonly templateServiceApiBaseUrl: string = 'templateServiceApiBaseUrl';   

    public static readonly AIProwSource: string = 'Agent Portal';

    public static readonly phoneType = 'PhoneType';
    public static readonly addressType = 'AddressType';
    public static readonly memberSource = 'registration';
    public static readonly emailAddressType = 'Personal';
    public static readonly deleteConfirmMsg = 'Are you sure you would like to delete?';
    public static readonly deleteAlertMsgForPhone = 'Cannot delete. Atleast 1 phone is required.';
    public static readonly deleteAlertMsgForAddress = 'Cannot delete. Atleast 1 address is required.';
    public static readonly deleteConfirmText = 'Delete Confirmation';
    public static readonly updateConfirmText = 'Update Confirmation';
    public static readonly addConfirmText='Add Confirmation';
    public static readonly validateMsgForPrimaryPhone = 'At least one Primary Phone is required.';
    public static readonly validateMsgForPrimaryAddress = 'At least one Primary Address is required.';
    public static readonly validateMsgForPrimaryEmail = 'At least one Primary Email is required.';
    public static readonly validateMsgForPrimaryEmailForNonRegisteredUser = 'A primary email address is required. Please add one by clicking the Add Email button.';
    public static readonly validateMsgForDuplicateEmail = 'The Entered Email Address already exists . Please enter unique Email Address.';
    public static readonly validateMsgForDuplicatePhone = 'The Entered Phone Number already exists . Please enter unique Phone Number';
    public static readonly validateMsgForDuplicateAddress = 'The Entered Address already exists . Please enter unique Address';
    public static readonly validateMsgForTextConsent = 'Must have at least one text phone for texting consent.';
    public static readonly primaryPhoneUpdateConfirmMsg = 'Are you sure to change the Primary Phone? Please note that the same information will be updated on Portal for Member view.';
    public static readonly primaryAddressUpdateConfirmMsg = 'Are you sure to change the Primary Address? Please note that the same information will be updated on Portal for Member view.';
    public static readonly primaryPhoneRecordDeleteMsg = 'Primary Phone cannot be deleted. If you want to continue this delete, change the other phone as Primary and click on Save.';
    public static readonly primaryAddressRecordDeleteMsg = 'Primary Address cannot be deleted. If you want to continue this delete, change the other address as Primary and click on Save.';
    
    public static readonly primaryEmailRecordDeleteMsg = 'Primary Email cannot be deleted. If you want to continue this delete, change the other email as Primary and click on Save.';
    public static readonly primaryEmailUpdateConfirmMsg = 'Are you sure you want to change the Primary Email?';

    public static readonly deleteAssessmentText = 'Delete the selected assessment?';
    public static readonly deleteAssessmentTitle = 'Delete Assessment';
    
    public static readonly memberType = 'Caregiver';
    public static readonly memberPortalConsentType = 'MemberPortal';
    public static readonly talkToCaregiverConsentType = 'TalkToCaregiver';
    public static readonly careGiverPhoneType = 'Mobile';
    public static readonly careGiverEmailAddressType = 'Personal';
    
    public static readonly downloadDocumentUrl = "/MemberDocument/DownloadMemberDocument"; 
    public static readonly deleteAlertMsgForCaregiver = 'Cannot delete. Atleast 1 caregiver is required.';
    public static readonly validateMsgForPrimaryCaregiver = 'At least one Primary Caregiver is required.';
    public static readonly primaryCaregiverRecordDeleteMsg = 'Primary Caregiver cannot be deleted. If you want to continue this delete, change the other caregiver as Primary and click on Save.';
    public static readonly primaryCaregiverUpdateConfirmMsg = 'Are you sure to change the Primary Caregiver? Please note that the same information will be updated on Portal for Member view.';

    public static readonly validateMsgForNoCaregiverForSave = 'No records found for save.';

    public static readonly isAlerted: string = '&IsAlerted=yes';
    public static readonly isStatus: string='&Status=Open';

    public static readonly GenAIAdvanceSearchKey = 'AdvanceSearchButton';
    public static readonly AdvancedSearchGenAISiteUrl = 'AdvancedSearchGenAISiteUrl';    
}

