import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/auth/models/configuration';
import { environment } from 'src/environments/environment';
import { Constant } from '../../utils/constant';
import { AppConfig } from '../../models/app-config';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private url = 'Configuration';

  headers: HttpHeaders;
  constructor(private http: HttpClient,@Inject(Constant.portalApiBaseUrl) private appConfig: AppConfig) {
    this.headers = new HttpHeaders()
    .set('UserName', 'Test')
    .set('RoleId', '1');
    environment.apiUrl = appConfig.portalApiBaseUrl; 
  }
  
  public getConfiguration(): Observable<Configuration[]> {
    return this.http.get<Configuration[]>(`${environment.apiUrl}/${this.url}`);
  }


  
}
