
<div class="contaner" *ngIf="portalUser">
  <table>
    <thead>
      <th colspan="2"><h2>{{ portalUser.firstName }} {{ portalUser.lastName }}</h2></th>
    </thead>
    <tbody>
      <tr>
        <td>Email address:</td>
        <td><input [(ngModel)]="portalUser.emailAddress" placeholder="Email address" /></td>
      </tr>
      <tr>
        <td>First name:</td>
        <td><input [(ngModel)]="portalUser.firstName" placeholder="First name" /></td>
      </tr>
      <tr>
        <td>Last name:</td>
        <td><input [(ngModel)]="portalUser.lastName" placeholder="Last name" /></td>
      </tr>
      <tr>
        <td colspan="2">
          <button class="button" (click)="updatePortalUser(portalUser)" *ngIf="portalUser.id">Save</button>&nbsp;
          <button class="button" (click)="deletePortalUser(portalUser)" *ngIf="portalUser.id">Delete</button>
          <button class="button" (click)="createPortalUser(portalUser)" *ngIf="!portalUser.id">Create</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>