import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';

import { TableColumnDto } from '../common/common-dtos';
import { MemberPopulationDetails, SavePopulationDetails, MemberPopulationDetailsDto } from './population-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { PopulationSectionService } from './population-section.service';

@Component({
    selector: 'population-section',
    templateUrl: 'population-section.component.html',
    styleUrls: ['population-section.component.scss'],
    providers: [PopulationSectionService]
})
export class PopulationSectionComponent extends BaseComponent implements OnDestroy {

    @Input() memberId: string = null;

    @Output() sectionLoadedEvent = new EventEmitter<string>();

    public tableColumns: TableColumnDto[] = [];
    public tableSort: number = -1;
    public noRecordsMessage = "No populations found";
    public memberPopulationInfoModel : MemberPopulationDetailsDto;
    public populationlist : any[];
    public populationSaveData : MemberPopulationDetails;
    public sectionSaveData : SavePopulationDetails;
    public sectionIsLoaded: boolean = false;
    
    private getSubscription: Subscription = null;

    constructor(private _sectionService: PopulationSectionService) {
        
        super();

        this.memberPopulationInfoModel = new MemberPopulationDetailsDto();
        this.initializeColumns();
    }

    public save() {
        // nothing to save
    }
   
    public isDirty(): boolean {
        return false;
    }

    public isLoaded(): boolean {
        return this.sectionIsLoaded;
    }

    public getSectionData() {

        this.sectionIsLoaded = true;
        
        if (this.isNotEmpty(this.getSubscription)) {
            this.getSubscription.unsubscribe();
        }

        this.getSubscription = this._sectionService.getSectionData(this.memberId).subscribe({
            next: (resultData) => {
                
                // console.log("getMemberPopulationDetails result " + JSON.stringify(resultData));

                if (this.isNotEmpty(resultData)) {

                    resultData.forEach(populationDto => {
                        
                        populationDto.startDate = this.formatDates(populationDto.startDate);
                        populationDto.endDate = this.formatDates(populationDto.endDate);
                    });
                }

                this.memberPopulationInfoModel.MemberPopulationDetailsSection = resultData;

                this.sectionLoadedEvent.emit('PopulationsSection');
            },
            error: (err: any) => { 
                console.error(err);
                this.sectionLoadedEvent.emit('PopulationsSection');
            }
        });
    }

    private formatDates(dateToParse: string): string {
     
        if (this.isNotEmpty(dateToParse)) {
    
            let startDateArray:string[] = dateToParse.split('T');
            let dateArray:string[] = startDateArray[0].split('-');
            let reconstitutedDate = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0];

            return reconstitutedDate;
        }
        
        return null;
    }

    private initializeColumns() {

        this.tableColumns = [];

        let columnPopulation: TableColumnDto = new TableColumnDto();
        columnPopulation.field = 'populationAssigned';
        columnPopulation.header = 'Population Assigned';
        columnPopulation.width = '40%';
        columnPopulation.align = 'left';
        
        let columnStatus: TableColumnDto = new TableColumnDto();
        columnStatus.field = 'status';
        columnStatus.header = 'Assignment Status';
        columnStatus.width = '20%';
        columnStatus.align = 'center';

        let columnStartDate: TableColumnDto = new TableColumnDto();
        columnStartDate.field = 'startDate';
        columnStartDate.header = 'Assignment Start Date';
        columnStartDate.width = '20%';
        columnStartDate.align = 'center';

        let columnEndDate: TableColumnDto = new TableColumnDto();
        columnEndDate.field = 'endDate';
        columnEndDate.header = 'Assignment End Date';
        columnEndDate.width = '20%';
        columnEndDate.align = 'center';
        
        this.tableColumns.push(columnPopulation);
        this.tableColumns.push(columnStatus);
        this.tableColumns.push(columnStartDate);
        this.tableColumns.push(columnEndDate);
    }

    public getNumColumns(): number {
        return this.isEmpty(this.tableColumns) ? 0 : this.tableColumns.length;
    }

    public filterTable($event: any, populationTable: Table, columnField: any) {
        populationTable.filter($event.target.value, columnField, 'contains');
    }

    public getTableHeight(): string {
        return '650px';
    }

    public customSort($event: { field: string; data: any[]; order: number; }) {

        let isDate: boolean = false;

        if (this.isNotEmpty($event) && this.isNotEmpty($event.field) && $event.field.toLowerCase().indexOf('date') > -1) {
            
            isDate = true;
        }

        $event.data.sort((data1, data2) => {
    
            let value1 = null;
            let value2 = null;
    
            if ( isDate) {
    
                let val1 = data1[$event.field];
                let val2 = data2[$event.field];
                value1 = new Date(val1);
                value2 = new Date(val2);
    
            } else {
    
                value1 = data1[$event.field];
                value2 = data2[$event.field];
            }
    
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ($event.order * result);
        });
    }

    ngOnDestroy(): void {

        if (this.getSubscription !== null) {
            this.getSubscription.unsubscribe();
        }
    }
}
