
export enum HealthAssessmentStatusType {
    ASSIGNED = 'Assigned',
    COMPLETED = 'Completed',
    IN_PROCESS = 'In Process'
}

export class MemberAssessmentHistoryDto {
    id: string;
    memberID: string;
    memberHealthAssessmentId: string;
    memberHealthAssessmentStatus: number;
    memberHealthAssessmentStatusDescription: string;
    memberHealthAssessmentStartDate: string;
    memberHealthAssessmentCompletionDate?: string;
    healthAssessmentName: string;
    assignmentDate: string;
    deletedDate: string;
    rowSource: string;
    allowContinue: number;
    allowInUIMode: string;
}

export class BasicAssessmentDefinitionDto {
    public id: string;
    public assessmentName: string;
    public assessmentVersion: number;
    public isDeleted: boolean;
    public allowContinue: boolean;
    public allowInUIMode: string;
}

export class PowerUserDto {
    public powerUserEmail: string;
}