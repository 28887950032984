import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { PortalUser } from '../models/portaluser';
import { Constant } from '../utils/constant';
import { AppConfig } from '../models/app-config';

@Injectable({
  providedIn: 'root',
})
export class PortalUserService {
  private url = 'PortalUser';

  headers: HttpHeaders;
  constructor(private http: HttpClient,@Inject(Constant.portalApiBaseUrl) private appConfig: AppConfig) {
    this.headers = new HttpHeaders()
    .set('UserName', 'Test')
    .set('RoleId', '1');
    environment.apiUrl = appConfig.portalApiBaseUrl; 
  }

  public getPortalUsers(): Observable<PortalUser[]> {
    return this.http.get<PortalUser[]>(`${environment.apiUrl}/${this.url}`);
  }
  
  public getPortalUserById(id:any): Observable<PortalUser> {
    return this.http.get<PortalUser>(`${environment.apiUrl}/${this.url}/${id}`);
  }

  public updatePortalUser(portalUser: PortalUser): Observable<PortalUser[]> {
    return this.http.put<PortalUser[]>(
      `${environment.apiUrl}/${this.url}`,
      portalUser
    );
  }

  public createPortalUser(portalUser: PortalUser): Observable<PortalUser[]> {
    return this.http.post<PortalUser[]>(
      `${environment.apiUrl}/${this.url}`,
      portalUser
    );
  }

  public deletePortalUser(portaluser: PortalUser): Observable<PortalUser[]> {
    return this.http.delete<PortalUser[]>(
      `${environment.apiUrl}/${this.url}/${portaluser.id}`
    );
  }
}