import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators,NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { SignupService } from 'src/app/auth/services/signup/signup.service';
import { PortalUser } from 'src/app/auth/models/portaluser';
import { PortalUserService } from 'src/app/auth/services/portaluser.service';
export class User { 
 gender: string;
} 

@Component({
  selector: 'app-sign-up-acknowledgement',
  templateUrl: './sign-up-acknowledgement.component.html',
  styleUrls: ['./sign-up-acknowledgement.component.scss']
})
export class SignUpAcknowledgementComponent implements OnInit {
  
	user = new User();
  agrees: string;
  Signature:any;
  isDisable:boolean=false;
  portalUser:PortalUser;
  isError:boolean=false;
  error:any;
  constructor(private authService:AuthService,private router: Router,
    private signupService:SignupService,private PortalUserService:PortalUserService) { 
    
  }
  ngOnInit(): void {
    this.isError=false;
    this.portalUser=this.signupService.getPortalUser();
    // if(this.portalUser.code==""){
    //   this.router.navigate(['sign-up-domain-specific']);
    // }
  }
	onSubmit(form: NgForm) {
    debugger;
	 	   //this.agrees = form.controls['agreement'].value;

        this.authService.UserRegistration(this.portalUser).subscribe({
          next: (data: PortalUser) => {
            debugger;
            if(data){ 
              //#region Login Aut User
              this.authService.ValidateUser(this.portalUser).subscribe({
                next: (v) => {
                  debugger;
                  if (v != null) {
                    var  token = JSON.parse(atob(v.split('.')[1]))
                    var result = Object.keys(token).map((key) => [token[key]]);
                    localStorage.setItem('authTokenDetail', result.toString())
                    this.authService.setToken(v);
                    data.userMfas.type=this.portalUser.userMfas.type;
                    data.userMfas.value=this.portalUser.phoneNumber;
                    data.userMfas.userId=this.portalUser.id;
                    this.signupService.setPortalUser(data);
                    this.PortalUserService.updatePortalUser(data).subscribe({
                      next: (users: PortalUser[]) => {
                        if(users){ 
                          this.authService.setLoggedUser(data);
                          this.router.navigate(['home']);
                        } 
                        else{
                          this.isError=true;
                          this.error=v; 
                        }
                      },
                      error: (e) => this.isError=true
                     })

                  }
                  else { this.isError=true;
                    this.error=v;
                  } //this.toastr.error(v) }
                },
                error: (e) => {
                  this.isError=true;
                  this.error=e;
                }
              })
              //#endregion
            } 
            else{this.isError=true }
          },
          error: (e) => this.isError=true
      })
	}
	changeAgrrement(e:any) {
    if(e.target.value=="Agree"){
      this.isDisable=false;
     this.Signature=new Date().toLocaleDateString().padStart(10, '0')
    }
    else{
      this.Signature="";
      this.isDisable=true;
    }
  }
	
}
