import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ContactSectionComponent } from './contact-section.component';
import { ButtonModule } from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {InputNumberModule} from 'primeng/inputnumber';
import {RadioButtonModule} from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {TooltipModule} from 'primeng/tooltip';
import {InputMaskModule} from 'primeng/inputmask';
import {CalendarModule} from 'primeng/calendar';
import { InputTextModule } from "primeng/inputtext";
import { CheckboxModule }  from 'primeng/checkbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule.forRoot(),
    ConfirmDialogModule,
    ButtonModule,
    DialogModule,
    TableModule,
    DropdownModule,
    InputNumberModule,
    RadioButtonModule,
    InputTextareaModule,
    TooltipModule,
    InputMaskModule,
    CalendarModule,
    InputTextModule,
    CheckboxModule
  ],
  declarations: [ContactSectionComponent],
  providers: [ContactSectionComponent],
  exports: [ContactSectionComponent]  
})
export class ContactSectionModule {}
