import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';

import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { MemberAssessmentHistoryDto, HealthAssessmentStatusType, PowerUserDto } from './assessment-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { TableColumnDto } from 'src/app/member/common/common-dtos';
import { AssessmentSectionService } from './assessment-section.service';

import { AssessmentWrapperComponent } from './assessment-wrapper/assessment-wrapper.component';
import { AssessmentWrapperService } from './assessment-wrapper/assessment-wrapper.service';
import { CreateMemberHealthAssessmentDto } from './assessment-wrapper/assessment-wrapper.dtos';
import { AssessmentPrintComponent } from './assessment-print/assessment-print.component';
import { Constant } from 'src/app/auth/utils/constant';
import { CustomSelectitem } from 'src/app/auth/models/CustomSelectItem';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'assessment-section',
    templateUrl: 'assessment-section.component.html',
    styleUrls: ['assessment-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [AssessmentSectionService, AssessmentWrapperService, ConfirmationService]
})
export class AssessmentSectionComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input() memberId: string = null;
    @Input() memberAtomId: string = null;
    @Input() memberAssessmentId: string = null;
    @Input() memberIsDeceased: boolean = false;

    @Output() sectionLoadedEvent = new EventEmitter<string>();
    @Output() assessmentCompletedEvent = new EventEmitter<string>();

    @ViewChild(AssessmentWrapperComponent, { static: false }) assessmentWrapperComponent: AssessmentWrapperComponent;
    @ViewChild(AssessmentPrintComponent, { static: false }) assessmentPrintComponent: AssessmentPrintComponent;
    @ViewChild('assignSelector') assignSelector: ElementRef;

    public memberHealthAssessmentId: string = null;
    public assessmentDefinitions: CustomSelectitem[];
    public selectedAssessmentId: string = null;
    public selectedAssessmentName: string = null;
    public alreadyExistsLabel: string = null;
    public showAssessmentPanel: boolean = false;
    public showAssessmentResponsesPanel: boolean = false;
    public sectionIsLoaded: boolean = false;
    public memberAssessmentHistoryList: MemberAssessmentHistoryDto[] = [];
    public memberAssessmentHistoryTableColumns: TableColumnDto[] = [];
    public assesmentHistorySort: number = 1;
    public noRecordsMessage = "No records found";
    public minAssignDate: Date = new Date();
    public assignedCount: number = 0;
    private powerUsers: PowerUserDto[];
    public isPowerUser: boolean = false;
    public powerUserEnabled: boolean = false;
    public btnCloseLabelAssesmentComplete: string = 'X';

    private saveSubscription: Subscription = null;
    private getSubscription: Subscription = null;
    private getAssessmentsSubscription: Subscription = null;
    private getPowerUsersSubscription: Subscription = null;
    private deleteAssessmentSubscription: Subscription = null;
    private createNewMemberHealthAssessmentSubscription: Subscription = null;
    private getMemberAssessmentNameSubscription: Subscription = null;

    constructor(private _sectionService: AssessmentSectionService,
        private _assessmentWrapperService: AssessmentWrapperService,
        private _authService: AuthService,
        private _confirmationService: ConfirmationService,
        private _changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {

        this.initializeColumns();
    }

    public isDirty(): boolean {

        return false;
    }

    public isLoaded(): boolean {

        return this.sectionIsLoaded;
    }

    public save() {
    }

    public assessmentWrapperCompletedEvent($event: string) {
        this.btnCloseLabelAssesmentComplete = ($event.toLowerCase() == 'success') ? 'Finish' : 'X';
        this.assessmentCompletedEvent.emit($event);
    }

    public setAssessment($event: any) {

        let matches: MemberAssessmentHistoryDto[] = this.memberAssessmentHistoryList.filter(a => a.memberHealthAssessmentId === this.selectedAssessmentId && this.isEmpty(a.memberHealthAssessmentCompletionDate)  && this.isEmpty(a.deletedDate));

        if (this.isNotEmpty(matches) && matches.length > 0) {

            this.alreadyExistsLabel = 'The selected assessment already exists in the assessment list.';

        } else {

            this.alreadyExistsLabel = null;

            if (this.isNotEmpty($event) && this.isNotEmpty($event.value)) {

                let assessment: CustomSelectitem = this.assessmentDefinitions.find(x => x.value === $event.value);
                this.selectedAssessmentName = assessment.label;
            }
        }
    }

    private getAssessmentName(memberAssessmentId: string): string {

        let memberAssessmentHistoryDto: MemberAssessmentHistoryDto = this.memberAssessmentHistoryList.find(x => x.id.toLowerCase() === memberAssessmentId.toLowerCase());
        return  memberAssessmentHistoryDto.healthAssessmentName;
    }

    private refreshHistoryList() {
        this.loadSectionData(this.memberIsDeceased);
    }

    public getSectionData(memberIsDeceased: boolean) {
        
        this.memberIsDeceased = memberIsDeceased;

        this.getPowerUsers();
    }

    private loadSectionData(memberIsDeceased: boolean) {

        this.sectionIsLoaded = true;
        
        this.getAssessmentDefinitions();

        if (this.isNotEmpty(this.getSubscription)) {
            this.getSubscription.unsubscribe();
        }

        this.getSubscription = this._sectionService.getAssessmentHistory(this.memberId).subscribe({
            next: (resultData) => {

                this.memberAssessmentHistoryList = [];

                if (this.isNotEmpty(resultData)) {

                    let countArray: MemberAssessmentHistoryDto[] = resultData.filter(a => (this.isEmpty(a.deletedDate) && (this.isEmpty(a.memberHealthAssessmentStartDate)
                        || (this.isNotEmpty(a.memberHealthAssessmentStartDate) && this.isEmpty(a.memberHealthAssessmentCompletionDate) && this.isNotEmpty(a.allowContinue) && a.allowContinue === 1))));

                    this.assignedCount = countArray.length;

                    let notCompletedArray: MemberAssessmentHistoryDto[] = resultData.filter(a => this.isEmpty(a.memberHealthAssessmentCompletionDate));
                    let completedArray: MemberAssessmentHistoryDto[] = resultData.filter(a => this.isNotEmpty(a.memberHealthAssessmentCompletionDate));

                    let sortedCompletedArray: MemberAssessmentHistoryDto[] = completedArray.sort((a: MemberAssessmentHistoryDto, b: MemberAssessmentHistoryDto) => {

                        let aStartDate: number = Date.parse(a.memberHealthAssessmentStartDate);
                        let bStartDate: number = Date.parse(b.memberHealthAssessmentStartDate);

                        if (this.isEmpty(a.memberHealthAssessmentStartDate)) {
                            return -1;
                        } else {
                            return (bStartDate - aStartDate);
                        }
                    });

                    let sortedNotCompletedArray: MemberAssessmentHistoryDto[] = notCompletedArray.sort((a: MemberAssessmentHistoryDto, b: MemberAssessmentHistoryDto) => {

                        let aAssignDate: number = Date.parse(a.assignmentDate);
                        let bAssignDate: number = Date.parse(b.assignmentDate);

                        return (aAssignDate - bAssignDate);
                    });

                    let fullSortedArray: MemberAssessmentHistoryDto[] = sortedNotCompletedArray.concat(sortedCompletedArray);

                    for (let rawMemberAssessmentHistory of fullSortedArray) {

                        if (rawMemberAssessmentHistory.memberHealthAssessmentStatus === 1) {

                            rawMemberAssessmentHistory.memberHealthAssessmentStatusDescription = HealthAssessmentStatusType.ASSIGNED;

                        } else if (rawMemberAssessmentHistory.memberHealthAssessmentStatus === 3) {

                            rawMemberAssessmentHistory.memberHealthAssessmentStatusDescription = HealthAssessmentStatusType.IN_PROCESS;

                        } else {

                            rawMemberAssessmentHistory.memberHealthAssessmentStatusDescription = HealthAssessmentStatusType.COMPLETED;
                        }

                        if (this.isNotEmpty(rawMemberAssessmentHistory.memberHealthAssessmentStartDate)) {

                            rawMemberAssessmentHistory.memberHealthAssessmentStartDate = this.formatMemberAssessmentDates(rawMemberAssessmentHistory.memberHealthAssessmentStartDate);
                        }

                        if (this.isNotEmpty(rawMemberAssessmentHistory.memberHealthAssessmentCompletionDate)) {

                            rawMemberAssessmentHistory.memberHealthAssessmentCompletionDate = this.formatMemberAssessmentDates(rawMemberAssessmentHistory.memberHealthAssessmentCompletionDate);
                        }

                        if (this.isNotEmpty(rawMemberAssessmentHistory.assignmentDate)) {

                            rawMemberAssessmentHistory.assignmentDate = this.formatMemberAssessmentDates(rawMemberAssessmentHistory.assignmentDate);
                        }

                        if (this.isNotEmpty(rawMemberAssessmentHistory.deletedDate)) {

                            rawMemberAssessmentHistory.deletedDate = this.formatMemberAssessmentDates(rawMemberAssessmentHistory.deletedDate);
                        }

                        this.memberAssessmentHistoryList.push(rawMemberAssessmentHistory);
                    }
                }

                this.sectionLoadedEvent.emit('AssessmentsSection');

                this.startAssessmentFromLink();
            },
            error: (err: any) => {
                console.error(err);
                this.sectionLoadedEvent.emit('AssessmentsSection');
            }
        });
    }

    private formatMemberAssessmentDates(dateToParse: string): string {

        if (this.isNotEmpty(dateToParse)) {

            let startDateArray:string[] = dateToParse.split('T');
            let dateArray:string[] = startDateArray[0].split('-');
            let reconstitutedDate = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0];

            return reconstitutedDate;
        }

        return null;
    }

    public loadPrintAssessment(memberHealthAssessmentId: string, assessmentName: string) {

        if (this.isNotEmpty(this.memberId) && this.isNotEmpty(memberHealthAssessmentId)) {

            this.showAssessmentPanel = false;
            this.showAssessmentResponsesPanel = true;

            // allow the page to be aware of the change before loading assessment
            this._changeDetectorRef.detectChanges();

            this.assessmentPrintComponent.loadAssessmentPrint(memberHealthAssessmentId, assessmentName);
        }
    }

    public redirectToPrintView(memberHealthAssessmentId: string) {

        if (this.isNotEmpty(this.getMemberAssessmentNameSubscription)) {
            this.getMemberAssessmentNameSubscription.unsubscribe();
        }

        this.getMemberAssessmentNameSubscription = this._sectionService.getMemberHealthAssessmentData(memberHealthAssessmentId).subscribe({
            next: (resultData) => {

                if (this.isNotEmpty(resultData.assessmentDefinitionId)) {

                    let assessmentName: string = 'Assessment';

                    if (this.isNotEmpty(this.assessmentDefinitions)) {

                        let assessment: CustomSelectitem = this.assessmentDefinitions.find(x => x.value === resultData.assessmentDefinitionId);

                        if (this.isNotEmpty(assessment)) {
                            assessmentName = assessment.label;
                        }
                    }

                    this.showAssessmentPanel = false;
                    this.showAssessmentResponsesPanel = true;
                    this._changeDetectorRef.detectChanges();

                    this.assessmentPrintComponent.loadAssessmentPrint(memberHealthAssessmentId, assessmentName);

                    this.sectionLoadedEvent.emit('AssessmentsSectionDelayed');
                }
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    public loadManualAssessment() {

        if (this.isNotEmpty(this.memberId) && this.isNotEmpty(this.selectedAssessmentId)) {

            this.showAssessmentPanel = true;
            this.showAssessmentResponsesPanel = false;

            // allow the page to be aware of the change before loading assessment
            this._changeDetectorRef.detectChanges();

            this.assessmentWrapperComponent.loadManualAssessment(this.memberId, this.selectedAssessmentId, this.memberAtomId);

            this.selectedAssessmentId = null;
        }
    }

    public getMemberHealthAssessmentData(memberHealthAssessmentId: string, assessmentName: string, isdirectLink: boolean) {

        if (!this.memberIsDeceased) {

            if (isdirectLink) {
                if (this.isNotEmpty(this.getMemberAssessmentNameSubscription)) {
                    this.getMemberAssessmentNameSubscription.unsubscribe();
                }

                this.getMemberAssessmentNameSubscription = this._sectionService.getMemberHealthAssessmentData(memberHealthAssessmentId).subscribe({
                    next: (resultData) => {

                        if (this.isNotEmpty(resultData.assessmentDefinitionId)) {
                            if (this.isNotEmpty(this.assessmentDefinitions)) {

                                let assessment: CustomSelectitem = this.assessmentDefinitions.find(x => x.value === resultData.assessmentDefinitionId);
                                if (assessment != undefined && assessment.allowContinue) {
                                    this.loadMemberHealthAssessment(assessmentName, memberHealthAssessmentId);
                                }
                            }
                        }
                    },
                    error: (err: any) => {
                        console.error(err);
                    }
                });
            }
            else {
                this.loadMemberHealthAssessment(assessmentName, memberHealthAssessmentId);
            }
        }
    }
    
    public loadMemberHealthAssessment(assessmentName: string, memberHealthAssessmentId: string) {
        this.showAssessmentPanel = true;
        this.showAssessmentResponsesPanel = false;
        this.selectedAssessmentName = assessmentName;

        // allow the page to be aware of the change before loading assessment
        this._changeDetectorRef.detectChanges();

        this.assessmentWrapperComponent.loadMemberHealthAssessment(memberHealthAssessmentId, this.memberAtomId);
    }

    public assignAssessment($event: any) {

        if (this.isNotEmpty(this.memberId) && this.isNotEmpty(this.selectedAssessmentId)) {

            const isoString = $event.toISOString();

            let createDto: CreateMemberHealthAssessmentDto = new CreateMemberHealthAssessmentDto();
            createDto.memberId = this.memberId;
            createDto.assessmentDefinitionId = this.selectedAssessmentId;
            createDto.e24TraversalId = null;
            createDto.assignmentDate = isoString;
            createDto.status = HealthAssessmentStatusType.ASSIGNED;
            createDto.rowSource = Constant.AIProwSource;

            if (this.isNotEmpty(this.createNewMemberHealthAssessmentSubscription)) {
                this.createNewMemberHealthAssessmentSubscription.unsubscribe();
            }

            this.createNewMemberHealthAssessmentSubscription = this._assessmentWrapperService.createNewMemberHealthAssessment(createDto).subscribe({
                next: (newRecordResult) => {

                    if (this.isNotEmpty(this.assignSelector) && this.isNotEmpty(this.assignSelector.nativeElement)) {

                        this.assignSelector.nativeElement.hide();
                    }

                    this.refreshHistoryList();

                    this.selectedAssessmentId = null;

                    this._changeDetectorRef.detectChanges();
                },
                error: (err: any) => {
                    console.error(err);
                }
            });
        }
    }

    public closeAssessmentPanel() {

        this.showAssessmentPanel = false;
        this.selectedAssessmentId = null;
        this.memberAssessmentId = null;

        if (!this.showAssessmentResponsesPanel) {
            this.refreshHistoryList();
        }

        this.showAssessmentResponsesPanel = false;
    }

    private getAssessmentDefinitions() {

        if (this.isNotEmpty(this.getAssessmentsSubscription)) {
            this.getAssessmentsSubscription.unsubscribe();
        }

        this.getAssessmentsSubscription = this._sectionService.getAssessmentDefinitions().subscribe({
            next: (assessmentDefinitions) => {

                assessmentDefinitions.sort(function(a, b) {
                    if (a.assessmentName < b.assessmentName) {
                        return -1;
                    } else if (a.assessmentName > b.assessmentName) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                this.assessmentDefinitions = [];

                this.assessmentDefinitions.push({ label: 'Select...', value: null });

                assessmentDefinitions.forEach(assessment => {

                    let added: boolean = false;

                    if (this.isNotEmpty(assessment.allowInUIMode) && assessment.allowInUIMode !== "Member" && assessment.allowInUIMode !== "None" && !assessment.isDeleted) {

                        this.assessmentDefinitions.push({ label: assessment.assessmentName, value: assessment.id, disabled: assessment.isDeleted, allowContinue: assessment.allowContinue });

                        added = true;
                    }

                    if (!added && this.isPowerUser && this.powerUserEnabled && assessment.id === 'fff5479a-a918-40fe-8dd3-e75b0ff73575') {

                        // is ICMA assessment.  add it.
                        this.assessmentDefinitions.push({ label: assessment.assessmentName, value: assessment.id, disabled: assessment.isDeleted, allowContinue: assessment.allowContinue });
                    }
                });
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    private startAssessmentFromLink() {

        if (this.isNotEmpty(this.memberAssessmentId)) {
        
            this.getMemberHealthAssessmentData(this.memberAssessmentId, this.getAssessmentName(this.memberAssessmentId), true);
        }
    }

    public deleteAssessment(rowData: MemberAssessmentHistoryDto) {
        
        console.debug('id to delete: ' + rowData.id);

        this._confirmationService.confirm({
            message: Constant.deleteAssessmentText,
            header: Constant.deleteAssessmentTitle,
            icon: 'pi pi-info-circle',
            accept: () => {
                this.deleteMemberHealthAssessment(rowData.id);                
            },
            reject: () => {
                return;
            }
        });
    }

    public enablePowerUser($event: any) {

        if (this.isNotEmpty($event)) {
            this.powerUserEnabled = $event.checked;
        } else {
            this.powerUserEnabled = false;
        }

        this.getAssessmentDefinitions();
    }

    private getPowerUsers() {

        if (this.isNotEmpty(this.getPowerUsersSubscription)) {
            this.getPowerUsersSubscription.unsubscribe();
        }

        this.getPowerUsersSubscription = this._sectionService.getPowerUsers().subscribe({
            next: (result) => {

                let usersEmail: string = null;

                if (this._authService !== null && this._authService !== undefined 
                    && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
                    && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {
        
                    usersEmail = this._authService.getLoggedUser().emailAddress.toLowerCase();
                }

                if (this.isNotEmpty(result) && this.isNotEmpty(usersEmail)) {
             
                    let users: PowerUserDto[] = result;
                    this.powerUsers = users;

                    let match: PowerUserDto = this.powerUsers.find(x => x.powerUserEmail.toLowerCase() === usersEmail);

                    if (this.isNotEmpty(match)) {
                        this.isPowerUser = true;
                    }
                }

                this.loadSectionData(this.memberIsDeceased);
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    private deleteMemberHealthAssessment(memberHealthAssessmentId: string) {

        if (this.isNotEmpty(this.deleteAssessmentSubscription)) {
            this.deleteAssessmentSubscription.unsubscribe();
        }

        this.deleteAssessmentSubscription = this._sectionService.deleteMemberHealthAssessment(memberHealthAssessmentId).subscribe({
            next: (result) => {

                this.loadSectionData(this.memberIsDeceased);
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    private initializeColumns() {

        this.memberAssessmentHistoryTableColumns = [];

        let tableColumnHealthAssessmentName: TableColumnDto = new TableColumnDto();
        tableColumnHealthAssessmentName.field = 'healthAssessmentName';
        tableColumnHealthAssessmentName.header = 'Health Assessment Name';
        tableColumnHealthAssessmentName.width = '25%';
        tableColumnHealthAssessmentName.align = 'left';

        let tableColumnAssessmentStatus: TableColumnDto = new TableColumnDto();
        tableColumnAssessmentStatus.field = 'memberHealthAssessmentStatusDescription';
        tableColumnAssessmentStatus.header = 'Status';
        tableColumnAssessmentStatus.width = '10%';
        tableColumnAssessmentStatus.align = 'center';

        let tableColumnAssignedDate: TableColumnDto = new TableColumnDto();
        tableColumnAssignedDate.field = 'assignmentDate';
        tableColumnAssignedDate.header = 'Assignment Due Date';
        tableColumnAssignedDate.width = '12%';
        tableColumnAssignedDate.align = 'center';

        let tableColumnAssessmentStartDate: TableColumnDto = new TableColumnDto();
        tableColumnAssessmentStartDate.field = 'memberHealthAssessmentStartDate';
        tableColumnAssessmentStartDate.header = 'Start Date';
        tableColumnAssessmentStartDate.width = '12%';
        tableColumnAssessmentStartDate.align = 'center';

        let tableColumnAssessmentCompletionDate: TableColumnDto = new TableColumnDto();
        tableColumnAssessmentCompletionDate.field = 'memberHealthAssessmentCompletionDate';
        tableColumnAssessmentCompletionDate.header = 'Completion Date';
        tableColumnAssessmentCompletionDate.width = '12%';
        tableColumnAssessmentCompletionDate.align = 'center';

        let tableColumnSource: TableColumnDto = new TableColumnDto();
        tableColumnSource.field = 'rowSource';
        tableColumnSource.header = 'Source';
        tableColumnSource.width = '10%';
        tableColumnSource.align = 'center';

        let tableColumnAssessmentAction: TableColumnDto = new TableColumnDto();
        tableColumnAssessmentAction.field = 'action';
        tableColumnAssessmentAction.header = '';
        tableColumnAssessmentAction.width = '19%';
        tableColumnAssessmentAction.align = 'center';

        this.memberAssessmentHistoryTableColumns.push(tableColumnHealthAssessmentName);
        this.memberAssessmentHistoryTableColumns.push(tableColumnAssignedDate);
        this.memberAssessmentHistoryTableColumns.push(tableColumnAssessmentStartDate);
        this.memberAssessmentHistoryTableColumns.push(tableColumnAssessmentCompletionDate);
        this.memberAssessmentHistoryTableColumns.push(tableColumnAssessmentStatus);
        this.memberAssessmentHistoryTableColumns.push(tableColumnSource);
        this.memberAssessmentHistoryTableColumns.push(tableColumnAssessmentAction);
    }

    public getNumColumns(): number {
        return this.isEmpty(this.memberAssessmentHistoryTableColumns) ? 0 : this.memberAssessmentHistoryTableColumns.length;
    }

    public filterTable($event: any, memberAssessmentHistoryTable: Table, columnField: any) {
        memberAssessmentHistoryTable.filter($event.target.value, columnField, 'contains');
    }

    public getTableHeight(): string {
        return '650px';
    }

    public customSort($event: { field: string; data: any[]; order: number; }) {

        let isDate: boolean = false;

        if (this.isNotEmpty($event) && this.isNotEmpty($event.field) && $event.field.toLowerCase().indexOf('date') > -1) {

            isDate = true;
        }

        $event.data.sort((data1, data2) => {

            let value1 = null;
            let value2 = null;

            if ( isDate) {

                let val1 = data1[$event.field];
                let val2 = data2[$event.field];
                value1 = new Date(val1);
                value2 = new Date(val2);

            } else {

                value1 = data1[$event.field];
                value2 = data2[$event.field];
            }

            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ($event.order * result);
        });
    }

    ngOnDestroy(): void {

        if (this.saveSubscription !== null) {
            this.saveSubscription.unsubscribe();
        }

        if (this.getSubscription !== null) {
            this.getSubscription.unsubscribe();
        }

        if (this.isNotEmpty(this.getAssessmentsSubscription)) {
            this.getAssessmentsSubscription.unsubscribe();
        }

        if (this.isNotEmpty(this.createNewMemberHealthAssessmentSubscription)) {
            this.createNewMemberHealthAssessmentSubscription.unsubscribe();
        }

        if (this.isNotEmpty(this.getMemberAssessmentNameSubscription)) {
            this.getMemberAssessmentNameSubscription.unsubscribe();
        }

        if (this.isNotEmpty(this.getPowerUsersSubscription)) {
            this.getPowerUsersSubscription.unsubscribe();
        }

        if (this.isNotEmpty(this.deleteAssessmentSubscription)) {
            this.deleteAssessmentSubscription.unsubscribe();
        }
    }
}
