
export class InterventionDto {
    interventionId: string;
    interventionName: string;
    interventionStatusId: string;
    interventionStatusDescription?: string;
    interventionActivityDate: string;
    update: boolean = false;
}

export class CarePlanProfileDto {
    public goalStatuses: CarePlanProfileItemDto[] = [];
    public goalPriorities: CarePlanProfileItemDto[] = [];
    public interventionStatuses: CarePlanProfileItemDto[] = [];
}

export class CarePlanProfileItemDto {
    public itemId: string;
    public itemDesc: string;
    public itemOrder: number;
    public itemType: string;
    public isActive: boolean;
}

export class SaveCarePlanDto {
    public memberId: string;
    public goal: CarePlanPersistGoalDto;
    public interventions: CarePlanPersistInterventionDto[] = [];
}

export class CarePlanPersistGoalDto {
    public memberCarePlanGoalId: string;
    public targetDate: string;
    public statusId: string;
    public memberPriorityId: string;
    public careGiverPriorityId: string;
    public careManagerPriorityId: string;
    public rowSource: string;
}

export class CarePlanPersistInterventionDto {
    public memberCarePlanGoalBarrierInterventionId: string;
    public statusId: string;
    public note: string;
    public rowSource: string;
    public interventionId?: string;
}

export class CarePlanPersistInstructionActivity {
    public memberCarePlanGoalBarrierInterventionId: string;
    public instructionId: string;
}

export class PersistCarePlanGoalResponseDto {
    public createdBy: string;
    public createdByDate: string;
}

