import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SaveDto } from './outreach-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { OutreachSectionService } from './outreach-section.service';

@Component({
    selector: 'outreach-section',
    templateUrl: 'outreach-section.component.html',
    styleUrls: ['outreach-section.component.scss'],
    providers: [OutreachSectionService]
})
export class OutreachSectionComponent extends BaseComponent implements OnInit, OnDestroy
{
    @Input() memberId: string = null;
    @Output() sectionLoadedEvent = new EventEmitter<string>();
    
    public saveClicked: boolean = false;
    public sectionIsLoaded: boolean = false;

    public sectionForm: FormGroup;
    public field1Control: any;
    public field2Control: any;

    private saveSubscription: Subscription = null;
    private getSubscription: Subscription = null;
    
    constructor(private _sectionService: OutreachSectionService) {
        super();
    }

    ngOnInit(): void {

        this.initializeForm();
    }
    
    public isDirty(): boolean {

        return this.sectionForm.dirty;
    }

    public isLoaded(): boolean {
        
        return this.sectionIsLoaded;
    }

    public save() {

        this.saveClicked = true;

        if (this.sectionForm.valid) {            

            let formToSave: SaveDto = this.getFormValues();

            if (this.saveSubscription != null) {
                this.saveSubscription.unsubscribe();
            }

            this.saveSubscription = this._sectionService.saveSectionData(formToSave).subscribe({
                next: (saveResult) => {

                    this.saveClicked = false;
                },
                error: (err: any) => { 
                    console.error(err);
                }
            });
        }
    }

    public getSectionData() {

        this.sectionIsLoaded = true;
        
        if (this.isNotEmpty(this.getSubscription)) {
            this.getSubscription.unsubscribe();
        }

        this.getSubscription = this._sectionService.getSectionData(this.memberId).subscribe({
            next: (resultData) => {

                // do something with the resultData
                // let tempDto: SaveDto = new SaveDto();
                // this.assignValuesToForm(tempDto);

                this.sectionLoadedEvent.emit('OutreachSection');
            },
            error: (err: any) => { 
                // do we need to persist error information to the server
                console.error(err);
                this.sectionLoadedEvent.emit('OutreachSection');
            }
        });
    }

    public addNew() {

        // build up a new dto and add its data to the sectionForm set of FormControls
    }

    public looksGood() {

        this.save();
    }

    private initializeForm() {

        this.sectionForm = null;
        let formGroup: any = {};

        formGroup['field2'] = new FormControl('');
        formGroup['field1'] = new FormControl('', Validators.required);

        this.sectionForm = new FormGroup(formGroup);

        this.field1Control = this.sectionForm.get('field1');
        this.field2Control = this.sectionForm.get('field2');

        this.setValidators(false);
    }

    private getFormValues(): SaveDto {

        let saveDto = new SaveDto();
        saveDto.val1 = this.sectionForm.controls['field1'].value;
        saveDto.val2 = this.sectionForm.controls['field2'].value;

        return saveDto;
    }

    private assignValuesToForm(sectionDto: SaveDto) {

        if (this.isNotEmpty(sectionDto) && this.isNotEmpty(this.sectionForm)) {

            (<FormControl> this.sectionForm.controls['field1']).setValue(sectionDto.val1);
            (<FormControl> this.sectionForm.controls['field2']).setValue(sectionDto.val2);
        }
    }

    private setValidators(booleanCondition: boolean) {

        if (booleanCondition) {

            (<FormControl> this.sectionForm.controls['field1']).setValidators(Validators.required);
            (<FormControl> this.sectionForm.controls['field1']).updateValueAndValidity();

            (<FormControl> this.sectionForm.controls['field2']).clearValidators();
            (<FormControl> this.sectionForm.controls['field2']).updateValueAndValidity();

        } else {

            (<FormControl> this.sectionForm.controls['field1']).setValidators(Validators.required);
            (<FormControl> this.sectionForm.controls['field1']).updateValueAndValidity();

            (<FormControl> this.sectionForm.controls['field2']).setValidators(Validators.required);
            (<FormControl> this.sectionForm.controls['field2']).updateValueAndValidity();
        }
    }

    ngOnDestroy(): void {

        if (this.saveSubscription !== null) {
            this.saveSubscription.unsubscribe();
        }

        if (this.getSubscription !== null) {
            this.getSubscription.unsubscribe();
        }
    }
}
