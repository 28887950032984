<section class="vh-100">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9 col-lg-6 px-0 col-xl-7">
        <div class="image">
          <img src="../assets/images/background_Image_5.png" alt="Login image" class="w-100 vh-100"
            style="object-fit: cover; object-position: left;">
          <div class="hero-section">
            <p class="welcome text-center">Welcome
            <p>
          </div>
          <div class="hero-info">
            <p> to the Agent Portal </p>
          </div>
        </div>
      </div>
      <div class="login-details col-md-8 col-lg-6 col-xl-5 mt-4">
        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm"
          [appMatchPassword]="['password', 'confirmPassword']" novalidate>
          <div class="lead d-flex flex-row align-items-center justify-content-center text-center ">
            <p class="mb-2 me-3">Sign Up</p>
          </div>
          <span class="small-text"> Enter your information to create a new user account or select from the below to link
            a supported profile: </span>

          <div class="d-flex flex-row align-items-center justify-content-center mt-2 mb-3">
            <!-- <button type="button" class="btn btn-floating mx-1">
              <img src="../assets/images/google.png" width="50px" alt="">
            </button>

            <button type="button" class="btn btn-floating mx-1">
              <img src="../assets/images/facebook.png" width="50px" alt="">
            </button>

            <button type="button" class="btn  btn-floating mx-1">
              <img src="../assets/images/twitter.png" width="50px" alt="">
            </button> -->
          </div>
          <div class="text-center" *ngIf="isError">
            <h6 style="color: red;" class="error">
              {{error}} 
            </h6> 
          </div>

          <div class="form-outline text-center mb-4">
            <!-- <input type="firstName" id="" class="form-control form-control-lg" placeholder="First Name" /> -->
            <input type="firstName" name="firstName" class="form-control form-control-lg" placeholder="First Name"
              [(ngModel)]="portalUser.firstName" #firstName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }" 
              required maxlength="15" minlength="3" pattern="^[a-zA-Z]+$">
            <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
              <div *ngIf="firstName.errors?.['required']">First Name is required</div>
              <div *ngIf="firstName.errors?.['minlength']">First Name must be at least 3 characters</div>
              <div *ngIf="firstName.errors?.['maxlength']">First Name max 15 characters only</div>
              <div *ngIf="firstName.errors?.['pattern']">First Name must be characters only </div>
            </div>
          </div>

          <div class="form-outline text-center mb-4">
            <!-- <input type="lastName" id="" class="form-control form-control-lg" placeholder="Last Name" /> -->
            <input type="lastName" name="lastName" class="form-control form-control-lg" placeholder="Last Name"
              [(ngModel)]="portalUser.lastName" #lastName="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }" required maxlength="15" 
              pattern="^[a-zA-Z]+$">
            <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
              <div *ngIf="lastName.errors?.['required']">Last Name is required</div>
              <div *ngIf="firstName.errors?.['maxlength']">First Name max 15 characters only</div>
              <div *ngIf="lastName.errors?.['pattern']">Last Name must be characters only </div>
            </div>
          </div>
          <!-- <div class="form-outline text-center mb-4">
            <input type="text" name="code" class="form-control form-control-md" placeholder="Registration Code"
              [(ngModel)]="portalUser.code" #code="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && code.invalid }" required>
            <div *ngIf="f.submitted && code.invalid" class="invalid-feedback">
              <div *ngIf="code.errors?.['required']">Code is required</div>
            </div>
          </div> -->

         
          <div class="d-flex justify-content-between align-items-center">
            <!-- Checkbox -->
            <div class="form-check mb-0">
              <input class="form-check-input me-2" id="acceptTerms" type="checkbox" name="acceptTerms"
                [(ngModel)]="portalUser.acceptTerms" #acceptTerms="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && acceptTerms.invalid }" required />
              <label class="form-check-label text-muted" for="acceptTerms">
                <span class="small-text"> I have read and accept the </span>
              </label>
              <span class="small-text"> <u><a style="cursor:pointer;color: rgb(3, 31, 131);"  (click)="openPopup()"> “Terms and Conditions”</a></u> for this site</span>
              <div *ngIf="f.submitted && acceptTerms.errors" class="invalid-feedback">
                <div *ngIf="acceptTerms.errors?.['required']">
                  Accept Terms and Condition required
                </div>

              </div>
            </div>

          </div>
          <div class="text-center pt-1 mt-4 mb-4 pb-1">
            <button type="submit" class="btn btn-lg btn-block log-in">Authenticate</button>
          </div>
          <!-- 
          <ul class="pagination">
            <li class="page-item"><a class="page-link"> <img src="../../assets/images/Previous-button.png" alt=""></a></li>
            <li class="page-item active" aria-current="page"><a class="page-link" href="#"> <img
                  src="../../assets/images/Dot-colored.png" alt=""></a></li>
            <li class="page-item active" aria-current="page"><a class="page-link" href="#"> <img
                  src="../../assets/images/Dot-normal.png" alt=""></a></li>
            <li class="page-item active" aria-current="page"><a class="page-link" href="#"> <img
                  src="../../assets/images/Dot-normal.png" alt=""></a></li>
            <li class="page-item active" aria-current="page"><a class="page-link" href="#"> <img
                  src="../../assets/images/Dot-normal.png" alt=""></a></li>
            <li class="page-item"><a class="page-link"><img src="../../assets/images/next-button.png" alt=""></a></li>
          </ul> -->

          <p class="mt-2 pt-1"><span>Already signed up?</span>
              <button type="button" class="sign-up btn btn-lg btn-block mt-2" routerLink="/login">Login</button>
    
        </form>
      </div>
    </div>
  </div>
</section>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Terms and Conditions</h4>
      </div>
      <div class="modal-body">
        <p></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" 
                (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>