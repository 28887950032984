
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { SampleDto, SaveDto } from './outreach-section.dtos';

@Injectable()
export class OutreachSectionService {

    private getSampleUrl: string = '/FactResolution/ResolveAssessmentFacts/{assessmentId}/{memberId}';
    private saveSampleUrl: string = '/IntakeAssessment/PersistAssessmentComplete';    

    private headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {

        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');

        environment.contentServiceApiBaseUrl = _appConfig.contentServiceApiBaseUrl;
    }

    public getSectionData(memberId: string): Observable<SampleDto> {

        let url = (this._appConfig.contentServiceApiBaseUrl + this.getSampleUrl)
            .replace('{memberId}', memberId);

        return this._http.get<SampleDto>(url, {headers: this.headers});
    }

    public saveSectionData(formToSave: SaveDto): Observable<SampleDto> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + this.saveSampleUrl);

        return this._http.post<SampleDto>(url, formToSave, {headers: this.headers});
    }
}