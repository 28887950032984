
import { Component } from '@angular/core';

@Component({
    selector: 'base-component',
    template: ''
})
export class BaseComponent {

    constructor() {   
    }

    protected isEmpty(object: any): boolean {

        if (object === null || object === undefined) {
            return true;
        } else if (typeof object === 'string' && object.trim().length === 0) {
            return true;
        }
        return false;
    }

    protected isNotEmpty(object: any): boolean {

        if (object === null || object === undefined) {
            return false;
        } else if (typeof object === 'string' && object.trim().length === 0) {
            return false;
        }
        return true;
    }

    protected toProperCase(str: string) {

        let newValue = str.toLowerCase().split(' ');

        for (let i = 0; i < newValue.length; i++) {
            newValue[i] = newValue[i].charAt(0).toUpperCase() + newValue[i].slice(1);
        }

        return newValue.join(' ');
    }   

    protected  allAreTrue(arr:any) {
        return arr.every((element: boolean) => element === true);
    }

}
