import { Component, OnInit } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, FormControl, Validators, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { AuthUser } from '../../models/authuser';
import { PortalUserService } from '../../services/portaluser.service';
import {PortalUser} from '../../models/portaluser';
import { ToastrService } from 'ngx-toastr';
import { SignupService } from 'src/app/auth/services/signup/signup.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  type: string = "password";
  isText: boolean = false;
  eyeIcon: string = "fa-eye-slash";
  user: AuthUser;
  portalUser:PortalUser;
  isSaveMe:boolean=false;
  isError:boolean=false;

  constructor(private router: Router, private authService: AuthService,private toastr: ToastrService,
     private formBuilder: FormBuilder,private portalUserService:PortalUserService,private signupService:SignupService) {
    this.user = new AuthUser();
    this.portalUser=new PortalUser();
  }

  ngOnInit(): void {
    this.isError=false;
    this.getMeIfSaved();
   }

  onSubmit() {
    this.toastr.clear();
    this.saveMe();
    //#region Login Aut User
    this.authService.ValidateUser(this.user).subscribe({
      next: (v) => {
        if (v != null) {
          var  token = JSON.parse(atob(v.split('.')[1]))
          var result = Object.keys(token).map((key) => [token[key]]);
          localStorage.setItem('authTokenDetail', result.toString())
          this.authService.setToken(v);
          this.portalUserService.getPortalUserById(result[0]).subscribe(
            {
              next:(user: PortalUser)=>{

                    this.authService.setLoggedUser(user);
                    
                    let redirectUrl: string = sessionStorage.getItem('redirectUrl');

                    if (redirectUrl !== null && redirectUrl !== undefined) {
                        
                        this.router.navigate([redirectUrl]);
                        sessionStorage.removeItem('redirectUrl');

                    } else {
                        this.router.navigate(['home'])
                    }
              },
                error: (e) => this.isError=true//this.toastr.error(e.error)
            })
        }
        else { this.isError=true;}//this.toastr.warning(v); }
      },
      error: (e) => this.isError=true
    })
    //#endregion
  }

  hideShowPass(): void {
    this.isText = !this.isText;
    this.eyeIcon = this.isText ? "fa-eye" : "fa-eye-slash";
    this.type = this.isText ? "text" : "password";
  }

  saveMe(){
    if(this.isSaveMe){
      localStorage.setItem('USER_ID',this.user.emailAddress)
      localStorage.setItem('USER_PWD',this.user.password)
     }
  }
  getMeIfSaved(){
    this.user.emailAddress = localStorage.getItem('USER_ID')
    this.user.password = localStorage.getItem('USER_PWD')
  }

}
