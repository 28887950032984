import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';

import { PlanDto, BenefitGroupDto, PlanBenefitsDto, LocalResourceDto, MemberAddressDto } from './benefits-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { TableColumnDto } from 'src/app/member/common/common-dtos';
import { BenefitsSectionService } from './benefits-section.service';
import { DateTime, Interval } from 'luxon';
import { LazyLoadEvent } from 'primeng/api';

@Component({
    selector: 'benefits-section',
    templateUrl: 'benefits-section.component.html',
    styleUrls: ['benefits-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [BenefitsSectionService]
})
export class BenefitsSectionComponent extends BaseComponent implements OnInit, OnDestroy
{
    @Input() memberId: string = null;
    @Output() sectionLoadedEvent = new EventEmitter<string>();

    private sectionIsLoaded: boolean = false;

    public memberAddressList: MemberAddressDto[] = [];
    public plansList: PlanDto[] = [];
    public localResourceList: LocalResourceDto[] = [];
    public planBenefitsList: PlanBenefitsDto[] = [];

    public isTableFixed:boolean = false;
    public isTableEmpty:boolean = false;
    public isTableVarie: boolean = false;
    public showPlanTab: boolean = false;
    private isDataLoaded: boolean = false;
    
    public zipCode: string = "";
    public planZipCode: string = "";
    public localResourcesZipCode: string = "";

    public keywords: string = "";
    public planIds: string = "";
    public pageSize: number = 50;
    public parameters: string = "pagenumber=1&pagesize=50'";
    public lastRecordsFromPlanBenefits: boolean = false;

    public selectedPlans: string[] = [];
    public localResourceColumns: TableColumnDto[] = [];
    public planBenefitColumns: TableColumnDto[] = [];
    public tableSort: number = -1;
    public noRecordsMessage = "No documents found";

    private getMemberAddressSubscription: Subscription = null;
    private getPlansSubscription: Subscription = null;
    private getPlanBenefitsSubscription: Subscription = null;
    private getLocalResourcesSubscription: Subscription = null;

    private static readonly physical = "Physical";
    private static readonly mailing = "Mailing";

    public maxSelectedLabels: number = 2;
    
    constructor(private _sectionService: BenefitsSectionService) {
        super();
    }

    ngOnInit(): void {

        this.initializeColumns();
    }

    public getSectionData() {

        this.sectionIsLoaded = true;
        
        if (this.isNotEmpty(this.getMemberAddressSubscription)) {
            this.getMemberAddressSubscription.unsubscribe();
        }
        
        this.getMemberAddressSubscription = this._sectionService.getAddressListByMemberId(this.memberId).subscribe({

            next: (resultData: Array<MemberAddressDto>) => {

                this.memberAddressList = resultData;
                this.setZipCode();
                
                               
             },
            error: (err: any) => { 
                console.error(err);
                this.sectionLoadedEvent.emit('BenefitsSection');
            }
        });

    }

    
    private setZipCode(){

        let memberAddress: MemberAddressDto = null;

        if(this.memberAddressList.find(x=>x.isPrimary == true)){
            memberAddress = this.memberAddressList.find(x=>x.isPrimary == true);
        }
        else{
            if(this.memberAddressList.find(x=>x.addressType == BenefitsSectionComponent.physical)){
                memberAddress = this.memberAddressList.find(x=>x.addressType == BenefitsSectionComponent.physical);
            } else if(this.memberAddressList.find(x=>x.addressType == BenefitsSectionComponent.mailing)){
                memberAddress = this.memberAddressList.find(x=>x.addressType == BenefitsSectionComponent.mailing);
            } else {
                memberAddress = this.memberAddressList.find(x=>x.zip !== "");
            }
        }

        let zipCode: string = "";

        if(this.isNotEmpty(memberAddress) && this.isNotEmpty(memberAddress.zip) && memberAddress.zip.length > 5){
            zipCode = memberAddress.zip.substring(0,5);           
        } else if(this.isNotEmpty(memberAddress) && this.isNotEmpty(memberAddress.zip) && memberAddress.zip.length <= 5) {
            zipCode = memberAddress.zip;            
        }

        if(this.isNotEmpty(zipCode)){
            this.zipCode = zipCode;
            this.planZipCode = zipCode; 
            this.localResourcesZipCode = zipCode; 
            this.getLocalResources();
            this.getPlans();  
        }
    }

    public cleanUrl(url:string) : string {

        let cleanedUrl:string = "";
        
        if(url.indexOf("://") > -1){
            cleanedUrl = url.substring(url.indexOf("://") + 3, url.length);
        } else{
            cleanedUrl = url;
        }


        if (cleanedUrl.startsWith('//')) {
            cleanedUrl = cleanedUrl.substring(2, cleanedUrl.length);
        }

        return cleanedUrl;
    }

    public resetPlanForm(){      
        this.keywords = "";
        this.showPlanTab =false;
        this.selectedPlans = [];
    }
    
    public searchForPlans(){
        if(this.isPlanZipCodeValid()){
            this.getPlans();
        }
    }

    public submitPlanForm(){
        if(this.isPlanZipCodeValid()){
            this.planBenefitsList = [];
            this.lastRecordsFromPlanBenefits = false;
            this.isDataLoaded = false;
            this.getPlanBenefitData("pagenumber=1&pagesize=50");
        }
    }

    public resetResourcesForm(){
        this.localResourceList = [];
        this.localResourcesZipCode = "";
    }

    public submitResourcesForm(){
        if(this.isLocalResourcesZipCodeValid()){
            this.localResourceList = [];
            this.getLocalResources();
        }
    }

    public isPlanZipCodeValid(){

        let planZipCodeIsValid: boolean = true; 
        
        if(this.isEmpty(this.planZipCode) || (this.isNotEmpty(this.planZipCode) && this.planZipCode.length !== 5)){
            planZipCodeIsValid = false;
            this.plansList = [];
            this.showPlanTab = false;
            this.planBenefitsList = [];

        }

        return planZipCodeIsValid;
    }

    public isLocalResourcesZipCodeValid(){

        let localResourcesZipCodeIsValid: boolean = true; 
        
        if(this.isEmpty(this.localResourcesZipCode) || (this.isNotEmpty(this.localResourcesZipCode) && this.localResourcesZipCode.length !== 5)){
            localResourcesZipCodeIsValid = false;
        }

        return localResourcesZipCodeIsValid;
    }

    private getLocalResources(){


        if (this.isNotEmpty(this.getLocalResourcesSubscription)) {
            this.getLocalResourcesSubscription.unsubscribe();
        }

        this.localResourceList = [];

      
        this.getLocalResourcesSubscription = this._sectionService.getLocalResoucesByZipCode(this.localResourcesZipCode).subscribe({

            next: (resultData: Array<LocalResourceDto>) => {

                this.localResourceList = resultData;
             
             },
            error: (err: any) => { 
                console.error(err);
            }
        });

    }

    private getPlans(){

        if (this.isNotEmpty(this.getPlansSubscription)) {
            this.getPlansSubscription.unsubscribe();
        }

        this.plansList = [];
  
        this.getPlansSubscription = this._sectionService.getPlansByZipCode(this.planZipCode).subscribe({

            next: (resultData: Array<PlanDto>) => {

                this.plansList = resultData;
                
                               
             },
            error: (err: any) => { 
                console.error(err);
            }
        });

    }

    public loadBenefits(event: any) {
        const div = document.querySelector("#infinite-table");
        if(!this.lastRecordsFromPlanBenefits && this.isDataLoaded){
          if (Math.abs(div.scrollHeight - div.clientHeight - div.scrollTop) <= 1) {
            this.isDataLoaded = false;
            let pagenumber = (this.planBenefitsList.length / this.pageSize) + 1;
            let parameters = "&pagenumber=" + pagenumber + "&pagesize=" + this.pageSize;
            this.getPlanBenefitData(parameters);
          }
        }
    }

    private getSelectedPlanIds(){

        let concatenatedPlanIds: string = "";
        this.planIds = "";

        if(this.isNotEmpty(this.selectedPlans)){
            for(let planId of this.selectedPlans){
                concatenatedPlanIds = concatenatedPlanIds + planId + ",";
            }
        }

        this.planIds = concatenatedPlanIds.substring(0,concatenatedPlanIds.length - 1);

    }

   
    private getPlanBenefitData(parameters: string){

        this.getSelectedPlanIds();

        this.getPlansSubscription = this._sectionService.getPlanBenefits(this.planZipCode, this.planIds, this.keywords, parameters).subscribe({

            next: (result: Array<PlanBenefitsDto>) => {

            if(result.length > 0 || this.planBenefitsList.length > 0)  {
                
                if(result.length > 0){
                    this.planBenefitsList.push.apply(this.planBenefitsList, result);
                }     


                if(this.planBenefitsList.length <=15){
                    this.isTableFixed = false;
                    this.isTableVarie = true;
                    this.isTableEmpty = false;      
                }
               
                else{
                this.isTableFixed = true;
                this.isTableVarie = false;
                this.isTableEmpty = false;      
               }
              
            } 
            else{
                this.isTableEmpty = true;        
            }

           
            this.lastRecordsFromPlanBenefits = (result.length < this.pageSize) ? true : false;
            this.isDataLoaded = true;
            this.showPlanTab = true;
                           
            },
            error: (err: any) => { 
                console.error(err);
            }
        });
    }


    public isDirty(): boolean {
        return false;
    }

    public isLoaded(): boolean {
        return this.sectionIsLoaded;
    }

   
    private initializeColumns() {

        this.localResourceColumns = [];
        this.planBenefitColumns = [];
 
        let serviceName: TableColumnDto = new TableColumnDto();
        serviceName.field = 'serviceName';
        serviceName.header = 'Service';
        serviceName.width = '15%';
        serviceName.align = 'left';
        
        let address1: TableColumnDto = new TableColumnDto();
        address1.field = 'address1';
        address1.header = 'Address 1';
        address1.width = '15%';
        address1.align = 'left';

        let address2: TableColumnDto = new TableColumnDto();
        address2.field = 'address2';
        address2.header = 'Address 2';
        address2.width = '15%';
        address2.align = 'left';

        let city: TableColumnDto = new TableColumnDto();
        city.field = 'city';
        city.header = 'City';
        city.width = '7%';
        city.align = 'center';

        let state: TableColumnDto = new TableColumnDto();
        state.field = 'stateCode';
        state.header = 'State';
        state.width = '3%';
        state.align = 'center';

        let zip: TableColumnDto = new TableColumnDto();
        zip.field = 'zipCode';
        zip.header = 'Zip Code';
        zip.width = '5%';
        zip.align = 'center';

        let agency: TableColumnDto = new TableColumnDto();
        agency.field = 'agency';
        agency.header = 'Agency';
        agency.width = '20%';
        agency.align = 'center';
        
        let phoneNumber: TableColumnDto = new TableColumnDto();
        phoneNumber.field = 'phoneNumber';
        phoneNumber.header = 'Phone Number';
        phoneNumber.width = '10%';
        phoneNumber.align = 'center';

        let website: TableColumnDto = new TableColumnDto();
        website.field = 'website';
        website.header = 'Website';
        website.width = '10%';
        website.align = 'center';

        this.localResourceColumns.push(serviceName);
        this.localResourceColumns.push(address1);
        this.localResourceColumns.push(address2);
        this.localResourceColumns.push(city);
        this.localResourceColumns.push(state);
        this.localResourceColumns.push(zip);
        this.localResourceColumns.push(agency);
        this.localResourceColumns.push(phoneNumber);
        this.localResourceColumns.push(website);

        let planName: TableColumnDto = new TableColumnDto();
        planName.field = 'planName';
        planName.header = 'Plan';
        planName.width = '33%';
        planName.align = 'center';
        
        let benefitName: TableColumnDto = new TableColumnDto();
        benefitName.field = 'benefitName';
        benefitName.header = 'Benefit';
        benefitName.width = '33%';
        benefitName.align = 'center';

        let coverage: TableColumnDto = new TableColumnDto();
        coverage.field = 'coverage';
        coverage.header = 'Coverage';
        coverage.width = '33%';
        coverage.align = 'center';

        this.planBenefitColumns.push(planName);
        this.planBenefitColumns.push(benefitName);
        this.planBenefitColumns.push(coverage);
    }

    public getNumLocalResourceColumns(): number {
        
        return this.isEmpty(this.localResourceColumns) ? 0 : this.localResourceColumns.length;
    }

    public getNumPlanBenefitColumns(): number {
        
        return this.isEmpty(this.planBenefitColumns) ? 0 : this.planBenefitColumns.length;
    }
    
    public filterTable($event: any, documentsTable: Table, columnField: any) {
        
        documentsTable.filter($event.target.value, columnField, 'contains');
    }

    public getTableHeight(): string {
        
        return '650px';
    }

    public customSort($event: { field: string; data: any[]; order: number; }) {

        let isDate: boolean = false;

        if (this.isNotEmpty($event) && this.isNotEmpty($event.field) && $event.field.toLowerCase().indexOf('date') > -1) {
            
            isDate = true;
        }

        $event.data.sort((data1, data2) => {
    
            let value1 = null;
            let value2 = null;
    
            if ( isDate) {
    
                let val1 = data1[$event.field];
                let val2 = data2[$event.field];
                value1 = new Date(val1);
                value2 = new Date(val2);
    
            } else {
    
                value1 = data1[$event.field];
                value2 = data2[$event.field];
            }
    
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ($event.order * result);
        });
    }

    private formatDates(dateToParse: string): string {
     
        return (DateTime.fromISO(dateToParse).toFormat('MM/dd/yyyy'));
    }

    ngOnDestroy(): void {

        if (this.getMemberAddressSubscription !== null) {
            this.getMemberAddressSubscription.unsubscribe();
        }

        if (this.getPlansSubscription !== null) {
            this.getPlansSubscription.unsubscribe();
        }

        if(this.getPlanBenefitsSubscription != null){
            this.getPlanBenefitsSubscription.unsubscribe();
        }

        if(this.getLocalResourcesSubscription != null){
            this.getLocalResourcesSubscription.unsubscribe();
        }
        
    }
}
