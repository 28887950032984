import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';

import { DocumentDto } from './documents-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { TableColumnDto } from 'src/app/member/common/common-dtos';
import { DocumentsSectionService } from './documents-section.service';
import { DateTime, Interval } from 'luxon';
import { Constant } from 'src/app/auth/utils/constant';
import { AppConfig } from 'src/app/auth/models/app-config';


@Component({
    selector: 'documents-section',
    templateUrl: 'documents-section.component.html',
    styleUrls: ['documents-section.component.scss'],
    providers: [DocumentsSectionService]
})
export class DocumentsSectionComponent extends BaseComponent implements OnInit, OnDestroy
{
    @Input() memberId: string = null;
    @Output() sectionLoadedEvent = new EventEmitter<string>();

    public documentsList: DocumentDto[] = [];
    private sectionIsLoaded: boolean = false;

    public tableColumns: TableColumnDto[] = [];
    public tableSort: number = -1;
    public noRecordsMessage = "No documents found";

    private getSubscription: Subscription = null;
    private downloadSubscription: Subscription = null;
    
    constructor(private _sectionService: DocumentsSectionService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {
        super();
    }

    ngOnInit(): void {

        this.initializeColumns();       
    }
    
    public getSectionData() {

        this.sectionIsLoaded = true;
        
        if (this.isNotEmpty(this.getSubscription)) {
            this.getSubscription.unsubscribe();
        }
        
        this.getSubscription = this._sectionService.getDocumentList(this.memberId).subscribe({

            next: (resultData: Array<DocumentDto>) => {

                this.documentsList = resultData;
                
                this.documentsList.map((el: DocumentDto) => {
                   el.createdDate = this.formatDates(el.createdDate);
                });
                
                this.sectionLoadedEvent.emit('DocumentsSection');
            },
            error: (err: any) => { 
                console.error(err);
                this.sectionLoadedEvent.emit('DocumentsSection');
            }
        });
    }

    public downloadDocument(rowData: DocumentDto) {

        if (this.isNotEmpty(this.downloadSubscription)) {
            this.downloadSubscription.unsubscribe();
        }
        this.downloadSubscription=this._sectionService.getDocument(rowData.documentName,rowData.id).subscribe(response => {
      const file = new Blob([response.body], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

     let openWindowUrl=this.ModifyfileURL(fileURL,rowData);
            window.open(openWindowUrl, '_blank');
    });
    }
    public ModifyfileURL(file:string, data:DocumentDto){
        let documentdefinitionUrl = this._appConfig.memberManagerApiBaseUrl +Constant.downloadDocumentUrl;
        documentdefinitionUrl=documentdefinitionUrl+'/'+data.id+'/'+encodeURIComponent(data.documentName);
        return  documentdefinitionUrl;


    }
    public isDirty(): boolean {
        return false;
    }

    public isLoaded(): boolean {
        return this.sectionIsLoaded;
    }

    public save() {
    }

    private initializeColumns() {

        this.tableColumns = [];
 
        let columnName: TableColumnDto = new TableColumnDto();
        columnName.field = 'documentName';
        columnName.header = 'Name';
        columnName.width = '50%';
        columnName.align = 'left';
        
        let columnCreatedOn: TableColumnDto = new TableColumnDto();
        columnCreatedOn.field = 'createdDate';
        columnCreatedOn.header = 'Created On';
        columnCreatedOn.width = '20%';
        columnCreatedOn.align = 'center';

        let columnSource: TableColumnDto = new TableColumnDto();
        columnSource.field = 'rowSource';
        columnSource.header = 'Source';
        columnSource.width = '20%';
        columnSource.align = 'center';

        let columnAction: TableColumnDto = new TableColumnDto();
        columnAction.field = 'action';
        columnAction.header = '';
        columnAction.width = '10%';
        columnAction.align = 'center';
        
        this.tableColumns.push(columnName);
        this.tableColumns.push(columnCreatedOn);
        this.tableColumns.push(columnSource);
        this.tableColumns.push(columnAction);
    }

    public getNumColumns(): number {
        
        return this.isEmpty(this.tableColumns) ? 0 : this.tableColumns.length;
    }

    public filterTable($event: any, documentsTable: Table, columnField: any) {
        
        documentsTable.filter($event.target.value, columnField, 'contains');
    }

    public getTableHeight(): string {
        
        return '650px';
    }

    public customSort($event: { field: string; data: any[]; order: number; }) {

        let isDate: boolean = false;

        if (this.isNotEmpty($event) && this.isNotEmpty($event.field) && $event.field.toLowerCase().indexOf('date') > -1) {
            
            isDate = true;
        }

        $event.data.sort((data1, data2) => {
    
            let value1 = null;
            let value2 = null;
    
            if ( isDate) {
    
                let val1 = data1[$event.field];
                let val2 = data2[$event.field];
                value1 = new Date(val1);
                value2 = new Date(val2);
    
            } else {
    
                value1 = data1[$event.field];
                value2 = data2[$event.field];
            }
    
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ($event.order * result);
        });
    }

    private formatDates(dateToParse: string): string {
     
        return (DateTime.fromISO(dateToParse).toFormat('MM/dd/yyyy'));
    }

    ngOnDestroy(): void {

        if (this.getSubscription !== null) {
            this.getSubscription.unsubscribe();
        }

        if (this.downloadSubscription !== null) {
            this.downloadSubscription.unsubscribe();
        }
    }
}
