import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { CarePlanPersistGoalDto, CarePlanPersistInterventionDto, CarePlanProfileItemDto, InterventionDto, SaveCarePlanDto } from './goal-detail.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { GoalDetailService } from './goal-detail.service';
import { CarePlanGoalDetailsDto, CarePlanGoalDto, InterventionEducationDto, InterventionInstructionDto } from '../careplan-section.dtos';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { DateTime } from 'luxon';
import { TableColumnDto } from '../../common/common-dtos';
import { Constant } from 'src/app/auth/utils/constant';
import { Editor } from 'primeng/editor';

@Component({
    selector: 'goal-detail',
    templateUrl: 'goal-detail.component.html',
    styleUrls: ['goal-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [GoalDetailService]
})
export class GoalDetailComponent extends BaseComponent implements OnInit, OnDestroy
{    
    @Output() goalSavedEvent = new EventEmitter<string>();
    @Output() goalCanceledEvent = new EventEmitter<string>();
    @Output() goalLoadedEvent = new EventEmitter<string>();
    @ViewChild('editor') noteEditor: Editor;

    private memberId: string;
    public dataLoaded: boolean = false;
    public saveClicked: boolean = false;
    public goal: CarePlanGoalDto;
    public goalIsEditable: boolean = false;
    public minAssignDate: Date = new Date();
    public barriers: string[] = [];
    public carePlanGoalDetails: CarePlanGoalDetailsDto[] = [];
    public interventions: InterventionDto[] = [];
    public openInterventions: InterventionDto[] = [];
    public currentIntervention: CarePlanGoalDetailsDto = null;
    public interventionColumns: TableColumnDto[] = [];
    public interventionBatchColumns: TableColumnDto[] = [];

    public goalStatuses: SelectItem[] = [];
    public closedGoalStatuses: SelectItem[] = [];
    public memberPriorities: SelectItem[] = [];
    public careGiverPriorities: SelectItem[] = [];
    public careManagerPriorities: SelectItem[] = [];
    public interventionStatuses: SelectItem[] = [];
    public closedInterventionStatuses: SelectItem[] = [];
    public closedInterventionStatus: string = null;
    public noRecordsMessage = "No records found";
    public messageCloseInterventions = "Apply Status To Selected Interventions";

    private getCarePlanProfileSubscription: Subscription = null;
    private getGoalDetailDataSubscription: Subscription = null;
    private getSubscription: Subscription = null;
    private saveSubscription: Subscription = null;
    private getEducationSubscription: Subscription = null;
    private getInstructionSubscription: Subscription = null;

    public selectAllInterventions: boolean = false;
    public displayCloseInterventionBatch: boolean = false;
    public clearText:string=null;
    
    constructor(private _goalDetailService: GoalDetailService,
        private _changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {

        this.initializeColumns();
    }

    public openCloseInterventionBatchDialog(){
        this.displayCloseInterventionBatch = true;
        this.closedInterventionStatus = null;
    }

    public closeInterventionBatchDialog(interventionsBatchTable:Table){
        this.displayCloseInterventionBatch = false;
        this.selectAllInterventions=false;
        if(this.clearText)
        {
            this.clearText='';
        }
        interventionsBatchTable.clear();
        this.clearUpdateFlag();
    }

    public updateInterventionBatch(){

        this.saveGoalEdit(true, false);

    }

    public getInterventionDescription(interventionStatusId: String) : string{

        let interventionDescription: string = '';
        
        for(let interventionStatus of this.interventionStatuses){

            if(interventionStatusId === interventionStatus.value){
                interventionDescription = interventionStatus.label;
                break;
            } 

        }

        return interventionDescription;

    }

    public disableCloseIntervetionsButton(): boolean {
        let disableButton: boolean = true;

        if(!this.checkIfGoalIsClosed(this.goal.goalStatusId) && !this.allInterventionsAreClosed()){
            disableButton = false;
        }

        return disableButton;

    }

    public selectAllOpenInterventions($event: any) {
      
        this.selectAllInterventions = $event.checked;
       
        for (let intervention of this.openInterventions) {
            intervention.update = this.selectAllInterventions;
        }

       }


    public childUpdateInterventionsCheckbox(){       
        this.selectAllInterventions =  this.allAreTrue(this.openInterventions.map(x=>x.update))
    }  

    private checkIfGoalIsClosed(goalStatusId: String) : boolean{

        let goalAlreadyClosed: boolean = false;
        
        for(let closedGoal of this.closedGoalStatuses){

            if(goalStatusId === closedGoal.value){
                goalAlreadyClosed = true;
                break;
            } 

        }

        return goalAlreadyClosed;

    }

    public checkIfInterventionIsClosed(interventionStatusId: String) : boolean{

        let interventionAlreadyClosed: boolean = false;
        
        for(let closedIntervention of this.closedInterventionStatuses){

            if(interventionStatusId === closedIntervention.value){
                interventionAlreadyClosed = true;
                break;
            } 

        }

        return interventionAlreadyClosed;

    }

    public allInterventionsAreClosed() : boolean{

        let allInterventionsAreClosed: boolean = true;
        
        for(let intervention of this.interventions){

            if(!this.checkIfInterventionIsClosed(intervention.interventionStatusId)){
                allInterventionsAreClosed = false;
                break;
            } 

        }

        return allInterventionsAreClosed;

    }

    public isDirty(): boolean {

        return this.goalIsEditable && this.isNotEmpty(this.goal);
    }

    public loadMemberGoal(memberCarePlanGoal: CarePlanGoalDto, memberId: string, editable: boolean) {
    
        this.memberId = memberId;

        this.goalIsEditable = editable;
  
        this.goal = memberCarePlanGoal;

        this.goal.goalStatusId

        this.getCarePlanProfile();
    }

    public cancelGoalEdit() {

        this.goalCanceledEvent.emit('cancel');
    }

    public saveGoalEdit(batchInterventionUpdate : boolean, closeInterventionScreen: boolean) {

        if (this.goalIsEditable) {

            this.saveClicked = true;

            let saveDto: SaveCarePlanDto = this.getFormData();

            if(batchInterventionUpdate){
               
                for(let intervention of saveDto.interventions){
                    
                    if(this.checkIfInterventionNeedsToBeUpdated(intervention.interventionId)){
                        intervention.statusId = this.closedInterventionStatus;
                    }
 
                }
            
            }

            if (this.saveSubscription != null) {
                this.saveSubscription.unsubscribe();
            }

            this.saveSubscription = this._goalDetailService.persistCarePlanGoal(saveDto).subscribe({
                next: (saveResult : any) => {
                  
                    this.goal.createdBy=saveResult.createdBy;
                    this.goal.goalActivityDate = (DateTime.fromISO(saveResult.createdByDate).toFormat('MM/dd/yyyy HH:mm'));
                  
                    this.saveClicked = false;

                    if(closeInterventionScreen){
                        this.goalSavedEvent.emit('success');
                    } else {
                        this.displayCloseInterventionBatch = false;
                        this.interventions = [];
                        this.openInterventions = [];
                        this.getGoalInterventions();
                    } 

                    this.clearUpdateFlag();
                },
                error: (err: any) => {
                    console.error(err);
                }
            });
        }
    }

    public disableUpdateInterventionsButton():boolean {
        let disableButton: boolean = true;

        if(this.isUpdateSelected() && this.isNotEmpty(this.closedInterventionStatus) ){
            disableButton = false;
        }

        return  disableButton;

    }


    private isUpdateSelected():boolean  {

        let updateSelected: boolean = false;

        for(let goal of this.openInterventions){
            if(goal.update){
                updateSelected = true;
                break;
            }
        }

        return updateSelected;

    }


    private clearUpdateFlag() {

        for(let intervention of this.openInterventions){

            intervention.update = false;
        }

    }

    private checkIfInterventionNeedsToBeUpdated(interventionId:string):boolean{

        let updateIntervention:boolean = false;
    
        for(let intervention of this.interventions){
            if(intervention.interventionId === interventionId && intervention.update){
                updateIntervention = true;
                break;
            }
        }
    
        return updateIntervention;


    }

    private getFormData(): SaveCarePlanDto {

        let saveData: SaveCarePlanDto = new SaveCarePlanDto();
        saveData.memberId = this.memberId;

        saveData.goal = new CarePlanPersistGoalDto();
        saveData.goal.memberCarePlanGoalId = this.goal.memberCarePlanGoalId;
        saveData.goal.targetDate = this.goal.targetDate; 
        saveData.goal.statusId = this.goal.goalStatusId;
        saveData.goal.memberPriorityId = this.goal.goalMemberPriorityId;
        saveData.goal.careGiverPriorityId = this.goal.goalCareGiverPriorityId;
        saveData.goal.careManagerPriorityId = this.goal.goalCareManagerPriorityId;
        saveData.goal.rowSource = Constant.AIProwSource;

        this.carePlanGoalDetails.forEach(detail => {
    
            let intervention: CarePlanPersistInterventionDto = new CarePlanPersistInterventionDto();
            intervention.memberCarePlanGoalBarrierInterventionId = detail.memberCarePlanGoalBarrierInterventionId;
            intervention.statusId = detail.interventionStatusId;
            intervention.note = detail.interventionNote;
            intervention.rowSource = Constant.AIProwSource;
            intervention.interventionId = detail.interventionId;

            saveData.interventions.push(intervention);
        });

        return saveData;
    }

    private getGoalInterventions() {
     
        if (this.isNotEmpty(this.getGoalDetailDataSubscription)) {
            this.getGoalDetailDataSubscription.unsubscribe();
        }

        this.getGoalDetailDataSubscription = this._goalDetailService.getGoalDetailData(this.goal.memberCarePlanGoalId).subscribe({
            next: (goalDetails) => {
               
                this.carePlanGoalDetails = this.sortInterventions(goalDetails);

                goalDetails.forEach(detail => {
                    
                    if (!this.barriers.includes(detail.barrierDescription)) {
                        this.barriers.push(detail.barrierDescription);
                    }

                    if (this.isNotEmpty(detail.interventionActivityDate)) {
                        detail.interventionActivityDate = (DateTime.fromISO(detail.interventionActivityDate).toFormat('MM/dd/yyyy HH:mm'));
                    }

                    if (this.isNotEmpty(detail.interventionUpdatedBy)) {
                    
                        detail.interventionCreatedBy = detail.interventionUpdatedBy;
                    }

                    let intervention: InterventionDto = new InterventionDto();
                    intervention.interventionId = detail.interventionId;
                    intervention.interventionName = detail.interventionDescription;
                    intervention.interventionActivityDate = detail.interventionActivityDate;
                    intervention.interventionStatusId = detail.interventionStatusId;
                    intervention.interventionStatusDescription = this.getInterventionDescription(detail.interventionStatusId);

                    let carePlanGoalDetail: CarePlanGoalDetailsDto = this.carePlanGoalDetails.find(x => x.interventionId === intervention.interventionId);

                    if(this.isNotEmpty(this.currentIntervention) && this.isNotEmpty(this.currentIntervention.interventionId) &&
                     this.currentIntervention.interventionId === carePlanGoalDetail.interventionId){
                        this.currentIntervention = carePlanGoalDetail;
                    }

                    this.interventions.push(intervention);
                });

                this.carePlanGoalDetails = goalDetails;

                this.checkGoalMetStatus();

                this.dataLoaded = true;

                this.createOpenInterventionList();
            
                this.goalLoadedEvent.emit('success');
                
                this._changeDetectorRef.detectChanges();
            },
            error: (err: any) => { 
                console.error(err);
            }
        });
    }

    private createOpenInterventionList(){
     
        for(let intervention of this.interventions){
            if(!this.checkIfInterventionIsClosed(intervention.interventionStatusId)){
                this.openInterventions.push(intervention);
            }
        }

    }

    private sortInterventions(interventions: CarePlanGoalDetailsDto[]) {

        let sortedInterventions: CarePlanGoalDetailsDto[] = interventions.sort((a: CarePlanGoalDetailsDto, b: CarePlanGoalDetailsDto) => {

            if (a.interventionOrder < b.interventionOrder) {
                return -1;
            } else if (a.interventionOrder > b.interventionOrder) {
                return 1;
            }

            return 0;
        });

        return sortedInterventions;
    }

    public checkGoalStatus() {

        this.checkGoalMetStatus();
    }

    private checkGoalMetStatus() {

        let exists: boolean = false;

        if (this.isNotEmpty(this.carePlanGoalDetails)) {
       
            this.carePlanGoalDetails.forEach(intervention => {
                
                if (intervention.interventionStatusId === 'available' || intervention.interventionStatusId === 'inprogress') {

                    exists = true;

                    let metStatus: SelectItem = this.goalStatuses.find(x => x.value === 'met');

                    if (this.isNotEmpty(metStatus)) {
                
                        metStatus.disabled = true;
                    }
                }
            });
        }

        if (!exists) {

            let metStatus: SelectItem = this.goalStatuses.find(x => x.value === 'met');

            if (this.isNotEmpty(metStatus)) {

                metStatus.disabled = false;
            }
        }
    }

    public interventionRowSelected(rowData: any) {
    
        if (this.isNotEmpty(rowData)) {
     
            let carePlanGoalDetail: CarePlanGoalDetailsDto = this.carePlanGoalDetails.find(x => x.interventionId === rowData.data.interventionId);

            if (this.isNotEmpty(carePlanGoalDetail)) {

                this.currentIntervention = carePlanGoalDetail;
                
                if(this.noteEditor) {
                    setTimeout(() => {
                        this.noteEditor.quill.setContents(this.noteEditor.quill.clipboard.convert({html: this.currentIntervention.interventionNote}));
                    }, 200 );
            
                }
                
                this.persistInstructionActivity(this.currentIntervention.instructions);
            }
        }
    }

    private persistInstructionActivity(instructions: InterventionInstructionDto[]) {

        instructions.forEach(instruction => {
           
            if (this.getInstructionSubscription !== null) {
                this.getInstructionSubscription.unsubscribe();
            }
    
            this.getInstructionSubscription = this._goalDetailService.persistInstructionActivity(this.currentIntervention.memberCarePlanGoalBarrierInterventionId, instruction.instructionId).subscribe({
                next : (res) => {
    
                    // do nothing
                },
                error: (err: any) => { 
                    console.error(err);
                }
            });
        });
    }

    public loadEducation(education: InterventionEducationDto) {

        if (this.getEducationSubscription !== null) {
            this.getEducationSubscription.unsubscribe();
        }

        this.getEducationSubscription = this._goalDetailService.downloadEducationDocument(this.currentIntervention.memberCarePlanGoalBarrierInterventionId, education.documentId).subscribe({
            next: (blob: Blob): void => {

                const file = new Blob([blob], {type: 'text/html'});
                const fileURL = URL.createObjectURL(file);
                var newWindow = window.open(fileURL, '_blank');
                URL.revokeObjectURL(fileURL);

                setTimeout(function () {
                    newWindow.document.title = education.documentName;      
                }, 150);
            },
            error: (err: any) => { 
                console.error(err);
            }
        });
    }

    private getCarePlanProfile() {

        let select: string = 'Select...';

        if (this.isNotEmpty(this.getCarePlanProfileSubscription)) {
            this.getCarePlanProfileSubscription.unsubscribe();
        }

        this.getCarePlanProfileSubscription = this._goalDetailService.getCarePlanProfile().subscribe({
            next: (goalDetails) => {

                if (this.isNotEmpty(goalDetails)) {

                    goalDetails.goalStatuses = this.sortList(goalDetails.goalStatuses);
                    goalDetails.goalPriorities = this.sortList(goalDetails.goalPriorities);
                    goalDetails.interventionStatuses = this.sortList(goalDetails.interventionStatuses);

                    for (let item of goalDetails.goalStatuses) {
                        this.goalStatuses.push({ label: item.itemDesc, value: item.itemId, disabled: !item.isActive });
                        
                        if(item.itemType === "Closed"){
                            this.closedGoalStatuses.push({ label: item.itemDesc, value: item.itemId, disabled: !item.isActive });
                        }


                    }

                    for (let item of goalDetails.interventionStatuses) {
                        this.interventionStatuses.push({ label: item.itemDesc, value: item.itemId, disabled: !item.isActive });

                        if(item.itemType === "Closed"){
                            this.closedInterventionStatuses.push({ label: item.itemDesc, value: item.itemId, disabled: !item.isActive });
                        }
                    }

                    this.closedInterventionStatuses.unshift({label: 'Select...', value: null, disabled: false});
                    this.closedInterventionStatus = null;

                    this.memberPriorities.push({ label: select, value: null });
                    for (let item of goalDetails.goalPriorities) {
                        if (item.itemType === 'member') {
                            this.memberPriorities.push({ label: item.itemDesc, value: item.itemId, disabled: !item.isActive });
                        }
                    }

                    this.careGiverPriorities.push({ label: select, value: null });
                    for (let item of goalDetails.goalPriorities) {
                        if (item.itemType === 'caregiver') {
                            this.careGiverPriorities.push({ label: item.itemDesc, value: item.itemId, disabled: !item.isActive });
                        }
                    }

                    this.careManagerPriorities.push({ label: select, value: null });
                    for (let item of goalDetails.goalPriorities) {
                        if (item.itemType === 'caremanager') {
                            this.careManagerPriorities.push({ label: item.itemDesc, value: item.itemId, disabled: !item.isActive });
                        }
                    }
                }

                this.getGoalInterventions();
            },
            error: (err: any) => { 
                console.error(err);
            }
        });
    }

    public getNumBatchColumns(): number {
        return this.isEmpty(this.interventionBatchColumns) ? 0 : this.interventionBatchColumns.length;
    }

    public getTableHeight(): string {
        return '600px';
    }

    public filterTable($event: any, interventionTable: Table, columnField: any) {
        interventionTable.filter($event.target.value, columnField, 'contains');
    }

    public customSort($event: { field: string; data: any[]; order: number; }) {

        let isDate: boolean = false;

        if (this.isNotEmpty($event) && this.isNotEmpty($event.field) && $event.field.toLowerCase().indexOf('date') > -1) {
            
            isDate = true;
        }

        $event.data.sort((data1, data2) => {
    
            let value1 = null;
            let value2 = null;
    
            if ( isDate) {
    
                let val1 = data1[$event.field];
                let val2 = data2[$event.field];
                value1 = new Date(val1);
                value2 = new Date(val2);
    
            } else {
    
                value1 = data1[$event.field];
                value2 = data2[$event.field];
            }
    
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ($event.order * result);
        });
    }

    private sortList(list: CarePlanProfileItemDto[]): CarePlanProfileItemDto[] {

        return list.sort((a: CarePlanProfileItemDto, b: CarePlanProfileItemDto) => {

            if (a.itemOrder < b.itemOrder) {
                return -1;
            } else if (a.itemOrder > b.itemOrder) {
                return 1;
            }

            return 0;
        });
    }

    public clearIntervention() {

        this.currentIntervention = null;
    }

    private initializeColumns() {

        this.interventionColumns = [];
        this.interventionBatchColumns = [];

        let tableColumnColor: TableColumnDto = new TableColumnDto();
        tableColumnColor.field = 'color';
        tableColumnColor.header = '';
        tableColumnColor.width = '1em';
        tableColumnColor.align = 'center';

        let tableColumnInterventionName: TableColumnDto = new TableColumnDto();
        tableColumnInterventionName.field = 'interventionName';
        tableColumnInterventionName.header = '';
        tableColumnInterventionName.width = '80%';
        tableColumnInterventionName.align = 'left';
        
        let tableColumnActivityDate: TableColumnDto = new TableColumnDto();
        tableColumnActivityDate.field = 'interventionActivityDate';
        tableColumnActivityDate.header = '';
        tableColumnActivityDate.width = '15%';
        tableColumnActivityDate.align = 'center';
        
        this.interventionColumns.push(tableColumnColor);
        this.interventionColumns.push(tableColumnInterventionName);
        this.interventionColumns.push(tableColumnActivityDate);

        let tableBatchColumnColor: TableColumnDto = new TableColumnDto();
        tableBatchColumnColor.field = 'color';
        tableBatchColumnColor.header = '';
        tableBatchColumnColor.width = '2%';
        tableBatchColumnColor.align = 'center';

        let tableBatchColumnInterventionName: TableColumnDto = new TableColumnDto();
        tableBatchColumnInterventionName.field = 'interventionName';
        tableBatchColumnInterventionName.header = 'Intervention';
        tableBatchColumnInterventionName.width = '75%';
        tableBatchColumnInterventionName.align = 'left';

        let tableBatchColumnInterventionDescription: TableColumnDto = new TableColumnDto();
        tableBatchColumnInterventionDescription.field = 'interventionStatusDescription';
        tableBatchColumnInterventionDescription.header = 'Status';
        tableBatchColumnInterventionDescription.width = '11.5%';
        tableBatchColumnInterventionDescription.align = 'center';
                
        let tableBatchColumnActions: TableColumnDto = new TableColumnDto();
        tableBatchColumnActions.field = 'update';
        tableBatchColumnActions.header = 'Update';
        tableBatchColumnActions.width = '11.5%';
        tableBatchColumnActions.align = 'center';

        this.interventionBatchColumns.push(tableBatchColumnColor);
        this.interventionBatchColumns.push(tableBatchColumnInterventionName);
        this.interventionBatchColumns.push(tableBatchColumnInterventionDescription);
        this.interventionBatchColumns.push(tableBatchColumnActions);
     
    }

    onInit() {
        setTimeout(() => {
            this.noteEditor.quill.setContents(this.noteEditor.quill.clipboard.convert({html: this.currentIntervention.interventionNote}));
        }, 200);
    }

    ngOnDestroy(): void {

        if (this.getSubscription !== null) {
            this.getSubscription.unsubscribe();
        }

        if (this.getCarePlanProfileSubscription !== null) {
            this.getCarePlanProfileSubscription.unsubscribe();
        }

        if (this.getGoalDetailDataSubscription !== null) {
            this.getGoalDetailDataSubscription.unsubscribe();
        }

        if (this.saveSubscription !== null) {
            this.saveSubscription.unsubscribe();
        }

        if (this.getEducationSubscription !== null) {
            this.getEducationSubscription.unsubscribe();
        }

        if (this.getInstructionSubscription !== null) {
            this.getInstructionSubscription.unsubscribe();
        }
    }
}
