
export class SampleDto {
    assessmentName: string;
    dateCreated: string;
    actualAssessmentDate: string;
    deliveryMode: string;
    createdBy: string;
    status: string;
    dateCompletedBy: string;
    completedBy: string;
    description: string;
    assessmentId: string;
    contentUId: string;
    assesmentType: string;
    assessmentContentType: string;
    assessmentAlgorithmId: string;
    allowAction?: boolean = false;
    programDescription: string;
}

// rename this dto to match your model
export class SaveDto {
    id: string;
    val1: string;
    val2: string;    
}