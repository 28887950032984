import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthUser } from 'src/app/auth/models/authuser';
import {PortalUser} from 'src/app/auth/models/portaluser'

@Injectable({
  providedIn: 'root'
})
export class SignupService {
 
  constructor() { }

  setPortalUser(user: PortalUser) {
    localStorage.removeItem('portalUser')
    localStorage.setItem('portalUser',JSON.stringify(user));
  }
  getPortalUser(){
    var data= JSON.parse(localStorage.getItem('portalUser'));
    return data==null?new PortalUser:data;
   }
   setDomainUser(user: PortalUser) {
    localStorage.removeItem('domainUser')
    localStorage.setItem('domainUser',JSON.stringify(user));
  }
  getDomainUser(){
   var data= JSON.parse(localStorage.getItem('domainUser'));
    return data==null?new PortalUser:data;
   }
}
