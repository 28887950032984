import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Constant } from 'src/app/auth/utils/constant';
import { AppConfig } from 'src/app/auth/models/app-config';
import { AuthService } from 'src/app/auth/services/auth/auth.service';

import { AssessmentResponseDto } from './assessment-print.dtos';

@Injectable()
export class AssessmentPrintService {

    private getMemberHealthAssessmentInfoUrl: string = '/MemberHealthAssessment/GetMemberHealthAssessmentResponses?id={id}';    

    private headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {

        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');
    }

    public getAssessmentResponses(memberAssessmentId: string): Observable<AssessmentResponseDto[]> {

        let url: string = this._appConfig.memberManagerApiBaseUrl + this.getMemberHealthAssessmentInfoUrl
            .replace('{id}', memberAssessmentId);

        return this._http.get<AssessmentResponseDto[]>(url, {headers: this.headers});
    }
}
