import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule }                 from '@angular/common/http';
import { ToolbarModule }                    from 'primeng/toolbar';
import { SharedModule }                     from 'primeng/api';
import { MessagesModule }                   from 'primeng/messages';
import { ConfirmDialogModule }              from 'primeng/confirmdialog';
import { InputTextareaModule }              from 'primeng/inputtextarea';
import { ButtonModule }                     from 'primeng/button';
import { ScrollPanelModule }                     from 'primeng/scrollpanel';

import { AssessmentPrintComponent }         from './assessment-print.component'
import { AssessmentPrintService }           from './assessment-print.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    ToolbarModule, ConfirmDialogModule, SharedModule, InputTextareaModule, ButtonModule, MessagesModule, ScrollPanelModule
  ],
  declarations: [AssessmentPrintComponent],
  providers:    [AssessmentPrintService],
  exports:      [AssessmentPrintComponent]
})
export class AssessmentPrintModule { }
