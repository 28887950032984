import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { AuthUser } from '../../models/authuser';
import { PortalUserService } from '../../services/portaluser.service';
import { PortalUser } from '../../models/portaluser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  passType: string = "password";
  confPassType: string = "password";
  isTextPass: boolean = false;
  isTextConfPass: boolean = false;
  pasEyeIcon: string = "fa-eye-slash";
  confPassEyeIcon: string = "fa-eye-slash";
  isError: boolean = false;
  Error: any;
  user: AuthUser;
  portalUser: PortalUser;
  inviteId: string;
  constructor(private router: Router, private authService: AuthService, private actRoute: ActivatedRoute,
    private portalUserService: PortalUserService, private toster: ToastrService) {
    this.user = new AuthUser();
    this.portalUser = new PortalUser();
  }

  ngOnInit(): void {
    //from email we are sedding this url after usr clicked we are redirecting reset 
    //http://localhost:4200/reset/id?4ab45d61-1ebc-457f-b7aa-a0e0505939d8=
    this.toster.clear();
    this.actRoute.paramMap.subscribe((params) => {
      this.inviteId = params.get('id')!;
    });

    if (this.inviteId.length > 0) {
      //#region Reset Aut User
      this.authService.AuthByML(this.inviteId).subscribe({
        next: (v) => {
          if (v != null) {
            this.getPoratlUser(v);
          }
          else {
            alert("Not a valid user")
            //this.toster.error("Not a valid user");
            this.router.navigate(['login'])
          }
        },
        error: (e) => {
          alert("Not a valid user")
          ////this.toster.error("Not a valid user");
          this.router.navigate(['login'])
        }
      })
      //#endregion
    }
    else {
      alert("Not a valid user")
      //this.toster.error("Not a valid user");
    }
  }

  onSubmit() {
    //#region Reset Aut User
    this.toster.clear();
    this.authService.ResetPassword(this.user).subscribe({
      next: (v) => {
        debugger
        if (v != null) {
          this.getPoratlUser(v);
          this.router.navigate(['home'])
        }
        else { 
          this.isError=true;
          this.Error=v;
          //this.toster.error(v)
         }
      },
      error: (e) =>  {this.Error=e.error;this.isError=true;} //this.toster.error(e.error)
    })
    //#endregion
  }

  getPoratlUser(v: any) {
    debugger;
    localStorage.setItem('authToken', v)
    var token = JSON.parse(atob(v.split('.')[1]))
    var result = Object.keys(token).map((key) => [token[key]]);
    localStorage.setItem('authTokenDetail', result.toString())
    this.portalUserService.getPortalUserById(result[0]).subscribe(
      {
        next: (data: PortalUser) => {
          this.portalUser = data;
          this.user.userid=this.portalUser.id;
          localStorage.setItem('portalUser', JSON.stringify(data))
        },
        error: (e) => {this.Error=e.error;this.isError=true;} //this.toster.error(e.error)
      })
  }

  hideShowPass(): void {
    this.isTextPass = !this.isTextPass;
    this.pasEyeIcon = this.isTextPass ? "fa-eye" : "fa-eye-slash";
    this.passType = this.isTextPass ? "text" : "password";
  }

  hideShowConfPass(): void {
    this.isTextConfPass = !this.isTextConfPass;
    this.confPassEyeIcon = this.isTextConfPass ? "fa-eye" : "fa-eye-slash";
    this.confPassType = this.isTextConfPass ? "text" : "password";
  }

}
