import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';

import { WebsiteVisitSectionComponent } from './website-visit-section.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TableModule,
    InputTextModule
  ],
  declarations: [WebsiteVisitSectionComponent],
  providers: [WebsiteVisitSectionComponent],
  exports: [WebsiteVisitSectionComponent]  
})
export class WebsiteVisitSectionModule {}
