<section class="vh-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 col-lg-6 px-0 col-xl-7">
                <div class="image">
                    <img src="../assets/images/background_Image_5.png" alt="Login image" class="w-100 vh-100"
                        style="object-fit: cover; object-position: left;">
                    <div class="hero-section">
                        <p class="welcome text-center">Welcome
                        <p>
                    </div>
                    <div class="hero-info">
                        <p> to the Agent Portal </p>
                    </div>
                </div>
            </div>
            <div class="login-details col-md-8 col-lg-6 col-xl-5 mt-4">
                <div class="text-center" *ngIf="isError">
                    <h6 style="color: red;" class="error">
                      {{error}}
                    </h6> 
                  </div>
                <form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)">
                    <div class="lead d-flex flex-row align-items-center justify-content-center text-center ">
                        <p class="mb-2 me-3">Sign Up</p>
                    </div>
                    <span>
                        <h5 class="fw-bold">Acknowledgement</h5>
                    </span>
                    <span class="small-text"><p> to the Agent Portal </p></span>

                    <!-- User Details -->
                    <div class="form-outline text-center mt-4 mb-4">
                        <input type="data" id="" name="Signature" datetime="MM-dd-yyyy"  [(ngModel)]="Signature" class="form-control form-control-lg" placeholder="Electronic Signature Date" disabled />
                    </div>

                    <div class="form-check mb-3">
                        <!-- <input type="radio" class="form-check-input" name="validationDefaultRadio" id="validationDefaultRadio1" required> -->
                        <input type="radio" class="form-check-input" id="Agree" value="Agree" name="agreement" [ngModel]="agrees" required #agreement="ngModel" (change)="changeAgrrement($event)"> 
                        <label class="form-check-label" for="Agree">Agree</label>
                      </div>
                      <div class="form-check mb-5">
                        <!-- <input type="radio" class="form-check-input" name="validationDefaultRadio" id="validationDefaultRadio2" required> -->
                        <input type="radio" class="form-check-input" id="DisAgree" value="DisAgree" name="agreement" [ngModel]="agrees" required #agreement="ngModel"(change)="changeAgrrement($event)"> 
                        <label class="form-check-label" for="DisAgree">Disagree</label>
                      </div>
                      <div *ngIf="agreement.invalid && f.submitted" [ngClass] = "'error'" style="color: red;margin-top: -40px;"> 
                        Acknowledgement required. 
                      </div>
                      
                      <div class="text-center pt-1 mt-4 mb-4 pb-1">
                        <button type="submit" class="btn btn-lg btn-block log-in" [disabled]="isDisable" >Complete</button>
                      </div>

              </form>
            </div>
        
        </div>
    </div>
</section>