<div id="careplanSectionBodyDiv">

    <ng-container *ngIf="!showGoalDetailPanel">
        <div class="row">
            
            <div class="p-grid" style="padding-left: 0; padding-bottom: 1em;">
                <div class="p-col-11" style="padding-top: 0">
                    <label for='wholePersonCareGoalEdit' class="whole-person-care-goal-label">Whole Person Care Goal</label>
                    <p-editor [(ngModel)]="careGoalText" [readonly]="careGoalTextIsReadOnly">
                        <p-header>                        
                            <span class="ql-formats">
                                <button class="ql-bold" aria-label="Bold"></button>
                                <button class="ql-italic" aria-label="Italic"></button>
                                <button class="ql-underline" aria-label="Underline"></button>
                            </span>
                            
                            <span class="ql-formats">
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                            </span>
                        
                            <span class="ql-formats">
                                <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
                                <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
                            </span>
                        </p-header>
                    </p-editor>
                </div>
                
                <div class="p-col-1" style="padding-top: 0">
                    <span style="display: block; margin-top: 1.6em;">
                        <button class="btn button-1" type="button" *ngIf="!careGoalTextIsReadOnly" (click)="saveCareGoalText()" style="width: 8em;">Save</button>
                        <button class="btn button-1" type="button" *ngIf="!careGoalTextIsReadOnly" (click)="cancelEditCareGoalText()" style="margin-top: .5em; width: 8em;">Cancel</button>
                        <button class="btn button-1" type="button" *ngIf="careGoalTextIsReadOnly" (click)="editCareGoalText()" style="width: 8em;">Edit</button>
                        <button class="btn button-1" type="button" [disabled]="allGoalsAreClosed()" (click)="openCloseGoalBatchDialog()" style="margin-top: 5px;width: 8em">Close Goals</button>
                    </span>
                </div>
            </div>

            <div style="border: 1px solid #d0d0c8; padding: 0">
                
                <p-table id="goalsTable" #goalsTable [columns]="goalTableColumns" [value]="goalsList" (sortFunction)="customSort($event)" 
                    [customSort]="true" sortMode="single" [scrollable]="true" [resizableColumns]="true" [scrollHeight]="getTableHeight()" 
                    styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field"  [style.width]="col.width" 
                                style="text-align: center !important; white-space: break-spaces;">
                                
                                {{col.header}}
                                <p-sortIcon  *ngIf="col.field !== 'action' && col.field !== 'color'" [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>

                        <tr>
                            <th *ngFor="let col of columns" style="flex: inherit" [style.width]="col.width"
                                [ngClass]="{'axis-align-center':(col.align==='center'), 'axis-align-left':(col.align!=='center')}">

                                <input  pInputText type="text" style="width:100%" *ngIf="col.field !== 'action' && col.field !== 'color'" 
                                    (input)="filterTable($event, goalsTable, col.field)">
                            
                                <span *ngIf="col.field === 'action'">&nbsp;</span>
                            </th>
                        </tr>  
                    </ng-template>

                    <ng-template pTemplate="body" let-columns="columns" let-rowData>
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" style="flex: inherit; white-space: break-spaces;" [style.width]="col.width" 
                                [ngClass]="{'table-cell-align-center':(col.align==='center'), 'table-cell-align-left':(col.align!=='center')}">

                                <div *ngIf="col.field === 'color'" 
                                    [ngClass]="{'goal-status-default':(rowData['goalStatusId']==='available'), 
                                                'goal-status-green':(rowData['goalStatusId']==='met'),
                                                'goal-status-yellow':(rowData['goalStatusId']==='inprogress'),
                                                'goal-status-grey':(rowData['goalStatusId'] !== 'inprogress') && rowData['goalStatusId'] !== 'available' && rowData['goalStatusId'] !== 'met'}">
                                    &nbsp;
                                </div>
                                
                                <div *ngIf="col.field === 'targetDate' && (rowData['goalStatusId']==='available' || rowData['goalStatusId']==='inprogress')" 
                                    [ngClass]="{'goal-target-yellow':(rowData['targetDateClass']==='goal-target-yellow'), 
                                                'goal-target-red':(rowData['targetDateClass']==='goal-target-red')}">

                                    <span [pTooltip]="rowData['targetDateDueDays']" tooltipPosition="top">{{rowData[col.field]}}</span>
                                </div>

                                <div *ngIf="col.field === 'targetDate' && rowData['goalStatusId'] !== 'available' && rowData['goalStatusId'] !== 'inprogress'">
                                    <span>{{rowData[col.field]}}</span>
                                </div>

                                <div *ngIf="col.field !== 'action' && col.field !== 'color' && col.field !== 'targetDate'" >{{rowData[col.field]}}</div>
                                <div *ngIf="col.field === 'action'">
                                    <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text" 
                                        (click)="editGoal(rowData)">
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td id="noGoalsFound" [attr.colspan]="getNumColumns()" style="text-align: center !important">
                                {{noRecordsMessage}}
                            </td>
                        </tr>
                    </ng-template>
                    
                </p-table>
            </div>
        </div>
        <p-dialog [(visible)]="displayCloseGoalBatch" header="{{messageCloseGoals}}" id="closeGoalDialog" [modal]="true" [style]="{'bottom': '25px', 'width': '900px'}" (onHide)="closeGoalBatchDialog(goalsBatchTable)">
            <div class="p-col-6" style="padding-top: 5px; padding-bottom: 5px;">
              <label for='closedGoalStatusDropdown' class="goal-dd-label">Closed Goal Status</label>
              <p-dropdown id="closedGoalStatusDropdown" appendTo="body" [options]="closedGoalStatuses" placeholder="Select..." [(ngModel)]="closedGoalDropdownStatus" [style]="{'width': '100%'}" scrollHeight="800px"></p-dropdown>
            </div>
          
            <div class="p-col-12">
              <p-table id="goalsBatchTable" #goalsBatchTable [columns]="goalBatchTableColumns" [value]="openGoalsList" (sortFunction)="customSort($event)" [customSort]="true" sortMode="single" [scrollable]="true" [resizableColumns]="true" [scrollHeight]="getTableHeight()" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field" [style.width]="col.width" style="text-align: center !important; white-space: break-spaces;">
                      {{col.header}}
                      <p-sortIcon *ngIf="col.field !== 'update' && col.field !== 'action' && col.field !== 'color'" [field]="col.field"></p-sortIcon>
                    </th>
                  </tr>
                  <tr>
                    <th *ngFor="let col of columns" style="flex: inherit" [style.width]="col.width" [ngClass]="{'axis-align-center': (col.align === 'center'), 'axis-align-left': (col.align !== 'center')}">
                      <input pInputText type="text" [(ngModel)]="clearText" style="width: 100%;" *ngIf="col.field !== 'goalStatusDescription' && col.field !== 'update' && col.field !== 'color'" (input)="filterTable($event, goalsBatchTable, col.field)">
                      <span *ngIf="col.field === 'action'">&nbsp;</span>
                      <p-checkbox [binary]="true" id="selectAllGoals" *ngIf="col.field === 'update'" style="padding-left: 2em;" (onChange)="selectAllOpenGoals($event)" [(ngModel)]="selectAllGoals"></p-checkbox>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-columns="columns" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" style="flex: inherit; white-space: break-spaces;" [style.width]="col.width" [ngClass]="{'table-cell-align-center': (col.align === 'center'), 'table-cell-align-left': (col.align !== 'center')}">
                      <div *ngIf="col.field === 'color'" [ngClass]="{'goal-status-default': (rowData['goalStatusId'] === 'available'), 'goal-status-green': (rowData['goalStatusId'] === 'met'), 'goal-status-yellow': (rowData['goalStatusId'] === 'inprogress'), 'goal-status-grey': (rowData['goalStatusId'] !== 'inprogress' && rowData['goalStatusId'] !== 'available' && rowData['goalStatusId'] !== 'met')}">
                        &nbsp;
                      </div>
                      <div *ngIf="col.field === 'goalDisplayText'">{{rowData[col.field]}}</div>
                      <div *ngIf="col.field !== 'update' && col.field !== 'color' && col.field !== 'targetDate' && col.field !== 'goalDisplayText'" style="text-align: center;">{{rowData[col.field]}}</div>
                      <div *ngIf="col.field === 'update'" style="padding-left: 2em;">
                        <p-checkbox [(ngModel)]="rowData.update" [binary]="true" name="updateGoalCheckbox_{{rowData.goalId}}" (onChange)="childUpdateGoalCheckbox()"></p-checkbox>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr>
                    <td id="noGoalsFound" [attr.colspan]="getNumBatchColumns()" style="text-align: center !important">
                      {{noRecordsMessage}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="p-col-12" style="display: flex; justify-content: center; padding-top: 1em;">
              <button pButton type="button" id="closeGoalBatchButton" (click)="closeGoalBatchDialog(goalsBatchTable)" label="Close"></button>
              <button pButton type="button" [disabled]="disableUpdateGoalsButton()" id="updateGoalBatchButton" (click)="closeGoalBatch()" label="Update Goals" style="margin-left: 1em;"></button>
            </div>
          </p-dialog>
          
    </ng-container>

    <ng-container *ngIf="showGoalDetailPanel">
        <div class="p-grid" style="padding: 0">
        
            <div class="p-col-12" style="padding: 0">
                <goal-detail
                    (goalCanceledEvent) = "cancelGoalEdit($event)"
                    (goalSavedEvent) = "saveGoalEdit($event)"
                    (goalLoadedEvent) = "goalLoadedEvent($event)">
                </goal-detail>
            </div>
        </div>
    </ng-container>
</div>
