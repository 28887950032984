import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import {AuthUser} from '../../models/authuser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-up-forgot-password',
  templateUrl: './sign-up-forgot-password.component.html',
  styleUrls: ['./sign-up-forgot-password.component.scss']
})
export class SignUpForgotPasswordComponent implements OnInit {
  isDisplay:boolean=false;
  isError:boolean=false;
  user:AuthUser;
  constructor(private _router: Router,private authService:AuthService,private router: Router,private toastr: ToastrService,) {
    this.user=new AuthUser();
  }

  ngOnInit(): void {
    this.isError=false
  }
  onSubmit() {
    this.toastr.clear();
    this.authService.ForgotPassword(this.user).subscribe({
      next:(v)=>{
          if (v != null) {
            //this.toastr.success("Password Reset Details sent to Email")
            this.isDisplay=true; 
          }
        },
         error: (e) =>this.isError=true //this.toastr.error(e.error)
        })
  }
}
