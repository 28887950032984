<form #careGiverForm="ngForm" (ngSubmit)="saveCaregiverDetails(careGiverForm)">
    <div class="row">
        <p-table #dtCaregiver [value]="careGiverDetails" dataKey="contactId" [responsive]="true"
        [scrollable]="true" editMode="row"  [tableStyle]="{'min-width': '50rem'}" 
         [resizableColumns]="true"  [scrollHeight]="getTableHeight()"> 
          <ng-template pTemplate="header" let-columns>
              <tr>
                <th class="wd-8">First Name <span class="text-danger">*</span></th>
                <th class="wd-8">Last Name <span class="text-danger">*</span></th>
                <th class="wd-20">Relationship <span class="text-danger">*</span></th>
                <th class="wd-15">Email Address</th>
                <th class="wd-13">Phone Number</th>
                <th class="wd-5">Primary <span class="text-danger">*</span></th>
                <th class="wd-9">Authorization</th>                
                <th class="wd-5">Source</th>
                <th class="wd-10">Notes</th>
                <th class="wd-17"></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
              <tr  [pEditableRow]="item" class="vert-top">
                  <td style="white-space: break-spaces">
                      <p-cellEditor>
                          <ng-template  pTemplate="input">
                            <input pInputText class="p-inputtext"  name="firstname{{i}}" pattern="[a-zA-Z'_\-][a-zA-Z'_\- ]+"
                            [(ngModel)]="item.firstName" #firstname="ngModel" required>
                            <div *ngIf="saveClicked && firstname.invalid " class="text-danger">
                                <div *ngIf="firstname.errors['required']">Please enter first name</div>
                                <div *ngIf="firstname.errors['maxlength']">you can enter only 100 characters</div>
                                <div *ngIf="firstname.errors['pattern']">Invalid first name</div>
                            </div>
                          </ng-template>
                          <ng-template  pTemplate="output">
                          <p pTooltip="{{item.firstName}}" tooltipPosition="bottom"  class="word-break">  {{item.firstName}} </p>
                          </ng-template>
                      </p-cellEditor>
                  </td>
                  <td style="white-space: break-spaces">
                      <p-cellEditor>
                          <ng-template  pTemplate="input">
                            <input pInputText class="p-inputtext"  name="lastname{{i}}" pattern="[a-zA-Z'_\-][a-zA-Z'_\- ]+"
                            [(ngModel)]="item.lastName" #lastname="ngModel" maxlength="100" required>
                            <div *ngIf="saveClicked && lastname.invalid " class="text-danger">
                                <div *ngIf="lastname.errors['required']">Please enter last name</div>
                                <div *ngIf="lastname.errors['maxlength']">you can enter only 100 characters</div>
                                <div *ngIf="lastname.errors['pattern']">Invalid last name</div>
                            </div>
                            </ng-template>
                          <ng-template  pTemplate="output">
                          <p pTooltip="{{item.lastName}}" tooltipPosition="bottom"  class="word-break">  {{item.lastName}} </p>
                          </ng-template>
                      </p-cellEditor>
                  </td>
                  <td style="white-space: break-spaces">
                    <p-cellEditor>
                      <ng-template  pTemplate="input">
                        <p-dropdown name="relationship-{{i}}" optionValue="id" optionLabel="name"  [options]="relationShipList" appendTo="body" 
                        [(ngModel)]="item.relationshipId" #relationship="ngModel" placeholder="Select" [style]="{'width':'70%'}" [required]="true">
                    </p-dropdown>
                          <div *ngIf="saveClicked && relationship.invalid" class="text-danger">
                            <div *ngIf="relationship.errors?.['required']">Relationship is mandatory</div>
                          </div>
                    </ng-template>
                    <ng-template  pTemplate="output">
                    <p>{{item.relationshipName}}</p>
                    </ng-template>
                    </p-cellEditor>
                  </td>
                  <td >
                    <p-cellEditor>
                      <ng-template  pTemplate="input">
                        <ng-container *ngIf="item.add" style="white-space: break-spaces">
                            <input pInputText class="p-inputtext" name="emailaddress{{i}}"
                            [(ngModel)]="item.firstEmailAddress" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-za-z]{2,4}$"
                            #emailaddress="ngModel">
                            <div *ngIf="saveClicked && emailaddress.invalid " class="text-danger">
                                <div *ngIf="emailaddress.errors['pattern']">Please enter valid email</div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!item.add">
                          <p  pTooltip="{{item.firstEmailAddress }}" tooltipPosition="bottom"  class="word-break" *ngIf="isEmailFieldDisable">{{item.firstEmailAddress}}</p>

                          <ng-container *ngIf="!isEmailFieldDisable">
                          <input  pInputText class="p-inputtext" name="emailaddress{{i}}"
                          [(ngModel)]="item.firstEmailAddress" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-za-z]{2,4}$"
                          #emailaddress="ngModel" >

                          <div *ngIf="saveClicked && emailaddress.invalid " class="text-danger">
                              <div *ngIf="emailaddress.errors['pattern']">Please enter valid email</div>
                          </div>
                        </ng-container>

                        </ng-container>
                    </ng-template>
                    <ng-template  pTemplate="output">
                    <p  pTooltip="{{item.firstEmailAddress }}" tooltipPosition="bottom"  class="word-break">{{item.firstEmailAddress }}</p>
                    </ng-template>
                    </p-cellEditor>
                  </td>
                  <td style="white-space: nowrap">
                    <p-cellEditor>
                      <ng-template  pTemplate="input">
                        <p-inputMask  mask="(999) 999-9999" placeholder="1234567890"
                        name="phonenumber{{i}}" #phonenumber="ngModel" [(ngModel)]="item.firstPhoneNumber">
                      </p-inputMask>
                    <div *ngIf="saveClicked && phonenumber.invalid " class="text-danger">
                        <!-- <div *ngIf="phonenumber.errors['required']">Please enter the phone number</div> -->
                        <div *ngIf="phonenumber.errors['pattern']">Enter valid phone number</div>
                    </div>
                    </ng-template>
                    <ng-template  pTemplate="output">
                    <p>{{item.firstPhoneNumber ? getMaskPhoneNumber(item.firstPhoneNumber) : item.firstPhoneNumber}}</p>
                    </ng-template>
                    </p-cellEditor>
                  </td>
                  <td style="white-space: break-spaces">
                    <p-cellEditor>
                      <ng-template  pTemplate="input">
                        <p-radioButton [inputId]="item" 
                        id="PrimaryPhone{{i}}"
                        (click)="checkConfirmPrimaryPhone(item, i)"
                          name="isPrimaryPhone" [value]="item" 
                        [(ngModel)]="selectedCaregiverPrimary"
                        ></p-radioButton>
                    </ng-template>
                    <ng-template  pTemplate="output">
                      <p>
                      <p-radioButton [inputId]="item"  [value]="item" 
                        [(ngModel)]="selectedCaregiverPrimary"  [disabled]="true" name="isViewPrimaryPhone"
                        ></p-radioButton>
                        </p>
                     </ng-template>
                    </p-cellEditor>
                  </td>
                  <td style="white-space: break-spaces">
                    <p-cellEditor>
                      <ng-template  pTemplate="input">
                        
                        <p-checkbox [(ngModel)]="item.authorization"  id="authorization{{i}}" [binary]="true"  name="careGiverAuthorization{{i}}"   >
						            </p-checkbox>                      
                      </ng-template>
                      <ng-template  pTemplate="output">                      
                       <p>
                        <p-checkbox [(ngModel)]="item.authorization" id="authorization{{i}}" [binary]="true" [disabled]="!item.isEdit" name="careGiverAuthorization{{i}}">
                         </p-checkbox>
                       </p>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template  pTemplate="input">
                        <p>{{item.rowSource}}</p>
                    </ng-template>
                    <ng-template  pTemplate="output">
                      <p>{{item.rowSource}}</p>
                    </ng-template>
                    </p-cellEditor>
                  </td>
                  <td style="white-space: break-spaces">
                    <p-cellEditor>
                        <ng-template  pTemplate="input">
                          <textarea pInputTextarea  class="p-inputtext"  name="notes{{i}}" 
                          [(ngModel)]="item.notes" #notes="ngModel" maxlength="100"></textarea>
                          <div *ngIf="saveClicked && notes.invalid " class="text-danger">
                             
                              <div *ngIf="notes.errors['maxlength']">you can enter only 100 characters</div>
                              
                          </div>
                          </ng-template>
                        <ng-template  pTemplate="output">
                        <p pTooltip="{{item.notes}}" tooltipPosition="bottom"  class="word-break">  {{item.notes}} </p>
                        </ng-template>
                    </p-cellEditor>
                </td>
                  <td>
                      <div class="flex align-items-center justify-content-center gap-2">
                        <button *ngIf="!item.isEdit && !item.add" [disabled]="isButtonDisabled" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="editRow(item)" class="p-button-rounded p-button-text"></button>
                        <button *ngIf="!item.isEdit && !item.add" [disabled]="isButtonDisabled" pButton pRipple type="button"  icon="pi pi-trash" (click)="deleteConfirm('top',item)" class="p-button-rounded p-button-text mr-7"></button>
                        <button *ngIf="item.isEdit && !item.add" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="cancelEdit(i, item)" class="p-button-rounded p-button-text"></button>
                        <button *ngIf="item.add" pButton pRipple type="button" icon="pi pi-times" (click)="removeRow(i)" class="p-button-rounded p-button-text"></button>
                        </div>

                       
                  </td>
              </tr>            
          </ng-template>
      </p-table>
      
    </div>
    <div class="item">
        <div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center mt-4">
                <button class="btn button-1" type="button" (click)="addNewCareGiver()">Add Caregiver</button>
                <button class="btn button-2" type="submit" *ngIf="isShowSaveButton">Save</button>
            </div>
        </div>
    </div>
</form>


<p-confirmDialog [style]="{ width: '40vw' }" key="positionDialog" [position]="position"
    rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<p-dialog header="Alert" [(visible)]="display" [style]="{width: '50vw'}" >
    <p>{{alertMsg}}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="display=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
