<!-- Type 1 log spinner -->
<!-- <div *ngIf="this.loader.getLoading()" class="cssload-container">
    <div class="cssload-speeding-wheel" ba></div>
</div> -->

<!-- Type 2 log spinner -->

<div *ngIf="this.loader.getLoading()" class="cssload-container">
    <div class="ring">
        <img src="../../assets/images/sagilityLogo.png" alt="" style="height: 30px; width:30px;">
        <span></span>
      </div>
</div> 