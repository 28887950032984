import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentifyingInformationRes } from '../../auth/models/provider-update';
import { ViewportScroller } from '@angular/common';

import {ConfirmationService} from 'primeng/api';

import { ContactSectionComponent } from '../contact-section/contact-section.component';
import { AssessmentSectionComponent } from '../assessment-section/assessment-section.component';
import { CaregiverSectionComponent } from '../caregiver-section/caregiver-section.component';
import { CareplanSectionComponent } from '../careplan-section/careplan-section.component';
import { HeaderSectionComponent } from '../header-section/header-section.component';
import { OutreachSectionComponent } from '../outreach-section/outreach-section.component';
import { PopulationSectionComponent } from '../population-section/population-section.component';
import { SecureMessageSectionComponent } from '../secure-message-section/secure-message-section.component';
import { WebsiteVisitSectionComponent } from '../website-visit-section/website-visit-section.component';
import { DocumentsSectionComponent } from '../documents-section/documents-section.component';
import { BenefitsSectionComponent } from '../benefits-section/benefits-section.component';
import { TaskSectionComponent } from '../tasks-section/task-section.component';
import { BaseComponent } from 'src/app/member/common/base.component';

@Component({
    selector: 'member-page',
    templateUrl: 'member-page.component.html',
    styleUrls: ['member-page.component.scss'],
    providers: [
        ContactSectionComponent,
        AssessmentSectionComponent,
        CaregiverSectionComponent,
        CareplanSectionComponent,
        HeaderSectionComponent,
        OutreachSectionComponent,
        PopulationSectionComponent,
        SecureMessageSectionComponent,
        WebsiteVisitSectionComponent,
        DocumentsSectionComponent,
        TaskSectionComponent,
        BenefitsSectionComponent 
    ],
})
export class MemberPageComponent extends BaseComponent implements OnInit, OnDestroy
{
    @ViewChild(AssessmentSectionComponent, { static: false }) assessmentSectionComponent: AssessmentSectionComponent;
    @ViewChild(CaregiverSectionComponent, { static: false }) caregiverSectionComponent: CaregiverSectionComponent;
    @ViewChild(CareplanSectionComponent, { static: false }) careplanSectionComponent: CareplanSectionComponent;
    @ViewChild(ContactSectionComponent, { static: false }) contactSectionComponent: ContactSectionComponent;
    @ViewChild(HeaderSectionComponent, { static: false }) headerSectionComponent: HeaderSectionComponent;
    @ViewChild(OutreachSectionComponent, { static: false }) outreachSectionComponent: OutreachSectionComponent;
    @ViewChild(PopulationSectionComponent, { static: false }) populationSectionComponent: PopulationSectionComponent;
    @ViewChild(SecureMessageSectionComponent, { static: false }) secureMessageSectionComponent: SecureMessageSectionComponent;
    @ViewChild(WebsiteVisitSectionComponent, { static: false }) websiteLinkSectionComponent: WebsiteVisitSectionComponent;
    @ViewChild(DocumentsSectionComponent, { static: false }) documentsSectionComponent: DocumentsSectionComponent;
    @ViewChild(TaskSectionComponent, { static: false }) taskSectionComponent: TaskSectionComponent;
    @ViewChild(BenefitsSectionComponent, { static: false }) benefitsSectionComponent: BenefitsSectionComponent;

    @ViewChild('assessmentButtonSection',{read: ElementRef})assessmentSectionRef!:ElementRef;

    public userId: string;
    public memberId: string;
    public memberAtomId: string;
    public memberAssessmentId: string;
    public memberIsDeceased: boolean = false;

    public sectionBenefitsExpanded: boolean = false;
    public sectionDocumentsExpanded: boolean = false;
    public sectionWebsiteExpanded: boolean = false;
    public sectionTasksExpanded: boolean = false;
    public sectionAssessmentExpanded: boolean = false;
    public sectionContactExpanded: boolean = false;
    public sectionCaregiverExpanded: boolean = false;
    public sectionCareplanExpanded: boolean = false;
    public sectionOutreachExpanded: boolean = false;
    public sectionPopulationExpanded: boolean = false;
    public sectionSecureMessagesExpanded: boolean = false;

    public sectionBenefitsOpenMode: boolean = false;
    public sectionDocumentsOpenMode: boolean = false;
    public sectionWebsiteOpenMode: boolean = false;
    public sectionTasksOpenMode: boolean = false;
    public sectionAssessmentOpenMode: boolean = false;
    public sectionContactOpenMode: boolean = false;
    public sectionCaregiverOpenMode: boolean = false;
    public sectionCareplanOpenMode: boolean = false;
    public sectionOutreachOpenMode: boolean = false;
    public sectionPopulationOpenMode: boolean = false;
    public sectionSecureMessagesOpenMode: boolean = false;

    public isReloadButtonClicked: boolean = false;
    public isCarePlanCalledFromContact: boolean = false;
    public assessmentAccordionClose: boolean = true;
    public benefitsSectionIsAvailable: boolean = false;
    public clientId: string;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private readonly viewport: ViewportScroller,
                private confirmationService: ConfirmationService,
                private _changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {

        this.memberId = this.activatedRoute.snapshot.params['memberid'];
        this.memberAssessmentId = this.activatedRoute.snapshot.params['memberassessmentid'];

        let user: string = localStorage.getItem('loggedIn');
        
        if (this.isNotEmpty(user)) {

            let userObject: any = JSON.parse(user);
            this.userId = userObject.id;
        }
    }

    ngAfterViewInit(): void {
        this.headerSectionComponent.getSectionData();
    }

    public setMemberIsDeceased(memberIsDeceased: boolean) {

        this.memberIsDeceased = memberIsDeceased;
    }

    public setHeaderIsLoaded(memberAtomId: string) {
        
        this.memberAtomId = memberAtomId;

        if (this.isNotEmpty(this.memberAssessmentId)){     
         
            if(this.assessmentAccordionClose)
            {
                this.assessmentSectionRef.nativeElement.click();
                this.assessmentAccordionClose=false;
            }
        }
    }  

    public setBenefitsSectionAvailable(benefitsSectionAvailable: boolean){

        this.benefitsSectionIsAvailable = benefitsSectionAvailable;
  
    }

    public getIsBenefitsAvailable() : boolean {
        return this.benefitsSectionIsAvailable;
    }

    
    public finalSubmit() {

        this.confirmationService.confirm({
            header: 'Submit All',
            message: 'Any unsaved changes to the sections will be submitted.<br><br>Please click "OK" to continue.',
            acceptLabel: 'OK',
            rejectLabel: 'Cancel',
            accept: () => {
                                
                if (this.isNotEmpty(this.caregiverSectionComponent) && this.caregiverSectionComponent.isDirty()) {
                    
                    this.caregiverSectionComponent.save();
                } 
                
                if (this.isNotEmpty(this.careplanSectionComponent) && this.careplanSectionComponent.isDirty()) {
                    
                    this.careplanSectionComponent.save();
                } 
                
                if (this.isNotEmpty(this.contactSectionComponent) && this.contactSectionComponent.isDirty()) {
                    
                    this.contactSectionComponent.save();
                } 
                
                if (this.isNotEmpty(this.secureMessageSectionComponent) && this.secureMessageSectionComponent.isDirty()) {
                    
                    this.secureMessageSectionComponent.save();    
                } 
                
                if (this.isNotEmpty(this.taskSectionComponent) && this.taskSectionComponent.isDirty()) {
                    
                    this.taskSectionComponent.save();
                }
            }
        });
    }

    public reloadPage() {

        this.confirmationService.confirm({
            header: 'Reload Member',
            message: 'Any unsaved changes to the sections will be discarded.<br><br>Please click "OK" to continue.',
            acceptLabel: 'OK',
            rejectLabel: 'Cancel',
            accept: () => {
                this.isReloadButtonClicked = true;
                if (this.isNotEmpty(this.headerSectionComponent)) {
                    
                    this.headerSectionComponent.getSectionData();        
                } 

                if (this.isNotEmpty(this.contactSectionComponent) && this.contactSectionComponent.isLoaded()) {
                    
                    this.sectionContactExpanded = false;
                    this.openSection('contact');
                }

                if (this.isNotEmpty(this.caregiverSectionComponent) && this.caregiverSectionComponent.isLoaded()) {
                    
                    this.sectionCaregiverExpanded = false;
                    this.openSection('caregiver');
                }

                if (this.isNotEmpty(this.secureMessageSectionComponent) && this.secureMessageSectionComponent.isLoaded()) {
                    
                    this.sectionSecureMessagesExpanded = false;
                    this.openSection('secureMessage');
                }

                if (this.isNotEmpty(this.populationSectionComponent) && this.populationSectionComponent.isLoaded()) {
                    
                    this.sectionPopulationExpanded = false;
                    this.openSection('population');
                }
                 
                if (this.isNotEmpty(this.careplanSectionComponent) && this.careplanSectionComponent.isLoaded()) {
                    
                    this.sectionCareplanExpanded = false;
                    this.openSection('careplan');
                } 
                
                if (this.isNotEmpty(this.assessmentSectionComponent) && this.assessmentSectionComponent.isLoaded()) {
            
                    this.sectionAssessmentExpanded = false;
                    this.openSection('assessment');
                } 
                                
                if (this.isNotEmpty(this.websiteLinkSectionComponent) && this.websiteLinkSectionComponent.isLoaded()) {
            
                    this.sectionWebsiteExpanded = false;
                    this.openSection('websiteVisits');
                }
                
                if (this.isNotEmpty(this.documentsSectionComponent) && this.documentsSectionComponent.isLoaded()) {
            
                    this.sectionDocumentsExpanded = false;
                    this.openSection('documents');
                }
                
                if (this.isNotEmpty(this.taskSectionComponent) && this.taskSectionComponent.isLoaded()) {
            
                    this.sectionTasksExpanded = false;
                    this.openSection('tasks');
                } 
                
                this.isReloadButtonClicked = false;
            }
        });
    }

    public openSection(section: string) {
        
        let sectionDiv: string = null;
        
        if (section === 'documents') {
            
            this.loadDocuments();
            sectionDiv = "DocumentsSection";

        } else if (section === 'websiteVisits') {
            
            this.loadWebSiteVisit();
            sectionDiv = "WebsitesSection";

        } else if (section === 'tasks') {

            this.loadTaskManagement();
            sectionDiv = "TasksSection";

        } else if (section === 'assessment') {            
            
            this.loadAssesment();
            sectionDiv = "AssessmentsSection";

        } else if (section === 'caregiver') {
            
            this.loadCareGiver();
            sectionDiv = "CaregiversSection";

        } else if (section === 'careplan') {

            this.loadCarePlan();
            sectionDiv = "CareplanSection";

        } else if (section === 'contact') {
            
            this.loadContactInfo();
            sectionDiv = "ContactsSection";

        } else if (section === 'outreach') {   
            
            this.loadOutReach();
            sectionDiv = "OutreachSection";

        } else if (section === 'population') {

            this.loadPopulation();           
            sectionDiv = "PopulationsSection";

        } else if (section === 'secureMessage') {            
            
            this.loadSecureMessage();
            sectionDiv = "SecureMessagesSection";

        }  else if (section === 'benefits') {            
            
            this.loadBenefits();
            sectionDiv = "BenefitsSection";
        }  

        
        this.scrollSection(sectionDiv);
    }

    loadContactInfo() {

        if (!this.sectionContactExpanded) {

            if ((this.isNotEmpty(this.contactSectionComponent) && !this.contactSectionComponent.isLoaded()) || this.isReloadButtonClicked){
                
                this.contactSectionComponent.getSectionData(this.memberIsDeceased);
            }

            if (this.isReloadButtonClicked ) {

                if (this.sectionContactOpenMode == true) {
                    this.sectionContactExpanded = true;
                } else {
                    this.sectionContactExpanded = false;
                }
              
            } else {

                this.sectionContactExpanded = true;
                this.sectionContactOpenMode = true;
            } 
             
        } else {
            this.sectionContactExpanded = false;
            this.sectionContactOpenMode = false;
        }
    }

    loadCareGiver() {

        if (!this.sectionCaregiverExpanded) {
        
            if((this.isNotEmpty(this.caregiverSectionComponent) && !this.caregiverSectionComponent.isLoaded()) || this.isReloadButtonClicked){
                
                this.caregiverSectionComponent.getSectionData();
            }

            if (this.isReloadButtonClicked ) {
                 
                if (this.sectionCaregiverOpenMode == true) {
                     this.sectionCaregiverExpanded = true;
                 } else {
                     this.sectionCaregiverExpanded = false;
                 }
              
             } else {
                
                this.sectionCaregiverExpanded = true;
                this.sectionCaregiverOpenMode = true;
             } 
             
         } else {
            
            this.sectionCaregiverExpanded = false;
            this.sectionCaregiverOpenMode = false;
         }
    }

    loadCarePlan() {

        if (!this.sectionCareplanExpanded) {
        
            if ((this.isNotEmpty(this.careplanSectionComponent) && !this.careplanSectionComponent.isLoaded()) || this.isReloadButtonClicked){
                
                this.isCarePlanCalledFromContact = false;
                this.careplanSectionComponent.getSectionData();
            }

            if (this.isReloadButtonClicked ) {
                 
                if (this.sectionCareplanOpenMode == true) {
                    this.sectionCareplanExpanded = true;
                } else {
                    this.sectionCareplanExpanded = false;
                }
              
             } else {
                
                this.sectionCareplanExpanded = true;
                this.sectionCareplanOpenMode = true;
             } 
             
         } else {
            
            this.sectionCareplanExpanded = false;
            this.sectionCareplanOpenMode = false;
         }
    }

    loadSecureMessage() {

        if (!this.sectionSecureMessagesExpanded) {
        
            if ((this.isNotEmpty(this.secureMessageSectionComponent) && !this.secureMessageSectionComponent.isLoaded()) || this.isReloadButtonClicked){
                
                this.secureMessageSectionComponent.getSectionData();
            }

            if (this.isReloadButtonClicked ) {
                
                if (this.sectionSecureMessagesOpenMode == true) {
                    this.sectionSecureMessagesExpanded = true;
                } else {
                    this.sectionSecureMessagesExpanded = false;
                }
              
             } else {
                
                this.sectionSecureMessagesExpanded = true;
                this.sectionSecureMessagesOpenMode = true;
             } 
             
         } else {
            
            this.sectionSecureMessagesExpanded = false;
            this.sectionSecureMessagesOpenMode = false;
         } 
    }
    
    loadTaskManagement(){
        
        if (!this.sectionTasksExpanded) {
        
            if ((this.isNotEmpty(this.taskSectionComponent) && !this.contactSectionComponent.isLoaded()) || this.isReloadButtonClicked){
            
                this.taskSectionComponent.getSectionData();
            }

            if (this.isReloadButtonClicked ) {
                 
                if (this.sectionTasksOpenMode == true) {
                    this.sectionTasksExpanded = true;
                } else {
                    this.sectionTasksExpanded = false;
                }
              
             } else {
                
                this.sectionTasksExpanded = true;
                this.sectionTasksOpenMode = true;
             } 
             
         } else {
            
            this.sectionTasksExpanded = false;
            this.sectionTasksOpenMode = false;
         }
    }

    loadWebSiteVisit() {

        if (!this.sectionWebsiteExpanded) {
        
            if ((this.isNotEmpty(this.websiteLinkSectionComponent) && !this.websiteLinkSectionComponent.isLoaded()) || this.isReloadButtonClicked){
                
                this.websiteLinkSectionComponent.getSectionData();
            }

            if (this.isReloadButtonClicked ) {
                
                if (this.sectionWebsiteOpenMode == true) {
                    this.sectionWebsiteExpanded = true;
                } else {
                    this.sectionWebsiteExpanded = false;
                }
              
             } else {
                
                this.sectionWebsiteExpanded = true;
                this.sectionWebsiteOpenMode = true;
             } 
             
         } else {
            
            this.sectionWebsiteExpanded = false;
            this.sectionWebsiteOpenMode = false;
         }
    }

    loadDocuments() {

        if (!this.sectionDocumentsExpanded) {
        
            if ((this.isNotEmpty(this.documentsSectionComponent) && !this.documentsSectionComponent.isLoaded()) || this.isReloadButtonClicked){
                
                this.documentsSectionComponent.getSectionData();
            }

            if (this.isReloadButtonClicked ) {
                
                if (this.sectionDocumentsOpenMode == true) {
                    this.sectionDocumentsExpanded = true;
                } else {
                    this.sectionDocumentsExpanded = false;
                }
              
             } else {
                
                this.sectionDocumentsExpanded = true;
                this.sectionDocumentsOpenMode = true;
             } 
             
         } else {
            
            this.sectionDocumentsExpanded = false;
            this.sectionDocumentsOpenMode = false;
         }
    }

    loadBenefits() {

        if (!this.sectionBenefitsExpanded) {
        
            if ((this.isNotEmpty(this.benefitsSectionComponent) && !this.benefitsSectionComponent.isLoaded()) || this.isReloadButtonClicked){
                
                this.benefitsSectionComponent.getSectionData();
            }

            if (this.isReloadButtonClicked ) {
                
                if (this.sectionBenefitsOpenMode == true) {
                    this.sectionBenefitsExpanded = true;
                } else {
                    this.sectionBenefitsExpanded = false;
                }
              
             } else {
                
                this.sectionBenefitsExpanded = true;
                this.sectionBenefitsOpenMode = true;
             } 
             
         } else {
            
            this.sectionBenefitsExpanded = false;
            this.sectionBenefitsOpenMode = false;
         }
    }

    loadAssesment() {

        if (!this.sectionAssessmentExpanded) {
         
            if ((this.isNotEmpty(this.assessmentSectionComponent) && !this.assessmentSectionComponent.isLoaded()) || this.isReloadButtonClicked){
             
                this.assessmentSectionComponent.getSectionData(this.memberIsDeceased);
            }

            if (this.isReloadButtonClicked ) {

                if (this.sectionAssessmentOpenMode == true) {
                    this.sectionAssessmentExpanded = true;
                } else {
                    this.sectionAssessmentExpanded = false;
                }
              
            } else {
                this.sectionAssessmentExpanded = true;
                this.sectionAssessmentOpenMode = true;
            } 
             
        } else {
            this.sectionAssessmentExpanded = false;
            this.sectionAssessmentOpenMode = false;
        }
    }

    loadPopulation() {

        if (!this.sectionPopulationExpanded) {

            if ((this.isNotEmpty(this.populationSectionComponent) && !this.populationSectionComponent.isLoaded()) || this.isReloadButtonClicked){
                this.populationSectionComponent.getSectionData();
            }

            if (this.isReloadButtonClicked ) {
                if (this.sectionPopulationOpenMode == true) {
                    this.sectionPopulationExpanded = true;
                } else {
                    this.sectionPopulationExpanded = false;
                }
              
            } else {
            
                this.sectionPopulationExpanded = true;
                this.sectionPopulationOpenMode = true;
            }
             
        } else {
            
            this.sectionPopulationExpanded = false;
            this.sectionPopulationOpenMode = false;
        } 
    }

    loadOutReach() {

        if (!this.sectionOutreachExpanded) {
        
            if ((this.isNotEmpty(this.outreachSectionComponent) && !this.outreachSectionComponent.isLoaded()) || this.isReloadButtonClicked){
                
                this.outreachSectionComponent.getSectionData();
            }

            if (this.isReloadButtonClicked ) {
                
                if (this.sectionOutreachOpenMode == true) {
                    this.sectionOutreachExpanded = true;
                } else {
                    this.sectionOutreachExpanded = false;
                }
              
             } else {
                this.sectionOutreachExpanded = true;
                this.sectionOutreachOpenMode = true;
             } 
             
        } else {
         
            this.sectionOutreachExpanded = false;
            this.sectionOutreachOpenMode = false;
        } 
    }

    public scrollSection(sectionDiv: string) {

        this._changeDetectorRef.detectChanges();
        
        if (this.isNotEmpty(sectionDiv)) {

            this.viewport.scrollToPosition([0, document.getElementById(sectionDiv).offsetTop]);
        }
    }

    public sectionLoadedEvent(sectionDiv: string) {

        let wait: number = 500;

        if (sectionDiv === "AssessmentsSectionDelayed") {
            wait = 1000;
            sectionDiv = "AssessmentsSection";
        }

        if(this.isCarePlanCalledFromContact && sectionDiv === "CareplanSection" ) {
            setTimeout(() => {

                this.viewport.scrollToPosition([0, document.getElementById("ContactsSection").offsetTop]);
            
            }, wait);
           
        } else {
            setTimeout(() => {

                this.viewport.scrollToPosition([0, document.getElementById(sectionDiv).offsetTop]);
            
            }, wait);
        } 
        if (sectionDiv.toLowerCase()==="contactssection") {
            if(!this.memberAssessmentId) {
            this.headerSectionComponent.getSectionData();
            }
        }
    }

    public memberIsDeceasedEvent(memberIsDeceased: boolean) {

        if (memberIsDeceased) {

            this.memberIsDeceased = true;
            this.isCarePlanCalledFromContact = true;
            this.careplanSectionComponent.getSectionData();
        }
    }

    public onScrollToTop() {
        this.viewport.scrollToPosition([0, 0]);
    }

    public assessmentCompletedEvent($event: any) {
       
        if (this.isNotEmpty(this.careplanSectionComponent)) {
     
            this.careplanSectionComponent.refreshCarePlan();
        }
       
        if (this.isNotEmpty(this.populationSectionComponent)) {

            this.populationSectionComponent.getSectionData();
        }

        if (this.isNotEmpty(this.headerSectionComponent)) {
            
            this.headerSectionComponent.getSectionData();
        }
    }

    public setClientIdEvent(clientId: string){
        this.clientId = clientId;
    }

    ngOnDestroy(): void {
    }
}
