
<div class="row">
    <div style="border: 1px solid #d0d0c8; padding: 0">

        <p-table id="documentsTable" #documentsTable [columns]="tableColumns" [value]="documentsList" 
            (sortFunction)="customSort($event)" [customSort]="true" sortMode="single" sortField="createdDate" [sortOrder]="tableSort" 
            [scrollable]="true" [resizableColumns]="true" [scrollHeight]="getTableHeight()" 
            styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">

            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field"  [style.width]="col.width" style="text-align: center !important">
                        {{col.header}}
                        <p-sortIcon  *ngIf="col.field !== 'action'" [field]="col.field"></p-sortIcon>
                    </th>
                </tr>

                <tr>
                    <th *ngFor="let col of columns" style="flex: inherit" [style.width]="col.width"
                        [ngClass]="{'axis-align-center':(col.align==='center'), 'axis-align-left':(col.align!=='center')}">

                        <input  pInputText type="text" style="width:100%" *ngIf="col.field !== 'action'" (input)="filterTable($event, documentsTable, col.field)">
                        <span *ngIf="col.field === 'action'">&nbsp;</span>
                    </th>
                </tr>  
            </ng-template>

            <ng-template pTemplate="body" let-columns="columns" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" style="flex: inherit" [style.width]="col.width" 
                        [ngClass]="{'table-cell-align-center':(col.align==='center'), 'table-cell-align-left':(col.align!=='center')}">

                        <div *ngIf="col.field !== 'action'" >{{rowData[col.field]}}</div>

                        <div *ngIf="col.field === 'action'">
                            <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-rounded p-button-text" 
                                (click)="downloadDocument(rowData)">
                            </button>
                        </div>

                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td id="noDocumentRecordsFound" [attr.colspan]="getNumColumns()" style="text-align: center !important">
                        {{noRecordsMessage}}
                    </td>
                </tr>
            </ng-template>
            
        </p-table>
    </div>
</div>