import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule }  from 'primeng/dialog';
import { TableModule }   from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SecureMessageSectionComponent } from './secure-message-section.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputTextModule, InputTextareaModule, DialogModule, TableModule, FileUploadModule, DropdownModule, TooltipModule, ScrollPanelModule
  ],
  declarations: [SecureMessageSectionComponent],
  providers: [SecureMessageSectionComponent],
  exports: [SecureMessageSectionComponent]  
})
export class SecureMessageSectionModule {}
