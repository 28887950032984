import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from './app/auth/models/app-config';
import { Constant } from './app/auth/utils/constant';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch('/assets/config.json')
  .then((response) => response.json())
  .then((config: AppConfig) => {
    
    platformBrowserDynamic([
      { provide: Constant.portalApiBaseUrl, useValue: config },
      { provide: Constant.contentServiceApiBaseUrl, useValue: config },
      { provide: Constant.memberManagerApiBaseUrl, useValue: config },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
