import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription, elementAt } from 'rxjs';
import { ConfirmationService, Header } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';

import { AddressDto, ContactInfoDto, PhoneDto, GenericMasterDataType, UserNameDto, StateDto, saveContactInfoData, EmailDto, LanguageDto, TimeZoneDto } from './contact-section.dtos';
import { BaseComponent } from 'src/app/member/common/base.component';
import { ContactSectionService } from './contact-section.service';
import { Constant } from '../.././auth/utils/constant';
import { DatePipe } from '@angular/common';
import { ConversationMessageDto } from '../secure-message-section/secure-message-section.dtos';

export interface DuplicateEmailDto {
    emailAddress: string;
    isPrimary?: boolean;
}
@Component({
    selector: 'contact-section',
    templateUrl: 'contact-section.component.html',
    styleUrls: ['contact-section.component.scss'],
    providers: [ContactSectionService, ConfirmationService]
})
export class ContactSectionComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() memberId: string = null;
    @Output() sectionLoadedEvent = new EventEmitter<string>();
    @Output() memberIsDeceasedEvent = new EventEmitter<boolean>();

    @ViewChild(NgForm) contactInfoForm: NgForm;
    @ViewChild('dtPhone', { static: false }) phoneTable: Table;
    @ViewChild('dtAddress', { static: false }) addressTable: Table;
    @ViewChild('dtEmail', { static: false }) emailTable: Table;

    public saveClicked: boolean = false;
    public memberIsDeceased: boolean = false;
    public isShowSaveButton: boolean = false;
    public display: boolean = false;
    public deceasedFlag: boolean = false;
    public alertMsg: string;
    public contactInfoModel: ContactInfoDto;
    public timeZones: TimeZoneDto[] = [];
    public languages: LanguageDto[] = [];
    public phoneTypes: GenericMasterDataType[] = [];
    public addressTypes: GenericMasterDataType[] = [];
    public states: StateDto[] = [];
    public deleteConfirmMsg: string = Constant.deleteConfirmMsg;
    public sectionIsLoaded: boolean = false;
    public selectedPhonePrimary: any = null;
    public selectedPrimaryAddress: any = null;
    public previousSelectedPrimaryPhone: string;
    public previousSelectedPrimaryAddress: string;
    public previousSelectedPrimaryEmail: string;
    public selectedPrimaryPhoneInitially: string;
    public selectedPrimaryEmailInitially: string;
    public selectedPrimaryAddressInitially: string;
    public noRecordsMessage = "No records found";
    public clonedNames: { [s: string]: UserNameDto; } = {};
    public clonedPhones: { [s: string]: PhoneDto; } = {};
    public clonedAddress: { [s: string]: AddressDto; } = {};
    public selectedPrimaryEmail: any = null;
    public clonedEmails: { [s: string]: EmailDto; } = {};
    public isEmailFieldDisable: boolean = false;

    public showNoTextPhoneWarningDialog: boolean = false;
    public alertMsgTextConsent = Constant.validateMsgForTextConsent;

    private getTimeZonesSubscription: Subscription = null;
    private getLanguagesSubscription: Subscription = null;
    private getPhoneTypesSubscription: Subscription = null;
    private getAddressTypesSubscription: Subscription = null;
    private getStateTypesSubscription: Subscription = null;
    private getMemberDetailsSubscription: Subscription = null;
    private getAddressListSubscription: Subscription = null;
    private getPhoneListSubscription: Subscription = null;
    private deletePhoneSubscription: Subscription = null;
    private deleteAddressSubscription: Subscription = null;
    private saveContactInfoSubscription: Subscription = null;


    private getEmailListSubscription: Subscription = null;
    private deleteEmailSubscription: Subscription = null;

    constructor(private _sectionService: ContactSectionService,
        private confirmationService: ConfirmationService,
        private primengConfig: PrimeNGConfig,
        private datePipe: DatePipe) {

        super();

        this.contactInfoModel = new ContactInfoDto();
        this.contactInfoModel.phoneSection = [];
        this.contactInfoModel.addressSection = [];
        this.contactInfoModel.emailSection = [];

        let userNameDto: UserNameDto = {
            id: "",
            firstName: "",
            lastName: "",
            dateOfBirth: null,
            deceasedDate: null,
            preferredName: "",
            userName: "",
            source: "",
            edit: false,
            textConsent: false,
            preferredLanguageValue: null,
            preferredLanguageText: null,
            policyHolderTimezoneId: null,
            policyHolderTimezoneName: null
        }

        this.contactInfoModel.nameSection = userNameDto;


    }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
    }


    public isDirty(): boolean {

        return this.contactInfoForm.dirty;
    }

    public isLoaded(): boolean {

        return this.sectionIsLoaded;
    }

    public save() {

        this.saveContactInfo(this.contactInfoForm);
    }

    public getSectionData(memberIsDeceased: boolean) {
        this.sectionIsLoaded = true;
        this.memberIsDeceased = memberIsDeceased;

        this.contactInfoForm.statusChanges.subscribe(() => {
            this.isShowSaveButton = this.contactInfoForm.dirty;
        });
        this.getTimeZones();
        this.getLanguages();
        this.getPhoneTypes();
        this.getAddressTypes();
        this.getStates();
        this.getMemberBasicData();
        this.getAddressData();
        this.getPhoneData();
        this.getEmailsData();


    }


    saveContactInfo(form: NgForm) {
        if (this.validateSectionEditMode) {
            if (this.isNotEmpty(this.saveContactInfoSubscription)) {
                this.saveContactInfoSubscription.unsubscribe();
            }
            this.saveClicked = true;
            if (form.valid) {
                if (this.validatePrimaryEmailAddress()) {
                    if(this.validatePrimaryPhone()){
                        if(this.validatePrimaryAddress()){
                    let req = this.createReqData();
                    this.saveContactInfoSubscription = this._sectionService.postContactInfoData(this.memberId, req).subscribe({
                        next: (res) => {
                            if (res) {
                                this.cancelEditRow();
                                this.getMemberBasicData();
                                this.getEmailsData();
                                this.getPhoneData();
                                this.getAddressData();
                                this.saveClicked = false;
                                form.resetForm();
                            }
                        },
                        error: (err: any) => {
                            if (err.error.length > 0) {
                                this.confirmationService.confirm({
                                    message: err.error[0],
                                    header: Constant.addConfirmText,
                                    icon: 'pi pi-info-circle',
                                    acceptLabel: 'OK',
                                    rejectVisible: false,



                                });

                            }
                        }
                    });
                }
            }
        }
        }
        }
    }

    public checkIfTextPhoneExists() {

        let phones: PhoneDto[] = this.contactInfoModel.phoneSection;
        let memberHasTextPhone: boolean = false;

        if (this.isNotEmpty(phones)) {
            for (let phone of phones) {

                if (phone.phoneType === 'Text') {

                    memberHasTextPhone = true;
                    break;
                }
            }
        }

        if (!memberHasTextPhone) {
            this.showNoTextPhoneWarningDialog = true;
        }

    }

    public enableIfTextPhoneExists(): boolean {

        let phones: PhoneDto[] = this.contactInfoModel.phoneSection;
        let disableTextConsentCheckBox: boolean = true;

        if (this.isNotEmpty(phones) && !this.memberIsDeceased) {

            for (let phone of phones) {

                if (phone.phoneType === 'Text') {

                    disableTextConsentCheckBox = false;
                    break;
                }
            }
        }

        return disableTextConsentCheckBox;
    }
    private validatePrimaryAddress(): boolean{
        let isValid = true;
        const isPrimaryAddressSelected: boolean = this.contactInfoModel.addressSection.some(element => element.isPrimary);
        if (!isPrimaryAddressSelected && this.contactInfoModel.nameSection.userName != null) {
            this.display = true;
            this.alertMsg = Constant.validateMsgForPrimaryAddress;
            isValid = false
        }
        const addressess: Array<AddressDto> = [];
        this.contactInfoModel.addressSection.forEach(element => {
            addressess.push(element);
        });

        if (this.IsDuplicatesAddressExistsOnAdd(addressess)) {
            this.display = true;
            this.alertMsg = Constant.validateMsgForDuplicateAddress;
            isValid = false
        }
        if (this.IsDuplicatesAddressExistsOnEdit(addressess)) {
            this.display = true;
            this.alertMsg = Constant.validateMsgForDuplicateAddress;
            isValid = false
        }
        return isValid;
    }
    private validatePrimaryPhone():boolean{
        let isValid = true;
        const isPrimaryPhoneSelected: boolean = this.contactInfoModel.phoneSection.some(element => element.isPrimary);
        if (!isPrimaryPhoneSelected && this.contactInfoModel.nameSection.userName != null) {
            this.display = true;
            this.alertMsg = Constant.validateMsgForPrimaryPhone;
            isValid = false;
        }
        const phones: Array<PhoneDto> = [];
        this.contactInfoModel.phoneSection.forEach(element => {
            element.number = this.unmask(element.number);
            phones.push(element);
        });

        if (this.IsDuplicatesPhoneExistsOnAdd(phones)) {
            this.display = true;
            this.alertMsg = Constant.validateMsgForDuplicatePhone;
            isValid = false
        }
        if (this.IsDuplicatesPhonesExistsOnEdit(phones)) {
            this.display = true;
            this.alertMsg = Constant.validateMsgForDuplicatePhone;
            isValid = false
        }
        return isValid;
    }

    private validatePrimaryEmailAddress(): boolean {
        let isValid = true;        

        const isPrimaryEmailSelected: boolean = this.contactInfoModel.emailSection.some(element => element.isPrimary);
        const isPrimaryPhoneSelected: boolean = this.contactInfoModel.phoneSection.some(element => element.isPrimary);
        const isPrimaryAddressSelected: boolean = this.contactInfoModel.addressSection.some(element => element.isPrimary);

        if (this.contactInfoModel.nameSection.userName == null) {
            if (this.contactInfoModel.nameSection.edit || this.contactInfoModel.phoneSection.some(item => item.edit) || this.contactInfoModel.addressSection.some(item => item.edit) || !this.contactInfoModel.nameSection.edit || this.contactInfoModel.emailSection.some(item => item.edit)) {
                if (this.contactInfoModel.phoneSection.some(item => item.add)) {
                    if (!isPrimaryPhoneSelected) {
                        this.display = true;
                        this.alertMsg = Constant.validateMsgForPrimaryPhone;
                        isValid = false;
                    }
                    else if(!this.contactInfoModel.emailSection.some(item=>item.isPrimary)) {

                        this.display = true;
                        this.alertMsg = Constant.validateMsgForPrimaryEmailForNonRegisteredUser;
                        isValid = true
                    }
                }
                else if (this.contactInfoModel.addressSection.some(item => item.add)) {
                    if (!isPrimaryAddressSelected) {
                        this.display = true;
                        this.alertMsg = Constant.validateMsgForPrimaryAddress;
                        isValid = false
                    }
                    else if(!this.contactInfoModel.emailSection.some(item=>item.isPrimary)) {
                        this.display = true;
                        this.alertMsg = Constant.validateMsgForPrimaryEmailForNonRegisteredUser;
                        isValid = true
                    }
                }
                else if (this.contactInfoModel.emailSection.some(item => item.add)) {
                    if (!isPrimaryEmailSelected) {
                        this.display = true;
                        this.alertMsg = Constant.validateMsgForPrimaryEmail;
                        isValid = false
                    }
                
                }
                else {
                    if(!this.contactInfoModel.emailSection.some(item=>item.isPrimary)){
                    this.display = true;
                    this.alertMsg = Constant.validateMsgForPrimaryEmailForNonRegisteredUser;
                    isValid = true
                    }
                }



            }
            else {
                this.display = true;
                this.alertMsg = Constant.validateMsgForPrimaryEmail;
                isValid = false
            }
        }
            if (!isPrimaryEmailSelected) {
                if (this.contactInfoModel.nameSection.userName != null){
                this.display = true;
                this.alertMsg = Constant.validateMsgForPrimaryEmail;
                isValid = false
                }

            }


        
        const emails: Array<EmailDto> = [];
        this.contactInfoModel.emailSection.forEach(element => {
            emails.push(element);
        });

        if (this.IsDuplicatesExistsOnAdd(emails)) {
            this.display = true;
            this.alertMsg = Constant.validateMsgForDuplicateEmail;
            isValid = false
        }

        if (this.IsDuplicatesExistsOnEdit(emails)) {
            this.display = true;
            this.alertMsg = Constant.validateMsgForDuplicateEmail;
            isValid = false
        }    
        
        return isValid;
    }
    hasDuplicates(arr: string[]): boolean {
        for (let i = 0; i < arr.length; i++) {
            if (arr.includes(arr[i], i + 1)) {
                return true;
            }
        }
        return false;
    }
    private IsDuplicatesExistsOnAdd(emails: Array<EmailDto>): boolean {

        var emailaddresses = emails.filter(element => element.add).map(element => element.emailAddress);
        if (this.hasDuplicates(emailaddresses)) {
            return true;
        }
        var existingemails = emails.filter(element => !element.add).map(e => e.emailAddress);
        var filteredemails = existingemails.filter(e => emailaddresses.indexOf(e) < 0);
        if (existingemails.length === filteredemails.length) {
            return false;
        }
        else {
            return true;
        }
    }
    private IsDuplicatesAddressExistsOnAdd(addresses: Array<AddressDto>): boolean {
        const normalizeAddress = (address: string) => address.trim().toLowerCase();
    
        var address = addresses.filter(element => element.add).map(element => normalizeAddress(element.street1));
        if (this.hasDuplicates(address)) {
            return true;
        }
    
        var existingAddresses = addresses.filter(element => !element.add).map(element => normalizeAddress(element.street1));
        var filteredAddresses = existingAddresses.filter(e => address.indexOf(e) < 0);
        
        if (existingAddresses.length === filteredAddresses.length) {
            return false;
        } else {
            return true;
        }
    }
    private IsDuplicatesAddressExistsOnEdit(addresses: Array<AddressDto>): boolean {
        const normalizeAddress = (address: string) => address.trim().toLowerCase();
        var address = addresses.filter(element => element.edit).map(element => normalizeAddress(element.street1));
        if (this.hasDuplicates(address)) {
            return true;
        }
        var existingadressess = addresses.filter(element => !element.edit).map(element => normalizeAddress(element.street1));
        var filteredaddressess = existingadressess.filter(e => address.indexOf(e) < 0);
        if (existingadressess.length === filteredaddressess.length) {
            return false;
        }
        else {
            return true;
        }
    }
    private IsDuplicatesPhoneExistsOnAdd(phones: Array<PhoneDto>): boolean {
        var phonenumbers = phones.filter(element => element.add).map(element => element.number);
        if (this.hasDuplicates(phonenumbers)) {
            return true;
        }
        var existingphones = phones.filter(element => !element.add).map(e => e.number);
        var filteredphones = existingphones.filter(e => phonenumbers.indexOf(e) < 0);
        if (existingphones.length === filteredphones.length) {
            return false;
        }
        else {
            return true;
        }
    }
    private IsDuplicatesPhonesExistsOnEdit(phones: Array<PhoneDto>): boolean {
     
                var phonenumbers = phones.filter(element => element.edit).map(element => element.number);
                if (this.hasDuplicates(phonenumbers)) {
                    return true;
                }
                var existingphonenumbers = phones.filter(element => !element.edit).map(e => e.number);
                var filteredphonenumbers = existingphonenumbers.filter(e => phonenumbers.indexOf(e) < 0);
                if (existingphonenumbers.length === filteredphonenumbers.length) {
                    return false;
                }
                else {
                    return true;
                }
            }
    private IsDuplicatesExistsOnEdit(emails: Array<EmailDto>): boolean {
                var emailaddresses = emails.filter(element => element.edit).map(element => element.emailAddress);
                if (this.hasDuplicates(emailaddresses)) {
                    return true;
                }
                var existingemails = emails.filter(element => !element.edit).map(e => e.emailAddress);
                var filteredemails = existingemails.filter(e => emailaddresses.indexOf(e) < 0);
                if (existingemails.length === filteredemails.length) {
                    return false;
                }
                else {
                    return true;
                }    
    }

    private cancelEditRow(): void {
        if (this.contactInfoModel.nameSection.edit) {
            delete this.clonedNames[this.contactInfoModel.nameSection.id];
        }
        if (this.contactInfoModel.phoneSection.length > 0) {
            this.contactInfoModel.phoneSection.forEach(element => {
                if (element.edit) {
                    this.phoneTable.editingRowKeys = { [element.phoneId]: false };
                    delete this.clonedPhones[element.phoneId];
                }
            });
        }

        if (this.contactInfoModel.addressSection.length > 0) {
            this.contactInfoModel.addressSection.forEach(element => {
                if (element.edit) {
                    this.addressTable.editingRowKeys = { [element.addressId]: false };
                    delete this.clonedAddress[element.addressId];

                }
            });
        }

        if (this.contactInfoModel.emailSection.length > 0) {
            this.contactInfoModel.emailSection.forEach(element => {
                if (element.edit) {
                    this.emailTable.editingRowKeys = { [element.emailId]: false };
                    delete this.clonedEmails[element.emailId];

                }
            });
        }
    }

    private createReqData(): saveContactInfoData {
        
        const memberAgentPhoneNumbers: Array<PhoneDto> = [];
        const memberAgentAddresses: Array<AddressDto> = [];
        const memberAgentEmails: Array<EmailDto> = [];

        this.contactInfoModel.emailSection.forEach(element => {
            if ((element.add || element.edit)) {
                element.rowSource = element.add ? Constant.AIProwSource : element.rowSource;
                memberAgentEmails.push(element);
            }
            });
        if (memberAgentEmails.length > 0) {
            if (this.selectedPrimaryEmailInitially !== this.previousSelectedPrimaryEmail) {
                const emailRow = memberAgentEmails.find(e => e.emailId === this.selectedPrimaryEmailInitially);
                if (emailRow == null) {
                    const initialEmailRow = this.contactInfoModel.emailSection.find(e => e.emailId === this.selectedPrimaryEmailInitially);
                    if (initialEmailRow) {
                        memberAgentEmails.push(initialEmailRow);
                    }
                }
            }
        }        

        this.contactInfoModel.phoneSection.forEach(element => {
            if (element.add || element.edit) {
                element.isActive = true;
                element.rowSource = element.add ? Constant.AIProwSource : element.rowSource;
                element.number = this.unmask(element.number);
                memberAgentPhoneNumbers.push(element);
            }
        });
        
        if (memberAgentPhoneNumbers.length > 0) {
            if (this.selectedPrimaryPhoneInitially !== this.previousSelectedPrimaryPhone) {
                const phoneRow = memberAgentPhoneNumbers.find(e => e.phoneId === this.selectedPrimaryPhoneInitially);
                if (phoneRow == null) {
                    const initialPhoneRow = this.contactInfoModel.phoneSection.find(e => e.phoneId === this.selectedPrimaryPhoneInitially);
                    if (initialPhoneRow) {
                        initialPhoneRow.number = this.unmask(initialPhoneRow.number);
                        memberAgentPhoneNumbers.push(initialPhoneRow);
                    }
                }
            }
        }

        this.contactInfoModel.addressSection.forEach(element => {
            if (element.add || element.edit) {
                element.isActive = true;
                element.rowSource = element.add ? Constant.AIProwSource : element.rowSource;
                memberAgentAddresses.push(element);
            }
        });

        if (memberAgentAddresses.length > 0) {
            if (this.selectedPrimaryAddressInitially !== this.previousSelectedPrimaryAddress) {
                const addressRow = memberAgentAddresses.find(e => e.addressId === this.selectedPrimaryAddressInitially);
                if (addressRow == null) {
                    const initialAddressRow = this.contactInfoModel.addressSection.find(e => e.addressId === this.selectedPrimaryAddressInitially);
                    if (initialAddressRow) {
                        initialAddressRow.isActive = true;
                        memberAgentAddresses.push(initialAddressRow);
                    }
                }
            }
        }

        let firstName: string = this.contactInfoModel.nameSection.firstName;
        firstName = firstName.endsWith('-') ? firstName.substring(0, firstName.length - 1) : firstName;

        let lastName: string = this.contactInfoModel.nameSection.lastName;
        lastName = lastName.endsWith('-') ? lastName.substring(0, lastName.length - 1) : lastName;

        return {
            memberSource: this.contactInfoModel.nameSection.source,
            memberExternalId: this.memberId,
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: this.contactInfoModel.nameSection.dateOfBirth.toString(),
            deceasedDate: this.contactInfoModel.nameSection.deceasedDate !== null && this.contactInfoModel.nameSection.deceasedDate !== undefined ? this.contactInfoModel.nameSection.deceasedDate.toString() : null,
            preferredName: this.contactInfoModel.nameSection.preferredName,
            memberEmailAddress: this.contactInfoModel.nameSection.userName,
            textConsent: this.contactInfoModel.nameSection.textConsent,
            memberAgentPhoneNumbers: memberAgentPhoneNumbers,
            memberAgentAddresses: memberAgentAddresses,
            MemberAgentEmailAddresses: memberAgentEmails,
            source: Constant.AIProwSource,
            PreferredLanguage: this.contactInfoModel.nameSection.preferredLanguageValue,
            PolicyHolderTimezone: this.contactInfoModel.nameSection.policyHolderTimezoneId
        };
    }

    editUserName(nameSection: UserNameDto) {
        this.clonedNames[nameSection.id] = { ...nameSection };
        this.contactInfoModel.nameSection.edit = true;
    }

    cancelEditUserName(nameSection: UserNameDto) {
        this.contactInfoModel.nameSection = this.clonedNames[nameSection.id];
        this.contactInfoModel.nameSection.edit = false;
        delete this.clonedNames[nameSection.id];
    }

    editPhone(phoneSection: PhoneDto) {
        phoneSection.edit = true;
        phoneSection.extension = phoneSection.extension ? phoneSection.extension : null;
        this.clonedPhones[phoneSection.phoneId] = { ...phoneSection };
    }

    cancelEditPhone(index: number, phoneSection: PhoneDto) {
        this.contactInfoModel.phoneSection[index] = this.clonedPhones[phoneSection.phoneId];
        this.contactInfoModel.phoneSection[index].edit = false;

        if (this.selectedPrimaryPhoneInitially != this.previousSelectedPrimaryPhone) {
            this.setIsPrimaryPhone();
        } else {
            if (this.contactInfoModel.phoneSection[index].isPrimary) {
                this.selectedPhonePrimary = this.contactInfoModel.phoneSection[index];
                this.previousSelectedPrimaryPhone = this.contactInfoModel.phoneSection[index].phoneId;
            }
        }

        delete this.clonedPhones[phoneSection.phoneId];
    }

    setIsPrimaryPhone() {
        this.contactInfoModel.phoneSection.forEach((el: PhoneDto) => {
            if (el.phoneId == this.selectedPrimaryPhoneInitially) {
                el.isPrimary = true;
                this.selectedPhonePrimary = el;
                this.previousSelectedPrimaryPhone = el.phoneId;
            } else {
                el.isPrimary = false;
            }
        });

    }

    editAddress(addressSection: AddressDto) {
        addressSection.edit = true;
        this.clonedAddress[addressSection.addressId] = { ...addressSection };

    }

    cancelEditAddress(index: number, addressSection: AddressDto) {

        this.contactInfoModel.addressSection[index] = this.clonedAddress[addressSection.addressId];
        this.contactInfoModel.addressSection[index].edit = false;

        if (this.selectedPrimaryAddressInitially != this.previousSelectedPrimaryAddress) {
            this.setIsPrimaryAddress();
        } else {
            if (this.contactInfoModel.addressSection[index].isPrimary) {
                this.selectedPrimaryAddress = this.contactInfoModel.addressSection[index];
                this.previousSelectedPrimaryAddress = this.contactInfoModel.addressSection[index].addressId;
            }
        }
        delete this.clonedAddress[addressSection.addressId];
    }

    setIsPrimaryAddress() {
        this.contactInfoModel.addressSection.forEach((el: AddressDto) => {
            if (el.addressId == this.selectedPrimaryAddressInitially) {
                el.isPrimary = true;
                this.selectedPrimaryAddress = el;
                this.previousSelectedPrimaryAddress = el.addressId;
            } else {
                el.isPrimary = false;
            }
        });
    }

    deleteConfirmPhone(phoneSection: PhoneDto) {
        if (phoneSection.phoneId === this.selectedPrimaryPhoneInitially) {
            this.display = true;
            this.alertMsg = Constant.primaryPhoneRecordDeleteMsg;
            return;
        }
        if (this.contactInfoModel.phoneSection.filter(a => a.phoneId).length > 1) {
            this.confirmationService.confirm({
                message: Constant.deleteConfirmMsg,
                header: Constant.deleteConfirmText,
                icon: 'pi pi-info-circle',
                accept: () => {
                    this.deletePhone(phoneSection);
                    if (!this.validateSectionEditMode) {
                        this.isShowSaveButton = false;
                    }

                },
                reject: () => {
                    return;
                }
            });

        } else {
            this.display = true;
            this.alertMsg = Constant.deleteAlertMsgForPhone;
        }
    }

    deletePhone(phoneSection: PhoneDto) {
        if (this.isNotEmpty(this.deletePhoneSubscription)) {
            this.deletePhoneSubscription.unsubscribe();
        }

        this.deletePhoneSubscription = this._sectionService.deletePhone(this.memberId, phoneSection.phoneId).subscribe({
            next: (res) => {
                if (res) {
                    this.getPhoneData();
                }
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    deleteConfirmAddress(addressSection: AddressDto) {
        if (addressSection.addressId === this.selectedPrimaryAddressInitially) {
            this.display = true;
            this.alertMsg = Constant.primaryAddressRecordDeleteMsg;
            return;
        }
        if (this.contactInfoModel.addressSection.filter(a => a.addressId).length > 1) {
            this.confirmationService.confirm({
                message: Constant.deleteConfirmMsg,
                header: Constant.deleteConfirmText,
                icon: 'pi pi-info-circle',
                accept: () => {
                    this.deleteAddress(addressSection);
                    if (!this.validateSectionEditMode) {
                        this.isShowSaveButton = false;
                    }

                },
                reject: () => {
                    return;
                }
            });
        } else {
            this.display = true;
            this.alertMsg = Constant.deleteAlertMsgForAddress;
        }
    }

    validateSectionEditMode(): boolean {
        let isValid = false;
        const isNameSectionEditMode: boolean = this.contactInfoModel.nameSection.edit;
        const isPhoneEditMode: boolean = this.contactInfoModel.phoneSection.some(element => element.edit || element.add);
        const isAddressEditMode: boolean = this.contactInfoModel.addressSection.some(element => element.edit || element.add);
        const isEmailEditMode: boolean = this.contactInfoModel.emailSection.some(element => element.edit || element.add);
        if (isPhoneEditMode || isNameSectionEditMode || isAddressEditMode || isEmailEditMode) {
            isValid = true;
        }
        return isValid;
    }

    deletePhoneRow(index: number) {
        this.contactInfoModel.phoneSection.splice(index, 1);
        this.setIsPrimaryPhone();
    }

    addPhoneRow() {
        if (this.contactInfoModel.phoneSection.length > 0) {
            const lastIndex = this.contactInfoModel.phoneSection.length - 1;
            if (this.contactInfoModel.phoneSection[lastIndex].add === true) {
                if (!this.contactInfoModel.phoneSection[lastIndex].phoneType.trim() ||
                    !this.contactInfoModel.phoneSection[lastIndex].number) {
                    return;
                }
            }
        }
        let phoneSectionDto: PhoneDto = {
            phoneType: "",
            number: null,
            extension: null,
            edit: false,
            add: true,
            rowSource: "",
            isActive: true,
            isPrimary: false,
            notes: null
        }

        this.contactInfoModel.phoneSection.push(phoneSectionDto);
        this.phoneTable.initRowEdit(phoneSectionDto);
        this.saveClicked = false;

    }

    addAddressRow() {
        if (this.contactInfoModel.addressSection.length > 0) {
            const lastIndex = this.contactInfoModel.addressSection.length - 1;
            if (this.contactInfoModel.addressSection[lastIndex].add === true) {
                if (!this.contactInfoModel.addressSection[lastIndex].addressType.trim() ||
                    !this.contactInfoModel.addressSection[lastIndex].street1.trim() ||
                    !this.contactInfoModel.addressSection[lastIndex].city.trim() ||
                    !this.contactInfoModel.addressSection[lastIndex].state.trim() ||
                    !this.contactInfoModel.addressSection[lastIndex].zip) {

                    return;
                }
            }
        }
        let addressSectionDto: AddressDto = {
            addressType: "",
            street1: "",
            street2: "",
            city: "",
            county: "",
            state: "",
            stateName: "",
            zip: null,
            edit: false,
            add: true,
            rowSource: '',
            isActive: true,
            isPrimary: false
        }
        this.contactInfoModel.addressSection.push(addressSectionDto);
        this.addressTable.initRowEdit(addressSectionDto);
        this.saveClicked = false;
    }

    deleteAddressRow(index: number) {
        this.contactInfoModel.addressSection.splice(index, 1);
        this.setIsPrimaryAddress();
    }

    private deleteAddress(addressSection: AddressDto) {
        if (this.isNotEmpty(this.deleteAddressSubscription)) {
            this.deleteAddressSubscription.unsubscribe();
        }

        this.deleteAddressSubscription = this._sectionService.deleteAddress(this.memberId, addressSection.addressId).subscribe({
            next: (res) => {
                if (res) {
                    this.getAddressData();
                }
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getLanguages() {
        if (this.isNotEmpty(this.getLanguagesSubscription)) {
            this.getLanguagesSubscription.unsubscribe();
        }

        this.getLanguagesSubscription = this._sectionService.getLanguageMasterData().subscribe({
            next: (resultData) => {
                this.languages = resultData;
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getTimeZones() {
        this.timeZones = [];
        if (this.isNotEmpty(this.getTimeZonesSubscription)) {
            this.getTimeZonesSubscription.unsubscribe();
        }

        this.getTimeZonesSubscription = this._sectionService.getTimeZoneMasterData().subscribe({
            next: (resultData) => {
                this.timeZones = resultData;
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getPhoneTypes() {
        if (this.isNotEmpty(this.getPhoneTypesSubscription)) {
            this.getPhoneTypesSubscription.unsubscribe();
        }

        this.getPhoneTypesSubscription = this._sectionService.getGenericMasterData(Constant.phoneType).subscribe({
            next: (resultData) => {
                this.phoneTypes = resultData;
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getAddressTypes() {
        if (this.isNotEmpty(this.getAddressTypesSubscription)) {
            this.getAddressTypesSubscription.unsubscribe();
        }

        this.getAddressTypesSubscription = this._sectionService.getGenericMasterData(Constant.addressType).subscribe({
            next: (resultData) => {
                this.addressTypes = resultData;
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getStates() {
        if (this.isNotEmpty(this.getStateTypesSubscription)) {
            this.getStateTypesSubscription.unsubscribe();
        }

        this.getStateTypesSubscription = this._sectionService.getStatesMasterData().subscribe({
            next: (resultData) => {
                this.states = resultData;
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getMemberBasicData() {
        if (this.isNotEmpty(this.getMemberDetailsSubscription)) {
            this.getMemberDetailsSubscription.unsubscribe();
        }

        this.getMemberDetailsSubscription = this._sectionService.getMemberBasicData(this.memberId).subscribe({
            next: (resultData) => {
                this.contactInfoModel.nameSection = resultData;

                if (resultData.deceasedDate != null && !this.deceasedFlag) {
                    this.memberIsDeceasedEvent.emit(true);
                    this.memberIsDeceased = true;
                }

                if (resultData.deceasedDate != null) {
                    this.deceasedFlag = true;
                }



            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getAddressData() {
        if (this.isNotEmpty(this.getAddressListSubscription)) {
            this.getAddressListSubscription.unsubscribe();
        }

        this.getAddressListSubscription = this._sectionService.getAddressData(this.memberId).subscribe({
            next: (resultData) => {
                this.contactInfoModel.addressSection = resultData;
                this.selectedPrimaryAddress = this.contactInfoModel.addressSection.find(e => e.isPrimary);
                if (this.selectedPrimaryAddress) {
                    this.previousSelectedPrimaryAddress = this.selectedPrimaryAddress.addressId;
                    this.selectedPrimaryAddressInitially = this.selectedPrimaryAddress.addressId;
                }
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

    getPhoneData() {

        if (this.isNotEmpty(this.getPhoneListSubscription)) {
            this.getPhoneListSubscription.unsubscribe();
        }

        this.getPhoneListSubscription = this._sectionService.getPhoneData(this.memberId).subscribe({
            next: (resultData) => {
                this.contactInfoModel.phoneSection = resultData;               
                this.selectedPhonePrimary = this.contactInfoModel.phoneSection.find(e => e.isPrimary);
                if (this.selectedPhonePrimary) {
                    this.previousSelectedPrimaryPhone = this.selectedPhonePrimary.phoneId;
                    this.selectedPrimaryPhoneInitially = this.selectedPhonePrimary.phoneId;
                }
                this.sectionLoadedEvent.emit('ContactsSection');
            },
            error: (err: any) => {
                console.error(err);
                this.sectionLoadedEvent.emit('ContactsSection');
            }
        });
    }

    checkConfirmPrimaryPhone(phoneSection: PhoneDto) {    
    if (!phoneSection.isPrimary) {
            this.confirmationService.confirm({
                message: Constant.primaryPhoneUpdateConfirmMsg,
                header: Constant.updateConfirmText,
                icon: 'pi pi-info-circle',
                accept: () => {
                    this.updatePrimaryPhone(phoneSection);
                },
                reject: () => {
                    this.undoPrimaryPhoneChanges();
                    return;
                }
            });

        }
    }    

    updatePrimaryPhone(phoneSection: PhoneDto) {
        this.contactInfoModel.phoneSection.forEach((el: PhoneDto) => {
            if (el.edit && el.phoneId === phoneSection.phoneId) {
                el.isPrimary = true;
                el.isActive = true;
                this.selectedPhonePrimary = el;
                this.previousSelectedPrimaryPhone = el.phoneId;
            } 
            else if (el.add && el.number === phoneSection.number){
                el.isPrimary = true;
                el.isActive = true;
                this.selectedPhonePrimary = el;
                this.previousSelectedPrimaryPhone = el.phoneId;
            }
            else if(el.isPrimary) {
                el.edit = true;
                el.isPrimary = false;
                el.isActive = true;
            }
        });        
    }

    undoPrimaryPhoneChanges() {
        this.contactInfoModel.phoneSection.forEach((el: PhoneDto) => {
            if (el.phoneId === this.previousSelectedPrimaryPhone) {
                el.isPrimary = true;
                this.selectedPhonePrimary = el;
            } else {
                el.isPrimary = false;
            }
        });
    }

    checkConfirmPrimaryAddress(addressSection: AddressDto) {
        if (!addressSection.isPrimary) {
            this.confirmationService.confirm({
                message: Constant.primaryAddressUpdateConfirmMsg,
                header: Constant.updateConfirmText,
                icon: 'pi pi-info-circle',
                accept: () => {
                    this.updatePrimaryAddress(addressSection);
                },
                reject: () => {
                    this.undoPrimaryAddressChanges();
                    return;
                }
            });
        }

    }

    updatePrimaryAddress(addressSection: AddressDto) {
        this.contactInfoModel.addressSection.forEach((el: AddressDto) => {
            if (el.edit && el.addressId === addressSection.addressId) {
                el.isPrimary = true;
                this.selectedPrimaryAddress = el;
                this.previousSelectedPrimaryAddress = el.addressId;
            } 
            else if(el.add && el.street1 === addressSection.street1){
                el.isPrimary = true;
                el.isActive = true;
                this.selectedPrimaryAddress = el;
                this.previousSelectedPrimaryAddress = el.addressId;
            }
            else if(el.isPrimary){
                el.edit = true;
                el.isPrimary = false;
                el.isActive = true;
            }
        });
    }

    undoPrimaryAddressChanges() {
        this.contactInfoModel.addressSection.forEach((el: AddressDto) => {
            if (el.addressId === this.previousSelectedPrimaryAddress) {
                el.isPrimary = true;
                this.selectedPrimaryAddress = el;
            } else {
                el.isPrimary = false;
            }
        });
    }

    public getTableHeight(): string {
        return '650px';
    }

    inputchange(event: any) {
        if (event.target.value) {
            this.isShowSaveButton = true;
        }
    }

    keyPress(event: KeyboardEvent) {
        const pattern = /[0-9]/;
        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }

    getCurrentDate(): string {
        const currentDate = new Date();
        return this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    }

    unmask(val: string) {

        return val ? val.replace(/\D+/g, '') : val;
    }

    getMaskPhoneNumber(rawNum: string) {
        const countryCodeStr = rawNum.slice(0, 3);
        const areaCodeStr = rawNum.slice(3, 6);
        const lastSectionStr = rawNum.slice(6, 10);
        return `(${countryCodeStr}) ${areaCodeStr}-${lastSectionStr}`;
    }

    getEmailsData() {
        if (this.isNotEmpty(this.getEmailListSubscription)) {
            this.getEmailListSubscription.unsubscribe();
        }
        this.getEmailListSubscription = this._sectionService.getEmailsData(this.memberId).subscribe({
            next: (resultData) => {

                if (resultData.length > 0) {
                    this.contactInfoModel.emailSection = resultData;
                    this.selectedPrimaryEmail = this.contactInfoModel.emailSection.find(e => e.isPrimary);
                    if (this.selectedPrimaryEmail) {
                        this.previousSelectedPrimaryEmail = this.selectedPrimaryEmail.emailId;
                        this.selectedPrimaryEmailInitially = this.selectedPrimaryEmail.emailId;
                    }
                } else {
                    this.contactInfoModel.emailSection = [];
                }


                this.sectionLoadedEvent.emit('ContactsSection');
            },
            error: (err: any) => {
                console.error(err);
                this.sectionLoadedEvent.emit('ContactsSection');
            }
        });
    }

    checkConfirmPrimaryEmail(emailSection: EmailDto) {
        if (!emailSection.isPrimary) {
            this.confirmationService.confirm({
                message: Constant.primaryEmailUpdateConfirmMsg,
                header: Constant.updateConfirmText,
                icon: 'pi pi-info-circle',
                accept: () => {
                    this.updatePrimaryEmail(emailSection);
                },
                reject: () => {
                    this.undoPrimaryEmailChanges();
                    return;
                }
            });

        }
    }
    
    
    updatePrimaryEmail(emailSection: EmailDto) {
        this.contactInfoModel.emailSection.forEach((el: EmailDto) => {
            if (el.edit && el.emailId === emailSection.emailId) {
                el.isPrimary = true;
                el.isActive = true;
                this.selectedPrimaryEmail = el;
                this.previousSelectedPrimaryEmail = el.emailId;
            }
            else if (el.add && el.emailAddress === emailSection.emailAddress) {
                el.isPrimary = true;
                el.isActive = true;
                this.selectedPrimaryEmail = el;
                this.previousSelectedPrimaryEmail = el.emailId;
            }
            else {
                el.isPrimary = false;
                el.isActive = false;
            }
        });
    }

    undoPrimaryEmailChanges() {
        this.contactInfoModel.emailSection.forEach((el: EmailDto) => {
            if (el.emailId === this.previousSelectedPrimaryEmail) {
                el.isPrimary = true;
                this.selectedPrimaryEmail = el;
            } else {
                el.isPrimary = false;
            }
        });
    }

    addEmailRow() {
        if (this.contactInfoModel.emailSection.length > 0) {

            const lastIndex = this.contactInfoModel.emailSection.length - 1;
            if (this.contactInfoModel.emailSection[lastIndex].add === true) {
                if (!this.contactInfoModel.emailSection[lastIndex].emailAddress.trim()) {
                    return;
                }
            }
        }
        let emailSectionDto: EmailDto = {
            emailAddress: "",
            edit: false,
            add: true,
            rowSource: Constant.AIProwSource,
            isActive: true,
            isPrimary: false
        }

        this.contactInfoModel.emailSection.push(emailSectionDto);
        this.emailTable.initRowEdit(emailSectionDto);
        this.saveClicked = false;

    }

    deleteEmailRow(index: number) {

        this.contactInfoModel.emailSection.splice(index, 1);
        this.setIsPrimaryEmail();

    }

    editEmail(emailSection: EmailDto) {

        emailSection.edit = true;
        this.clonedEmails[emailSection.emailId] = { ...emailSection };

    }
    onEmailChanged(emailSection: EmailDto) {
        emailSection.isEmailChanged = true;
    }
    cancelEditEmail(index: number, emailSection: EmailDto) {
        this.contactInfoModel.emailSection[index] = this.clonedEmails[emailSection.emailId];
        this.contactInfoModel.emailSection[index].edit = false;
        if (this.selectedPrimaryEmailInitially != this.previousSelectedPrimaryEmail) {
            this.setIsPrimaryEmail();
        } else {
            if (this.contactInfoModel.emailSection[index].isPrimary) {
                this.selectedPrimaryEmail = this.contactInfoModel.emailSection[index];
                this.previousSelectedPrimaryEmail = this.contactInfoModel.emailSection[index].emailId;
            }
        }

        delete this.clonedPhones[emailSection.emailId];
    }

    setIsPrimaryEmail() {
        this.contactInfoModel.emailSection.forEach((el: EmailDto) => {
            if (el.emailId == this.selectedPrimaryEmailInitially) {
                el.isPrimary = true;
                this.selectedPrimaryEmail = el;
                this.previousSelectedPrimaryEmail = el.emailId;
            } else {
                el.isPrimary = false;
            }
        });
    }

    deleteConfirmEmail(emailSection: EmailDto) {

        if (emailSection.emailId === this.selectedPrimaryEmailInitially) {
            this.display = true;
            this.alertMsg = Constant.primaryEmailRecordDeleteMsg;
            return;
        }
        if (this.contactInfoModel.emailSection.filter(a => a.emailId).length > 1) {
            this.confirmationService.confirm({
                message: Constant.deleteConfirmMsg,
                header: Constant.deleteConfirmText,
                icon: 'pi pi-info-circle',
                accept: () => {
                    this.deleteEmail(emailSection);
                    if (!this.validateSectionEditMode) {
                        this.isShowSaveButton = false;
                    }

                },
                reject: () => {
                    return;
                }
            });

        } else {
            this.display = true;
            this.alertMsg = Constant.deleteAlertMsgForPhone;
        }

    }

    deleteEmail(emailSection: EmailDto) {
        if (this.isNotEmpty(this.deleteEmailSubscription)) {
            this.deleteEmailSubscription.unsubscribe();
        }

        this.deleteEmailSubscription = this._sectionService.deleteEmail(this.memberId, emailSection.emailId).subscribe({
            next: (res) => {
                if (res) {
                    this.getEmailsData();
                }
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }


    ngOnDestroy(): void {

        if (this.getTimeZonesSubscription !== null) {
            this.getTimeZonesSubscription.unsubscribe();
        }

        if (this.getLanguagesSubscription !== null) {
            this.getLanguagesSubscription.unsubscribe();
        }

        if (this.getPhoneTypesSubscription !== null) {
            this.getPhoneTypesSubscription.unsubscribe();
        }

        if (this.getAddressTypesSubscription !== null) {
            this.getAddressTypesSubscription.unsubscribe();
        }

        if (this.getStateTypesSubscription !== null) {
            this.getStateTypesSubscription.unsubscribe();
        }

        if (this.getMemberDetailsSubscription !== null) {
            this.getMemberDetailsSubscription.unsubscribe();
        }

        if (this.getPhoneListSubscription !== null) {
            this.getPhoneListSubscription.unsubscribe();
        }

        if (this.getAddressListSubscription !== null) {
            this.getAddressListSubscription.unsubscribe();
        }

        if (this.deletePhoneSubscription !== null) {
            this.deletePhoneSubscription.unsubscribe();
        }

        if (this.deleteAddressSubscription !== null) {
            this.deleteAddressSubscription.unsubscribe();
        }

        if (this.saveContactInfoSubscription !== null) {
            this.saveContactInfoSubscription.unsubscribe();
        }

        if (this.getEmailListSubscription !== null) {
            this.getEmailListSubscription.unsubscribe();
        }

        if (this.deleteEmailSubscription !== null) {
            this.deleteEmailSubscription.unsubscribe();
        }
    }




}
