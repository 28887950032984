import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { PortalUser } from 'src/app/auth/models/portaluser';
import { environment } from 'src/environments/environment';
import { AuthUser } from '../../models/authuser';
import { AppConfig } from '../../models/app-config';
import { Constant } from '../../utils/constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private Auth = 'auth';
  private LoginUrl = 'auth/login';
  private RegUrl = 'auth/register';
  private ResetpassUrl = 'auth/reset-password';
  private ForgotpassUrl = 'Auth/forgot-password';
  private RegistrationVerify = 'Registration/verify';
  private EmailExists = 'Auth/email-exists';
   
  headers: HttpHeaders;
  constructor(private http: HttpClient,@Inject(Constant.portalApiBaseUrl) private appConfig: AppConfig) {
    this.headers = new HttpHeaders()
    .set('UserName', 'Test')
    .set('RoleId', '1');
    environment.apiUrl = appConfig.portalApiBaseUrl; 
  }

  setToken(token: string) {
    localStorage.removeItem('token')
    localStorage.setItem('token',token);
  }
  getToken(){
    return localStorage.getItem('token');
   }
  setLoggedUser(user: PortalUser) {
    localStorage.removeItem('loggedIn')
    localStorage.setItem('loggedIn',JSON.stringify(user));
  }
  getLoggedUser(){
    var data= JSON.parse(localStorage.getItem('loggedIn'));
    return data==null?new PortalUser:data;
   }
   LogOut(){
    localStorage.removeItem('loggedIn')
    return localStorage.getItem('token');
   }


  public Authenticate(code: string): Observable<any> {
    var url=`${environment.apiUrl}/${this.RegistrationVerify+"?code="+code}`;
    return this.http.get(url,{ responseType: 'text' });
  }
  public CheckEmailExist(emailAddress: string): Observable<any> {
    var url=`${environment.apiUrl}/${this.EmailExists+"?email="+emailAddress}`;
    return this.http.get(url,{ responseType: 'text' });
  }
  public UserRegistration(user: AuthUser): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${this.RegUrl}`,user);
  }

  public ValidateUser(user: AuthUser): Observable<string> {
    return this.http.post(`${environment.apiUrl}/${this.LoginUrl}`, user,{ responseType: 'text' });
  }

  public ForgotPassword(user: AuthUser): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${this.ForgotpassUrl}`, user,{ responseType: 'text' });
  }
  public AuthByML(MagicLink: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/${this.Auth}/${MagicLink}`,{ responseType: 'text' });
  }
  public ResetPassword(user: AuthUser): Observable<any> {
    var url=`${environment.apiUrl}/${this.ResetpassUrl+"?userid="+user.userid}`;
    return this.http.post(url,user,{ responseType: 'text' });
  }


}
