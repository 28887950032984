import {  NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CaregiverSectionComponent } from './caregiver-section.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from "primeng/inputtext";
import { CheckboxModule }  from 'primeng/checkbox';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule.forRoot(),
    ConfirmDialogModule,
    ButtonModule,
    DropdownModule,
    InputMaskModule,
    DialogModule,
    TableModule,
    RadioButtonModule,
    TooltipModule,
    InputTextModule,
    CheckboxModule
    
  ],
  declarations: [CaregiverSectionComponent],
  providers: [CaregiverSectionComponent],
  exports: [CaregiverSectionComponent],
  bootstrap:[CaregiverSectionComponent],

})
export class CaregiverSectionModule {}
