import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule }                 from '@angular/common/http';

import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { SharedModule } from 'primeng/api';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { SpinnerModule } from 'primeng/spinner';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';

import { AssessmentWrapperComponent }  from './assessment-wrapper.component';
import { AssessmentCtrlModule } from 'assessment-ctrl';

@NgModule({
  imports: [
    HttpClientModule,

    FormsModule, ReactiveFormsModule, CommonModule, 

    CheckboxModule, RadioButtonModule, MessagesModule, ConfirmDialogModule, CalendarModule, ButtonModule, DropdownModule,
    DialogModule, SharedModule, PanelModule, SpinnerModule, InputTextModule, InputTextareaModule,

    AssessmentCtrlModule
  ],

  declarations: [AssessmentWrapperComponent],
  providers: [AssessmentWrapperComponent],
  exports: [AssessmentWrapperComponent]
})
export class AssessmentWrapperModule { }
