<div id="assessmentSectionBodyDiv">
    
    <ng-container *ngIf="!showAssessmentPanel && !showAssessmentResponsesPanel">

        <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

        <div class="row">

            <div style="padding-bottom: .5em;">
                There are <span style="color: #ff4d4d; font-size: 19px;"> {{assignedCount}} </span> assessments to perform.
                
                <span style="float: right;" *ngIf="isPowerUser">
                    <p-checkbox id="enablePowerUserCheckbox" label="Enable Power User Abilities"  (onChange)="enablePowerUser($event)" [binary]="true"></p-checkbox>
                </span>
            </div>

            <div style="border: 1px solid #d0d0c8; padding: 0">
                
                <p-table id="memberAssessmentHistoryTable" #memberAssessmentHistoryTable [columns]="memberAssessmentHistoryTableColumns"
                    [value]="memberAssessmentHistoryList" (sortFunction)="customSort($event)" [customSort]="true" sortMode="single"
                    sortField="MemberHealthAssessmentStartDate" [sortOrder]="assesmentHistorySort" [scrollable]="true" [resizableColumns]="true"
                    [scrollHeight]="getTableHeight()" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm">

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field"  [style.width]="col.width" 
                                style="text-align: center !important; white-space: break-spaces;">
                            
                                {{col.header}}
                                <p-sortIcon  *ngIf="col.field !== 'action'" [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>

                        <tr>
                            <th *ngFor="let col of columns" style="flex: inherit" [style.width]="col.width"
                                [ngClass]="{'axis-align-center':(col.align==='center'), 'axis-align-left':(col.align!=='center')}">

                                <input  pInputText type="text" style="width:100%" *ngIf="col.field !== 'action'"
                                    (input)="filterTable($event, memberAssessmentHistoryTable, col.field)">
                                <span *ngIf="col.field === 'action'">&nbsp;</span>
                            </th>
                        </tr>  
                    </ng-template>

                    <ng-template pTemplate="body" let-columns="columns" let-rowData>
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns" style="flex: inherit; white-space: break-spaces;" [style.width]="col.width" 
                                [ngClass]="{'table-cell-align-center':(col.align==='center'), 'table-cell-align-left':(col.align!=='center')}">

                                <div *ngIf="col.field !== 'action'" >{{rowData[col.field]}}</div>
                                <div *ngIf="col.field === 'action'" style="display: inline-flex;">
                                    
                                    <div *ngIf="rowData['deletedDate'] !== null && rowData['deletedDate'] !== undefined" style="display: inline-flex;">
                                        Deleted {{rowData['deletedDate']}}
                                    </div>

                                    <div *ngIf="rowData['memberHealthAssessmentStatusDescription'] === 'Completed' && (rowData['deletedDate'] === null || rowData['deletedDate'] === undefined)" 
                                        style="display: inline-flex;">
                                        
                                        <button class="btn button-1" type="button" (click)="loadPrintAssessment(rowData['id'], rowData['healthAssessmentName'])">
                                            Show Assessment Results
                                        </button>
                                    </div>

                                    <div *ngIf="rowData['memberHealthAssessmentStatusDescription'] === 'In Process' && rowData['allowContinue'] === 1 && (rowData['deletedDate'] === null || rowData['deletedDate'] === undefined)" 
                                        style="display: inline-flex;">
                                        
                                        <button class="btn button-1" type="button" (click)="getMemberHealthAssessmentData(rowData['id'], rowData['healthAssessmentName'],false)">
                                            Continue Assessment
                                        </button>
                                    </div>

                                    <div *ngIf="rowData['memberHealthAssessmentStatusDescription'] === 'Assigned' && rowData['allowInUIMode'] !== 'Member' && (rowData['deletedDate'] === null || rowData['deletedDate'] === undefined)" 
                                        style="display: inline-flex;">
                                        
                                        <button class="btn button-2" type="button" (click)="getMemberHealthAssessmentData(rowData['id'], rowData['healthAssessmentName'],false)">
                                            Start Assessment
                                        </button>
                                    </div>

                                    <div *ngIf="powerUserEnabled && (rowData['memberHealthAssessmentStatusDescription'] === 'Assigned' || rowData['memberHealthAssessmentStatusDescription'] === 'In Process') 
                                        && rowData['allowInUIMode'] !== 'Member' && (rowData['deletedDate'] === null || rowData['deletedDate'] === undefined)" style="display: inline-flex; margin-left: .8em;">
                                        
                                        <button pButton pRipple type="button" icon="pi pi-trash" (click)="deleteAssessment(rowData)" class="btn button-1" style="max-height: 2.7em;"></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td id="noMatchingMembersFound" [attr.colspan]="getNumColumns()" style="text-align: center !important">
                            {{noRecordsMessage}}
                            </td>
                        </tr>
                    </ng-template>
                    
                </p-table>
            </div>
        </div>

        <div class="hr-divider"></div>
    </ng-container>

    <div class="row" style="padding-top: .8em;" *ngIf="!showAssessmentResponsesPanel">

        <div class="p-col-12 p-col-top-pad">
            
            <ng-container *ngIf="!showAssessmentPanel && !memberIsDeceased">

                <label for='assessmentDropdown' style="padding-right: .8em">Do Assessment</label>
                <p-dropdown id="assessmentDropdown" [options]="assessmentDefinitions" [(ngModel)]="selectedAssessmentId" (onChange)="setAssessment($event)" 
                    [filter]="true" [style]="{'width':'400px'}" scrollHeight="400px"></p-dropdown>
                
                <span *ngIf="alreadyExistsLabel !== null" style="padding-left:1em; color: red">{{alreadyExistsLabel}}</span>

                <span style="padding-left: .5em" *ngIf="alreadyExistsLabel === null && selectedAssessmentId !== null && selectedAssessmentId !== undefined">
                    <button class="btn button-1" type="button" (click)="loadManualAssessment()">Go</button>
                </span>

                <span style="padding-left: .5em" *ngIf="alreadyExistsLabel === null && selectedAssessmentId !== null && selectedAssessmentId !== undefined">

                    <p-overlayPanel #assignSelector [showCloseIcon]="true" [style]="{width: '417px'}">
                        <ng-template pTemplate>
                            Select the assignment date for the assessment.

                            <p-calendar [inline]="true" [showWeek]="true" [minDate]="minAssignDate" (onSelect)="assignAssessment($event)"></p-calendar>
                        </ng-template>
                    </p-overlayPanel>
                    
                    <button class="btn button-1" type="button" (click)="assignSelector.toggle($event)">Assign</button>
                </span>

                <span *ngIf="showAssessmentPanel" style="float: right;">
                    <button class="btn button-1  me-md-2" type="button" (click)="closeAssessmentPanel()">X</button>
                </span>
            </ng-container>

            <ng-container *ngIf="showAssessmentPanel">
                
                <span *ngIf="showAssessmentPanel">
                    <label for='assessmentDropdown' style="padding-right: .8em">Assessment</label>
                    <span style="padding-left: .2em">{{selectedAssessmentName}}</span>
                </span>
                
                <span style="float: right;" *ngIf="btnCloseLabelAssesmentComplete == 'X'">
                    <button class="btn button-1 me-md-2" type="button" (click)="closeAssessmentPanel()">{{btnCloseLabelAssesmentComplete}}</button>
                </span>
            </ng-container>
        </div>
    </div>

    <div class="row" *ngIf="showAssessmentPanel">
        <assessment-ctrl
            (assessmentCompletedEvent) = "assessmentWrapperCompletedEvent($event)"
            (redirectToPrintViewEvent) = "redirectToPrintView($event)">
        </assessment-ctrl>
    </div>

    <div class="row" style="padding-top: .8em; text-align: center;" *ngIf="!showAssessmentResponsesPanel && btnCloseLabelAssesmentComplete == 'Finish' ">
        <ng-container *ngIf="showAssessmentPanel">
            <span style="float: center;">
                <button class="btn button-1 me-md-2" type="button" (click)="closeAssessmentPanel()">{{btnCloseLabelAssesmentComplete}}</button>
            </span>
        </ng-container>
    </div>

    <div class="row" *ngIf="showAssessmentResponsesPanel">
      
        <div class="p-col-12" style="padding-top:0">
            <span style="float: right;">
                <button class="btn button-1" type="button" (click)="closeAssessmentPanel()">X</button>
            </span>
        </div>

        <assessment-print></assessment-print>
    </div>
</div>
