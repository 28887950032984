<div>
    <div class="p-grid">

        <div class="p-col-12" id="assessmentInitDiv" *ngIf="initializingAssessment">
            <div class="assessment-wrapper">
                Initializing assessment...
            </div>
        </div>

        <div class="p-col-12" id="assessmentTesterDiv" *ngIf="showAssessment">
            <div class="assessment-wrapper">

                <assmnt-assessment-ctrl style="width: 100%;"
                
                    [webserviceUrlBase]="assessmentEnvironmentUrl" 
                    [memberId]="memberAtomId" 
                    [language]="language"                    
                    [assessmentAlgorithmId]="assessmentAlgorithmId" 
                    [assessmentName]="assessmentName" 
                    [assessmentStartPrepop]="assessmentStartData"
                    [assessmentCompletedLabel]="assessmentCompletedLabel"
                    [e24TraversalId]="e24TraversalId"
                    [e24MemberId] = "e24MemberId"
        
                    (assessmentErrorOccurred)="assessmentErrorOccurred($event)"
                    (assessmentIsComplete)="assessmentIsComplete($event)"
                    (assessmentTraversalIdSet)="assessmentTraversalIdSet($event)">
        
                </assmnt-assessment-ctrl>

            </div>
        </div>

        <div class="p-col-12" *ngIf="assessmentError !== null">

            <div class="assessment-error-message">{{assessmentError}}</div> 
        </div>
    </div>
</div>
