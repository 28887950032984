
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { MemberAssessmentHistoryDto, BasicAssessmentDefinitionDto, PowerUserDto } from './assessment-section.dtos';
import { AssessmentDto } from './assessment-wrapper/assessment-wrapper.dtos';

@Injectable()
export class AssessmentSectionService {

    private getPowerUsersUrl: string = '/MemberHealthAssessment/GetPowerUsers';
    private deleteAssessment: string = '/MemberHealthAssessment/{id}';
    private getAssessmentDefinitionsUrl: string = '/AssessmentDefinition?includeDeactived=true';
    private getAssessmentHistoryUrl: string = '/MemberHealthAssessmentSummary/{memberId}';
    private getMemberHealthAssessmentInfoUrl: string = '/MemberHealthAssessment/GetMemberHealthAssessmentById?id={id}';    

    private headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {

        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');

        environment.contentServiceApiBaseUrl = _appConfig.contentServiceApiBaseUrl;
    }

    public getMemberHealthAssessmentData(memberHealthAssessmentId: string): Observable<AssessmentDto> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getMemberHealthAssessmentInfoUrl).replace('{id}', memberHealthAssessmentId);

        return this._http.get<AssessmentDto>(url, {headers: this.headers});
    }

    public getAssessmentHistory(memberId: string):Observable<MemberAssessmentHistoryDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getAssessmentHistoryUrl)
            .replace('{memberId}', memberId);

        return this._http.get<MemberAssessmentHistoryDto[]>(url, {headers: this.headers});
    }

    public getAssessmentDefinitions(): Observable<BasicAssessmentDefinitionDto[]> {

        let url = this._appConfig.contentServiceApiBaseUrl + this.getAssessmentDefinitionsUrl;

        return this._http.get<BasicAssessmentDefinitionDto[]>(url, {headers: this.headers});
    }

    public getPowerUsers(): Observable<PowerUserDto[]> {

        let url = this._appConfig.memberManagerApiBaseUrl + this.getPowerUsersUrl;

        return this._http.get<PowerUserDto[]>(url, {headers: this.headers});
    }

    public deleteMemberHealthAssessment(memberHealthAssessmentId: string) {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.deleteAssessment).replace('{id}', memberHealthAssessmentId);

        return this._http.delete(url, {headers: this.headers, responseType: 'text'});
    }
}