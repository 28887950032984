
export class SampleDto {
    assessmentName: string;
    dateCreated: string;
    actualAssessmentDate: string;
    deliveryMode: string;
    createdBy: string;
    status: string;
    dateCompletedBy: string;
    completedBy: string;
    description: string;
    assessmentId: string;
    contentUId: string;
    assesmentType: string;
    assessmentContentType: string;
    assessmentAlgorithmId: string;
    allowAction?: boolean = false;
    programDescription: string;
}


export class MemberPopulationDetailsDto {
    MemberPopulationDetailsSection: Array<MemberPopulationDetails>;
    PopulationSection : Array<PopulationDetails>;
}
export class MemberPopulationDetails {
    populationAssigned: string;
    externalMemberPopulationId :string;
    populationId : string;
    externalPopulationID : string;
    reason : string
    status: string;
    source:string;
    startDate: string; 
    endDate: string; 
    memberPopulationId :string;      
    edit: boolean = false;
    add: boolean = false;
}

export class PopulationDetails
{
id:string;
name :string;
description : string;
}


export class UpdateMemberPopulationStatus {
    memberPopulationId :string;      
    populationId : string;
}

export class DeleteDto {    
    status: string;  
}


  export class SavePopulationDetails {
    memberPopulationId :string;
    externalMemberPopulationId :string;
    populationId : string;
    externalPopulationID : string;
    reason : string
    status: string;
    source:string;
    startDate: string; 
    endDate: string; 

}
