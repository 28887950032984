import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortalUser } from 'src/app/auth/models/portaluser';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { SignupService } from 'src/app/auth/services/signup/signup.service';

@Component({
  selector: 'app-sign-up-userdetails',
  templateUrl: './sign-up-userdetails.component.html',
  styleUrls: ['./sign-up-userdetails.component.scss']
})
export class SignUpUserdetailsComponent implements OnInit {
  passType: string = "password";
  confPassType: string = "password";
  isTextPass: boolean = false;
  isTextConfPass: boolean = false;
  pasEyeIcon: string = "fa-eye-slash";
  confPassEyeIcon: string = "fa-eye-slash";
  portalUser:PortalUser=new PortalUser();
  isError:boolean=false;
  error:any;
  constructor(private authService:AuthService,private router: Router,private signupService:SignupService) { 
     
  }

  ngOnInit(): void {
    debugger;
    this.isError=false;
    this.portalUser=this.signupService.getPortalUser();
    // if(this.portalUser.code=="")
    //     this.router.navigate(['sign-up']);
  }

  onSubmit(){
    debugger
    this.authService.CheckEmailExist(this.portalUser.emailAddress).subscribe({
      next: (v) => {
        debugger;
            if (v=="false") {
              this.signupService.setPortalUser(this.portalUser);
              this.router.navigate(['sign-up-domain-specific']);
            }
            else {
              this.isError=true;
              this.error="Email already exist";
            }
          },
          error: (e) => {
            this.isError=true;
            this.error="Email already exist";
          }
      })

  }

  
  hideShowPass(): void {
    this.isTextPass = !this.isTextPass;
    this.pasEyeIcon = this.isTextPass ? "fa-eye" : "fa-eye-slash";
    this.passType = this.isTextPass ? "text" : "password";
  }

  hideShowConfPass(): void {
    this.isTextConfPass = !this.isTextConfPass;
    this.confPassEyeIcon = this.isTextConfPass ? "fa-eye" : "fa-eye-slash";
    this.confPassType = this.isTextConfPass ? "text" : "password";
  }
 
}
