<section class="vh-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9 col-lg-6 px-0 col-xl-7">
                <div class="image">
                  <img src="../assets/images/background_Image_5.png" alt="Login image" class="w-100 vh-100"
                    style="object-fit: cover; object-position: left;">
                  <div class="hero-section">
                    <p class="welcome text-center">Welcome<p>
                  </div>
                  <div class="hero-info">
                    <p> to the Agent Portal </p>
                  </div>
                </div>
             </div>
              <div class="login-details col-md-8 col-lg-6 col-xl-5 mt-4">
                        
                            <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm"
                                [appMatchPassword]="['password', 'confirmPassword']" novalidate>
                                <div class="lead d-flex flex-row align-items-center justify-content-center text-center ">
                                    <p class="mb-2 me-3 ">Reset Password</p>
                                    <h5 style="color: rgb(14, 124, 4);">{{portalUser.emailAddress}}</h5>
                                  </div>
                                    <div class="text-center" *ngIf="isError">
                                        <h6 style="color: red" class="error">
                                          {{Error}}
                                        </h6> 
                                      </div>
                                <!-- <div class="form-group pt-3">
                                    <div class="input-field">
                                        <span class="fa fa-user p-2"></span>
                                        <input type="email" name="emailAddress" placeholder="hello@example.com"
                                            [(ngModel)]="user.emailAddress" #email="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email>
                                        <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                            <div *ngIf="email.errors?.['required']">Email is required</div>
                                            <div *ngIf="email.errors?.['email']">Email must be a valid email address</div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-outline text-center mb-1">
                                    <img src="../../assets/images/shield.png" class="icons" alt=""> 
                                    <span (click)="hideShowPass()" class="eyeicons fa {{pasEyeIcon}} fa-lg"></span>
                                    <input type="{{passType}}" name="password" class="form-control form-control-lg"
                                        [(ngModel)]="user.password" #password="ngModel"
                                        [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required
                                        minlength="6">
                                    <app-password-strength [passwordToCheck]="f.value.password"></app-password-strength>
                                    <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                                        <div *ngIf="password.errors?.['required']">Password is required</div>
                                        <div *ngIf="password.errors?.['minlength']">Password must be at least 6 characters
                                        </div>
                                    </div>
                                </div>
                                <div class="form-outline text-center mb-1">
                                    <img src="../../assets/images/shield.png" class="icons" alt=""> 
                                    <span (click)="hideShowConfPass()" class="eyeicons fa {{confPassEyeIcon}}  fa-lg"></span>
                                    <input type="{{confPassType}}" name="confirmPassword"
                                        class="form-control form-control-lg" [(ngModel)]="user.confirmPassword"
                                        #confirmPassword="ngModel"
                                        [ngClass]="{ 'is-invalid': f.submitted && confirmPassword.invalid }" required
                                        minlength="1">
                                    <div *ngIf="f.submitted && confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="confirmPassword.errors?.['required']">
                                            Confirm Password is required
                                        </div>
                                        <div *ngIf="confirmPassword.errors?.['matching']">
                                            Confirm Password does not match
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center pt-1 mt-4 mb-4 pb-1">
                                    <button type="submit" class="btn btn-lg btn-block log-in">Reset</button>
                                </div>
    
                            </form>
              
              </div>
         
        </div>
    </div>
</section>