
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { AuthService } from 'src/app/auth/services/auth/auth.service';

import { CareGoalTextDto, CarePlanGoalDto, SaveCarePlanGoalsDto } from './careplan-section.dtos';

@Injectable()
export class CareplanSectionService {

    private getCarePlanGoalsUrl: string = '/MemberCarePlan/GetMemberCarePlanGoals?memberId={memberId}';
    private getCareGoalTextUrl: string = '/MemberCarePlan/GetMemberCareGoalText?memberId={memberId}';
    private persistCareGoalTextUrl: string = '/MemberCarePlan/UpdateMemberCareGoal';
    private persistCarePlanGoalsUrl: string = '/MemberCarePlan/PersistMemberCarePlanGoals';

    private headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {

        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');
    }

    public getCarePlanData(memberId: string): Observable<CarePlanGoalDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getCarePlanGoalsUrl).replace('{memberId}', memberId);

        return this._http.get<CarePlanGoalDto[]>(url, {headers: this.headers});
    }

    public getCareGoalText(memberId: string): Observable<CareGoalTextDto> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getCareGoalTextUrl).replace('{memberId}', memberId);

        return this._http.get<CareGoalTextDto>(url, {headers: this.headers});
    }

    public persistCareGoalText(careGoalTextDto: CareGoalTextDto): Observable<any> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + this.persistCareGoalTextUrl);

        return this._http.post<any>(url, careGoalTextDto, {headers: this.headers});
    }

    public persistCarePlanGoals(goals: SaveCarePlanGoalsDto): Observable<any> {
        
        let url = (this._appConfig.memberManagerApiBaseUrl + this.persistCarePlanGoalsUrl);

        return this._http.post<any>(url, goals, {headers: this.headers});
    }
}