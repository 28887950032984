import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmationService, Message } from 'primeng/api';

import { BaseComponent } from 'src/app/member/common/base.component';
import { AssessmentPrintService } from './assessment-print.service';
import { AssessmentResponseDto } from './assessment-print.dtos';

@Component({
    selector: 'assessment-print',
    templateUrl: './assessment-print.component.html',
    styleUrls: ['assessment-print.component.css'],
    providers: [AssessmentPrintService, ConfirmationService]
})
export class AssessmentPrintComponent extends BaseComponent implements OnInit, OnDestroy {

    public loading: boolean = false;
    public memberHealthAssessmentId: string;
    public assessmentName: string;
    public assessmentResponses: AssessmentResponseDto[] = [];
    private tabTitleLabel: string = 'Print Tab';
    public showNoResponsesMessage: boolean = false;
    public noResponsesMessage: string = 'There are no recorded questions and responses for this assessment.';

    private getAssessmentDataSubscription: Subscription = null;

    constructor(private _apAssessmentService: AssessmentPrintService) {

        super();
    }

    public ngOnInit() {
    }

    public print() {

        let printContents = document.getElementById('assessmentPrintBody_' + this.memberHealthAssessmentId).innerHTML;

        let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write('<html><head><title>' + this.tabTitleLabel + '</title></head><body onload="window.print();window.close()">${printContents}</body></html>');
        popupWin.document.close();
    }

    public loadAssessmentPrint(memberHealthAssessmentId: string, assessmentName: string) {

        this.assessmentName = assessmentName;
        this.memberHealthAssessmentId = memberHealthAssessmentId;

        this.loading = true;
        this.showNoResponsesMessage = false;

        if (this.getAssessmentDataSubscription !== null) {
            this.getAssessmentDataSubscription.unsubscribe();
        }

        this.getAssessmentDataSubscription = this._apAssessmentService.getAssessmentResponses(memberHealthAssessmentId).subscribe({
        
            next: (responses) => {

                this.assessmentResponses = responses;

                if (this.isNotEmpty(responses) && responses.length === 0) {
                    this.showNoResponsesMessage = true;
                } else {
                    this.assessmentResponses.forEach(question => {
                        question.questionText = question.questionText.replace(/(?:\r\n|\r|\n)/g, '<br />');
                    });
                }
                
                this.loading = false;
            },
            error: (err: any) => {
                this.loading = false; 
                console.error(err);
            }
        });
    }
    
    public getScrollPanelHeight(scrollPanel: any): string {

        return (window.innerHeight - 220) + 'px';
    }

    public ngOnDestroy() {

        if (this.getAssessmentDataSubscription !== null) {
            this.getAssessmentDataSubscription.unsubscribe();
        }
    }
}
