
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/auth/models/app-config';
import { Constant } from 'src/app/auth/utils/constant';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { PlanDto, BenefitGroupDto, PlanBenefitsDto, LocalResourceDto, MemberAddressDto } from './benefits-section.dtos';

@Injectable()
export class BenefitsSectionService {

    private getPlanListByZip: string = '/Plans/{zipCode}';
    private getBenefitGroupList: string = '/BenefitGroups?Ordered=ASC';
    private getPlanBenefitsList: string = '/PlanBenefits/{zipCode}?planids={plans}&keyword={keyword}&order=ASC&pagenumber=1&pagesize=50';
    private getLocalResoucesListByZip = '/LocalResource/{zipCode}';
    private getZipCodeByMemberId = '/MemberAddress/MemberAddressDetails?MemberId={memberId}';

    private headers: HttpHeaders;

    constructor(private _http: HttpClient, private _authService: AuthService,
        @Inject(Constant.contentServiceApiBaseUrl) private _appConfig: AppConfig) {
        let userName: string = 'NotProvided';
        
        if (this._authService !== null && this._authService !== undefined 
            && this._authService.getLoggedUser() !== null && this._authService.getLoggedUser() !== undefined
            && this._authService.getLoggedUser().id !== null && this._authService.getLoggedUser().id !== undefined) {

            userName = this._authService.getLoggedUser().id;
        }

        this.headers = new HttpHeaders()
            .set('UserName', userName)
            .set('RoleId', '1');

        environment.contentServiceApiBaseUrl = _appConfig.contentServiceApiBaseUrl;
    }
  
    public getPlansByZipCode(zipCode: string): Observable<PlanDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getPlanListByZip)
            .replace('{zipCode}', zipCode);

        return this._http.get<PlanDto[]>(url, {headers: this.headers});
    }

    public getBenefitGroups(): Observable<BenefitGroupDto[]> {

        let url = this._appConfig.memberManagerApiBaseUrl + this.getBenefitGroupList;

        return this._http.get<BenefitGroupDto[]>(url, {headers: this.headers});
    }

    public getPlanBenefits(planZipCode: string, plans: string, keyword: string, parameters: string): Observable<PlanBenefitsDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getPlanBenefitsList)
        .replace('{zipCode}', planZipCode).replace('{plans}', plans).replace('{keyword}', keyword)
        .replace('pagenumber=1&pagesize=50', parameters);

        return this._http.get<PlanBenefitsDto[]>(url, {headers: this.headers});
    }

    public getLocalResoucesByZipCode(zipCode: string): Observable<LocalResourceDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getLocalResoucesListByZip)
            .replace('{zipCode}', zipCode);

        return this._http.get<LocalResourceDto[]>(url, {headers: this.headers});

    }

    public getAddressListByMemberId(memberId: string): Observable<MemberAddressDto[]> {

        let url = (this._appConfig.memberManagerApiBaseUrl + this.getZipCodeByMemberId)
            .replace('{memberId}', memberId);

        return this._http.get<MemberAddressDto[]>(url, {headers: this.headers});

    }


    
}
