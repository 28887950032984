import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from '././auth/components/login/login.component';
import { SignUpComponent } from '././auth/components/sign-up/sign-up.component';
import { SignUpUserdetailsComponent } from '././auth/components/sign-up-userdetails/sign-up-userdetails.component';
import { SignUpDomainSpecificComponent } from '././auth/components/sign-up-domain-specific/sign-up-domain-specific.component';
import { SignUpAcknowledgementComponent } from '././auth/components/sign-up-acknowledgement/sign-up-acknowledgement.component';
import { SignUpForgotPasswordComponent } from '././auth/components/sign-up-forgot-password/sign-up-forgot-password.component';
import { ResetComponent } from '././auth/components/reset/reset.component';
import { MagiclinkComponent } from '././auth/components/magiclink/magiclink.component';
import { EditPortalUserComponent } from '././auth/components/edit-portaluser/edit-portaluser.component';
import { HomeComponent } from '././auth/components/home/home.component';
import { MatchPasswordDirective } from '././auth/utils/directives/password-pattern.directive';
import { SpinnerComponent } from '././auth/components/spinner/spinner.component';
import { LoadingInterceptor } from '././auth/utils/Interceptor/loader/loading.interceptor';
import { PasswordStrengthComponent } from './auth/components/password-strength/password-strength.component';
import { TokenInterceptor } from '././auth/utils/Interceptor/token/token.interceptor';
import { NotFoundComponent } from './auth/components/not-found/not-found.component';

import { MemberPageComponent } from './member/member-page/member-page.component';
import { ContactSectionModule } from './member/contact-section/contact-section.module';
import { AssessmentSectionModule } from './member/assessment-section/assessment-section.module';
import { CaregiverSectionModule } from './member/caregiver-section/caregiver-section.module';
import { CareplanSectionModule } from './member/careplan-section/careplan-section.module';
import { HeaderSectionModule } from './member/header-section/header-section.module';
import { OutreachSectionModule } from './member/outreach-section/outreach-section.module';
import { PopulationSectionModule } from './member/population-section/population-section.module';
import { SecureMessageSectionModule } from './member/secure-message-section/secure-message-section.module';
import { WebsiteVisitSectionModule } from './member/website-visit-section/website-visit-section.module';
import { DocumentsSectionModule } from './member/documents-section/documents-section.module';
import { TaskSectionModule } from './member/tasks-section/task-section.module';
import { BenefitsSectionModule } from './member/benefits-section/benefits-section.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    SignUpUserdetailsComponent,
    SignUpDomainSpecificComponent,
    SignUpAcknowledgementComponent,
    ResetComponent,
    MagiclinkComponent,
    EditPortalUserComponent,
    HomeComponent,
    MatchPasswordDirective,
    SignUpForgotPasswordComponent,
    SpinnerComponent,
    PasswordStrengthComponent,
    NotFoundComponent,
    MemberPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DatePipe,
    ToastrModule.forRoot({
      //timeOut: 15000, // 15 seconds
      closeButton: true,
      progressBar: true,
    }),
    AutocompleteLibModule,
    ConfirmDialogModule,
    ContactSectionModule, AssessmentSectionModule, CaregiverSectionModule, CareplanSectionModule, HeaderSectionModule, DocumentsSectionModule,
    OutreachSectionModule, PopulationSectionModule, SecureMessageSectionModule, WebsiteVisitSectionModule, TaskSectionModule, BenefitsSectionModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    },
    { 
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true 
    },
    ConfirmationService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
